<!--
@Author: Florian Merel <florian>
@Date:   30-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: NavRows.vue
@Last modified by:   florian
@Last modified time: 09-Jul-2021
-->

<template>
  <div
    class="md-layout-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <item-tickets :item="item"></item-tickets>
    <div
      class="md-layout-item md-small-size-100 md-size-100"
      style=""
      v-if="radio"
    >
      <select-statut v-model="statutSelect"></select-statut>
    </div>

    <div class="md-layout" v-if="radio || (comment && comment.length > 0)">
      <div class="md-layout-item md-small-size-100 md-size-100" style="">
        <div class="colorTextProkov">Description interne :</div>
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-100"
        style="margin-top: -20px"
      >
        <md-field>
          <md-textarea v-model="comment" :disabled="!radio"></md-textarea>
        </md-field>
      </div>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="radio || (badges && badges.length > 0)"
      style="margin-top: 0px"
    >
      <div class="colorTextProkov">Badges :</div>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      style="margin-top: -15px"
      v-if="radio || (badges && badges.length > 0)"
    >
      <div v-if="!radio">
        <md-chip
          class=""
          style="margin-top: 20px"
          v-for="chip in badges"
          :key="chip"
          >{{ chip }}
        </md-chip>
      </div>

      <div v-if="radio">
        <div
          v-if="badgesCorrespondance.length > 0"
          style="margin-left: 60px; margin-top: -9px"
        >
          <md-chip
            class=""
            v-for="chip in badgesCorrespondance"
            :key="chip"
            @click="clickChips(chip)"
            md-clickable
            >{{ chip }}</md-chip
          >
        </div>

        <md-chips
          v-model="badges"
          :md-format="updateBadge"
          md-placeholder="..."
        ></md-chips>
      </div>
    </div>

    <div
      v-if="activity.length > 0"
      class="md-layout-item md-small-size-100 md-size-100"
      style="margin-top: 10px"
    >
      <div class="colorTextProkov">Activité :</div>
      <activity-card
        style="margin-top: 12px"
        :activity="activity"
      ></activity-card>
    </div>

    <div
      v-if="actif && radio"
      class="md-layout-item md-size-100 text-right"
      style="margin-top: -20px"
    >
      <md-button class="md-raised md-prokov" @click="sendValue()"
        >Enregistrer</md-button
      >
    </div>

    <!--<md-avatar
      v-if="item.containsMessages"
      class="md-avatar-icon md-small badgeMessage md-info"
      style=""
    >
      <md-icon
        >message
        <md-tooltip md-direction="top"
          >Ce ticket contient des messages</md-tooltip
        >
      </md-icon>
    </md-avatar>-->

    <!--<md-avatar class="md-avatar-icon md-small badgeNews md-warning" style="">
      <md-icon>mark_email_unread</md-icon>
    </md-avatar>-->

    <div v-if="item.interet">
      <md-chip
        v-if="item.interet == 11"
        class="md-accent rightTopLeftAssistance"
      >
        <md-tooltip md-direction="top">Ticket crée par l'assitance</md-tooltip>
      </md-chip>
      <md-chip v-if="item.interet == 10" class="md-info rightTopLeftAssistance">
        <md-tooltip md-direction="top">Ticket créé en interne</md-tooltip>
      </md-chip>
    </div>

    <div v-if="item.files > 0" class="numberAttachement">
      <span>{{ item.files }}</span
      ><md-icon>attach_file</md-icon>
    </div>

    <div v-if="hover">
      <md-button
        class="md-button md-round md-just-icon md-info rightTopLeft"
        @click="$refs.diacommunication.open()"
      >
        <md-icon>visibility</md-icon>
      </md-button>

      <md-button
        v-if="radio"
        class="md-button md-round md-just-icon md-prokov rightTop"
        @click="editValue()"
      >
        <md-icon>edit</md-icon>
      </md-button>

      <md-button
        v-if="!radio"
        class="md-button md-round md-just-icon md-success rightTop"
        @click="editValue()"
      >
        <md-icon>edit</md-icon>
      </md-button>
    </div>
    <dialog-communication
      ref="diacommunication"
      :item="item"
    ></dialog-communication>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import SelectStatut from "../Field/SelectStatut.vue";
import toolsFormat from "@/tools/toolsFormat.js";
import DialogCommunication from "../Dialogs/DialogCommunication.vue";
import ItemTickets from "./ItemTickets.vue";
import ActivityCard from "../Cards/ActivityCard.vue";

export default {
  name: "ticket",
  components: {
    SelectStatut,
    DialogCommunication,
    ItemTickets,
    ActivityCard,
  },
  props: ["item", "edition_all"],
  data() {
    return {
      commentData: null,
      badgesData: [],
      badgesCorrespondance: [],
      statutSelectData: null,
      actif: false,
      radio: false,
      initBadge: false,
      hover: false,
    };
  },
  created() {
    this.radio = this.edition_all;
  },
  watch: {
    edition_all: function (newVal, oldVal) {
      this.radio = newVal;
    },
  },
  computed: {
    comment: {
      get() {
        if (this.item.PropFollowUp) {
          return this.item.PropFollowUp.comment;
        } else {
          return "";
        }
      },
      set(value) {
        this.actif = true;
        this.commentData = value;
      },
    },
    activity: {
      get() {
        if (this.item && this.item.activity && this.item.activity.activity) {
          return this.item.activity.activity.rows;
        }
        return [];
      },
    },
    badges: {
      get() {
        if (!this.initBadge && this.item.PropFollowUp) {
          const bds = JSON.parse(this.item.PropFollowUp.badges);
          this.setInitBadge(bds);
          return bds;
        } else {
          return this.badgesData;
        }
      },
      set(value) {
        this.actif = true;
        this.badgesData = value.slice();
        this.$emit("input", value);
      },
    },
    statutSelect: {
      get() {
        return { aSupprimer: this.item.aSupprimer, traite: this.item.traite };
      },
      set(value) {
        this.actif = true;
        this.statutSelectData = value;
      },
    },
    badgesList() {
      return store.getters["tickets/getBadgeResult"];
    },
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getType(type) {
      return toolsFormat.getType(type);
    },
    setInitBadge(badges) {
      this.badgesData = badges;
      this.initBadge = true;
    },
    updateBadge(val) {
      var ar = [];
      if (val.length > 0) {
        if (this.badgesList && this.badgesList.badges) {
          for (var i = 0; i < this.badgesList.badges.rows.length; i++) {
            if (
              this.badgesList.badges.rows[i].name
                .toLowerCase()
                .indexOf(val.toLowerCase()) == 0
            ) {
              ar.push(this.badgesList.badges.rows[i].name);
            }
          }
        }
      }
      if (ar.length == 1) {
        this.badgesCorrespondance = [];
        return ar[0];
      }
      this.badgesCorrespondance = ar;
      return null;
    },
    clickChips(chip) {
      this.actif = true;
      if (!this.badgesData.includes(chip)) {
        this.badgesData.push(chip);
      }
    },
    sendValue() {
      var param = {
        url: this.item.id,
        data: {},
        urlGet: this.$route.fullPath.split("/"),
      };

      if (this.commentData != null) {
        param.data.comment = this.commentData;
      }

      if (this.badgesData != null) {
        param.data.badges = this.badgesData;
      }

      if (this.item.PropFollowUp) {
        param.data.propId = this.item.PropFollowUp.id;
      }

      if (this.statutSelectData != null) {
        param.data.aSupprimer = this.statutSelectData.aSupprimer;
        param.data.traite = this.statutSelectData.traite;
      }
      store.dispatch("tickets/servicePostExtension", param);
      this.actif = false;
    },

    editValue() {
      this.radio = !this.radio;
    },
  },
};
</script>

<style lang="scss">
.rightTop {
  position: absolute !important;
  top: 0;
  right: 0;
}

.rightTopLeft {
  position: absolute !important;
  top: 0;
  right: 45px;
}

.rightTopLeftAssistance {
  position: absolute !important;
  top: 10px;
  height: 10px !important;
  right: 90px;
}

.numberAttachement {
  position: absolute !important;
  bottom: 30px;
  height: 10px !important;
  right: 90px;
}

.badgeMessage {
  position: absolute !important;
  top: 10px;
  right: 90px;
}

.badgeNews {
  position: absolute !important;
  top: 10px;
  right: 115px;
}
</style>
