<!--
@Author: Florian Merel <florian>
@Date:   13-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesLogs.vue
@Last modified by:   florian
@Last modified time: 15-Jul-2021
-->

<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesChanges.vue
@Last modified by:   florian
@Last modified time: 15-Jul-2021
-->

<template>
  <div>
    <div class="md-subheading">Suivre l'activité d'une station</div>
    <md-divider style="margin-top: 5px; margin-bottom: 10px"></md-divider>

    <div class="md-layout" style="margin-bottom: 25px">
      <div
        class="md-layout-item md-small-size-100 md-size-33"
        style="text-align: center"
      >
        Date
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-33"
        style="text-align: center"
      >
        Status
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-33"
        style="text-align: center"
      >
        Comment
      </div>
    </div>

    <md-card v-if="getListData.length == 0" style="margin-top: -30px">
      <md-card-content>
        <div class="md-layout">
          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="text-align: center"
          >
            Aucune activité enregistrée.
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div v-if="getListData.length > 0">
      <div
        v-for="dt in getListData"
        :key="dt.time"
        class="md-layout"
        :style="getColor(dt)"
      >
        <div
          class="md-layout-item md-small-size-100 md-size-33"
          style="text-align: center"
        >
          {{ getDate(dt.time) }}
        </div>

        <div
          class="md-layout-item md-small-size-100 md-size-33"
          style="text-align: center"
        >
          {{ dt.status }}
        </div>

        <div
          class="md-layout-item md-small-size-100 md-size-33"
          style="text-align: center"
        >
          {{ dt.comments }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";

export default {
  name: "licences-logs",
  props: ["propsTarget", "propsLicence"],
  computed: {},
  data() {
    return {};
  },
  computed: {
    getListData() {
      let x = store.getters["licences/getReportData"];
      if (
        x &&
        x.result &&
        x.result.licence &&
        x.result.licence.network &&
        x.result.licence.network.stations
      ) {
        for (var i = 0; i < x.result.licence.network.stations.length; i++) {
          if (
            x.result.licence.network.stations[i].syncReport &&
            x.result.licence.network.stations[i].syncReport.ackLst &&
            x.result.licence.network.stations[i].syncReport.ackLst.length > 0
          ) {
            return x.result.licence.network.stations[i].syncReport.ackLst;
          }
        }
      }
      return [];
    },
  },
  methods: {
    getDate(da) {
      return toolsFormat.getDateLong(da);
    },

    getColor(gc) {
      if (gc.status.includes("success")) {
        return "background-color: #C9FFB0;";
      } else {
        return "background-color: #FFB0B0";
      }
    },
  },
};
</script>

<style lang="scss"></style>
