/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: usersList.js
 * @Last modified by:   florian
 * @Last modified time: 23-Dec-2020
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getUsers(context) {
    return context.users;
  },

  getData(context) {
    return context.data;
  },

  getUsersUnlock(context) {
    return context.usersUnlock;
  },

  getQuestions(context) {
    if (
      context.data &&
      context.data.data &&
      context.data.data.questions &&
      context.data.data.questions.rows
    ) {
      return context.data.data.questions.rows;
    }
    return null;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async service(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.usersQuestions,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }

    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
