<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Files.vue
@Last modified by:   florian
@Last modified time: 25-Aug-2021
-->

<template>
  <div
    v-if="getTicket != null"
    class="md-layout-item md-medium-size-100 md-size-100"
  >
    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="n in getTicket.ticketFiles"
            :key="n.id"
            cols="12"
            md="2"
          >
            <v-item>
              <v-card max-width="344" height="160" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ n.originalname }}
                    </div>
                    <v-list-item-subtitle
                      >{{
                        Math.ceil(n.size / 1000000)
                      }}
                      Mo</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions style="position: absolute; bottom: 0">
                  <v-btn @click="download(n)" text> Télécharger </v-btn>
                </v-card-actions>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-files",
  data() {
    return {};
  },
  methods: {
    async download(file) {
      let res = await store.dispatch("downloadFiles/downloadFiles", {
        id: file.id,
        fileName: file.originalname,
      });
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
