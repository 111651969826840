/**
 * @Author: Florian Merel <florian>
 * @Date:   10-Jun-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: routes.js
 * @Last modified by:   florian
 * @Last modified time: 22-Sep-2021
 */

// ----------------------------------------------------------------------------
// Importer toutes les pages
// ----------------------------------------------------------------------------

import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Dashboard from "@/pages/Dashboard.vue";
import UsersList from "@/pages/UsersList.vue";
import LicencesList from "@/pages/LicencesList.vue";
import Administrator from "@/pages/Administrator.vue";

import Login from "@/pages/Login.vue";
import Disconnect from "@/pages/Disconnect.vue";

import Tickets from "@/pages/Tickets.vue";
import FollowUp from "@/pages/FollowUp.vue";
import UsersInscription from "@/pages/UsersInscription.vue";
import PublicTicket from "@/pages/Publicticket.vue";
import Server from "@/pages/Server.vue";

import CreatedTicket from "@/pages/Tickets/CreatedTicket.vue";
import SetupTickets from "@/pages/Tickets/SetupTickets.vue";
import Analitics from "@/pages/Analitics.vue";
import Statistiques from "@/pages/Statistiques.vue";
import Providers from "@/pages/Providers.vue";
import Community from "@/pages/Community.vue";

import TicketsV2 from "@/pages/TicketsV2.vue";
import TicketsV2Filters from "@/pages/TicketsV2/TicketsV2Filters.vue";
import ParkManagement from "@/pages/ParkManagement.vue";
import Versions from "@/pages/Versions.vue";
import Logs from "@/pages/Logs.vue";

import Subscription from "@/pages/Subscriptions.vue";

import Segur from "@/pages/Segur.vue";
import Segurasp from "@/pages/Segurasp.vue";
import Segurbon from "@/pages/Segurbon.vue";

import Mss from "@/pages/Mss.vue";
import Services from "@/pages/Services.vue";

// ----------------------------------------------------------------------------
// Référencement de toutes les routes, et leurs pages
// ----------------------------------------------------------------------------

const routes = [
  {
    path: "/",
    redirect: "/login",
  },

  {
    path: "/publicticket/:id",
    name: "PublicTicket",
    component: PublicTicket,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "users",
        name: "Utilisateurs",
        component: UsersList,
      },
      {
        path: "licences",
        name: "Licences",
        component: LicencesList,
      },
      {
        path: "parkmanagement",
        name: "Parkmanagement",
        component: ParkManagement,
      },
      {
        path: "subscription",
        name: "Subscription",
        component: Subscription,
      },
      {
        path: "segur",
        name: "Segur",
        component: Segur,
      },
      {
        path: "mss",
        name: "Mss",
        component: Mss,
      },
      {
        path: "services",
        name: "Services",
        component: Services,
      },
      {
        path: "segurasp",
        name: "segurasp",
        component: Segurasp,
      },
      {
        path: "segurbon",
        name: "segurbon",
        component: Segurbon,
      },
      {
        path: "versions",
        name: "Versions",
        component: Versions,
      },
      // TICKET
      {
        path: "tickets",
        redirect: "tickets/new",
      },
      {
        path: "tickets/:type",
        name: "ticketsType",
        component: Tickets,
      },
      {
        path: "createdTicket",
        name: "createdTicketId",
        component: CreatedTicket,
      },
      {
        path: "setupTickets",
        name: "setupTicketsId",
        component: SetupTickets,
      },

      {
        path: "followup",
        name: "FollowUpId",
        component: FollowUp,
      },
      {
        path: "administrator",
        name: "AdministratorId",
        component: Administrator,
      },
      {
        path: "usersinscription",
        name: "usersinscriptionId",
        component: UsersInscription,
      },
      {
        path: "server",
        name: "serverId",
        component: Server,
      },
      {
        path: "analytics",
        name: "analiticsId",
        component: Analitics,
      },
      {
        path: "providers",
        name: "PovidersId",
        component: Providers,
      },
      {
        path: "C-community",
        name: "CommunityId",
        component: Community,
      },
      {
        path: "statistiques",
        name: "StatistiquesId",
        component: Statistiques,
      },
      {
        path: "logs",
        name: "LogsIs",
        component: Logs,
      },
      {
        path: "ticketsv2",
        name: "TicketsV2",
        redirect: "ticketsv2/filters",
        component: TicketsV2,
        children: [
          {
            path: "filters",
            name: "TicketsV2Filters",
            component: TicketsV2Filters,
          },
          {
            path: "filters/:filters",
            name: "TicketsV2Filter",
            component: TicketsV2Filters,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/disconnect",
    component: Disconnect,
  },
  { path: "*", component: Login },
];

export default routes;
