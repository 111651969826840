<!--
@Author: Florian Merel <florian>
@Date:   02-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Serveurs.vue
@Last modified by:   florian
@Last modified time: 16-Jun-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <auto-complete-search-bar
          v-bind:ar="this.ar"
          v-on:passData="search"
          v-on:changeEvent="changeEvent"
          :query="query"
        ></auto-complete-search-bar>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        style="margin-top: 10px"
        v-if="this.stats == null"
      >
        <div style="text-align: center">
          La recherche ne trouve aucun token.
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <statistiques-charts
          ref="nbStats"
          :stats="this.stats"
        ></statistiques-charts>
      </div>

      <!--         v-if="
          systemeVersionMacOS.datasets[0].data.length > 0 && this.stats == null
        "-->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">Versions MacOS (1 mois)</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-50
                "
              >
                <PieCharts
                  ref="refsystemeVersionMacOSmin"
                  :chartData="systemeVersionMacOSmin"
                />
              </div>
              <div
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-50
                "
              >
                <PieCharts
                  ref="refsystemeVersionMacOSmin2"
                  :chartData="systemeVersionMacOS"
                />
              </div>

              <div
                v-for="st in systemeVersionMacOSmin.labels"
                :key="st + 'x'"
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-100
                "
              >
                <md-divider
                  class="md-inset"
                  style="margin-bottom: 10px; margin-top: 10px"
                ></md-divider>
                <div class="md-layout">
                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">{{ st }}</div>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">
                      {{ getValue(systemeVersionMacOSmin, st) }}
                    </div>
                  </div>
                </div>
              </div>

              <!--div style="margin-top: 100px"></div>

              <div
                v-for="st in systemeVersionMacOS.labels"
                :key="st"
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-100
                "
              >
                <md-divider
                  class="md-inset"
                  style="margin-bottom: 10px; margin-top: 10px"
                ></md-divider>
                <div class="md-layout">
                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">{{ st }}</div>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">
                      {{ getValue(systemeVersionMacOS, st) }}
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">Versions iOS (1 mois)</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-50
                "
              >
                <PieCharts
                  ref="refsystemeVersioniOS"
                  :chartData="systemeVersioniOSmin"
                />
              </div>
              <div
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-50
                "
              >
                <PieCharts
                  ref="refsystemeVersioniOS2"
                  :chartData="systemeVersioniOS"
                />
              </div>

              <div
                v-for="st in systemeVersioniOSmin.labels"
                :key="st"
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-100
                "
              >
                <md-divider
                  class="md-inset"
                  style="margin-bottom: 10px; margin-top: 10px"
                ></md-divider>
                <div class="md-layout">
                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">{{ st }}</div>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">
                      {{ getValue(systemeVersioniOSmin, st) }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div style="margin-top: 100px"></div>

              <div
                v-for="st in systemeVersioniOS.labels"
                :key="st"
                class="
                  md-layout-item
                  md-medium-size-100
                  md-xsmall-size-100
                  md-size-100
                "
              >
                <md-divider
                  class="md-inset"
                  style="margin-bottom: 10px; margin-top: 10px"
                ></md-divider>
                <div class="md-layout">
                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">{{ st }}</div>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-50
                    "
                  >
                    <div style="text-align: center; width: 100%">
                      {{ getValue(systemeVersioniOS, st) }}
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteSearchBar from "@/components/SearchBar/AutoCompleteSearchBar.vue";
import store from "@/store";
import { StatistiquesCharts } from "@/components";
import PieCharts from "../components/Charts/PieCharts.vue";
import Vue from "vue";

export default {
  components: {
    AutoCompleteSearchBar,
    StatistiquesCharts,
    PieCharts,
  },
  data: () => ({
    query: "",
    ar: [],
    lastSearch: "",
    stats: null,
    systemeVersionMacOSmin: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },
    systemeVersionMacOS: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },
    systemeVersioniOSmin: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },
    systemeVersioniOS: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },

    /*
    // UNIQUE
    systemeUniqueVersioniOS: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },

        // UNIQUE
    systemeUniqueVersionMacOS: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#F4D03F",
            "#5499C7",
            "#E74C3C",
            "#E67E22",
            "#9B59B6",
            "#2C3E50",
          ],
        },
      ],
    },*/
  }),
  methods: {
    search: function (data) {
      // Get token with description
      if (data.includes("[")) {
        var res = data.split("[");
        if (res[1]) {
          if (res[1].substr(res[1].length - 1) == "]") {
            data = res[1].slice(0, -1);
          } else {
            data = res[1];
          }
        }
      }

      if (this.$route.query.q != data) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { q: data }),
        });
      }
      this.getSearch(this.$route.query.q);
      //}
    },

    getValue(obj, key) {
      let index = obj.labels.findIndex((t) => t === key);
      return obj.datasets[0].data[index];
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    async getSearch(search) {
      if (search) {
        store.dispatch("statistiques/getStat", search).then(
          (response) => {
            if (
              response.data &&
              response.data.success &&
              response.data.data.count != 0
            ) {
              this;
              this.stats = response.data.data;
              this.$refs.nbStats.refresh(response.data.data);
            } else {
              this.stats = null;
            }
          },
          (error) => {}
        );
      }
    },

    systemVersion() {
      store.dispatch("statistiques/getStat", "SYSTEM_VERSION").then(
        (response) => {
          if (
            response.data &&
            response.data.success &&
            response.data.data.length != 0
          ) {
            for (let i = 0; i < response.data.data.rows.length; i++) {
              if (response.data.data.rows[i].environment == "macOS") {
                this.systemVersionMacOS(response.data.data.rows[i]);
              } else if (response.data.data.rows[i].environment == "iOS") {
                this.systemVersionIOS(response.data.data.rows[i]);
              }
            }
          } else {
          }
        },
        (error) => {}
      );
    },

    systemVersionIOS(res) {
      if (res.stats) {
        res.stats = res.stats.sort(function (a, b) {
          if (a.version > b.version) {
            return -1;
          }
          return 0;
        });

        for (let i = 0; i < res.stats.length; i++) {
          var Difference_In_Time =
            (new Date(Date.now()) - new Date(res.stats[i].date)) /
            (1000 * 3600 * 24);
          if (Difference_In_Time < 30) {
            if (res.stats[i].version == null) {
              res.stats[i].version = "Inconnu";
            }

            if (!this.systemeVersioniOS.labels.includes(res.stats[i].version)) {
              this.systemeVersioniOS.labels.push(res.stats[i].version);
              this.systemeVersioniOS.datasets[0].data.push(res.stats[i].nombre);
            } else {
              let index = this.systemeVersioniOS.labels.findIndex(
                (t) => t === res.stats[i].version
              );
              this.systemeVersioniOS.datasets[0].data[index] +=
                res.stats[i].nombre;
            }

            var vers = res.stats[i].version.split(".");
            if (vers.length > 0) {
              if (
                !this.systemeVersioniOSmin.labels.includes("iOS " + vers[0])
              ) {
                this.systemeVersioniOSmin.labels.push("iOS " + vers[0]);
                this.systemeVersioniOSmin.datasets[0].data.push(
                  res.stats[i].nombre
                );
              } else {
                let index = this.systemeVersioniOSmin.labels.findIndex(
                  (t) => t === "iOS " + vers[0]
                );
                this.systemeVersioniOSmin.datasets[0].data[index] +=
                  res.stats[i].nombre;
              }
            }
          }
        }
      }
      this.$refs.refsystemeVersioniOS.callRenderChart();
      this.$refs.refsystemeVersioniOS2.callRenderChart();
    },

    systemVersionMacOS(res) {
      if (res.stats) {
        for (let i = 0; i < res.stats.length; i++) {
          var Difference_In_Time =
            (new Date(Date.now()) - new Date(res.stats[i].date)) /
            (1000 * 3600 * 24);
          if (Difference_In_Time < 30) {
            if (res.stats[i].version == null) {
              res.stats[i].version = "Inconnu";
            }
            if (
              !this.systemeVersionMacOS.labels.includes(res.stats[i].version)
            ) {
              this.systemeVersionMacOS.labels.push(res.stats[i].version);
              this.systemeVersionMacOS.datasets[0].data.push(
                res.stats[i].nombre
              );
            } else {
              let index = this.systemeVersionMacOS.labels.findIndex(
                (t) => t === res.stats[i].version
              );
              this.systemeVersionMacOS.datasets[0].data[index] +=
                res.stats[i].nombre;
            }

            var vers = res.stats[i].version.split(".");
            if (vers.length > 0) {
              if (vers[0] == "10") {
                vers[0] = "10." + vers[1];
                if (vers[1] == "15") {
                  vers[0] = "Catalina";
                }
                if (vers[1] == "14") {
                  vers[0] = "Mojave";
                }
                if (vers[1] == "13") {
                  vers[0] = "High Sierra";
                }
              } else if (vers[0] == "11") {
                vers[0] = "Big Sur";
              } else if (vers[0] == "12") {
                vers[0] = "Monterey";
              }
              if (!this.systemeVersionMacOSmin.labels.includes(vers[0])) {
                this.systemeVersionMacOSmin.labels.push(vers[0]);
                this.systemeVersionMacOSmin.datasets[0].data.push(
                  res.stats[i].nombre
                );
              } else {
                let index = this.systemeVersionMacOSmin.labels.findIndex(
                  (t) => t === vers[0]
                );
                this.systemeVersionMacOSmin.datasets[0].data[index] +=
                  res.stats[i].nombre;
              }
            }
          }
        }
      }
      this.$refs.refsystemeVersionMacOSmin.callRenderChart();
      this.$refs.refsystemeVersionMacOSmin2.callRenderChart();
    },

    changeEvent: function (data) {
      if (data.length == 0 || data == this.lastSearch) {
        return;
      }

      var excludeToken = ["SYSTEM_VERSION"];

      this.lastSearch = data;
      var art = [];
      store.dispatch("statistiques/getToken", "?q=" + data).then(
        (response) => {
          if (response.data && response.data.success) {
            response.data.data.rows.forEach(function (el) {
              if (!art.includes(el.token) && !excludeToken.includes(el.token)) {
                if (el.description) {
                  art.push(el.description + "   [" + el.token + "]");
                } else {
                  art.push(el.token);
                }
              }
            });
            this.ar = art;
          }
        },
        (error) => {}
      );
    },
  },

  created: function () {
    this.systemVersion();
  },

  beforeMount() {
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
      //nbStats
      this.getSearch(this.query);
    }
  },
};
</script>
