/**
 * @Author: Florian Merel <florian>
 * @Date:   18-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: publicTicket.js
 * @Last modified by:   florian
 * @Last modified time: 23-Dec-2020
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";
import store from "@/store";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getData(context) {
    return context.data;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async getPublicTicket(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.publicTicket + "/" + id,
        headers: server.getHeader(),
      });

      context.commit("setResponse", res);
      context.commit("setData", res.data);
    } catch (error) {
      //if (servicesError.isConnection(error)) {
      servicesError.analyseErrorService(error.response.data);
      context.commit("setError", error.response.data);
      //}
    }
    context.commit("setLoading", false);
  },

  async sendPublicMessage(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.publicTicket,
        headers: server.getHeader(),
        data: params,
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data);
      store.commit("activity/setData", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async sendResponsePublicMessage(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.publicTicket + "/" + params.url,
        data: params.param,
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async deletePublicLink(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url: server.url + server.urls.publicTicket + "/" + id,
        headers: server.getHeader(),
      });
      return true;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    return false;
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
