<!--
@Author: Florian Merel <florian>
@Date:   22-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: parkmanagement.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<!--v-model="licences"
        :table-header-color="tableHeaderColor"
        @md-selected="onSelect"
        v-if="licences.length != 0"-->

<template>
  <div class="content">
    <v-card elevation="0" outlined>
      <v-card-title></v-card-title>
      <v-card-text>
        <md-card style="margin-bottom: 40px; margin-top: 10px">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field style="">
                <label>Recherche</label>
                <md-input v-on:keyup.enter="search" v-model="query"></md-input>
              </md-field>
            </div>
          </div>
          <md-button class="md-prokov researcheButton" @click="search()"
            >Rechercher</md-button
          >
        </md-card>

        <div class="row" style="margin-top: -30px">
          <div class="col">
            <md-table
              v-if="this.rowsMss && this.rowsMss.rows"
              v-model="this.rowsMss.rows"
            >
              <md-table-row
                slot="md-table-row"
                md-selectable="single"
                slot-scope="{ item }"
              >
                <md-table-cell md-label="Identité" v-if="item.MSS_MSSUser">
                  {{ item.MSS_MSSUser.INFOS_PORTEUR_PrenomUsuel }}
                  {{ item.MSS_MSSUser.INFOS_PORTEUR_NomPatronym }}
                </md-table-cell>
                <md-table-cell v-else md-label="Identité"> --- </md-table-cell>

                <md-table-cell md-label="Email" v-if="item.MSS_MSSUser">
                  {{ item.MSS_MSSUser.email }}
                </md-table-cell>
                <md-table-cell v-else md-label="Email"> --- </md-table-cell>

                <md-table-cell md-label="MS Story">
                  {{ item.email }}
                </md-table-cell>

                <md-table-cell md-label="Type"> {{ item.type }}</md-table-cell>

                <md-table-cell md-label="Status">
                  {{ item.emailStatus }}
                </md-table-cell>

                <md-table-cell md-label="Date de paiement">
                  {{ getDate(item.datePayement) }}
                </md-table-cell>
              </md-table-row>
            </md-table>

            <div class="text-center">
              <v-pagination v-model="page" :length="size"></v-pagination>
            </div>

            <span style="color: black; font-weight: bold"> </span>
            <br />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import Vue from "vue";
import server from "../store/server.js";
import axios from "axios";

export default {
  name: "Mss",
  data: () => ({
    rowsMss: null,
    page: 1,
    size: 0,
    query: "",
  }),
  created: async function () {
    this.getMss("");
  },

  watch: {
    page: function (val, oldVal) {
      this.getMss();
    },
  },
  computed: {},
  methods: {
    async getMss() {
      var calc = (this.page - 1) * 100;

      try {
        let res = await axios({
          method: "GET",
          url: server.url + "/mss?offset=" + calc + "&search=" + this.query,
          headers: server.getHeader(),
        });

        if (res && res.data && res.data.data) {
          this.rowsMss = res.data.data;

          this.size = Math.round(this.rowsMss.count / 100) + 1;
        }
      } catch (error) {
        alert("Echec de l'envoie.");
      }
    },

    getDate(dd) {
      return toolsFormat.getDateLong(dd);
    },

    search() {
      this.getMss();
    },
  },
};
</script>
