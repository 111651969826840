<!--
@Author: Florian Merel <florian>
@Date:   10-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogsTickets.vue
@Last modified by:   florian
@Last modified time: 05-Mar-2021
-->

<template>
  <div class="md-layout">
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title class="diag">
        <md-icon style="margin-top: -4px">source</md-icon>
        <span> {{ item.sujet }} - {{ item.id }}</span>
      </md-dialog-title>

      <button class="diagCloseButton" @click="showDialog = false">
        <md-icon>close</md-icon>
      </button>

      <div class="md-layout scrollAllPage">
        <!--<div class="md-layout-item md-medium-size-100 md-size-100" style="margin-top: -22px;">
             <div class="md-layout-item md-medium-size-100 md-size-100">
               <span style="margin-left: -5px;" class="emailTicket">Dans la liste Nouveaux</span>
             </div>
          </div>-->

        <div
          class="md-layout-item md-medium-size-100 md-size-80"
          style="margin-top: -30px"
        >
          <div class="md-layout" style="margin-top: 30px; margin-bottom: 15px">
            <div class="md-layout-item md-medium-size-100 md-size-50">
              <span
                v-if="
                  item.PropFollowUp &&
                  item.PropFollowUp.email &&
                  item.PropFollowUp.email.length > 0
                "
                class="emailTicket"
                >{{ item.PropFollowUp.email }}
              </span>
              <span
                v-if="
                  !item.PropFollowUp ||
                  !item.PropFollowUp.email ||
                  item.PropFollowUp.email.length == 0
                "
                class="emailTicket"
                >{{ item.EeUser.email }}
              </span>
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-50">
              <div class="dateTicket">{{ getDate(item.date) }}</div>
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-50">
              <span class="typeTicket"
                >{{ item.specialites }}
                <md-tooltip md-direction="top">Spécialités</md-tooltip>
              </span>
            </div>
            <div class="md-layout-item md-medium-size-100 md-size-50">
              <span class="typeTicket"
                >{{ getType(item.kindID) }}
                <md-tooltip md-direction="top">type</md-tooltip>
              </span>
            </div>
          </div>

          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-icon style="margin-top: -4px; margin-left: -6px"
              >subject</md-icon
            >
            <span class="md-body-2" style="margin-left: 6px">Description</span>
          </div>
          <div
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-top: 0px"
          >
            <!-- overflow: scroll; max-height: 300px -->
            <div
              :contenteditable="split"
              @input="onDivInput($event)"
              style="white-space: pre-line"
              v-html="item.description"
            ></div>
          </div>

          <div
            v-if="
              this.item &&
              this.item.PropFollowUp &&
              this.item.PropFollowUp.comment
            "
          >
            <div
              class="md-layout-item md-medium-size-100 md-size-100"
              style="margin-top: 30px"
            >
              <md-icon style="margin-left: -6px">notes</md-icon>
              <span class="md-body-2" style="margin-left: 6px"
                >Description interne</span
              >
            </div>
            <div
              class="md-layout-item md-medium-size-100 md-size-100"
              style="margin-top: 0px"
            >
              <div>{{ this.item.PropFollowUp.comment }}</div>
            </div>
          </div>

          <div
            v-if="badges && badges.length > 0"
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-top: 30px"
          >
            <md-icon style="margin-left: -6px">label</md-icon>
            <span class="md-body-2" style="margin-left: 6px">Badges</span>

            <div>
              <md-chip
                class=""
                style="margin-top: 0px"
                v-for="chip in badges"
                :key="chip"
                >{{ chip }}
              </md-chip>
            </div>
          </div>

          <!-- écire un commentaire -->
          <div v-if="writeCommentary || split">
            <div
              class="md-layout-item md-medium-size-100 md-size-100"
              style="margin-top: 30px"
            >
              <md-icon style="margin-left: -6px">insert_comment</md-icon>
              <span
                v-if="writeCommentary"
                class="md-body-2"
                style="margin-left: 6px"
                >Écrire un commentaire</span
              >

              <span v-if="split" class="md-body-2" style="margin-left: 6px"
                >Description du ticket scinder</span
              >
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-100"
              style="margin-top: -20px"
            >
              <md-field>
                <md-textarea v-model="comment"></md-textarea>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-100 text-right"
              style="margin-top: -20px"
            >
              <md-button
                v-if="writeCommentary"
                class="md-raised md-prokov"
                @click="sendCommentary()"
                :disabled="comment.length == 0"
                >Envoyer</md-button
              >

              <md-button
                v-if="split"
                class="md-raised md-prokov"
                @click="splitExecute()"
                :disabled="comment.length == 0"
                >Scinder</md-button
              >
            </div>
          </div>

          <!-- Envoyer un message à l'utilisateur -->
          <div v-if="sendMessage">
            <div
              class="md-layout-item md-medium-size-100 md-size-100"
              style="margin-top: 30px"
            >
              <md-icon style="margin-left: -6px">email</md-icon>
              <span
                v-if="sendMessage"
                class="md-body-2"
                style="margin-left: 6px"
                >Envoyer un message à l'utilisateur</span
              >
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-radio
                v-if="
                  item.PropFollowUp &&
                  item.PropFollowUp.email &&
                  item.PropFollowUp.email.length > 0
                "
                v-model="radio"
                :value="item.PropFollowUp.email"
                class="md-info"
                >{{ item.PropFollowUp.email }}</md-radio
              >
              <md-radio v-else v-model="radio" value="accent" class="md-info">{{
                item.EeUser.email
              }}</md-radio>

              <md-radio
                v-if="
                  item.PropFollowUp &&
                  item.PropFollowUp.contactEmail &&
                  item.PropFollowUp.contactEmail.length > 0
                "
                v-model="radio"
                :value="item.PropFollowUp.contactEmail"
                class="md-info"
                >{{ item.PropFollowUp.contactEmail }}</md-radio
              >
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-100"
              style="margin-top: -16px"
            >
              <span style="position: relative; margin-right: 10px; top: -4px"
                >L'utilisateur (ne) peut
              </span>
              <md-radio v-model="openEchange" :value="true" class="md-primary"
                >répondre</md-radio
              >
              <md-radio v-model="openEchange" :value="false" class="md-primary"
                >pas répondre</md-radio
              >
              <span style="position: relative; top: -5px">au message.</span>
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-100"
              style="margin-top: -20px"
            >
              <md-field>
                <md-textarea v-model="comment"></md-textarea>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-100 text-right"
              style="margin-top: -20px"
            >
              <md-button
                class="md-raised md-prokov"
                @click="sendValue()"
                :disabled="disabledSend"
                >Envoyer</md-button
              >
            </div>
          </div>

          <!-- GESTION DES FICHIERS -->
          <dialog-Files :item="item" v-if="filesVues"> </dialog-Files>

          <!-- ACTIVITER -->
          <div>
            <div
              v-if="activity && activity.length > 0"
              class="md-layout-item md-medium-size-100 md-size-100"
              style="margin-top: 30px"
            >
              <md-icon style="margin-left: -6px">view_list</md-icon>
              <span class="md-body-2" style="margin-left: 6px">Activité</span>
            </div>
            <div
              class="md-layout-item md-xsmall-size-100 md-size-100 scrollHeight"
              style="margin-top: 10px"
            >
              <div style="margin-bottom: 10px"></div>

              <activity-card
                style="margin-top: 0px"
                :activity="activity"
              ></activity-card>
              <!--<md-card
                v-for="act in activity"
                :key="act.id"
                style="margin-top: -20px"
              >
                <div class="layout">
                  <div class="md-layout">
                    <div
                      class="md-layout-item md-size-5 md-alignment-center-center"
                      style="margin-top: 11px; margin-bottom: 5px"
                    >
                      <md-avatar
                        v-if="act.source == 'Created'"
                        class="md-avatar-icon md-small md-info"
                        ><md-icon>create_new_folder</md-icon></md-avatar
                      >

                      <md-avatar
                        v-if="act.source == 'PublicQuestion'"
                        class="md-avatar-icon md-small md-success"
                        ><md-icon>contact_support</md-icon></md-avatar
                      >

                      <md-avatar
                        v-if="act.source == 'PublicResponse'"
                        class="md-avatar-icon md-small md-warning"
                        ><md-icon>chat</md-icon></md-avatar
                      >

                      <md-avatar
                        v-if="act.source == 'Update'"
                        class="md-avatar-icon md-small md-info"
                        ><md-icon>sync_alt</md-icon></md-avatar
                      >

                      <md-avatar
                        v-if="act.source == 'ClosureResponse'"
                        class="md-avatar-icon md-small md-danger"
                        ><md-icon>subtitles_off</md-icon></md-avatar
                      >

                      <md-avatar
                        v-if="act.source == 'Commentaire'"
                        class="md-avatar-icon md-small md-success"
                        ><md-icon>insert_comment</md-icon></md-avatar
                      >

                    </div>

                    <div
                      v-if="act.source == 'Update'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span class="spanInfo"
                        >{{ act.userLogin }} a mis à jour le ticket</span
                      >
                    </div>

                    <div
                      v-if="act.source == 'Created'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span class="spanInfo">Création du ticket</span>
                    </div>

                    <div
                      v-if="act.source == 'PublicQuestion'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span class="spanInfo"
                        >{{ act.userLogin }} a posé une question à
                        l'utilisateur</span
                      >
                    </div>

                    <div
                      v-if="act.source == 'ClosureResponse'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span>{{ act.userLogin }} a répondu à l'utilisateur</span>
                    </div>

                    <div
                      v-if="act.source == 'PublicResponse'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span class="spanInfo"
                        >Réponse de la part de l'utilisateur</span
                      >
                    </div>

                    <div
                      v-if="act.source == 'Commentaire'"
                      class="md-layout-item md-alignment-center-center md-size-75"
                      style="margin-top: 5px; margin-bottom: 5px"
                    >
                      <span class="spanInfo"
                        >{{ act.userLogin }} - Discussion</span
                      >
                    </div>

                    <div
                      class="md-layout-item md-alignment-center-center md-size-20"
                      style="margin-top: 5px"
                    >
                      <div class="dateTicket">
                        {{ getDateLong(act.createdAt) }}
                      </div>
                    </div>

                    <div
                      class="md-layout-item md-alignment-center-center md-size-5"
                    ></div>

                    <div
                      v-if="act.comment"
                      class="md-layout-item md-alignment-center-center md-size-95"
                      style="margin-top: -10px; margin-bottom: 5px"
                    >
                      {{ act.comment }}
                    </div>
                  </div>
                </div>
              </md-card>-->
            </div>
          </div>
        </div>

        <div class="md-layout-item md-medium-size-100 md-size-20 text-center">
          <span>ACTIONS</span>

          <div class="md-layout-item md-medium-size-100 md-size-100 text-right">
            <button
              class="actionButton"
              v-bind:class="[
                sendMessage ? 'actionButtonActif' : 'actionButton',
              ]"
              @click="
                (sendMessage = !sendMessage),
                  (split = false),
                  (writeCommentary = false),
                  (filesVues = false)
              "
            >
              Envoyer un message
            </button>

            <button
              class="actionButton"
              v-bind:class="[
                writeCommentary ? 'actionButtonActif' : 'actionButton',
              ]"
              @click="
                (writeCommentary = !writeCommentary),
                  (split = false),
                  (sendMessage = false),
                  (filesVues = false)
              "
            >
              Écrire un commentaire
            </button>

            <button
              class="actionButton"
              v-bind:class="[split ? 'actionButtonActif' : 'actionButton']"
              @click="
                (split = !split),
                  (sendMessage = false),
                  (writeCommentary = false),
                  (filesVues = false)
              "
            >
              Scinder le ticket
            </button>

            <button
              class="actionButton"
              v-if="files.length > 0"
              v-bind:class="[filesVues ? 'actionButtonActif' : 'actionButton']"
              @click="
                (filesVues = !filesVues),
                  (sendMessage = false),
                  (writeCommentary = false),
                  (split = false)
              "
            >
              {{ files.length }} fichiers
            </button>

            <!--
            <button
              class="actionButton"
              v-bind:class="[
                sendMessage ? 'actionButtonActif' : 'actionButton',
              ]"
              @click="sendMessage = !sendMessage"
            >
              Scinder
            </button>-->

            <button
              v-if="ticketexchange"
              @click="removePublicPage()"
              class="actionButton"
            >
              Supprimer la page de discussion
            </button>
          </div>

          <div class="md-layout-item md-medium-size-100 md-size-100 text-right">
            <button
              v-if="ticketexchange"
              @click="openPublic()"
              class="actionButton"
            >
              Voir la page de discussion
            </button>
          </div>

          <div style="margin-top: 50px">
            <span>LÉGENDE</span>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-info"
              ><md-icon>create_new_folder</md-icon></md-avatar
            >
            <span style="margin-left: 20px">Création du ticket</span>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-success"
              ><md-icon>insert_comment</md-icon></md-avatar
            >
            <span style="margin-left: 20px">Commentaire</span>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-success"
              ><md-icon>contact_support</md-icon></md-avatar
            >
            <span style="margin-left: 20px"
              >Question émise à l'utilisateur</span
            >
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-warning"
              ><md-icon>chat</md-icon></md-avatar
            >
            <span style="margin-left: 20px">Réponse de l'utilisateur</span>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-info"
              ><md-icon>sync_alt</md-icon></md-avatar
            >
            <span style="margin-left: 20px">Mise à jour du ticket</span>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100 text-left"
            style="margin-top: 5px"
          >
            <md-avatar class="md-avatar-icon md-small md-danger"
              ><md-icon>subtitles_off</md-icon></md-avatar
            >
            <span style="margin-left: 20px">Réponse émise à l'utilisateur</span>
          </div>
        </div>
      </div>

      <md-dialog-actions>
        <!--<md-button class="md-primary" @click="showDialog = false">Close</md-button>
         <md-button class="md-primary" @click="showDialog = false">Save</md-button>-->
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import toolsFormat from "@/tools/toolsFormat.js";
import DialogFiles from "./DialogFiles.vue";
import ActivityCard from "../Cards/ActivityCard.vue";
import store from "@/store";

export default {
  name: "dialog-communication",
  props: ["item"],
  components: {
    ActivityCard,
    DialogFiles,
  },
  data() {
    return {
      filesVues: true,
      split: false,
      writeCommentary: false,
      showDialog: false,
      sendMessage: false,
      disabledSend: true,
      openEchange: null,
      radio: null,
      comment: "",
      newDescrition: null,
    };
  },
  computed: {
    badges: {
      get() {
        if (this.item.PropFollowUp) {
          return JSON.parse(this.item.PropFollowUp.badges);
        } else {
          return [];
        }
      },
    },
    ticketexchange: {
      get() {
        var act = store.getters["activity/getData"];
        if (act && act.data && act.data.ticketexchange) {
          return act.data.ticketexchange;
        } else {
          return null;
        }
      },
    },
    files: {
      get() {
        if (!this.item.PropFollowUp) {
          return [];
        }
        var act = store.getters["activity/getData"];
        if (act && act.data && act.data.files) {
          return act.data.files.rows;
        }
        return [];
      },
    },
    activity: {
      get() {
        if (!this.item.PropFollowUp) {
          return [];
        }
        var act = store.getters["activity/getData"];
        if (act && act.data && act.data.activity) {
          return act.data.activity.rows;
        }
        return [];
      },
    },
  },
  watch: {
    comment(value) {
      if (
        this.comment.length > 0 &&
        this.radio != null &&
        this.openEchange != null
      ) {
        this.disabledSend = false;
      } else {
        this.disabledSend = true;
      }
    },
    openEchange(value) {
      if (
        this.comment.length > 0 &&
        this.radio != null &&
        this.openEchange != null
      ) {
        this.disabledSend = false;
      } else {
        this.disabledSend = true;
      }
    },
    radio(value) {
      if (
        this.comment.length > 0 &&
        this.radio != null &&
        this.openEchange != null
      ) {
        this.disabledSend = false;
      } else {
        this.disabledSend = true;
      }
    },
  },
  methods: {
    onDivInput: function (e) {
      this.newDescrition = e.target.innerHTML;
    },
    open() {
      this.showDialog = true;
      if (this.item.PropFollowUp) {
        store.dispatch("activity/getActivity", this.item.PropFollowUp.id);
      } else {
        // On clear activity
        store.commit("activity/setData", null);
        // On créer le Followup
        store.dispatch("followup/createFollowup", this.item.id);
      }
    },
    async removePublicPage() {
      const res = await store.dispatch(
        "publicTicket/deletePublicLink",
        this.ticketexchange.id
      );
      if (res) {
        store.commit("activity/removeTicketexchange", null);
      }
    },
    sendCommentary() {
      if (this.comment.length > 0) {
        var params = { id: this.item.PropFollowUp.id };
        var data = {};
        data.comment = this.comment;
        data.publicAct = false;
        data.deletePossible = false;
        data.source = "Commentaire";

        params.data = data;
        store.dispatch("activity/postActivity", params);
        this.comment = "";
        this.writeCommentary = false;
      }
    },
    splitExecute() {
      var desc = "";
      if (this.newDescrition == null) {
        desc = this.item.description;
      } else {
        desc = this.newDescrition;
      }

      if (desc.length > 0 && this.comment.length > 0) {
        var params = {};
        params.id = this.item.id;
        params.data = { desc_ticket: desc, desc_splitticket: this.comment };
        store.dispatch("tickets/serviceSplitTicket", params);
        this.split = false;
        this.comment = "";
        this.newDescrition = "";
      }
    },
    sendValue() {
      if (
        this.radio != null &&
        this.comment.length > 0 &&
        this.openEchange != null
      ) {
        var params = {
          email: this.radio,
          message: this.comment,
          withResponse: this.openEchange,
          propFollowUpId: this.item.PropFollowUp.id,
        };
        store.dispatch("publicTicket/sendPublicMessage", params);
        this.radio = null;
        this.openEchange = null;
        this.comment = "";
        this.sendMessage = false;
      }
    },
    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getDateLong(date) {
      return toolsFormat.getDateLong(date);
    },

    getType(type) {
      return toolsFormat.getType(type);
    },

    openPublic() {
      let routeData = this.$router.resolve({
        path: "/publicticket/" + this.ticketexchange.id,
        query: {},
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss">
.diag {
  //min-width: 1200px !important;
  max-width: 1400px !important;
  width: 1400px !important;
}

.diagCloseButton {
  position: absolute !important;
  top: 5px;
  right: 5px;
  border-style: none;
  background-color: white;
  border-color: white;
  cursor: pointer;
}

.actionButton {
  margin-top: 10px;
  border-color: white;
  border-style: none;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  //background-color: white;
  width: 100%;
}

.actionButtonActif {
  margin-top: 10px;
  border-color: green;
  border-style: none;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #82e0aa;
  width: 100%;
}

.actionButton.selected {
  color: red;
}

//.scrollHeight {
//overflow-y: scroll; height: 400px;
//}

.scrollAllPage {
  overflow-y: scroll;
}

//overflow-y: scroll; height: 400px
/*
.heightDialog {
  height: 60% !important;
  overflow-y: scroll;
}*/
</style>
