<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Type.vue
@Last modified by:   florian
@Last modified time: 09-Sep-2021
-->

<template>
  <div v-if="getTicket != null" class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      v-if="getActivities.length > 0"
    >
      <v-container>
        <v-timeline dense clipped>
          <v-timeline-item
            class="mb-4"
            :color="getColor(n.source)"
            icon-color="grey lighten-2"
            small
            v-for="n in getActivities"
            :key="n.id"
          >
            <span v-if="n.userLogin">{{ n.userLogin }} - </span>
            <span>{{ getText(n.source) }} - {{ getDate(n.createdAt) }}</span>
            <v-divider></v-divider>
            <span> {{ n.comment }}</span>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      style="margin-top: 10px"
    >
      <div class="md-body-2" color="primary">
        Envoyer un message à l'utilisateur
      </div>
    </div>

    <v-container style="margin-bottom: -10px">
      <v-text-field
        label="Adresse email du déstinataire"
        v-model="email"
        :rules="rules"
        hide-details="auto"
      ></v-text-field>
    </v-container>

    <v-container>
      <v-switch
        v-model="resp"
        color="blue"
        :label="getTextSelect(resp)"
        hide-details
      ></v-switch>
    </v-container>

    <v-container fluid>
      <v-textarea
        outlined
        name="input-7-4"
        color="primary"
        label="Mail"
        v-model="comment"
      ></v-textarea>
    </v-container>

    <v-row style="margin-top: -45px; margin-bottom: 50px">
      <v-col> </v-col>
      <v-spacer></v-spacer>
      <v-col style="margin-right: 10px">
        <v-btn
          class="white--text"
          color="primary accent-4"
          style="float: right"
          v-on:click="add"
          depressed
        >
          Envoyer
        </v-btn>
        <v-btn
          class="white--text"
          color="primary accent-4"
          style="float: right"
          v-on:click="clear"
          text
        >
          <v-icon dark> delete </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-mail",
  data() {
    return {
      comment: "",
      resp: true,
      email: "",
      rules: [
        (value) => !!value || "Requis.",
        (value) =>
          (value && toolsFormat.verificationEmail(value)) ||
          "Addresse email invalide.",
      ],
    };
  },
  methods: {
    clear() {
      this.comment = "";
    },

    onEnter() {
      this.add();
    },

    getTextSelect(yn) {
      if (yn) {
        return "L'utilisateur peut répondre à votre message.";
      } else {
        return "L'utilisateur ne peut pas répondre à votre message.";
      }
    },

    async add() {
      var obj = this.getTicket;
      if (this.comment.length > 0 && this.email.length > 0) {
        var params = {
          email: this.email,
          message: this.comment,
          withResponse: this.resp,
          propFollowUpId: obj.propFollowUpID,
        };
        var res = await store.dispatch(
          "publicTicket/sendPublicMessage",
          params
        );
        obj.ticketActivities = res?.data?.activity?.rows;
        this.comment = "";
      }
    },

    setEmail(email) {
      this.email = email;
    },

    getDate(date) {
      return toolsFormat.getDateText(date);
    },

    getColor(source) {
      var color = {
        Created: "green",
        PublicQuestion: "purple",
        PublicResponse: "yellow",
        Update: "blue",
        ClosureResponse: "orange",
        Commentaire: "red",
      };
      return color[source];
    },

    getText(source) {
      var text = {
        Created: "Création du ticket",
        PublicQuestion: "Question à l'utilisateur",
        PublicResponse: "Réponse à l'utilisateur",
        Update: "Mise à jour du ticket",
        ClosureResponse: "Réponse fermer à l'utilisateur",
        Commentaire: "Commentaire",
      };
      return text[source];
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.clear();
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.email) {
        this.setEmail(obj.ticketFollowUp.email);
      }
      return obj;
    },

    getActivities() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      var act = [];

      if (obj.ticketActivities) {
        act = obj.ticketActivities;
      }
      act = act.filter(
        (ob) =>
          ob.source == "PublicQuestion" ||
          ob.source == "PublicResponse" ||
          ob.source == "ClosureResponse"
      );
      return act;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
