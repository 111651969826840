/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: usersList.js
 * @Last modified by:   florian
 * @Last modified time: 20-Jan-2021
 */

import axios from "axios";
import Vue from "vue";
import server from "../server.js";
import servicesError from "../ServicesError.js";
import store from "@/store";

const state = {
  loading: false,
  error: {},
  data: {},
  openTicket: null,
  badgeResult: null,
  tickets: [],
  folders: null,
  ticketEnAttente: null,
  ticketResponse: null,
};

const getters = {
  getNbTicket: (state) => (ticket) => {
    if (state.tickets && state.tickets[ticket]) {
      return state.tickets[ticket].count;
    }
    return 0;
  },

  getOpenTicket(context) {
    return context.openTicket;
  },

  getData(context) {
    return context.data;
  },

  getBadgeResult(context) {
    return context.badgeResult;
  },

  getFolders(state, data) {
    return state.folders;
  },

  getTicketEnAttente(state, data) {
    return state.ticketEnAttente;
  },

  getTicketResponse(state, data) {
    return state.ticketResponse;
  },

  getTicket: (state) => (ticket) => {
    if (state.tickets && state.tickets[ticket]) {
      return state.tickets[ticket];
    }
    return [];
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setTicketEnAttente(context, ticket) {
    context.ticketEnAttente = ticket;
  },

  setTicketResponse(context, ticket) {
    context.ticketResponse = ticket;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setTicket(context, ticket) {
    context.openTicket = ticket;
  },

  updateTicket(state, data) {
    if (state.tickets) {
      for (const [key, value] of Object.entries(state.tickets)) {
        var objIndex = state.tickets[key].rows.findIndex(
          (obj) => obj.id == data.id
        );

        if (objIndex != -1) {
          var ar = state.tickets[key];
          if (data && data.followUp) {
            if (data.followUp.comment && ar.rows[objIndex].PropFollowUp) {
              ar.rows[objIndex].PropFollowUp.comment = data.followUp.comment;
            }
            if (data.followUp.badges && ar.rows[objIndex].PropFollowUp) {
              ar.rows[objIndex].PropFollowUp.badges = data.followUp.badges;
            }

            if (ar.rows[objIndex].PropFollowUp == null) {
              ar.rows[objIndex].propFollowUpID = data.followUp.id;
              ar.rows[objIndex].PropFollowUp = data.followUp;
            }
            Vue.set(state.tickets, key, ar);
          }
        }
      }
    }
  },

  removeTicket(state, id) {
    if (state.data && state.data.type) {
      var obj = state.tickets[state.data.type];
      obj.rows = state.tickets[state.data.type].rows.filter(function (el) {
        return el.id != id;
      });
      Vue.set(state.tickets, state.data.type, obj);
    }
  },

  setData(state, data) {
    state.data = data;
    if (data && data.tickets && data.type) {
      Vue.set(state.tickets, data.type, data.tickets);
    }
  },

  addDataTickets(state, data) {
    if (data && data.tickets && data.type && state.tickets[data.type]) {
      var all_tickets = {};
      all_tickets.count = state.tickets[data.type].count;
      all_tickets.rows = state.tickets[data.type].rows.concat(
        data.tickets.rows
      );
      Vue.set(state.tickets, data.type, all_tickets);
    }
  },

  setFolder(context, data) {
    context.folders = data;
  },

  setBadgeResult(context, data) {
    context.badgeResult = data;
  },

  setBadges(context, data) {
    if (context.badgeResult && context.badgeResult.badges && data.badges) {
      context.badgeResult.badges = data.badges;
    }
  },

  setTicketSplit(context, data) {
    if (context.tickets) {
      for (const [key, value] of Object.entries(context.tickets)) {
        var objIndex = context.tickets[key].rows.findIndex(
          (obj) => obj.id == data.updated.id
        );

        if (objIndex != -1) {
          var ar = context.tickets[key];
          ar.rows[objIndex].description = data.updated.description;
          //ar.unshift(data.created);

          var newar = [];
          for (var i = 0; i < ar.rows.length; i++) {
            if (i == objIndex + 1) {
              newar.push(data.created);
            }
            newar.push(ar.rows[i]);
          }
          ar.rows = newar;
          Vue.set(context.tickets, key, ar);
        }
      }
    }
  },
};

const actions = {
  // Récupèrer les tickets
  async serviceGet(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.tickets + params,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data.data);

      if (res.data.data.folders) {
        context.commit("setFolder", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //Récupèrer plus de tickets
  async serviceGetMoreTickets(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.tickets + params,
        headers: server.getHeader(),
      });
      //context.commit("setResponse", res);
      context.commit("addDataTickets", res.data.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  // Récupèrer les badges
  async serviceGetBadges(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.ticketsBadges,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setBadgeResult", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  // Créer un Badge
  async servicePostBadges(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.ticketsBadges,
        headers: server.getHeader(),
        data: params,
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setBadges", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  // Récupèrer les folder (Statut)
  // DEPRECATED
  /*async serviceGetFolder(context) {
    if (store.getters["tickets/getFolder"] != null) {
      return;
    }
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.ticketsFolders,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setFolder", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },*/

  // POST pour l'attribution d'un ticket
  async servicePostExtension(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.tickets + "/" + params.url,
        data: params.data,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        // Si il réussi on actualise la liste courante
        //store.dispatch("tickets/serviceGet", "/" + params.urlGet.slice(-1)[0]);
        if (
          res.data.data.extension.traite != undefined ||
          res.data.data.extension.aSupprimer != undefined
        ) {
          if (res.data.data.ticketId) {
            context.commit("removeTicket", res.data.data.ticketId);
          }
        } else if (res.data.data.followUp) {
          context.commit("updateTicket", {
            id: res.data.data.ticketId,
            followUp: res.data.data.followUp,
          });
        }

        // On envoie une notification sur la page web
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "ticketsAttribution",
        });
        if (res.data.data.folders) {
          context.commit("setFolder", res.data.data);
        }
      } else {
        // On envoie une notification erreur sur la page web
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "ticketsAttribution",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  // Create ticket
  async servicePostCreatedTickets(context, params) {
    context.commit("setLoading", true);
    var h = server.getHeader();
    h["Content-Type"] = "multipart/form-data";

    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.createdTicket,
        data: params,
        headers: h,
      });
      if (res.data && res.data.data && res.data.data.folders) {
        context.commit("setFolder", res.data.data);
      }

      return res;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Delete badges
  async serviceDeleteBadges(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url: server.url + server.urls.ticketsBadges,
        data: params,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setBadges", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Create Folder (Statut)
  async servicePostFolder(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.ticketsFolders,
        data: params,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setFolder", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Delte folder
  async serviceDeleteFolder(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url: server.url + server.urls.ticketsFolders,
        data: params,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setFolder", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Récupèrer les tickets en attente
  async serviceTicketEnAttente(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.ticketsEnAttente,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setTicketEnAttente", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Récupèrer les tickets avec une Réponse
  async serviceTicketResponse(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.ticketsResponse,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setTicketResponse", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },

  // Scinder en deux un ticket
  async serviceSplitTicket(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.splitTicket + "/" + params.id,
        data: params.data,
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.success && res.data.data) {
        context.commit("setTicketSplit", res.data.data);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return error.response.data;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
