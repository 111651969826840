<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: NavTabsTable.vue
@Last modified by:   florian
@Last modified time: 06-Jan-2021
-->

<!-- @mouseover="hover = true"
@mouseleave="hover = false"
-->
<template>
  <div>
    <md-table v-if="tickets.rows" v-model="tickets.rows">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell>
          <div class="md-layout">
            <div class="md-layout" style="margin-top: 5px">
              <ticket :item="item" :edition_all="edition_all"></ticket>
            </div>
          </div>
        </md-table-cell>
        <md-table-cell> </md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="tickets && tickets.rows && tickets.rows.length == 0">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center">Aucun ticket trouvé.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Ticket from "./Ticket.vue";

export default {
  name: "tickets-table",
  components: {
    Ticket,
  },
  props: ["edition_all"],
  computed: {
    tickets() {
      return store.getters["tickets/getTicket"](this.$route.params.type);
    },
  },
};
</script>

<style lang="scss">
.emailTicket {
  margin-bottom: 5px;
  font-size: 12px;
  color: gray;
}

.dateTicket {
  margin-bottom: 5px;
  font-size: 12px;
  color: #e2007a;
  opacity: 0.5;
}

.colorTextProkov {
  color: #e2007a;
  opacity: 0.5;
}

.typeTicket {
  font-size: 12px;
  color: gray;
}

.commentText {
  font-style: italic;
}
</style>
