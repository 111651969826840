/**
 * @Author: Florian Merel <florian>
 * @Date:   23-Jul-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: TicketsV2Filters.js
 * @Last modified by:   florian
 * @Last modified time: 01-Sep-2021
 */

import axios from "axios";
import server from "../../server.js";
import servicesError from "../../ServicesError.js";
import Vue from "vue";

const state = {
  loading: false,
  error: {},
  data: {},
  tickets: {},
  select_ticket: null,
};

const getters = {
  getData(state) {
    return state.data;
  },

  getDataTickets(state) {
    return state.tickets;
  },

  getDataTickets: (state) => (id) => {
    if (state.tickets && state.tickets[id]) {
      return state.tickets[id];
    }
    return null;
  },

  getSelectTickets(state) {
    return state.select_ticket;
  },
};

const mutations = {
  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },

  setDataTickets(context, data) {
    Vue.set(context.tickets, data.id, data);
  },

  setSelectTickets(context, data) {
    context.select_ticket = data;
  },
};

const actions = {
  async getFilters(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.ticketV2filters,
        headers: server.getHeader(),
      });
      context.commit("setData", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async postTicketsFilter(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.ticketV2filter + "/" + params.id,
        headers: server.getHeader(),
        data: params.data,
      });
      res.data.id = params.id;
      context.commit("setDataTickets", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        //context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
    return null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
