<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: ProfessionField.vue
@Last modified by:   florian
@Last modified time: 10-Nov-2020
-->

<template>
  <md-field>
    <label for="prospec">Spécialité</label>
    <md-select v-model="myProf">
      <md-option value="G15_10/R01_SM01"
        >Anatomie et cytologie pathologiques</md-option
      >
      <md-option value="G15_10/R01_SM02">Anesthesie-réanimation</md-option>
      <md-option value="G15_10/R01_SM03">Biologie médicale</md-option>
      <md-option value="G15_10/R01_SM04"
        >Cardiologie et maladies vasculaires</md-option
      >
      <md-option value="G15_10/R01_SM05">Chirurgie générale</md-option>
      <md-option value="G15_10/R01_SM06">Chirurgie Maxillo-Faciale</md-option>
      <md-option value="G15_10/R01_SM07"
        >Chirurgie maxillo-faciale et stomatologie</md-option
      >
      <md-option value="G15_10/R01_SM08"
        >Chirurgie orthopédique et traumatologie</md-option
      >
      <md-option value="G15_10/R01_SM09">Chirurgie infantile</md-option>
      <md-option value="G15_10/R01_SM10"
        >Chirurgie plastique reconstructrice et esthétique</md-option
      >
      <md-option value="G15_10/R01_SM11"
        >Chirurgie thoracique et cardio-vasculaire</md-option
      >
      <md-option value="G15_10/R01_SM12">Chirurgie urologique</md-option>
      <md-option value="G15_10/R01_SM13">Chirurgie vasculaire</md-option>
      <md-option value="G15_10/R01_SM14"
        >Chirurgie viscérale et digestive</md-option
      >
      <md-option value="G15_10/R01_SM15"
        >Dermatologie et vénéréologie</md-option
      >
      <md-option value="G15_10/R01_SM16"
        >Endocrinologie et métabolisme</md-option
      >
      <md-option value="G15_10/R01_SM17">Génétique médicale</md-option>
      <md-option value="G15_10/R01_SM18">Gériatrie</md-option>
      <md-option value="G15_10/R01_SM19">Gynécologie médicale</md-option>
      <md-option value="G15_10/R01_SM20">Gynécologie-obstétrique</md-option>
      <md-option value="G15_10/R01_SM21">Hématologie</md-option>
      <md-option value="G15_10/R01_SM22"
        >Hématologie (option Maladie du sang)</md-option
      >
      <md-option value="G15_10/R01_SM23"
        >Hématologie (option Onco-hématologie)</md-option
      >
      <md-option value="G15_10/R01_SM24"
        >Gastro-entérologie et hépatologie</md-option
      >
      <md-option value="G15_10/R01_SM25">Médecine du travail</md-option>
      <md-option value="G15_10/R01_SM26">Médecine générale</md-option>
      <md-option value="G15_10/R01_SM27">Médecine interne</md-option>
      <md-option value="G15_10/R01_SM28">Médecine nucléaire</md-option>
      <md-option value="G15_10/R01_SM29"
        >Médecine physique et réadaptation</md-option
      >
      <md-option value="G15_10/R01_SM30">Néphrologie</md-option>
      <md-option value="G15_10/R01_SM31">Neurochirurgie</md-option>
      <md-option value="G15_10/R01_SM32">Neurologie</md-option>
      <md-option value="G15_10/R01_SM33">Neuro-psychiatrie</md-option>
      <md-option value="G15_10/R01_SM34"
        >O.R.L et chirurgie cervico faciale</md-option
      >
      <md-option value="G15_10/R01_SM35"
        >Oncologie (option onco-hématologie)</md-option
      >
      <md-option value="G15_10/R01_SM36">Oncologie option médicale</md-option>
      <md-option value="G15_10/R01_SM37"
        >Oncologie option radiothérapie</md-option
      >
      <md-option value="G15_10/R01_SM38">Ophtalmologie</md-option>
      <md-option value="G15_10/R01_SM39">Oto-rhino-laryngologie</md-option>
      <md-option value="G15_10/R01_SM40">Pédiatrie</md-option>
      <md-option value="G15_10/R01_SM41">Pneumologie</md-option>
      <md-option value="G15_10/R01_SM42">Psychiatrie</md-option>
      <md-option value="G15_10/R01_SM43"
        >Psychiatrie option enfant et adolescent</md-option
      >
      <md-option value="G15_10/R01_SM44">Radio-diagnostic</md-option>
      <md-option value="G15_10/R01_SM45">Radio-thérapie</md-option>
      <md-option value="G15_10/R01_SM46">Réanimation médicale</md-option>
      <md-option value="G15_10/R01_SM47">Recherche médicale</md-option>
      <md-option value="G15_10/R01_SM48">Rhumatologie</md-option>
      <md-option value="G15_10/R01_SM49"
        >Santé publique et médecine sociale</md-option
      >
    </md-select>
  </md-field>
</template>

<script>
import store from "@/store";

export default {
  name: "profession-spec-field",
  //props: ['profession'],
  props: {
    profession: {
      type: String,
      default: "G15_10/R01_SM01",
    },
  },
  data() {
    return {
      prof: null,
    };
  },
  computed: {
    myProf: {
      get() {
        return this.profession;
      },
      set(newValue) {
        this.prof = newValue;
        store.commit("user/setObj", { spec: newValue });
      },
    },
  },
};
</script>
<style></style>
