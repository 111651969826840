<!--
@Author: Florian Merel <florian>
@Date:   09-Dec-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: CardsFollowUp.vue
@Last modified by:   florian
@Last modified time: 08-Jan-2021
-->

<template>
  <md-card style="margin-top: 0px">
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-80">
        <span class="emailTicket">{{ item.EeUser.email }}</span>
      </div>

      <!--<md-icon class="rightArrow">keyboard_arrow_right</md-icon>
      <md-icon class="leftArrow">keyboard_arrow_left</md-icon>-->
      <div
        @click="$refs.diacommunication.open()"
        class="md-layout-item md-xsmall-size-100 md-size-20 text-right"
        style="margin-top: 5px"
      >
        <md-avatar class="md-avatar-icon md-small"
          ><md-icon>edit</md-icon></md-avatar
        >
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-100">
        <span style="font-size: 13px; color: green">{{ item.sujet }}</span>
        <br />
        <span style="font-size: 13px">{{ item.description }} </span>
      </div>

      <div
        class="md-layout-item md-xsmall-size-100 md-size-100"
        style="margin-bottom: 10px"
      >
        <md-chip
          class="badgesChips"
          style=""
          v-for="chip in badges"
          :key="chip"
          >{{ chip }}</md-chip
        >
      </div>
    </div>

    <dialog-communication
      ref="diacommunication"
      :item="item"
    ></dialog-communication>
  </md-card>
</template>

<script>
import DialogCommunication from "../Dialogs/DialogCommunication.vue";

export default {
  name: "follow-up-card",
  props: ["item"],
  components: {
    DialogCommunication,
  },
  data: () => ({
    badgesCorrespondance: ["Claude", "Florian", "EtherEgo", "Station"],
  }),
  computed: {
    info: {
      get() {
        return this.item;
      },
    },
    badges: {
      get() {
        if (this.item.PropFollowUp) {
          const bds = JSON.parse(this.item.PropFollowUp.badges);
          return bds;
        }
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.rightArrow {
  right: 0;
  top: 50%;
  color: #1abc9c !important;
  position: absolute;
}

.leftArrow {
  left: 0;
  top: 50%;
  color: #e74c3c !important;
  position: absolute;
}
</style>
