<!--
@Author: Florian Merel <florian>
@Date:   27-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Liste.vue
@Last modified by:   florian
@Last modified time: 20-Sep-2021
-->

<template>
  <div class="ticketContenu">
    <v-card v-if="getTicket == null || getTicket.ticketFollowUp == null">
      <v-banner two-line> Aucun ticket n'a été trouvée. </v-banner>
    </v-card>

    <v-card
      elevation="0"
      tile
      class=""
      v-if="getTicket != null && getTicket.ticketFollowUp != null"
    >
      <div class="md-layout">
        <tickets-data></tickets-data>
        <tickets-files></tickets-files>

        <div
          class="md-layout-item md-medium-size-100 md-size-100"
          style="margin-top: 15px"
        >
          <md-divider></md-divider>
        </div>

        <tickets-type></tickets-type>
        <tickets-badges></tickets-badges>
        <tickets-keywords></tickets-keywords>
        <tickets-follow></tickets-follow>

        <div
          class="md-layout-item md-medium-size-100 md-size-100"
          style="margin-top: 15px; margin-bottom: 15px"
        >
          <md-divider></md-divider>
        </div>

        <v-tabs
          v-model="tabs"
          background-color="blue"
          dark
          style="margin-right: 10px; margin-left: 10px"
        >
          <v-tab key="1"> Activité </v-tab>

          <v-tab key="2" v-if="!getIsAssistance()"> Mail </v-tab>

          <v-tab key="3" v-if="!getIsAssistance()"> Option </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs" style="width: 100%">
          <v-tab-item key="1">
            <tickets-steps></tickets-steps>
          </v-tab-item>
          <v-tab-item key="2">
            <tickets-mail></tickets-mail>
          </v-tab-item>

          <v-tab-item key="3">
            <tickets-option></tickets-option>
          </v-tab-item>
        </v-tabs-items>

        <!--

        <div class="md-layout-item md-medium-size-100 md-size-100">
          <v-container>
            <v-timeline dense clipped>
              <v-slide-x-transition group>
                <v-timeline-item
                  v-for="event in timeline"
                  :key="event.id"
                  class="mb-4"
                  color="pink"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="7" v-text="event.text"></v-col>
                    <v-col
                      class="text-right"
                      cols="5"
                      v-text="event.time"
                    ></v-col>
                  </v-row>
                </v-timeline-item>
              </v-slide-x-transition>

              <v-timeline-item
                class="mb-4"
                color="grey"
                icon-color="grey lighten-2"
                small
              >
                <v-row justify="space-between">
                  <v-col cols="7"> This order was archived. </v-col>
                  <v-col class="text-right" cols="5"> 15:26 EDT </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item class="mb-4" small>
                <v-row justify="space-between">
                  <v-col cols="7">
                    <v-chip class="white--text ml-0" color="purple" label small>
                      APP
                    </v-chip>
                    Digital Downloads fulfilled 1 item.
                  </v-col>
                  <v-col class="text-right" cols="5"> 15:25 EDT </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item class="mb-4" color="grey" small>
                <v-row justify="space-between">
                  <v-col cols="7">
                    Order confirmation email was sent to John Leider
                    (john@vuetifyjs.com).
                  </v-col>
                  <v-col class="text-right" cols="5"> 15:25 EDT </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item class="mb-4" hide-dot>
                <v-btn class="mx-0"> Resend Email </v-btn>
              </v-timeline-item>

              <v-timeline-item class="mb-4" color="grey" small>
                <v-row justify="space-between">
                  <v-col cols="7">
                    A $15.00 USD payment was processed on PayPal Express
                    Checkout
                  </v-col>
                  <v-col class="text-right" cols="5"> 15:25 EDT </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="grey" small>
                <v-row justify="space-between">
                  <v-col cols="7">
                    John Leider placed this order on Online Store (checkout
                    #1937432132572).
                  </v-col>
                  <v-col class="text-right" cols="5"> 15:25 EDT </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </div>
      -->

        <!-- FILES -->
        <!--<div>


          <v-container
              class="fill-height"
              fluid
            >
              <v-fade-transition mode="out-in">
                <v-row>
                  <v-col cols="6">
                    <v-card>
                      <v-img
                        contain
                        src="https://picsum.photos/id/11/10/6"
                        max-height="300"
                        max-width="500"
                      ></v-img>
                      <v-card-title class="body-2">
                        height
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-container>

        </div>-->
      </div>
    </v-card>
    <!--<div v-else>
      <h1> bonsoir </h1>
    </div>-->
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";
import Vue from "vue";
import Tickets_Tools from "./../Tickets_Tools.js";

import TicketsBadges from "./TicketsBadges.vue";
import TicketsType from "./TicketsType.vue";
import TicketsFiles from "./TicketsFiles.vue";
import TicketsData from "./TicketsData.vue";
import TicketsKeywords from "./TicketsKeywords.vue";
import TicketsFollow from "./TicketsFollow.vue";
import TicketsSteps from "./TicketsSteps.vue";
import TicketsMail from "./TicketsMail.vue";
import TicketsOption from "./TicketsOption.vue";

export default {
  name: "tickets-open",
  components: {
    TicketsBadges,
    TicketsType,
    TicketsFiles,
    TicketsData,
    TicketsKeywords,
    TicketsFollow,
    TicketsSteps,
    TicketsMail,
    TicketsOption,
  },
  data() {
    return {
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    getIsAssistance() {
      return store.getters["authentification/getIsAssistance"];
    },

    // ------ FOLDER ------
    getChipsFolder(ticket) {
      return toolsFormat.getChipsFolder(ticket);
    },

    getChipsFolderColor(ticket) {
      return toolsFormat.getChipsFolderColor(ticket);
    },

    // ----- Identité -----
    getIdentity(ticket) {
      return Tickets_Tools.getIdentity(ticket);
    },

    getMail(ticket) {
      return Tickets_Tools.getMail(ticket);
    },

    // ------ TYPE ------
    getChipsTypeColor(type) {
      return toolsFormat.getChipsTypeColor(type);
    },

    async download(file) {
      let res = await store.dispatch("downloadFiles/downloadFiles", {
        id: file.id,
        fileName: file.originalname,
      });
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created: async function () {},
};
</script>

<style lang="scss" scoped>
.ticketContenu {
  margin-top: 12px;
  background-color: #dcdcdc !important;
  padding-right: 1px;
  padding-left: 1px;
  padding-bottom: 1px;
  padding-top: 1px;
}
</style>
