<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: UsersTable.vue
@Last modified by:   florian
@Last modified time: 08-Dec-2020
-->

<template>
  <div>
    <md-table v-model="users" @md-selected="onSelect" v-if="users.length > 0">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Email"
          >{{ getInterne(item) }} {{ item.email }}</md-table-cell
        >
        <md-table-cell md-label="Nom">{{ item.familyname }}</md-table-cell>
        <md-table-cell md-label="Prénom">{{ item.givenname }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="users.length == 0">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center">
            La recherche avec "{{ this.$route.query.q }}" ne trouve aucun
            utilisateur.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "users-table",
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    getInterne(item) {
      if (item.prokov) {
        return "🏆";
      }

      /*
      // ----- Pas très utile ----
      if (item.id == "31877") {
        return "🐼";
      } // Ludovic
      if (item.id == "31883") {
        return "🐯";
      } // Laurent
      if (item.id == "31890") {
        return "🦁";
      } // Lionel
      if (item.id == "31906") {
        return "🐻";
      } // Damien
      */

      return "";
    },

    onSelect(item) {
      var obj = { u: undefined };
      if (item && item.id) {
        obj = { u: item.id };
      } else {
        return;
      }

      if (this.$route.query.u == obj.u) {
        return;
      }
      this.$router.push({ query: Object.assign({}, this.$route.query, obj) });
    },
  },
  computed: {
    users() {
      return store.getters["usersList/getUsers"];
    },
  },
};
</script>
