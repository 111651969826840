<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 15-Sep-2021
-->

<template>
  <div class="content">
    <div class="md-layout-item md-large-size-100 md-size-100">
      <md-button class="md-prokov" to="/ticketsv2/filters/" :disabled="loading">
        <md-icon style="margin-right: 10px; margin-left: -20px">undo</md-icon>
        Retour</md-button
      >
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <form>
          <md-card>
            <md-card-header data-background-color="prokov">
              <h4 class="title">Créer un ticket</h4>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <div class="md-subheading">Ticket</div>
                  <md-divider />
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-50"
                  style="margin-bottom: -10px"
                >
                  <md-field>
                    <label>Email</label>
                    <md-input v-model="email" type="text"></md-input>
                  </md-field>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-50"
                  style="margin-bottom: -10px"
                >
                  <md-field>
                    <label>Email de contact</label>
                    <md-input v-model="contactEmail" type="text"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-50"
                  style="margin-bottom: -10px"
                >
                  <md-field>
                    <label>Titre</label>
                    <md-input v-model="titre" type="text"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-50"
                  style="margin-bottom: -10px"
                >
                  <select-type-tickets
                    :multiple="false"
                    v-model="selectedType"
                  ></select-type-tickets>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Spécialités</label>
                    <md-input v-model="spec" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Références</label>
                    <md-input v-model="ref1" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Autre référence</label>
                    <md-input v-model="ref2" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>Autre référence</label>
                    <md-input v-model="ref3" type="text"></md-input>
                  </md-field>
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  style="margin-top: 10px"
                >
                  <div class="md-subheading">Description</div>
                  <md-divider />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <md-textarea v-model="description"></md-textarea>
                  </md-field>
                </div>

                <div
                  class="md-layout-item md-medium-size-100 md-size-100"
                  style="margin-top: 10px"
                >
                  <div class="md-subheading">Informations, Badges</div>
                  <md-divider />
                </div>

                <div
                  class="md-layout-item md-small-size-100 md-size-100"
                  style="margin-bottom: -10px"
                >
                  <badges-lists-chips></badges-lists-chips>
                </div>

                <div
                  class="md-layout-item md-size-100 text-right"
                  style="margin-top: 10px"
                >
                  <md-divider />
                  <md-button
                    class="md-raised md-prokov"
                    @click="createdTicket()"
                    :disabled="loading"
                    >Créer</md-button
                  >
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>

      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-content
          class=""
          v-bind:class="{
            borderExit: borderDragAndDrop,
            borderEnter: !borderDragAndDrop,
          }"
          v-cloak
          @drop.prevent="addFile"
          @dragover.prevent
          @dragenter="enterDrag()"
          @dragleave="exitDrag()"
        >
          <div>
            <md-icon style="margin-right: 20px">backup</md-icon>
          </div>
          Glisser et Deposer des fichiers ici
        </md-content>

        <!--<form action="http://localhost:3004/uploadfiles"  enctype="multipart/form-data" method="POST">
          Select images: <input type="file" name="myFiles" multiple>
          <input type="submit" value="Upload your files"/>
        </form>-->

        <div style="margin-bottom: 30px"></div>
        <md-card v-for="(file, i) in files" :key="i" style="margin-top: -30px">
          <div class="md-layout">
            <div class="md-layout-item md-xsmall-size-100 md-size-70">
              <div class="filesUploadText">
                {{ file.name }} ({{ file.size | kb }} kb)
              </div>
            </div>
            <div
              class="md-layout-item md-xsmall-size-100 md-size-30 text-right"
            >
              <md-button class="md-raised md-prokov" @click="removeFile(file)"
                ><md-icon style="">delete</md-icon></md-button
              >
            </div>
          </div>
        </md-card>
      </div>

      <div class="loading-overlay centerSpinner" v-if="loading">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-stroke="2"
        ></md-progress-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { BadgesListsChips, SelectTypeTickets } from "@/components";
import store from "@/store";
import Vue from "vue";

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.filter("kb", (val) => {
  return Math.floor(val / 1024);
});

export default {
  components: {
    BadgesListsChips,
    SelectTypeTickets,
  },
  data: () => ({
    description: "",
    statutSelect: null,
    selectedType: null,
    titre: "",
    spec: "",
    ref1: "",
    ref2: "",
    ref3: "",
    email: "",
    loading: false,
    contactEmail: "",
    files: [],
    borderDragAndDrop: true,
  }),
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
    //loading() {
    //  return store.getters["uploadFiles/getLoading"];
    //},
    ticketsInput() {
      return store.getters["tickets/getBadgeResult"];
    },
    statut() {
      return this.ticketsInput.statut;
    },
  },
  beforeMount() {
    this.email = store.getters["authentification/getEmail"];
    if (this.ticketsInput == null) {
      store.dispatch("tickets/serviceGetBadges");
    } else {
      for (var i = 0; i < this.ticketsInput.badges.rows.length; i++) {
        Vue.set(this.ticketsInput.badges.rows[i], "select", false);
      }
    }
  },
  methods: {
    getSelectBadgesWithCat(cat) {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) =>
            element.cat == cat &&
            element.select != undefined &&
            element.select == true
        );
        ar = [...new Set(ar.map((item) => item.name))];
      }
      return ar;
    },

    async createdTicket() {
      if (
        this.email.length == 0 ||
        this.description.length == 0 ||
        this.titre.length == 0
      ) {
        var msg = "Echec de création un email est obligatoire.";
        if (this.email.length == 0) {
          msg = "Echec de création un email est obligatoire.";
        }
        if (this.titre.length == 0) {
          msg = "Echec de création une titre est obligatoire.";
        } else if (this.description.length == 0) {
          msg = "Echec de création un description est obligatoire.";
        }

        store.dispatch("notification/sendNotification", {
          message: msg,
          icon: "warning",
          type: "danger",
        });
        return;
      }

      let params = new FormData();
      //var params = {};
      params.append("email", this.email);
      params.append("sujet", this.titre);
      params.append("description", this.description);
      if (this.selectedType != null) {
        params.append("kindID", this.selectedType);
      }
      if (this.statutSelect != null) {
        params.append("status", this.statutSelect);
      }
      params.append("ref1", this.ref1);
      params.append("ref2", this.ref2);
      params.append("ref3", this.ref3);
      params.append("specialites", this.spec);
      params.append("contactEmail", this.contactEmail);

      //let params = new FormData();
      this.files.forEach((f, x) => {
        params.append("myFiles", f);
      });

      var arBadge = [];
      arBadge = this.ticketsInput.badges.rows.filter(
        (element) => element.select != undefined && element.select == true
      );

      params.append(
        "badges",
        JSON.stringify([...new Set(arBadge.map((item) => item.name))])
      );
      //params.badges = [...new Set(arBadge.map((item) => item.name))];
      this.loading = true;
      var t = await store.dispatch("tickets/servicePostCreatedTickets", params);
      this.loading = false;

      if (t.data) {
        if (t.data.success) {
          this.email = "";
          this.titre = "";
          this.contactEmail = "";
          this.description = "";
          this.selectedType = null;
          this.statutSelect = null;
          this.ref1 = "";
          this.ref2 = "";
          this.ref2 = "";
          this.spec = "";
          this.files = [];

          for (var i = 0; i < this.ticketsInput.badges.rows.length; i++) {
            Vue.set(this.ticketsInput.badges.rows[i], "select", false);
          }

          store.dispatch("notification/sendNotification", {
            message: "Création du ticket réussi.",
            icon: "verified_user",
            type: "success",
          });
        } else {
          var msg = "Une erreur s'est produite merci de réessayer.";
          if (t.data.error) {
            msg = t.data.error;
          }
          store.dispatch("notification/sendNotification", {
            message: msg,
            icon: "warning",
            type: "danger",
          });
        }
      }
    },

    getBadgesWithCat(cat) {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) =>
            element.cat == cat &&
            (element.select == undefined || element.select == false)
        );
        ar = [...new Set(ar.map((item) => item.name))];
      }
      return ar;
    },

    clickChips(chip) {
      this.ticketsInput.badges.rows;
      const index = this.ticketsInput.badges.rows.findIndex(
        (element) => element.name == chip
      );

      if (this.ticketsInput.badges.rows[index].select) {
        Vue.set(this.ticketsInput.badges.rows[index], "select", false);
      } else {
        Vue.set(this.ticketsInput.badges.rows[index], "select", true);
      }
    },

    exitDrag() {
      this.borderDragAndDrop = true;
    },

    enterDrag() {
      this.borderDragAndDrop = false;
    },

    // UPLOAD SYSTEM
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },

    /*upload() {
      let formData = new FormData();
      this.files.forEach((f,x) => {
        formData.append('myFiles', f);
      });

      store.dispatch("uploadFiles/postFiles", formData);
    },*/
  },
};
</script>

<style lang="scss" scoped>
.md-content {
  width: 100%;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.borderExit {
  border: dashed #e2007a;
}

.borderEnter {
  border: dashed green;
}

.filesUploadText {
  margin-top: 18px;
}

.centerSpinner {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
