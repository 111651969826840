<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DashboardLayout.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div class="wrapper largre" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <!--     style="width: 100px;"
    :style="this.$route.path.includes('/ticketsv2') ? 'width: 100px;' : ''"
    :title="this.$route.path.includes('/ticketsv2') ? '' : 'EtherEgo'"
    -->
    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
      :style="this.$route.path.includes('/ticketsv2') ? 'width: 100px;' : ''"
      :title="this.$route.path.includes('/ticketsv2') ? '' : 'EtherEgo'"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard" :style="getStyles()">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>

      <sidebar-link
        to="/server"
        v-if="getRules('CONTROL_SERVER')"
        :style="getStyles()"
      >
        <md-icon>dns</md-icon>
        <p>Serveurs</p>
      </sidebar-link>

      <sidebar-link
        to="/analytics"
        v-if="getRules('CONTROL_ANALITICS')"
        :style="getStyles()"
      >
        <md-icon>analytics</md-icon>
        <p>Analytics</p>
      </sidebar-link>

      <sidebar-link to="/statistiques" :style="getStyles()">
        <md-icon>show_chart</md-icon>
        <p>Tracking</p>
      </sidebar-link>

      <!--
      <sidebar-link to="/versions" :style="getStyles()">
        <md-icon>content_copy</md-icon>
        <p>Versions</p>
      </sidebar-link>
      -->

      <sidebar-link
        :style="getStyles(30)"
        to="/users"
        v-if="getRules('CONTROL_USERS')"
      >
        <md-icon>supervisor_account</md-icon>
        <p>Utilisateurs</p>
      </sidebar-link>
      <sidebar-link
        to="/usersinscription"
        v-if="getRules('CONTROL_USERS_INSCRIPTION')"
        :style="getStyles()"
      >
        <md-icon>mark_email_unread</md-icon>
        <p>Inscription en cours</p>
      </sidebar-link>

      <sidebar-link
        to="/licences"
        v-if="getRules('CONTROL_LICENCES')"
        :style="getStyles()"
      >
        <md-icon>vpn_key</md-icon>
        <p>Licences</p>
      </sidebar-link>

      <sidebar-link
        to="/logs"
        v-if="getRules('CONTROL_LOGS')"
        :style="getStyles()"
      >
        <md-icon>format_list_numbered_rtl</md-icon>
        <p>Logs EtherEgo</p>
      </sidebar-link>

      <sidebar-link
        to="/parkmanagement"
        v-if="getRules('CONTROL_LICENCES')"
        :style="getStyles()"
      >
        <md-icon>devices</md-icon>
        <p>Gestion du parc</p>
      </sidebar-link>

      <sidebar-link
        to="/subscription"
        v-if="getRules('CONTROL_SEGUR_ASP')"
        :style="getStyles()"
      >
        <md-icon>feed</md-icon>
        <p>Abonnements</p>
      </sidebar-link>

      <sidebar-link
        to="/segur"
        v-if="getRules('CONTROL_SEGUR')"
        :style="getStyles()"
      >
        <md-icon>feed</md-icon>
        <p>Ségur</p>
      </sidebar-link>

      <sidebar-link
        to="/segurbon"
        v-if="getRules('CONTROL_SEGUR_ASP')"
        :style="getStyles()"
      >
        <md-icon>create</md-icon>
        <p>Ségur Création</p>
      </sidebar-link>

      <sidebar-link
        to="/segurasp"
        v-if="getRules('CONTROL_SEGUR_ASP')"
        :style="getStyles()"
      >
        <md-icon>send</md-icon>
        <p>Ségur ASP</p>
      </sidebar-link>

      <!--
      <sidebar-link
        to="/segurbon"
        v-if="getRules('CONTROL_SEGUR')"
        :style="getStyles()"
      >
        <md-icon>send</md-icon>
        <p>Ségur Bon</p>
      </sidebar-link>
-->

      <sidebar-link
        to="/mss"
        :style="getStyles()"
        v-if="getRules('CONTROL_SEGUR')"
      >
        <md-icon>email</md-icon>
        <p>MSS</p>
      </sidebar-link>

      <sidebar-link
        to="/services"
        :style="getStyles()"
        v-if="getRules('CONTROL_SEGUR')"
      >
        <md-icon>archive</md-icon>
        <p>Services/Caisses</p>
      </sidebar-link>

      <!--<sidebar-link
      to="/licences"
      style="margin-top: 30px"
      v-if="getRules('CONTROL_USERS')">
        <md-icon>vpn_key</md-icon>
        <p>Licences</p>
      </sidebar-link>-->

      <!-- <sidebar-link
        to="/tickets"
        :style="getStyles(30)"
        v-if="getRules('CONTROL_TICKETS')"
      >
        <md-icon>confirmation_number</md-icon>
        <p>Tickets</p>
      </sidebar-link>
-->
      <sidebar-link
        to="/ticketsv2/filters"
        :style="getStyles(30)"
        v-if="getRules('CONTROL_TICKETS')"
      >
        <md-icon>confirmation_number</md-icon>
        <p>tickets</p>
      </sidebar-link>

      <!--<sidebar-link
        to="/followup"
        v-if="getRules('CONTROL_FOLLOWUP')"
        :style="getStyles()"
      >
        <md-icon>developer_board</md-icon>
        <p>À Faire</p>
      </sidebar-link>-->

      <sidebar-link
        to="/providers"
        v-if="getRules('CONTROL_PROVIDERS')"
        :style="getStyles(30)"
      >
        <md-icon>local_shipping</md-icon>
        <p>Prestataires</p>
      </sidebar-link>

      <!--
      <sidebar-link to="/Community">
        <md-icon>groups</md-icon>
        <p>Communauté</p>
      </sidebar-link>-->

      <!--
      <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link>-->

      <!--<sidebar-link
        to="/administrator"
        style="margin-bottom: 60px"
        class="active-pro"
      >
        <md-icon>admin_panel_settings</md-icon>
        <p>Administrateur</p>
      </sidebar-link>-->

      <sidebar-link to="/disconnect" :style="getStyles(60)">
        <md-icon>exit_to_app</md-icon>
        <p>Déconnexion</p>
      </sidebar-link>

      <!--<sidebar-link to="/disconnect" class="active-pro" :style="getStyles()">
        <md-icon>exit_to_app</md-icon>
        <p>Déconnexion</p>
      </sidebar-link>-->
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content> </dashboard-content>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
//import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import store from "@/store";

//import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    //ContentFooter,
    MobileMenu,
    //FixedPlugin
  },
  data() {
    return {
      sidebarBackground: "prokov",
      sidebarBackgroundImage: require("@/assets/img/accueil.png"),
    };
  },
  computed: {
    allRules: {
      get() {
        var x = store.getters["authentification/getDroits"];
        return x;
      },
    },
  },
  beforeMount() {
    if (this.allRules == null) {
      store.dispatch("authentification/getTokenIsValid");
    }
  },
  created: function () {
    store.subscribe((mutation, state) => {
      if (mutation.type === "notification/setNotification") {
        var n = store.getters["notification/getNotification"];
        if (n.notif) {
          this.notifyVue(n);
          n.notif = false;
        }
      }
    });
  },

  methods: {
    removeToken() {},

    notifyVue(n) {
      this.$notify(n);
    },
    getRules(rules) {
      if (this.allRules) {
        return this.allRules.includes(rules);
      }
      return false;
    },
    getStyles(top) {
      var st = "";
      if (top) {
        st = "margin-top: " + top + "px;";
      }
      if (this.$route.path.includes("ticketsv2")) {
        st = st + " width: 83px;";
      }
      return st;
    },
    getTicketsv2() {
      return true;
    },
  },
};
</script>

<style>
/*
<img src="../assets/img/logo.png" />
*/
</style>
