<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Badges.vue
@Last modified by:   florian
@Last modified time: 17-Sep-2021
-->

<template>
  <div
    v-if="getTicket != null"
    class="md-layout-item md-medium-size-100 md-size-50"
    style="margin-top: 10px"
  >
    <div class="md-body-2" style="color: #e2007a">Badges</div>

    <vue-tags-input
      v-model="tag"
      :tags="tags"
      placeholder="Badges"
      :autocomplete-items="autocompleteItems"
      :add-only-from-autocomplete="true"
      @tags-changed="update"
      @focus="disabledKeyEvent"
      @blur="enabledKeyEvent"
    />
  </div>
</template>

<script>
import store from "@/store";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "tickets-badges",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      selectedBadges: [],
      chipsPrev: [],
      st: false,

      autocompleteItems: [],
      tag: "",
      tags: [],
    };
  },
  methods: {
    disabledKeyEvent() {
      store.dispatch("keyup/setKeyupAction", false);
    },

    enabledKeyEvent() {
      store.dispatch("keyup/setKeyupAction", true);
    },

    update(newTags) {
      this.tags = newTags;
      this.selectedBadges = [];
      var obj = this.getTicket;
      for (let i = 0; i < newTags.length; i++) {
        this.selectedBadges.push(newTags[i].text);
      }
      obj.ticketFollowUp.badges = JSON.stringify(this.selectedBadges);
    },

    getIsAssistance() {
      return store.getters["authentification/getIsAssistance"];
    },

    getBadgesTicket(obj) {
      var ar = [];
      this.tag = "";
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.badges) {
        let badges = this.getBadges;
        let jsbadge = JSON.parse(obj.ticketFollowUp.badges);
        for (let i = 0; i < jsbadge.length; i++) {
          const found = badges.find(
            (elem) => elem.id == jsbadge[i] || elem.name == jsbadge[i]
          );
          if (found) {
            ar.push({ text: found.name });
          }
        }
      }
      this.tags = ar;
    },
  },
  computed: {
    getBadges() {
      var obj = store.getters["ticketsV2Badges/getData"];
      if (obj != null && obj.data && obj.data.badges && obj.data.badges.rows) {
        return obj.data.badges.rows;
      }
      return [];
    },

    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.getBadgesTicket(obj);
      return obj;
    },
  },
  watch: {
    tag: {
      handler(val) {
        var ar = this.getBadges;
        this.autocompleteItems = [];
        if (val.length != 0) {
          ar = ar.filter((txt) =>
            txt.name.toUpperCase().includes(val.toUpperCase())
          );
          for (let i = 0; i < ar.length; i++) {
            this.autocompleteItems.push({ text: ar[i].name });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
/* style the background and the text color of the input ... */
.vue-tags-input {
  background: #ffffff !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent !important;
  color: #000000 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px !important;
  transition: border-bottom 200ms ease !important;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 0 !important;
  border-bottom: 0.1mm solid #e2007a !important;
}

.vue-tags-input .ti-input {
  border: 0 !important;
  border-bottom: 0.1mm solid rgb(220, 220, 220, 1) !important;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #ffffff !important;
  border: 1px solid #edf1f3 !important;
  border-top: none !important;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #e2007a !important;
  color: #ffffff !important;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6 !important;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6 !important;
}

//.vue-tags-input .ti-tag.tiv

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6 !important;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative !important;
  background: #e2007a !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74 !important;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #000000 !important;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through !important;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s !important;
  position: absolute !important;
  content: "" !important;
  height: 2px !important;
  width: 50% !important;
  left: 25% !important;
  top: calc(50% - 1px) !important;
  background-color: #000 !important;
  transform: scaleX(0) !important;
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(2) !important;
}
</style>
