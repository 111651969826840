<!--
@Author: Florian Merel <florian>
@Date:   22-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: parkmanagement.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <md-card style="margin-bottom: 50px">
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field style="">
              <label>Recherche</label>
              <md-input v-on:keyup.enter="search" v-model="query"></md-input>
            </md-field>
          </div>
        </div>
        <!--<md-button class="md-prokov researcheButton" @click="search()"
          >Rechercher</md-button
        >-->
      </md-card>

      <v-progress-linear
        v-if="parkList.length == 0"
        indeterminate
        color="prokov"
      ></v-progress-linear>

      <v-tabs v-model="tabs" fixed-tabs background-color="prokov" dark>
        <v-tab key="1">
          <v-badge color="black" :content="getNumberList(0)">
            Problème de synchronisation
          </v-badge>
        </v-tab>

        <v-tab key="2">
          <v-badge color="black" :content="getNumberList(1)">
            Déconnectée
          </v-badge>
        </v-tab>

        <v-tab key="3">
          <v-badge color="black" :content="getNumberList(2)">
            Connectée
          </v-badge>
        </v-tab>
      </v-tabs>

      <div :v-if="syncAck" style="width: 100%">
        <div
          class="md-layout-item md-medium-size-100 md-size-100"
          v-for="(ti, nb) in this.getParkList()"
          :key="nb"
        >
          <v-card
            elevation="0"
            tile
            class="md-layout-item"
            style="margin-bottom: 5px; margin-top: 5px"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ ti.cleBonjour }}
                  <span
                    style="float: right; color: green"
                    v-if="ti.PL_MsLicence && ti.PL_MsLicence.LicenceID"
                    >{{ ti.PL_MsLicence.LicenceID }}</span
                  >
                </div>
                <div
                  v-if="
                    ti.PL_MsLicence &&
                    ti.PL_MsLicence &&
                    ti.PL_MsLicence.PL_Licence &&
                    ti.PL_MsLicence.PL_Licence.customer
                  "
                  class="mb-4"
                  style="margin-top: -20px"
                >
                  {{ ti.PL_MsLicence.PL_Licence.customer }}
                </div>

                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <v-list-item-title class="caption" style="color: green">
                      Depuis le début
                    </v-list-item-title>
                    <div v-if="ti.st">
                      <div v-for="sta in ti.st" :key="sta.status">
                        <v-list-item-subtitle
                          v-bind:style="getClass(sta.status)"
                        >
                          {{ sta.count }} {{ getText(sta.status) }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-list-item-title class="caption" style="color: green">
                      Dernière 48h
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="ti.la48h.length == 0">
                      _ _ _
                    </v-list-item-subtitle>

                    <div v-if="ti.la48h">
                      <div v-for="sta in ti.la48h" :key="sta.status">
                        <v-list-item-subtitle
                          v-bind:style="getClass(sta.status)"
                        >
                          {{ sta.count }} {{ getText(sta.status) }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="prokov" text @click="selectOverLay(ti)">
                Voir les logs
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>

      <v-dialog v-model="overlay" scrollable max-width="700px">
        <v-card v-if="selectOverlay">
          <v-card-title>{{ selectOverlay.cleBonjour }} </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="">
            <v-list-item-subtitle
              v-for="(ti, nb) in selectOverlay.la"
              :key="nb"
            >
              <v-row no-gutters v-bind:style="getClass(ti.status)">
                <v-col>
                  <div>{{ getDate(ti.time) }}</div>
                </v-col>

                <v-col>
                  <div>{{ getText(ti.status) }}</div>
                </v-col>

                <v-col>
                  <div>{{ ti.comments }}</div>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="prokov" text @click="overlay = false"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "Parkmanagement",
  data() {
    return {
      loading: false,
      tabs: null,
      parkList: [],
      syncPrb: [],
      syncDeco: [],
      syncCo: [],
      query: "",
      overlay: false,
      selectOverlay: null,
      zIndex: 999,
    };
  },
  created: function () {},
  watch: {
    query: function (value) {},
  },
  computed: {
    syncAck() {
      var res = store.getters["parkManagement/getData"];
      if (res == null) {
        store.dispatch("parkManagement/getSyncAck");
        return null;
      } else {
        this.analyser(res);
      }
      return true;
    },
  },
  methods: {
    analyser(response) {
      if (response && response.data && response.data.rows) {
        this.parkList = response.data.rows;
        for (let i = 0; i < this.parkList.length; i++) {
          if (this.parkList[i].status && this.parkList[i].status.length > 0) {
            this.parkList[i].st = JSON.parse(this.parkList[i].status);
          }
          if (
            this.parkList[i].lastAcks &&
            this.parkList[i].lastAcks.length > 0
          ) {
            this.parkList[i].la = JSON.parse(this.parkList[i].lastAcks).sort(
              function (a, b) {
                return b.time - a.time;
              }
            );
          }

          var dt = new Date(); //dt.setHours( dt.getHours() - 48);
          dt.setHours(dt.getHours() - 48);
          var dictLabel = [];

          const ar = this.parkList[i].la.filter((a) => new Date(a.time) > dt);
          for (let j = 0; j < ar.length; j++) {
            var df = dictLabel.findIndex((st) => st.status == ar[j].status);
            if (df != -1) {
              dictLabel[df].count += 1;
            } else {
              dictLabel.push({ status: ar[j].status, count: 1 });
            }
          }

          this.parkList[i].la48h = dictLabel;
          var countFailed = 0;
          var countSuccess = 0;

          for (var k in dictLabel) {
            if (dictLabel[k].status.includes("success")) {
              countSuccess += dictLabel[k].count;
            } else {
              countFailed += dictLabel[k].count;
            }
          }

          if (countFailed * 3 > countSuccess) {
            this.syncPrb.push(this.parkList[i]);
          } else if (ar.length == 0) {
            this.syncDeco.push(this.parkList[i]);
          } else {
            this.syncCo.push(this.parkList[i]);
          }
        }
      }
    },

    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    selectOverLay(obj) {
      this.selectOverlay = obj;
      this.overlay = true;
    },

    search() {},

    getNumberList(ta) {
      var ar = [];
      if (ta == 0) {
        ar = this.syncPrb;
      } else if (ta == 1) {
        ar = this.syncDeco;
      } else if (ta == 2) {
        ar = this.syncCo;
      }

      return ar.filter(
        (item) =>
          item.cleBonjour.toUpperCase().includes(this.query.toUpperCase()) ||
          (item.PL_MsLicence &&
            item.PL_MsLicence.LicenceID &&
            item.PL_MsLicence.LicenceID.includes(this.query)) ||
          (item.PL_MsLicence &&
            item.PL_MsLicence.PL_Licence &&
            item.PL_MsLicence.PL_Licence.customer &&
            item.PL_MsLicence.PL_Licence.customer
              .toUpperCase()
              .includes(this.query.toUpperCase()))
      ).length;
    },

    getParkList() {
      var ar = [];
      if (this.tabs == 0) {
        ar = this.syncPrb;
      } else if (this.tabs == 1) {
        ar = this.syncDeco;
      } else if (this.tabs == 2) {
        ar = this.syncCo;
      }

      return ar.filter(
        (item) =>
          item.cleBonjour.toUpperCase().includes(this.query.toUpperCase()) ||
          (item.PL_MsLicence &&
            item.PL_MsLicence.LicenceID &&
            item.PL_MsLicence.LicenceID.includes(this.query)) ||
          (item.PL_MsLicence &&
            item.PL_MsLicence.PL_Licence &&
            item.PL_MsLicence.PL_Licence.customer &&
            item.PL_MsLicence.PL_Licence.customer
              .toUpperCase()
              .includes(this.query.toUpperCase()))
      );
    },

    getClass(st) {
      if (st.includes("success")) {
        return "color: green";
      } else if (st.includes("failed")) {
        return "color: red";
      } else {
        return "color: purple";
      }
    },

    getText(st) {
      var txt = {
        synch_export_success: "Exportation réussie",
        synch_export_failed: "Exportation échouée",
        synch_import_failed: "Importation échouée",
        synch_import_success: "Importation réussie",
        server_unreachable: "Serveur inaccessible",
      };

      if (txt[st]) {
        return txt[st];
      } else {
        return st;
      }
    },
  },
};
</script>
