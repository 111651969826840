<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 23-Jul-2021
-->

<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "TicketsGestures",
  components: {},
  data: () => ({}),
  computed: {},
  created: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
