<!--
@Author: Thomas <thomas>
@Date:   01-Aug-2022
@Email:  thomas.meilender@prokov.com
@Project: EtherEgoAdmin
@Filename: Segurbon.vue
@Last modified by:   thomas
@Last modified time: 01-Aug-2022
-->

<template>
  <div class="content">
    <div class="md-layout">
      <h3>Étape 1 : Sélectionner le type de saisie</h3>

      <div class="md-layout-item md-medium-size-100 md-size-100">
        <v-radio-group v-model="orderTypeRadio" mandatory>
          <v-radio
            label="Bon de commande financé SEGUR"
            value="withinSegur"
          ></v-radio>
          <v-radio
            label="Pack SEGUR hors financement"
            value="outsideSegur"
          ></v-radio>
        </v-radio-group>
      </div>

      <h3>Étape 2 : Informations générales</h3>

      <div class="md-layout-item md-medium-size-100 md-size-100">
        <md-field style="margin-top: -10px">
          <label>Genre</label>
          <md-select v-model="genre" name="Titre" id="titre">
            <md-option value="22" selected>Madame</md-option>
            <md-option value="31">Monsieur</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Nom</label>
          <md-input v-model="nom"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Prénom</label>
          <md-input v-model="prenom"></md-input>
        </md-field>

        <md-field style="margin-top: 20px">
          <label>Téléphone</label>
          <md-input v-model="phone"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Email</label>
          <md-input v-model="email"></md-input>
        </md-field>

        <md-field style="margin-top: 20px">
          <label>Adresse</label>
          <md-input v-model="adr"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Code postal</label>
          <md-input v-model="cp"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Ville</label>
          <md-input v-model="city"></md-input>
        </md-field>
      </div>

      <v-radio-group v-model="radios" mandatory>
        <v-radio label="Individuel" value="ind"></v-radio>
        <v-radio label="MSP" value="msp"></v-radio>
      </v-radio-group>

      <div
        v-if="radios == 'ind'"
        class="md-layout-item md-medium-size-100 md-size-100"
      >
        <md-field style="margin-top: -20px">
          <label>RPPS</label>
          <md-input v-model="rpps"></md-input>
        </md-field>
      </div>

      <div
        v-if="radios == 'msp'"
        class="md-layout-item md-medium-size-100 md-size-100"
      >
        <md-field style="margin-top: -20px">
          <label>Finess</label>
          <md-input v-model="finess"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Montant Ségur HT</label>
          <md-input v-model="mtsegur"></md-input>
        </md-field>
      </div>

      <div
        v-if="orderTypeRadio == 'withinSegur'"
        class="md-layout-item md-medium-size-100 md-size-100"
      >
        <h3>
          Étape 3 : Informations relatives à la création d'un bon de commande
          Ségur
        </h3>
        <h4>Bon de commande</h4>

        <md-field style="margin-top: -20px">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Date de signature"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </md-field>

        <md-field style="margin-top: -20px">
          <label>Operateur de messagerie</label>
          <md-input v-model="opmess" v-on:input="mssupdate"></md-input>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés au DMP directement depuis
            le LGC ?</label
          >
          <md-select v-model="dmpLGC" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés au DMP via une application
            tierce ?</label
          >
          <md-select v-model="dmpt" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés par la MSS Pro directement
            depuis le LGC ?</label
          >
          <md-select v-model="mssLGC" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés par la MSS Pro via une
            application tierce ?</label
          >
          <md-select v-model="msst" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés par la MSS Citoyen
            directement depuis le LGC ?</label
          >
          <md-select v-model="msscLGC" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>

        <md-field style="margin-top: -20px">
          <label
            >Les documents de santé sont-ils envoyés par la MSS Citoyen via une
            application tierce ?</label
          >
          <md-select v-model="mssct" name="apps" id="apps">
            <md-option value="1" selected>Oui</md-option>
            <md-option value="0">Non</md-option>
          </md-select>
        </md-field>
      </div>

      <div class="md-layout-item md-medium-size-100 md-size-100">
        <v-btn color="blue-grey" class="ma-2 white--text" @click="createBDC()">
          Créer
        </v-btn>
      </div>

      <div
        v-if="orderTypeRadio == 'withinSegur'"
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: 100px; margin-bottom: 100px"
      >
        <h3>
          Étape 4 : Inscrire le numéro de bon de commande sur le PDF et
          envoyez-le :
        </h3>

        <input
          class="form-control"
          type="file"
          @change="downloadBDC"
          id="formFile"
        />

        <md-field style="margin-top: -20px">
          <label>Numéro éditeur</label>
          <md-input v-model="numediteur"></md-input>
        </md-field>

        <v-btn color="blue-grey" class="ma-2 white--text" @click="sendBdcPdf()">
          Envoyer le BDC
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import server from "../store/server.js";
import axios from "axios";

//import {required, email} from "vuelidate/lib/validators";

export default {
  name: "SegurBon",
  data() {
    return {
      orderTypeRadio: "withinSegur",
      genre: 22,
      nom: "",
      prenom: "",
      rpps: "",
      phone: "",
      email: "",
      apps: "MS4",
      city: " ",
      cp: " ",
      adr: " ",
      radios: "individuel",

      finess: null,
      datesign: null,
      mtsegur: null,
      mspcity: null,
      mspcp: null,
      mspadr: null,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,

      opmess: null,
      dmpLGC: "1",
      dmpt: "0",
      mssLGC: "1",
      msst: "0",
      msscLGC: "1",
      mssct: "0",
      bdfpdf: null,
      numediteur: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async sendBdcPdf() {
      if (this.bdfpdf == null) {
        alert("Aucun fichier.");
        return;
      }

      if (this.numediteur == null) {
        alert("Aucun numéro éditeur.");
        return;
      }

      var obj = {
        pdf: this.bdfpdf,
        numeroEditeur: this.numediteur,
      };

      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/createorderformpdf",
          headers: server.getHeader(),
          data: { obj: obj },
        });

        if (res && res.data) {
          this.numediteur = "";
          alert("BON DE COMMANDE Réussi !");

          this.genre = 22;
          this.nom = "";
          this.prenom = "";
          this.rpps = "";
          this.phone = "";
          this.email = "";
          this.apps = "MS4";
          this.city = " ";
          this.cp = " ";
          this.adr = " ";
          this.radios = "individuel";

          this.finess = null;
          this.datesign = null;
          this.mtsegur = null;
          this.mspcity = null;
          this.mspcp = null;
          this.mspadr = null;
          (this.date = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10)),
            (this.opmess = null);
          this.dmpLGC = "1";
          this.dmpt = "0";
          this.mssLGC = "1";
          this.msst = "0";
          this.msscLGC = "1";
          this.mssct = "0";
          this.bdfpdf = null;
          this.numediteur = null;
        }
      } catch (error) {}
    },

    async createBDC() {
      var obj = {};
      obj.resident = {};
      obj.resident.RPPS = this.rpps;
      obj.resident.INFOS_PS2_CodeProfession = 10;
      obj.resident.INFOS_PORTEUR_PrenomUsuel = this.prenom;
      obj.resident.INFOS_PORTEUR_NomPatronym = this.nom;
      obj.resident.INFOS_PORTEUR_CodeCivilite = this.genre;
      obj.resident.phone = this.phone;
      obj.resident.email = this.email;
      obj.resident.apps = this.apps;
      obj.resident.city = this.city;
      obj.resident.zip = this.cp;
      obj.resident.address = this.adr;

      obj.msp = {};
      obj.msp.FINESS = this.finess;
      obj.msp.mtsegur = this.mtsegur;
      obj.msp.city = this.mspcity;
      obj.msp.zip = this.mspcp;
      obj.msp.address = this.mspadr;

      obj.orderForm = {};
      obj.orderForm.operateurMessagerie = this.opmess;
      obj.orderForm.isProSanteConnectNeeded = true;
      obj.orderForm.isDirectToDMP = this.dmpLGC == "0" ? false : true;
      obj.orderForm.isPFIToDMP = this.dmpt == "0" ? false : true;
      obj.orderForm.isDirectToMSSPro = this.mssLGC == "0" ? false : true;
      obj.orderForm.isPFIToMSSPro = this.msst == "0" ? false : true;
      obj.orderForm.isDirectToMSSCitoyen = this.msscLGC == "0" ? false : true;
      obj.orderForm.isPFIToMSSCitoyen = this.mssct == "0" ? false : true;
      obj.orderForm.createdAt = new Date(this.date);

      // Correspond à un achat direct des fonctionnalités Segur, hors financement
      obj.isOutsideSegur = this.orderTypeRadio == "outsideSegur" ? true : false;

      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/createorderform",
          headers: server.getHeader(),
          data: { obj: obj },
        });

        if (res && res.data && res.data.data) {
          if (res.data.data.error) {
            alert(res.data.data.error);
            return;
          }
          if (this.orderTypeRadio == "withinSegur") {
            alert(
              "Numéro éditeur à inscrire sur le BDC " +
                res.data.data.numeroEditeur
            );
          } else {
            alert("Pack Segur enregistré ! ");
          }
          this.numediteur = res.data.data.numeroEditeur;
        }
      } catch (error) {}
    },

    mssupdate(evt) {
      if (evt == "MAI_15" || evt == "PHA_34") {
        this.dmpLGC = "1";
        this.dmpt = "0";
        this.mssLGC = "1";
        this.msst = "0";
        this.msscLGC = "1";
        this.mssct = "0";
      } else {
        this.dmpLGC = "0";
        this.dmpt = "1";
        this.mssLGC = "0";
        this.msst = "1";
        this.msscLGC = "0";
        this.mssct = "1";
      }
    },

    downloadBDC(event) {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
      ) {
        if (event.target.files[0].type == "application/pdf") {
          var fileReader = new FileReader();
          var cl = this;
          fileReader.onload = function (event) {
            var sizeMo = event.total / 1000000;
            if (sizeMo > 3) {
              alert("Impossible, le fichier ne doit pas dépasser 3Mo.");
              document.getElementById("formFile").value = "";
              cl.bdfpdf = null;
              return;
            }

            var base64File = event.target.result.split(",")[1];
            cl.bdfpdf = base64File;
          };
          fileReader.readAsDataURL(event.target.files[0]);
        }
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
