<!--
@Author: Florian Merel <florian>
@Date:   16-Dec-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Publicticket.vue
@Last modified by:   florian
@Last modified time: 23-Dec-2020
-->

<template>
  <div class="centered-container" style="">
    <div class="background" style="position: fixed" />
    <div class="drawRectTop" />
    <div class="drawRectBot" style="position: fixed" />

    <md-card class="widthToolbar" style="padding: 30px">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center; font-size: 16px">
            L'échange est maintenant terminé.
          </div>
        </div>
      </div>
    </md-card>

    <md-card
      v-if="item && item.data && item.data.extension"
      class="widthToolbar"
      style="margin-bottom: 30px"
    >
      <md-card-header data-background-color="prokov">
        <div class="md-title">{{ item.data.extension.sujet }}</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout-item md-medium-size-100 md-size-100">
          <md-icon style="margin-top: -6px; margin-left: -6px"
            >emoji_objects</md-icon
          >
          <span class="md-body-2" style="margin-left: 6px">Idée</span>
          <md-divider></md-divider>
        </div>

        <item-tickets :item="item.data.extension"></item-tickets>

        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-top: 15px; margin-bottom: 30px"
          >
            <md-icon style="margin-top: -6px; margin-left: -6px"
              >subject</md-icon
            >
            <span class="md-body-2" style="margin-left: 6px">Messages</span>
            <md-divider></md-divider>
          </div>

          <!--<div class="md-layout-item md-medium-size-100 md-size-100" style="margin-top: 15px; margin-bottom: 30px;">
            <md-divider></md-divider>
          </div>-->

          <md-card
            v-for="act in item.data.rows"
            :key="act.id"
            :data-background-color="
              act.source == 'PublicResponse'
                ? 'tchatCleanProkov'
                : 'tchatProkov'
            "
            style="margin-top: -18px; margin-left: 10px; margin-right: 10px"
          >
            <div class="layout">
              <div class="md-layout">
                <div
                  v-if="act.source == 'PublicQuestion'"
                  class="md-layout-item md-alignment-center-center md-size-100"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="dateTicket">
                    {{ getDateLong(act.createdAt) }}
                  </span>

                  <span style="color: gray"> - EtherEgo</span>

                  <div
                    v-if="act.comment"
                    class="
                      md-layout-item md-alignment-center-center md-size-100
                    "
                    style="margin-top: -0px; margin-bottom: 5px; color: black"
                  >
                    {{ act.comment }}
                  </div>
                </div>

                <div
                  v-if="act.source == 'PublicResponse'"
                  class="
                    md-layout-item md-alignment-center-center md-size-100
                    text-right
                  "
                  style="margin-top: 0px; margin-bottom: 5px"
                >
                  <span class="dateTicket">
                    {{ getDateLong(act.createdAt) }}
                  </span>
                  <span style="color: gray"> - Utilisateur</span>

                  <div
                    v-if="act.comment"
                    class="
                      md-layout-item md-alignment-center-center md-size-100
                      text-right
                    "
                    style="margin-top: -0px; margin-bottom: 5px; color: black"
                  >
                    {{ act.comment }}
                  </div>
                </div>
              </div>
            </div>
          </md-card>

          <div class="md-layout-item md-small-size-100 md-size-100" style="">
            <md-divider style="margin-bottom: 5px"></md-divider>
            <div class="colorTextProkov">Réponse :</div>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <md-textarea v-model="comment" md-counter="1000"></md-textarea>
            </md-field>
          </div>
        </div>
      </md-card-content>

      <md-card-actions>
        <md-button class="md-raised md-prokov" @click="sendValue()"
          >Envoyer</md-button
        >
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import { ItemTickets } from "@/components";

export default {
  components: {
    ItemTickets,
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    item() {
      return store.getters["publicTicket/getData"];
    },
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getType(type) {
      return toolsFormat.getType(type);
    },

    getDateLong(date) {
      return toolsFormat.getDateLong(date);
    },
    sendValue() {
      if (this.comment && this.comment.length > 0) {
        //sendResponsePublicMessage
        var params = {
          url: this.$route.params.id,
          param: {
            comment: this.comment,
          },
        };
        store.dispatch("publicTicket/sendResponsePublicMessage", params);
        this.comment = "";
      }
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      store.dispatch("publicTicket/getPublicTicket", this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.widthToolbar {
  position: absolute;
  top: 30px;
  width: 90%;

  max-width: 1400px;
  //margin-left: 10;
  //margin-right: ;
}
</style>
