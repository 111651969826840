<!--
@Author: Florian Merel <florian>
@Date:   17-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: TicketsOption.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div v-if="getTicket != null" class="md-layout">
    <div class="md-layout">
      <!-- SCINDER -->
      <div
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: 10px; margin-left: 10px; margin-bottom: 10px"
      >
        <v-btn
          v-if="scindeOpen"
          class="white--text"
          color="primary"
          depressed
          @click="scindeOpen = !scindeOpen"
        >
          Scinder
        </v-btn>

        <v-btn
          v-if="!scindeOpen"
          class="white--text"
          color="primary"
          outlined
          @click="scindeOpen = !scindeOpen"
        >
          Scinder
        </v-btn>

        <div
          v-if="scindeOpen"
          class="md-layout-item md-medium-size-100 md-size-100"
          style="margin-left: 10px; margin-bottom: 10px"
        >
          <v-divider style="margin-top: 10px; margin-bottom: 10px"></v-divider>

          <v-textarea
            outlined
            name="input-7-4"
            color="primary"
            label="Description du ticket"
            v-model="descTicket"
          ></v-textarea>

          <v-textarea
            outlined
            name="input-7-4"
            color="primary"
            label="Description du nouveau ticket"
            v-model="newDescTicket"
          ></v-textarea>

          <v-btn
            style="float: right; margin-top: -10px"
            :disabled="descTicket.length == 0 || newDescTicket.length == 0"
            class="white--text"
            color="primary"
            outlined
            @click="scinderAction"
          >
            Valider
          </v-btn>
        </div>
      </div>
      <!-- FIN SCINDER -->

      <div class="md-layout-item md-medium-size-100 md-size-100">
        <v-divider></v-divider>
      </div>

      <!--<div
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: 10px; margin-left: 10px; margin-bottom: 10px;"
      >
      <v-btn style=""
        class="white--text"
        color="primary accent-4"
        depressed
        @click="fusioOpen = !fusioOpen"
      >
        Fusionner
      </v-btn>

    </div>-->
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-option",
  data() {
    return {
      scindeOpen: false,
      fusioOpen: false,
      newDescTicket: "",
      descTicket: "",
    };
  },
  methods: {
    splitExecute() {
      var desc = "";
      if (this.newDescrition == null) {
        desc = this.item.description;
      } else {
        desc = this.newDescrition;
      }

      if (desc.length > 0 && this.comment.length > 0) {
        var params = {};
        params.id = this.item.id;
        params.data = { desc_ticket: desc, desc_splitticket: this.comment };
        store.dispatch("tickets/serviceSplitTicket", params);
        this.split = false;
        this.comment = "";
        this.newDescrition = "";
      }
    },

    scinderAction() {
      if (this.descTicket.length > 0 && this.newDescTicket.length > 0) {
        var params = {};
        var obj = this.getTicket;
        params.id = obj.id;
        params.data = {
          desc_ticket: this.descTicket,
          desc_splitticket: this.newDescTicket,
        };
        store.dispatch("tickets/serviceSplitTicket", params);

        obj.description = this.descTicket;
        this.descTicket = obj.description;

        this.newDescTicket = "";
        this.scindeOpen = false;
      }
    },

    setDesc(obj) {
      this.descTicket = obj.description;
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.setDesc(obj);
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
