<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 08-Dec-2020
-->

<template>
  <div class="content">
    <div class="md-layout-item md-large-size-100 md-size-100">
      <md-button class="md-prokov" to="/tickets">
        <md-icon style="margin-right: 10px; margin-left: -20px">undo</md-icon>
        Retour</md-button
      >
    </div>
    <div class="md-layout-item md-large-size-100 md-size-50">
      <form>
        <md-card>
          <md-card-header data-background-color="prokov">
            <h4 class="title">Configuration</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div
                class="md-layout-item md-medium-size-100 md-size-100"
                style="margin-top: 10px"
              >
                <div class="md-subheading">Ajouter un Statut</div>
                <md-divider />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-100">
                <folders-table></folders-table>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>Nom du statut</label>
                  <md-input v-model="statutName" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>Url du statut</label>
                  <md-input v-model="statutUrl" type="text"></md-input>
                </md-field>
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-100 text-right"
              >
                <md-button class="md-raised md-prokov" @click="addStatut()"
                  >Ajouter un statut</md-button
                >
                <md-divider />
              </div>

              <div
                class="md-layout-item md-small-size-100 md-size-100"
                style="margin-bottom: 10px"
              ></div>

              <badges-created style="margin-top: 10px"></badges-created>

              <div class="md-layout-item md-medium-size-100 md-size-100">
                <div class="md-subheading">Supprimer des badges</div>

                <md-divider />
              </div>

              <badges-lists-chips chipColor="md-accent"></badges-lists-chips>

              <div
                class="md-layout-item md-size-100 text-right"
                style="margin-top: 10px"
              >
                <md-divider />
                <md-button
                  class="md-raised md-danger"
                  @click="deletebadge()"
                  :disabled="getNumbersSelectBadges() == 0"
                  >Supprimer les badges</md-button
                >
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>

<script>
import ticketsType from "@/tools/ticketsType.js";
import { BadgesListsChips, BadgesCreated, FoldersTable } from "@/components";
import store from "@/store";
import Vue from "vue";

export default {
  components: {
    BadgesListsChips,
    FoldersTable,
    BadgesCreated,
  },
  data: () => ({
    badgeName: "",
    badgeCategorie: "",
    showDialog: false,
    statutName: "",
    statutUrl: "",
  }),
  computed: {
    ticketsInput() {
      return store.getters["tickets/getBadgeResult"];
    },
  },
  watch: {
    statutName: function (val) {
      this.statutName = val.toUpperCase();
    },
    statutUrl: function (val) {
      this.statutUrl = val.toLowerCase();
      this.statutUrl = this.statutUrl.replace(/[^a-z]+/g, "");
    },
  },
  methods: {
    getNumbersSelectBadges() {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) => element.select != undefined && element.select == true
        );
      }
      return ar.length;
    },

    deletebadge() {
      var ar = this.ticketsInput.badges.rows.filter(
        (element) => element.select != undefined && element.select == true
      );
      ar = [...new Set(ar.map((item) => item.id))];

      let params = {
        badges: ar,
      };
      store.dispatch("tickets/serviceDeleteBadges", params);
    },

    addStatut() {
      if (
        this.statutName &&
        this.statutUrl &&
        this.statutName.length > 0 &&
        this.statutUrl.length > 0
      ) {
        let params = {
          name: this.statutName,
          url: this.statutName,
        };
        store.dispatch("tickets/servicePostFolder", params);
      }
    },
  },
};
</script>
