/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 05-Jul-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";
import store from "@/store";

const state = {
  loading: false,
  error: {},
  data: {},
  companyData: {},
  memberData: {},
  memberCompanyData: {},
  licForExpertData: {},
  memberCompanyIdData: {},
  company4Dlabels: {},
  labelToCompany: {},
};

const getters = {
  getData(context) {
    return context.data;
  },
  getCompanyData(context) {
    return context.companyData;
  },

  getMemberData(context) {
    return context.memberData;
  },

  getMemberCompanyData(context) {
    return context.memberCompanyData;
  },

  getLicForExpertData(context) {
    return context.licForExpertData;
  },

  getMemberCompanyIdData(context) {
    return context.memberCompanyIdData;
  },

  getCompany4DlabelsData(context) {
    return context.company4Dlabels;
  },

  getLabelToCompany(context) {
    return context.labelToCompany;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },

  setCompanyData(context, data) {
    context.companyData = data;
  },

  setMemberData(context, data) {
    context.memberData = data;
  },

  setMemberCompanyData(context, data) {
    context.memberCompanyData = data;
  },

  setMemberCompanyIdData(context, data) {
    context.memberCompanyIdData = data;
  },

  setLicForExpertData(context, data) {
    context.licForExpertData = data;
  },

  setCompany4Dlabels(context, data) {
    context.company4Dlabels = data;
  },

  setLabelToCompany(context, data) {
    context.labelToCompany = data;
  },
};

const actions = {
  async getCompany(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/company" + q,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setCompanyData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getMemberCompany(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/members" + q,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setMemberCompanyData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getMember(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/members" + q,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setMemberData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async putMember(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.providers + "/members/" + params.url,
        headers: server.getHeader(),
        data: params.obj,
      });
      if (res.data.success) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async putCompany(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.providers + "/company/" + params.url,
        headers: server.getHeader(),
        data: params.obj,
      });
      if (res.data.success) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async postCompany(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.providers + "/company/",
        headers: server.getHeader(),
        data: params,
      });
      if (res.data.success) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getMemberToCompany(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/company/" + q + "/members/",
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setMemberCompanyIdData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getLicForExpert(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url:
          server.url + server.urls.providers + "/company/" + q + "/licences/",
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setLicForExpertData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getCompany4Dlabels(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/company/company4Dlabels",
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setCompany4Dlabels", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getLabelToCompany(context, q) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.providers + "/company/" + q + "/labels/",
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setLabelToCompany", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
      return error;
    }
    context.commit("setLoading", false);
  },

  async putLabelToCompany(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url:
          server.url +
          server.urls.providers +
          "/company/" +
          params.id +
          "/label/",
        headers: server.getHeader(),
        data: params.data,
      });
      context.commit("setResponse", res);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
      return error;
    }
    context.commit("setLoading", false);
  },

  async deleteLabelToCompany(context, params, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url:
          server.url +
          server.urls.providers +
          "/company/" +
          params.id +
          "/label/",
        headers: server.getHeader(),
        data: params.data,
      });
      context.commit("setResponse", res);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
      return error;
    }
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
