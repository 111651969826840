<!--
@Author: Florian Merel <florian>
@Date:   02-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Serveurs.vue
@Last modified by:   florian
@Last modified time: 08-Jun-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <md-card>
        <md-card-header data-background-color="green">
          <h4 class="title">MédiStory Station</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-card>
              <md-card-content>
                <div class="content">
                  <div class="md-layout">
                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <AreaCharts
                        ref="nbLicenceMonth"
                        :chartData="nbStations"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version système des stations</p>
                      <PieCharts
                        ref="vStationsSystem"
                        :chartData="vStationsSystem"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version des stations</p>
                      <PieCharts ref="pieStation" :chartData="vStations" />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version des stations (24h)</p>
                      <PieCharts
                        ref="pieStation24h"
                        :chartData="vStations24h"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header data-background-color="blue">
          <h4 class="title">ExpressVitale</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-card>
              <md-card-content>
                <div class="content">
                  <div class="md-layout">
                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <AreaCharts ref="nbEp" :chartData="nbEp" />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version système de ExpressVitale</p>
                      <PieCharts
                        ref="vEchartSystem"
                        :chartData="vEchartSystem"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de ExpressVitale</p>
                      <PieCharts
                        ref="vEchartVersion"
                        :chartData="vEchartVersion"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de ExpressVitale (24h)</p>
                      <PieCharts
                        ref="vEchartVersion24h"
                        :chartData="vEchartVersion24h"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header data-background-color="orange">
          <h4 class="title">VigiPaiement</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-card>
              <md-card-content>
                <div class="content">
                  <div class="md-layout">
                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <AreaCharts ref="nbVp" :chartData="nbVp" />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version système de VigiPaiement</p>
                      <PieCharts
                        ref="vPchartSystem"
                        :chartData="vPchartSystem"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de VigiPaiement</p>
                      <PieCharts
                        ref="vPchartVersion"
                        :chartData="vPchartVersion"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de VigiPaiement (24h)</p>
                      <PieCharts
                        ref="vPchartVersion24h"
                        :chartData="vPchartVersion24h"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header data-background-color="green">
          <h4 class="title">VigiCompta</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-card>
              <md-card-content>
                <div class="content">
                  <div class="md-layout">
                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <AreaCharts ref="nbCp" :chartData="nbCp" />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version système de VigiCompta</p>
                      <PieCharts
                        ref="vCchartSystem"
                        :chartData="vCchartSystem"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de VigiCompta</p>
                      <PieCharts
                        ref="vCchartVersion"
                        :chartData="vCchartVersion"
                      />
                    </div>

                    <div
                      class="
                        md-layout-item
                        md-medium-size-100
                        md-xsmall-size-100
                        md-size-25
                      "
                    >
                      <p>Version de VigiCompta (24h)</p>
                      <PieCharts
                        ref="vCchartVersion24h"
                        :chartData="vCchartVersion24h"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </md-card-content>
      </md-card>

      <div class="md-layout-item md-medium-size-100 md-size-50">
        <md-card>
          <md-card-content>
            <div class="content">
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  <md-card>
                    <md-card-header data-background-color="blue">
                      <h4 class="title">Tickets</h4>
                    </md-card-header>
                    <md-card-content>
                      <AreaCharts ref="nbTicketsMonth" :chartData="nbTickets" />
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-size-50">
        <md-card>
          <md-card-content>
            <div class="content">
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  <md-card>
                    <md-card-header data-background-color="blue">
                      <h4 class="title">Utilisateurs</h4>
                    </md-card-header>
                    <md-card-content>
                      <AreaCharts
                        ref="nbUtilisateursMonth"
                        :chartData="nbUtilisateurs"
                      />
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <!--  <div class="md-layout-item md-medium-size-100 md-size-100">
        <div class="md-title" style="margin-bottom: -15px;"> MédiStory 4 </div>
        <md-card>
          <md-card-content>
            <div class="content">
              <div class="md-layout">
                <div
                  class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
                >
                  <md-card>
                    <md-card-header data-background-color="green">
                      <h4 class="title">
                        Nouvelles stations dans la base de données
                      </h4>
                    </md-card-header>
                    <md-card-content>
                      <AreaCharts
                        ref="nbLicenceMonth"
                        :chartData="nbStations"
                      />
                    </md-card-content>
                  </md-card>
                </div>

                <div
                  class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25"
                >
                  <md-card>
                    <md-card-header data-background-color="green">
                      <h4 class="title">Versions des stations</h4>
                    </md-card-header>
                    <md-card-content>
                      <PieCharts ref="pieStation" :chartData="vStations" />
                    </md-card-content>
                  </md-card>
                </div>

                <div
                  class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25"
                >
                  <md-card>
                    <md-card-header data-background-color="green">
                      <h4 class="title">Versions des stations actives (24h)</h4>
                    </md-card-header>
                    <md-card-content>
                      <PieCharts
                        ref="pieStation24h"
                        :chartData="vStations24h"
                      />
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>-->
    </div>
  </div>
</template>

<script>
import store from "@/store";
import PieCharts from "../components/Charts/PieCharts.vue";
import AreaCharts from "../components/Charts/AreaCharts.vue";

export default {
  components: {
    PieCharts,
    AreaCharts,
  },
  data: () => ({
    year: new Date().getFullYear(),
    objTranslate: {
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
    },
    vStations: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vStations24h: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    nbStations: {
      labels: [],
      datasets: [
        {
          label: "Nombre de nouvelles stations",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total de station",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },
    nbTickets: {
      labels: [],
      datasets: [
        {
          label: "Nombre de nouveaux tickets",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total de ticket",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },
    nbUtilisateurs: {
      labels: [],
      datasets: [
        {
          label: "Nombre de nouveaux utilisateurs",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total d'utilisateur",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },

    vPchartSystem: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vPchartVersion: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vPchartVersion24h: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    nbVp: {
      labels: [],
      datasets: [
        {
          label: "Nouvelles licences VigiPaiement",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total de VigiPaiement",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },

    vEchartSystem: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vStationsSystem: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vEchartVersion: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vEchartVersion24h: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    nbEp: {
      labels: [],
      datasets: [
        {
          label: "Nouvelles licences ExpressVitale",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total d'ExpressVitale",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },

    vCchartSystem: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vCchartVersion: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    vCchartVersion24h: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
          backgroundColor: [
            "#1abc9c",
            "#5499C7",
            "#2980B9",
            "#2471A3",
            "#1F618D",
            "#1A5276",
            "#154360",
          ],
        },
      ],
    },
    nbCp: {
      labels: [],
      datasets: [
        {
          label: "Nouvelles licences VigiCompta",
          borderColor: "#1abc9c",
          pointBackgroundColor: "#1abc9c",
          pointBorderColor: "#1abc9c",
          borderWidth: 1,
          data: [],
        },
        {
          label: "Nombre total de VigiCompta",
          borderColor: "#26A0F2",
          pointBackgroundColor: "#26A0F2",
          pointBorderColor: "#26A0F2",
          borderWidth: 1,
          data: [],
        },
      ],
    },
  }),
  created: async function () {
    var res = await store.dispatch("analitics/getAnalitics");

    if (res && res.data) {
      this.initvStations(res);
      this.initvStations24h(res);
      this.initnbStations(res);
      this.initnbUtilisateurs(res);
      this.initnbTickets(res);

      // ----- VP ------
      this.initArea(
        res.data.vp.versions,
        this.$refs.vPchartVersion,
        this.vPchartVersion
      );
      this.initArea(
        res.data.vp.versions24,
        this.$refs.vPchartVersion24h,
        this.vPchartVersion24h
      );
      this.initArea(
        res.data.vp.system,
        this.$refs.vPchartSystem,
        this.vPchartSystem
      );
      this.fct_init_array(
        res.data.vp.count,
        res.data.vp.nbLicenceMonth,
        this.nbVp,
        this.$refs.nbVp
      );

      // ----- EV ------
      this.initArea(
        res.data.ex.versions,
        this.$refs.vEchartVersion,
        this.vEchartVersion
      );
      this.initArea(
        res.data.ex.versions24,
        this.$refs.vEchartVersion24h,
        this.vEchartVersion24h
      );
      this.initArea(
        res.data.ex.system,
        this.$refs.vEchartSystem,
        this.vEchartSystem
      );
      this.fct_init_array(
        res.data.ex.count,
        res.data.ex.nbLicenceMonth,
        this.nbEp,
        this.$refs.nbEp
      );

      // ----- STATION ------
      this.initArea(
        res.data.systemStation,
        this.$refs.vStationsSystem,
        this.vStationsSystem
      );

      // ----- VC ------
      this.initArea(
        res.data.vc.versions,
        this.$refs.vCchartVersion,
        this.vCchartVersion
      );

      this.initArea(
        res.data.vc.versions24,
        this.$refs.vCchartVersion24h,
        this.vCchartVersion24h
      );
      this.initArea(
        res.data.vc.system,
        this.$refs.vCchartSystem,
        this.vCchartSystem
      );
      this.fct_init_array(
        res.data.vc.count,
        res.data.vc.nbLicenceMonth,
        this.nbCp,
        this.$refs.nbCp
      );
    }
  },
  computed: {},
  methods: {
    initvStations(res) {
      if (res.data.versions) {
        res.data.versions.sort((a, b) =>
          a.total < b.total ? 1 : b.total < a.total ? -1 : 0
        );
        for (let i = 0; i < res.data.versions.length && i < 7; i++) {
          this.vStations.labels.push(res.data.versions[i].usedVersion);
          this.vStations.datasets[0].data.push(res.data.versions[i].total);
        }
        this.$refs.pieStation.callRenderChart();
      }
    },
    initvStations24h(res) {
      if (res.data.versions24) {
        res.data.versions24.sort((a, b) =>
          a.total < b.total ? 1 : b.total < a.total ? -1 : 0
        );
        for (let i = 0; i < res.data.versions24.length; i++) {
          this.vStations24h.labels.push(res.data.versions24[i].usedVersion);
          this.vStations24h.datasets[0].data.push(res.data.versions24[i].total);
        }
        this.$refs.pieStation24h.callRenderChart();
      }
    },

    initArea(ar, ref, target) {
      ar.sort((a, b) => (a.total < b.total ? 1 : b.total < a.total ? -1 : 0));

      for (let i = 0; i < ar.length; i++) {
        if (ar[i].version != null) {
          target.labels.push(ar[i].version);
          target.datasets[0].data.push(ar[i].total);
        }
        if (ar[i].system != null && ar[i].system.length > 0) {
          var vers = ar[i].system.split(".");
          if (vers.length > 0) {
            if (vers[0] == "10") {
              vers[0] = "10." + vers[1];
              if (vers[1] == "15") {
                vers[0] = "Catalina";
              }
              if (vers[1] == "14") {
                vers[0] = "Mojave";
              }
              if (vers[1] == "13") {
                vers[0] = "High Sierra";
              }
            } else if (vers[0] == "11") {
              vers[0] = "Big Sur";
            } else if (vers[0] == "12") {
              vers[0] = "Monterey";
            }
          }

          if (!target.labels.includes(vers[0])) {
            target.labels.push(vers[0]);
            target.datasets[0].data.push(ar[i].total);
          } else {
            let index = target.labels.findIndex((t) => t === vers[0]);
            target.datasets[0].data[index] += ar[i].total;
          }
        }
      }

      ref.callRenderChart();
    },

    fct_init_array(nbU, nbUM, target, ref) {
      var resX = nbU;
      for (let i = 0; i < nbUM.length; i++) {
        resX = resX - nbUM[i].total;
      }

      for (let i = 0; i < nbUM.length; i++) {
        if (nbUM[i].Année != this.year) {
          target.labels.push(this.objTranslate[nbUM[i].Mois]);
          target.datasets[0].data.push(nbUM[i].total);

          resX = resX + nbUM[i].total;
          target.datasets[1].data.push(resX);
        }
      }

      for (let i = 0; i < nbUM.length; i++) {
        if (nbUM[i].Année == this.year) {
          target.labels.push(this.objTranslate[nbUM[i].Mois]);
          target.datasets[0].data.push(nbUM[i].total);

          resX = resX + nbUM[i].total;
          target.datasets[1].data.push(resX);
        }
      }
      target.datasets[0].backgroundColor = ref.getGradientColor();
      target.datasets[1].backgroundColor = ref.getGradient2Color();
      ref.callRenderChart();
    },
    initnbTickets(res) {
      if (res.data.nbTickets) {
        this.fct_init_array(
          res.data.nbTickets,
          res.data.nbTicketsMonth,
          this.nbTickets,
          this.$refs.nbTicketsMonth
        );
      }
    },
    initnbUtilisateurs(res) {
      if (res.data.nbUtilisateurs) {
        this.fct_init_array(
          res.data.nbUtilisateurs,
          res.data.nbUtilisateursMonth,
          this.nbUtilisateurs,
          this.$refs.nbUtilisateursMonth
        );
      }
    },
    initnbStations(res) {
      if (res.data.nbLicenceMonth) {
        this.fct_init_array(
          res.data.nbLicence,
          res.data.nbLicenceMonth,
          this.nbStations,
          this.$refs.nbLicenceMonth
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
