/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: authentification.js
 * @Last modified by:   florian
 * @Last modified time: 25-Jan-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  token: null,
  error: {},
  data: {},
  droits: null,
};

const getters = {
  getLogin(context) {
    return context.data;
  },

  getToken(context) {
    context.token = localStorage.getItem("token");
    return context.token;
  },

  getData(context) {
    return context.data;
  },

  getDroits(context) {
    return localStorage.getItem("droits");
  },

  getIsAssistance(context) {
    const id = localStorage.getItem("id");
    const ar_ids = [21, 15, 14, 5, 16];
    if (id) {
      return ar_ids.includes(parseInt(id));
    }
    return false;
  },

  getEmail(context) {
    return localStorage.getItem("email");
  },

  getId(context) {
    return localStorage.getItem("id");
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
    if (data && data.data) {
      if (data.data.id) {
        localStorage.setItem("id", data.data.id);
      }

      if (data.data.droits) {
        localStorage.setItem("droits", data.data.droits);
        context.droits = data.data.droits;
      }

      if (data.data.email) {
        localStorage.setItem("email", data.data.email);
      }

      if (data.data.version) {
        var v = localStorage.getItem("version");
        if (v != data.data.version) {
          localStorage.setItem("version", data.data.version);
          window.location.reload(true);
        }
      }
    }
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setToken(context, token) {
    context.token = token;
    localStorage.setItem("token", context.token);
  },
};

const actions = {
  login(context, params) {
    return new Promise((resolve, reject) => {
      context.commit("setLoading", true);
      axios({
        method: "POST",
        url: server.url + server.urls.login,
        data: params,
      })
        .then((response) => {
          context.commit("setLoading", false);
          if (response.data) {
            context.commit("setResponse", response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          context.commit("setLoading", false);
          if (error.response) {
            context.commit("setError", error.response.data);
          }
          reject(error.response);
        });
    });
  },

  async getTokenIsValid(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.tokenIsValid,
        headers: server.getHeader(),
      });
      context.commit("setLoading", false);
      context.commit("setResponse", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        context.commit("setLoading", false);
        context.commit("setError", error.response.data);
        servicesError.analyseErrorService(error.response.data);
        return error.response.data;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
