/**
 * @Author: Florian Merel <florian>
 * @Date:   06-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: ticketsType.js
 * @Last modified by:   florian
 * @Last modified time: 15-Sep-2021
 */

import ticketsType from "@/tools/ticketsType.js";
import store from "@/store";

function getDate(date) {
  var d = new Date(date);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return d.toLocaleDateString(undefined, options);
}

function newDate(value) {
  if (isNaN(value)) {
    value = value.replace(" ", "T");
  }
  var date = new Date(value);
  return date;
}

function getDateText(date) {
  var d = newDate(date);
  var days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  var month = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  var res = days[d.getDay() - 1];
  res =
    res +
    " " +
    d.getDate() +
    " " +
    month[d.getMonth()] +
    " " +
    d.getFullYear() +
    " à " +
    d.getHours() +
    ":" +
    d.getMinutes();
  return res;
}

function verificationEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function getDateLongWithTimeZone(date) {
  var d = newDate(date);
  d.setHours(d.getHours() - 2);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  //.getHours()
  //.getSeconds()
  var res =
    d.toLocaleDateString(undefined, options) +
    " " +
    (d.getHours() < 10 ? "0" : "") +
    d.getHours() +
    ":" +
    (d.getMinutes() < 10 ? "0" : "") +
    d.getMinutes();
  return res;
}

function getDateLong(date) {
  if (!date) {
    return "";
  }
  var d = newDate(date);

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  //.getHours()
  //.getSeconds()
  var res =
    d.toLocaleDateString(undefined, options) +
    " " +
    (d.getHours() < 10 ? "0" : "") +
    d.getHours() +
    ":" +
    (d.getMinutes() < 10 ? "0" : "") +
    d.getMinutes();
  return res;
}

function getDateInscription(date) {
  var ts_ms = parseInt(date) * 1000;
  var date_ob = newDate(ts_ms);

  // year as 4 digits (YYYY)
  var year = date_ob.getFullYear();

  // month as 2 digits (MM)
  var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // date as 2 digits (DD)
  var date = ("0" + date_ob.getDate()).slice(-2);

  // hours as 2 digits (hh)
  var hours = ("0" + date_ob.getHours()).slice(-2);

  // minutes as 2 digits (mm)
  var minutes = ("0" + date_ob.getMinutes()).slice(-2);

  return date + "-" + month + "-" + year + " " + hours + ":" + minutes;
}

function getChipsTypeColor(type) {
  var obj = { Idée: "orange", Bug: "red", Question: "blue" };
  if (obj[type]) {
    return obj[type];
  }
  return "indigo";
}

function getChipsFolder(ticket) {
  var ba = store.getters["ticketsV2Filters/getData"];
  if (ba && ba.data && ba.data.folders) {
    const fo = ba.data.folders.rows.find(
      (el) =>
        el.traiteValue == ticket.traite && el.deleteValue == ticket.aSupprimer
    );
    if (fo) {
      return fo.name;
    }
  }
  return "";
}

function getChipsFolderColor(ticket) {
  var ba = store.getters["ticketsV2Filters/getData"];

  var obj = {
    1: "blue",
    2: "green",
    3: "red",
    4: "orange",
    "3394e4c0-38a4-11eb-b28f-a7abe451c058": "green darken-1",
    "483a3910-3abc-11eb-9921-af77831a8159": "green darken-1",
    "c33aed90-3ab6-11eb-9921-af77831a8159": "green darken-1",
  };

  if (ba && ba.data && ba.data.folders) {
    const fo = ba.data.folders.rows.find(
      (el) =>
        el.traiteValue == ticket.traite && el.deleteValue == ticket.aSupprimer
    );

    if (fo && obj[fo.id]) {
      return obj[fo.id];
    }
  }
  return "indigo";
}

function getType(type) {
  return ticketsType.ticketsTypes[type];
}

export default {
  getDate,
  getType,
  getDateLong,
  getDateInscription,
  getDateLongWithTimeZone,
  getChipsTypeColor,
  getChipsFolderColor,
  getChipsFolder,
  getDateText,
  verificationEmail,
};
