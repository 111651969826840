<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 08-Jun-2021
-->

<template>
  <div class="content"></div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  components: {},
  name: "Community",
  data: () => ({}),
  computed: {},
  created: function () {},
  methods: {},
};
</script>
