/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: usersList.js
 * @Last modified by:   florian
 * @Last modified time: 23-Dec-2020
 */

import axios from "axios";
import server from "../server.js";
import store from "@/store";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getData(context) {
    return context.data;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setUser(context, obj) {
    context.data.data.user = obj;
  },

  setObj(context, obj) {
    context.data.data.user = Object.assign(context.data.data.user, obj);
  },
};

const actions = {
  service(context, url) {
    return new Promise((resolve, reject) => {
      context.commit("setLoading", true);
      const token = store.getters["authentification/getToken"];
      axios({
        method: "GET",
        url: server.url + server.urls.users + url,
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          context.commit("setLoading", false);
          context.commit("setResponse", response.data);
          resolve(response);
        })
        .catch((error) => {
          if (servicesError.isConnection(error)) {
            context.commit("setLoading", false);
            context.commit("setError", error.response.data);
            servicesError.analyseErrorService(error.response.data);
          }
          reject(error.response);
        });
    });
  },

  async servicePut(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.users + params.url,
        headers: server.getHeader(),
        data: params.obj,
      });
      if (res.data.success) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async servicePutSecu(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.usersSecu + params.url,
        headers: server.getHeader(),
        data: params.obj,
      });
      if (res.data.success) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
