/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 01-Sep-2021
 */

const state = {
  keyup: true,
};

const getters = {
  getKeyup(context) {
    return context.keyup;
  },
};

const mutations = {
  setKeyup(context, key) {
    context.keyup = key;
  },
};

const actions = {
  setKeyupAction(context, keyup) {
    context.commit("setKeyup", keyup);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
