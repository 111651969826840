<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Company.vue
@Last modified by:   florian
@Last modified time: 05-Jul-2021
-->

<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      v-if="companyData.length != 1"
    >
      <div class="md-layout margeTopSearchBar">
        <div class="md-layout-item md-xsmall-size-100 md-size-80">
          <md-field>
            <label>Recherche</label>
            <md-input v-on:keyup.enter="getCompany" v-model="query"></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-size-20"
          style="margin-top: 20px"
        >
          <md-button class="md-info" @click="getCompany()"
            >Rechercher</md-button
          >
          <md-button class="md-success" style="" @click="showDialog = true"
            >Créer une société</md-button
          >
        </div>
      </div>

      <md-card>
        <div class="md-subheading titleTables">Sociétés</div>
        <md-divider></md-divider>
        <company-table
          v-on:passData="search"
          :dataArray="companyData"
        ></company-table>
      </md-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      v-if="companyData.length == 1"
    >
      <md-button class="md-success" @click="returnCompany()">
        <md-icon style="margin-right: 10px; margin-left: -20px">undo</md-icon>
        Retour</md-button
      >

      <md-card v-if="companyData.length == 1">
        <md-card-header data-background-color="green">
          <h4 class="title">{{ companyData[0].name }}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout margeTopSearchBar">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <span class="md-subheading">Informations</span>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Identifiant société</label>
                <md-input
                  v-model="companyData[0].companyID"
                  type="text"
                  disabled
                ></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nom</label>
                <md-input v-model="companyData[0].name" type="text"></md-input>
                <!--<md-input v-model="companyData[0].name" type="text"></md-input>-->
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Contact</label>
                <md-input v-model="companyData[0].email" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label for="movie">Validation Société</label>
                <md-select v-model="companyData[0].validated">
                  <md-option value="1">Oui</md-option>
                  <md-option value="0">Non</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label for="movie">Actif</label>
                <md-select v-model="companyData[0].active">
                  <md-option value="1">Oui</md-option>
                  <md-option value="0">Non</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Mot de passe (6 caractères minimum)</label>
                <md-input v-model="pass" type="password"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Confirmation (6 caractères minimum)</label>
                <md-input v-model="passConf" type="password"></md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-size-100 text-right"
              style="margin-top: -20px"
            >
              <md-button class="md-raised md-info" @click="enregistrerValue()"
                >Enregistrer</md-button
              >
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-100">
              <md-field>
                <label>Labels</label>

                <md-select
                  v-model="selectedLabels"
                  name="badges"
                  id="badges"
                  multiple
                >
                  <md-option
                    v-for="badge in allLabels"
                    :key="badge"
                    :value="badge"
                    >{{ badge }}</md-option
                  >
                </md-select>
              </md-field>
            </div>

            <div
              v-if="uptLabel"
              class="md-layout-item md-size-100 text-right"
              style="margin-top: -20px"
            >
              <md-button class="md-raised md-success" @click="updateLabel()">
                Mettre à jour les Labels</md-button
              >
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-50">
              <md-card>
                <div class="md-subheading titleTables">Membres</div>
                <md-divider></md-divider>
                <member-table
                  v-on:passData="search"
                  :dataArray="memberData"
                ></member-table>
              </md-card>
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-50">
              <md-card>
                <div class="md-subheading titleTables">Licences</div>
                <md-divider></md-divider>
                <licences-company-table
                  v-on:passData="search"
                  :dataArray="licencesData"
                ></licences-company-table>
              </md-card>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Créer une société</md-dialog-title>

      <div class="md-layout margeTopSearchBar">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-field>
            <label>Nom</label>
            <md-input v-model="members.name" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-field>
            <label>Contact</label>
            <md-input v-model="members.email" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-field>
            <label for="movie">Validation Société</label>
            <md-select v-model="members.validated">
              <md-option value="1">Oui</md-option>
              <md-option value="0">Non</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Mot de passe</label>
            <md-input v-model="members.password" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Confirmation</label>
            <md-input v-model="members.passConf" type="text"></md-input>
          </md-field>
        </div>
      </div>

      <md-dialog-actions>
        <md-button class="md-danger" @click="showDialog = false"
          >Fermer</md-button
        >
        <md-button
          class="md-success"
          @click="
            showDialog = false;
            createCompany();
          "
          >Créer une société</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import store from "@/store";
import SimpleSearchBar from "../SearchBar/SimpleSearchBar.vue";
import CompanyTable from "./CompanyTable.vue";
import MemberTable from "./MemberTable.vue";
import LicencesCompanyTable from "./LicencesCompanyTable.vue";

export default {
  components: {
    CompanyTable,
    MemberTable,
    LicencesCompanyTable,
  },
  name: "company",
  data() {
    return {
      query: "",
      pass: "",
      passConf: "",
      showDialog: false,
      lastquery: null,
      members: {},
      selectedLabels: [],
      oldList: [],
    };
  },
  methods: {
    search: function (data) {
      this.query = data;
      this.getCompany();
    },

    getCompany() {
      var q = "";
      if (this.query.length > 0) {
        q = "?q=" + this.query;
      }
      if (this.lastquery != q) {
        store.dispatch("providers/getCompany", q);
        this.lastquery = q;
      }
    },

    returnCompany() {
      this.query = "";
      this.getCompany();
    },

    enregistrerValue() {
      var obj = this.companyData[0];
      if (
        this.pass.length > 5 &&
        this.passConf.length > 5 &&
        this.pass == this.passConf
      ) {
        obj.password = this.pass;
      } else if (
        this.pass.length > 0 ||
        this.passConf.length > 0 ||
        this.pass != this.passConf
      ) {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
        return;
      }

      obj.active = parseInt(obj.active);
      obj.validated = parseInt(obj.validated);
      store.dispatch("providers/putCompany", {
        url: obj.companyID,
        obj: obj,
      });
    },

    createCompany() {
      var obj = {};

      var authorize = ["password", "passConf", "name", "email", "validated"];
      for (const key of authorize) {
        if (!this.members[key]) {
          store.dispatch("notification/sendNotification", {
            type: "fail",
            method: "update",
          });
          return;
        }
      }

      if (this.members.password != this.members.passConf) {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
      this.members.validated = parseInt(this.members.validated);

      store.dispatch("providers/postCompany", this.members);
    },

    async updateLabel() {
      var rm_labels = [];
      var upt_labels = [];

      for (var i = 0; i < this.selectedLabels.length; i++) {
        if (!this.oldList.includes(this.selectedLabels[i])) {
          upt_labels.push(this.selectedLabels[i]);
        }
      }

      for (var i = 0; i < this.oldList.length; i++) {
        if (!this.selectedLabels.includes(this.oldList[i])) {
          rm_labels.push(this.oldList[i]);
        }
      }

      for (var i = 0; i < upt_labels.length; i++) {
        var st = await store.dispatch("providers/putLabelToCompany", {
          data: { label: upt_labels[i] },
          id: this.getIdCompany,
        });
      }

      for (var i = 0; i < rm_labels.length; i++) {
        var st = await store.dispatch("providers/deleteLabelToCompany", {
          data: { label: rm_labels[i] },
          id: this.getIdCompany,
        });
      }
      store.dispatch("providers/getLicForExpert", this.getIdCompany);
      this.getLabelCompany();
    },

    getLabelCompany() {
      store
        .dispatch("providers/getLabelToCompany", this.getIdCompany)
        .then((res) => {
          if (res && res.data && res.data.labels) {
            this.oldList = res.data.labels;
            this.selectedLabels = res.data.labels;
          }
        });
    },
  },
  beforeMount() {
    this.getCompany();
    store.dispatch("providers/getCompany4Dlabels");
  },
  computed: {
    companyData() {
      var cp = store.getters["providers/getCompanyData"];
      if (cp && cp.data && cp.data.company && cp.data.company.rows) {
        if (cp.data.company.rows.length == 1) {
          store.dispatch(
            "providers/getMemberToCompany",
            cp.data.company.rows[0].companyID
          );
          store.dispatch(
            "providers/getLicForExpert",
            cp.data.company.rows[0].companyID
          );
          this.getLabelCompany();
        }
        return cp.data.company.rows;
      }
      return [];
    },

    getIdCompany() {
      var cp = store.getters["providers/getCompanyData"];
      if (cp && cp.data && cp.data.company && cp.data.company.rows) {
        if (cp.data.company.rows.length == 1) {
          return cp.data.company.rows[0].companyID;
        }
      }
      return -1;
    },

    uptLabel() {
      return (
        JSON.stringify(this.selectedLabels) != JSON.stringify(this.oldList)
      );
    },

    memberData() {
      var cp = store.getters["providers/getMemberCompanyIdData"];
      if (cp && cp.data && cp.data.member && cp.data.member.rows) {
        return cp.data.member.rows;
      }
      return [];
    },

    licencesData() {
      var cp = store.getters["providers/getLicForExpertData"];
      if (cp && cp.data && cp.data.list) {
        return cp.data.list;
      }
      return [];
    },

    allLabels() {
      var cp = store.getters["providers/getCompany4DlabelsData"];
      if (cp && cp.data && cp.data.list) {
        return cp.data.list;
      }
      return [];
    },
  },
};
</script>
