<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 22-Jan-2021
-->

<template>
  <div class="content">
    <!--<md-empty-state
      md-icon="dashboard"
      md-label="Dashboard en cours de création"
    >
  </md-empty-state>-->

    <div class="md-layout">
      <div
        v-if="getRules('CONTROL_TICKETS')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="heightStandard">
          <md-card-header data-background-color="blue">
            <h4 class="title">Tickets</h4>
          </md-card-header>
          <md-card-content>
            <p></p>
            <ul>
              <li>Administration de la boîte à idées</li>
              <li>Création de ticket</li>
              <li>Suivi des tickets</li>
              <li>Badger et trier les tickets</li>
            </ul>

            <div class="text-right">
              <md-button class="md-raised md-info" to="/createdTicket"
                >Créer un ticket</md-button
              >
              <md-button
                class="md-raised md-info"
                to="/ticketsv2/filters/3a4567ae-ebb6-11eb-9a03-0242ac130003"
                >Tickets</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div
        v-if="getRules('CONTROL_LICENCES')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="heightStandard">
          <md-card-header data-background-color="orange">
            <h4 class="title">Licences</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualisation des licences.</p>
            <ul>
              <li>Trouver une licence</li>
              <li>Voir la date de mise en service</li>
              <li>Voir les connexions</li>
              <li>Voir la capacité</li>
            </ul>
            <div class="text-right">
              <md-button class="md-raised md-warning" to="/licences"
                >Licences</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div
        v-if="getRules('CONTROL_USERS_INSCRIPTION')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="heightStandard">
          <md-card-header data-background-color="prokov">
            <h4 class="title">Inscription En Cours</h4>
          </md-card-header>
          <md-card-content>
            <p>Administration des inscriptions.</p>
            <ul>
              <li>Liste des personnes en attente de validation</li>
              <li>Renvoyer un mail d'activation</li>
              <li>Supprimer une inscription en cours</li>
            </ul>

            <div class="text-right">
              <md-button class="md-raised md-prokov" to="/usersinscription"
                >Inscription En Cours</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div
        v-if="getRules('CONTROL_USERS')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card>
          <md-card-header data-background-color="prokov">
            <h4 class="title">Utilisateurs</h4>
          </md-card-header>
          <md-card-content>
            <p>L'administration des utilisateurs EtherEgo vous permet de :</p>
            <ul>
              <li>Trouver un utilisateur</li>
              <li>Voir et modifier ses informations personnelles</li>
              <li>Changer l'adresse email</li>
              <li>Changer le mot de passe</li>
              <li>Renvoyer un mail d'activation</li>
              <li>Voir les questions et réponses secrètes</li>
            </ul>

            <div class="text-right">
              <md-button class="md-raised md-prokov" to="/users"
                >Utilisateurs</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <!-- <div
        v-if="getRules('CONTROL_LICENCES')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-66"
      >
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">Gestion du parc</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualisation des stations connectées, déconnectées ou qui subissent des pertubations.</p>
            <ul>
              <li>Trouver un utilisateur</li>
              <li>Voir et modifier ses informations personnelles</li>
              <li>Changer l'adresse email</li>
              <li>Changer le mot de passe</li>
              <li>Renvoyer un mail d'activation</li>
              <li>Voir les questions et réponses secrètes</li>
            </ul>

            <div class="text-right">
              <md-button class="md-raised md-warning" to="/users"
                >Gestion du parc</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>


     <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="">
          <md-card-header data-background-color="blue">
            <h4 class="title">Serveurs</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualiser l'état en temps réel des serveurs EtherEgo.</p>
            <div class="text-right">
              <md-button class="md-raised md-info" to="/licences"
                >Serveurs</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

     <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="">
          <md-card-header data-background-color="blue">
            <h4 class="title">Analitycs</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualiser l'état en temps réel des serveurs EtherEgo.</p>
            <div class="text-right">
              <md-button class="md-raised md-info" to="/licences"
                >Analitycs</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

          <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card class="">
          <md-card-header data-background-color="red">
            <h4 class="title">Statistiques</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualiser l'état en temps réel des serveurs EtherEgo.</p>
            <div class="text-right">
              <md-button class="md-raised md-danger" to="/licences"
                >Statistiques</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-66"
      >
        <md-card class="">
          <md-card-header data-background-color="green">
            <h4 class="title">Prestataires</h4>
          </md-card-header>
          <md-card-content>
            <p>Visualiser l'état en temps réel des serveurs EtherEgo.</p>
            <div class="text-right">
              <md-button class="md-raised md-success" to="/licences"
                >Prestataires</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>-->
      <!--<div
        v-if="getRules('CONTROL_USERS_INSCRIPTION')"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">À Faire</h4>
          </md-card-header>
          <md-card-content>
            <p>Organisation et suivi des tickets à faire.</p>
            <ul>
              <li>Gérer vos tickets</li>
              <li>Commenter</li>
              <li>Supprimer une inscription en cours</li>
            </ul>

            <div class="text-right">
              <md-button class="md-raised md-info" to="/followup"
                >À Faire</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>-->
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  components: {},
  computed: {
    allRules: {
      get() {
        var x = store.getters["authentification/getDroits"];
        return x;
      },
    },
  },
  methods: {
    getRules(rules) {
      if (this.allRules) {
        return this.allRules.includes(rules);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.heightStandard {
  height: 260px;
}
</style>
