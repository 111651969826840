<!--
@Author: Florian Merel <florian>
@Date:   15-Jan-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogFilesVue.vue
@Last modified by:   florian
@Last modified time: 05-Mar-2021
-->
<!--
@Author: Florian Merel <florian>
@Date:   10-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogsTickets.vue
@Last modified by:   florian
@Last modified time: 05-Mar-2021
-->

<template>
  <div>
    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      style="margin-top: 30px; margin-bottom: 30px"
    >
      <md-icon style="margin-left: -6px">attach_file</md-icon>
      <span class="md-body-2" style="margin-left: 6px">Fichiers</span>
    </div>

    <md-card v-for="(file, i) in files" :key="i" style="margin-top: -30px">
      <div class="md-layout">
        <div class="md-layout-item md-xsmall-size-100 md-size-70">
          <div class="filesUploadText">
            {{ file.originalname }} ({{ file.size | kb }} kb)
          </div>
          <md-progress-bar
            v-if="loading"
            md-mode="indeterminate"
          ></md-progress-bar>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-size-30 text-right">
          <md-button
            class="md-raised md-info"
            @click="download(file)"
            :disabled="loading"
          >
            <md-icon style="">save_alt</md-icon>
            <md-tooltip md-direction="top">Télécharger</md-tooltip>
          </md-button>
        </div>

        <!--<div class="md-layout-item md-xsmall-size-100 md-size-100">
          <img v-if="incl(file.originalname)" class="fit-picture" src="{{/media/cc0-images/grapefruit-slice-332-332.jpg}}" alt="Grapefruit slice atop a pile of other slices">
        </div>-->
      </div>
    </md-card>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";

export default {
  name: "dialog-Files",
  props: ["item"],
  data: () => ({
    loading: false,
  }),
  computed: {
    files: {
      get() {
        if (!this.item.PropFollowUp) {
          return [];
        }
        var act = store.getters["activity/getData"];
        if (act && act.data && act.data.files) {
          return act.data.files.rows;
        }
        return [];
      },
    },
  },
  methods: {
    async download(file) {
      this.loading = true;
      let res = await store.dispatch("downloadFiles/downloadFiles", {
        id: file.id,
        fileName: file.originalname,
      });
      //console.log(res);
      //return "";
      this.loading = false;
    },

    incl(fileName) {
      if (fileName.includes("jpg")) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.borderExit {
  border: dashed #e2007a;
}

.borderEnter {
  border: dashed green;
}

.filesUploadText {
  margin-top: 18px;
}
</style>
