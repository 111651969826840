<template>
  <ul class="nav nav-mobile-menu"></ul>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
