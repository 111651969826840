<script src="https://cdn.tailwindcss.com"></script>
<template>
  <v-card>
    <div></div>
  </v-card>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import Vue from "vue";
import server from "../store/server.js";
import axios from "axios";

export default {
  name: "SubscriptionsPrices",
  data() {
    return {
      tab: null,
      tabs: "all",
      query: "",
      apps: "MSX",
      counter: null,
      dialog: false,
      item_select: null,
      items: [
        "N° Éditeur",
        "Numero ASP",
        "Montant",
        "Date de commande",
        "BDC 4D",
        "BDC ASP",
        "Solde 4D",
        "Solde ASP",
        "Messagerie",
      ],
      items_select: "N° Éditeur",
      items_ordre: ["Croissant", "Décroissant"],
      items_select_ordre: "Décroissant",
      page: 1,
      size: 0,
    };
  },
  created: async function () {},
  watch: {
    tab: function (params) {},

    tabs: function (val, oldVal) {
      this.page = 1;
      this.size = 0;
      this.search();
    },
  },
  computed: {},
  methods: {
    changes(newVal) {
      if (newVal && newVal.count) {
        //   this.size = Math.round(newVal.count / 100);
      }

      // this.size += 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
