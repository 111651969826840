<!--
@Author: Florian Merel <florian>
@Date:   22-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: parkmanagement.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div class="content">
    <v-card v-if="counter" elevation="0" outlined>
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="row" style="margin-top: -30px">
          <div class="col">
            Nombre de bon de commande :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm }}
            </span>
            <br />
          </div>
          <div class="col">
            Nombre de bon de commande envoyer à l'ASP :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_send_ASP }}
            </span>
          </div>
          <div class="col">
            Nombre de bon de commande à envoyer :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm - counter.nb_OrderForm_send_ASP }}
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="row">
      <div class="col">
        <v-card elevation="12" outlined>
          <v-card-title>Exporter les bons de commandes Ségur</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-col class="d-flex" cols="12" sm="12">
                <v-select
                  :items="items"
                  label="Nombre de bon commande à exporter"
                  dense
                  v-model="item"
                ></v-select>
              </v-col>

              <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="exportBdc()"
              >
                Exporter les derniers bons de commande
                <v-icon right dark> mdi-file-export </v-icon>
              </v-btn>
            </v-row>

            <div
              v-if="this.oldExport"
              style="color: red; font-weight: bold; margin-top: 15px"
            >
              Dernier export : {{ getDate(this.oldExport) }}
            </div>
          </v-card-text>

          <v-progress-linear
            v-if="generate"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <v-card elevation="12" outlined>
          <v-card-title>Importer le retour de l'ASP</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-col class="d-flex" cols="12" sm="12">
                <label for="formFile" class="form-label"></label>
                <input
                  class="form-control"
                  @change="previewFiles"
                  type="file"
                  id="formFile"
                />
                <v-btn
                  v-if="fileImport"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="sendFiles()"
                >
                  Envoyer
                </v-btn>
              </v-col>
            </v-row>
            <div
              class="md-layout-item"
              style=""
              v-for="ti in this.importData"
              :key="ti"
            >
              {{ ti }}
            </div>

            <!-- <span v-if="this.oldImport" style="color: black; font-weight: bold">
              {{ this.oldImport }}
            </span>-->

            <div
              v-if="this.oldImport"
              style="color: red; font-weight: bold; margin-top: 15px"
            >
              Dernier import : {{ getDate(this.oldImport) }}
            </div>
          </v-card-text>
          <v-progress-linear
            v-if="generateImport"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card>
      </div>
    </div>

    <v-card v-if="counter" elevation="0" style="margin-top: 20px" outlined>
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="row" style="margin-top: -30px">
          <div class="col">
            Total factures :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice }}
            </span>
            <br />
          </div>
          <div class="col">
            Factures envoyées à l'ASP :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice_send_ASP }}
            </span>
          </div>
          <div class="col">
            Factures à envoyer :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice - counter.nb_Invoice_send_ASP }}
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="row">
      <div class="col">
        <v-card elevation="12" outlined>
          <v-card-title>Les factures Ségur</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-col class="d-flex" cols="12" sm="12">
                <v-select
                  :items="items"
                  label="Nombre de facture à exporter"
                  dense
                  v-model="itemFacts"
                ></v-select>
              </v-col>

              <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="exportFact()"
              >
                Exporter les factures
                <v-icon right dark> mdi-file-export </v-icon>
              </v-btn>
            </v-row>

            <v-row
              align="center"
              class="mx-0"
              style="margin-top: 20px; margin-bottom: -30px"
            >
              <h4>Importer le retour de l'ASP</h4>
            </v-row>

            <v-row align="center" class="mx-0">
              <input
                class="form-control"
                type="file"
                @change="previewFactures"
                id="formFile"
              />

              <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="sendFacture()"
              >
                Envoyer
              </v-btn>
            </v-row>
          </v-card-text>

          <v-progress-linear
            v-if="generatefact"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card>
      </div>
    </div>

    <!--<v-card v-if="counter" elevation="0" style="margin-top: 20px" outlined>
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="row" style="margin-top: -30px">
          <div class="col">
            Bon de commande qui pose problème :
            <span v-if="prb" style="color: black; font-weight: bold">
              {{ prb.count }}
            </span>
            <br />
          </div>
        </div>
      </v-card-text>
    </v-card>

   <div class="row">
      <div class="col">
        <v-card v-if="prb" elevation="12" outlined style="margin-bottom: 20px;">

          <div v-for="pr in this.prb.rows"
            :key="pr.id" style="margin-left: 30px;" >
            {{ pr.id }} - {{ pr.apps }} - {{ pr.codeTraitement }} - {{ pr.libelleTraitement }}
          </div>


        </v-card>
      </div>
    </div>-->

    <!--
        <div class="row">
      <div class="col">
        <v-card elevation="12" outlined>
          <v-card-title>Mauvais Traitements</v-card-title>

          <v-card-text>
          </v-card-text>
          <v-progress-linear
            v-if="generateImport"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card>
      </div>
    </div>-->
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import Vue from "vue";
import server from "../store/server.js";
import axios from "axios";
var JSZip = require("jszip");
import saveAs from "file-saver";

export default {
  name: "SegurASP",
  data() {
    return {
      items: ["1", "2", "10", "50", "100", "200"],
      item: "200",
      itemFacts: "200",
      counter: null,
      generate: false,
      generatefact: false,
      fileImport: null,
      factureImport: null,
      generateImport: false,
      oldExport: null,
      oldImport: null,
      importData: [],
      prb: null,
    };
  },
  created: async function () {
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/count",
        headers: server.getHeader(),
      });

      if (res && res.data && res.data.data) {
        this.counter = res.data.data;
      }
    } catch (error) {}

    /* try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/prb",
        headers: server.getHeader(),
      });

      if (res && res.data && res.data.data) {
        console.log(res.data.data.rows);
        this.prb = res.data.data;
      }
    } catch (error) {}
    */

    this.getLocalStorage();
  },

  watch: {},
  computed: {},
  methods: {
    async sendFiles() {
      this.generateImport = true;
      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/import",
          headers: server.getHeader(),
          data: { json: this.fileImport },
        });

        this.importData = [];
        if (res && res.data && res.data.data && res.data.data.lignesImport) {
          for (let i = 0; i < res.data.data.lignesImport.length; i++) {
            if (res.data.data.lignesImport[i].success_update) {
              this.importData.push(
                res.data.data.lignesImport[i].numeroEditeur +
                  " - mise à jour réussie !"
              );
            } else {
              if (!res.data.data.lignesImport[i].numeroASP) {
                this.importData.push(
                  res.data.data.lignesImport[i].numeroEditeur +
                    " - échec de mise à jour Numéro ASP introuvable !"
                );
              } else if (res.data.data.lignesImport[i].numeroEditeur) {
                this.importData.push(
                  res.data.data.lignesImport[i].numeroEditeur +
                    " - échec de mise à jour !"
                );
              }
            }
          }

          localStorage.setItem("oldImport", Date.now());
          this.generateImport = false;
        }
      } catch (error) {
        this.generateImport = false;
        alert("Echec de l'envoie.");
      }
    },

    async sendFacture() {
      this.generatefact = true;
      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/import/facture",
          headers: server.getHeader(),
          data: { json: this.factureImport },
        });

        if (res && res.data && res.data.data) {
          if (res.data.data.sucess) {
            alert("Mise à jour des Bons de commandes Ségur Réussi");
            this.generatefact = false;
            return;
          }

          var zip = new JSZip();

          if (res.data.data.zip_folder) {
            for (let i = 0; i < res.data.data.zip_folder.length; i++) {
              zip.file(
                res.data.data.zip_folder[i].filename,
                res.data.data.zip_folder[i].filedata,
                { base64: true }
              );
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, res.data.data.nameFile);
            });
          }

          var dataJS = JSON.stringify(res.data.data.fileJson);
          const blob = new Blob([dataJS], { type: "application/json" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = res.data.data.nameFile;
          link.click();
          URL.revokeObjectURL(link.href);
          this.generatefact = false;
        }

        //this.importData = [];
        /*if (res && res.data && res.data.data && res.data.data.lignesImport) {
          for (let i = 0; i < res.data.data.lignesImport.length; i++) {
            if (res.data.data.lignesImport[i].success_update) {
              this.importData.push(
                res.data.data.lignesImport[i].numeroEditeur +
                  " - mise à jour réussie !"
              );
            } else {
              if (!res.data.data.lignesImport[i].numeroASP) {
                this.importData.push(
                  res.data.data.lignesImport[i].numeroEditeur +
                    " - échec de mise à jour Numéro ASP introuvable !"
                );
              } else if (res.data.data.lignesImport[i].numeroEditeur) {
                this.importData.push(
                  res.data.data.lignesImport[i].numeroEditeur +
                    " - échec de mise à jour !"
                );
              }
            }
          }

          localStorage.setItem("oldImport", Date.now());
          this.generateImport = false;
        }*/
      } catch (error) {
        alert("Echec de l'envoie.");
      }
    },

    previewFactures(event) {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
      ) {
        if (event.target.files[0].type == "application/json") {
          var fileReader = new FileReader();
          var cl = this;
          fileReader.onload = function () {
            var data = fileReader.result;
            cl.factureImport = data;
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          alert("Merci d'envoyer uniquement des fichiers JSON de L'ASP");
        }
      }
    },

    previewFiles(event) {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
      ) {
        if (event.target.files[0].type == "application/json") {
          var fileReader = new FileReader();
          var cl = this;
          fileReader.onload = function () {
            var data = fileReader.result;
            cl.fileImport = data;
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          alert("Merci d'envoyer uniquement des fichiers JSON de L'ASP");
        }
      }
    },

    getLocalStorage() {
      if (localStorage.getItem("oldExport")) {
        try {
          this.oldExport = JSON.parse(localStorage.getItem("oldExport"));
        } catch (e) {
          localStorage.removeItem("oldExport");
        }
      }

      if (localStorage.getItem("oldImport")) {
        try {
          this.oldImport = JSON.parse(localStorage.getItem("oldImport"));
        } catch (e) {
          localStorage.removeItem("oldImport");
        }
      }
    },

    async exportBdc() {
      if (this.oldExport) {
        if (this.oldImport) {
          var d2 = new Date(this.oldExport);
          var d1 = new Date(this.oldImport);

          if (d2.getDate() !== d1.getDate()) {
            if (
              confirm(
                "Nous avons détecté qu'un export a déjà eu lieu aujourd'hui mais aucun import êtes-vous sûr de vouloir d'exporter ?"
              )
            ) {
            } else {
              return;
            }
          }
        } else {
          if (
            confirm(
              "Nous avons détecté qu'un export a déjà eu lieu aujourd'hui mais aucun import êtes-vous sûr de vouloir d'exporter ?"
            )
          ) {
          } else {
            return;
          }
        }
      }

      var dt = new Date();
      //dt.setDate(dt.getDate()-1);
      var nbFile = 1;
      if (localStorage.getItem(dt.getDate() + "_nbExport")) {
        nbFile = parseInt(localStorage.getItem(dt.getDate() + "_nbExport"));
      }

      this.generate = true;
      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/export",
          headers: server.getHeader(),
          data: { nb: this.item },
        });

        if (res && res.data && res.data.data) {
          var zip = new JSZip();

          if (res.data.data.zip_folder) {
            for (let i = 0; i < res.data.data.zip_folder.length; i++) {
              zip.file(
                res.data.data.zip_folder[i].filename,
                res.data.data.zip_folder[i].filedata,
                { base64: true }
              );
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(
                content,
                res.data.data.nameFile.replace("001", "00" + nbFile)
              );
            });
          }

          var dataJS = JSON.stringify(res.data.data.fileJson);
          const blob = new Blob([dataJS], { type: "application/json" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          link.download = res.data.data.nameFile.replace("001", "00" + nbFile);
          link.click();
          URL.revokeObjectURL(link.href);
          this.generate = false;

          localStorage.setItem(dt.getDate() + "_nbExport", nbFile + 1);
          localStorage.setItem("oldExport", Date.now());
          getLocalStorage();
        }
      } catch (error) {
        this.generate = false;
      }
    },

    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    async exportFact() {
      this.generatefact = true;

      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.segur + "/export/facture",
          headers: server.getHeader(),
          data: { nb: this.itemFacts },
        });

        if (res && res.data && res.data.data) {
          var zip = new JSZip();

          if (res.data.data.zip_folder) {
            for (let i = 0; i < res.data.data.zip_folder.length; i++) {
              zip.file(
                res.data.data.zip_folder[i].filename,
                res.data.data.zip_folder[i].filedata,
                { base64: true }
              );
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, res.data.data.nameFile);
            });
          }

          var dataJS = JSON.stringify(res.data.data.fileJson);
          const blob = new Blob([dataJS], { type: "application/json" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = res.data.data.nameFile;
          link.click();
          URL.revokeObjectURL(link.href);
          this.generatefact = false;
        }
      } catch (error) {
        this.generatefact = false;
      }
    },
  },
};
</script>
