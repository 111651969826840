/**
 * @Author: Florian Merel <florian>
 * @Date:   13-Jan-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: uploadFiles.js
 * @Last modified by:   florian
 * @Last modified time: 15-Jan-2021
 */
/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 15-Jan-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getData(context) {
    return context.data;
  },

  getLoading(context) {
    return context.loading;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async postFiles(context, params) {
    context.commit("setLoading", true);
    var h = server.getHeader();
    h["Content-Type"] = "multipart/form-data";
    try {
      let res = await axios({
        method: "POST",
        data: params,
        url: server.url + server.urls.uploadFiles,
        headers: h,
      });
      context.commit("setLoading", false);
    } catch (error) {}
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
