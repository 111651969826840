<!--
@Author: Florian Merel <florian>
@Date:   09-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: RightPanel.vue
@Last modified by:   florian
@Last modified time: 01-Sep-2021
-->

<template>
  <div style="background-color: white">
    <div class="md-layout margeTopSearchBar">
      <div class="md-layout-item md-xsmall-size-100 md-size-100">
        <md-field>
          <label>Recherche</label>
          <md-input v-on:keyup.enter="onEnter" v-model="query"></md-input>
        </md-field>

        <md-button
          class="md-button md-prokov md-round md-just-icon"
          style="margin-top: -70px; float: right; z-index: 999"
          @click="onEnter()"
        >
          <md-icon
            >search
            <md-tooltip md-direction="top">Recherche</md-tooltip>
          </md-icon>
        </md-button>
      </div>
    </div>
  </div>
</template>

<!--           style="position: absolute; margin-top: -70px; float: right"
-->

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";
import Vue from "vue";

export default {
  name: "tickets-search-bar",
  data() {
    return {
      query: "",
    };
  },
  methods: {
    onEnter() {
      if (this.query && this.query.length != 0) {
        store.dispatch("ticketsV2Search/getSearch", this.query);
      }
    },
  },
  computed: {},
  watch: {
    $route(to, from) {},
  },
  created: async function () {},
};
</script>

<style lang="scss"></style>
