<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Login.vue
@Last modified by:   florian
@Last modified time: 14-Dec-2020
-->

<template>
  <div class="centered-container">
    <md-dialog-alert
      :md-active.sync="alert.active"
      :md-content="alert.message"
      md-title="Erreur de connexion"
      md-confirm-text="Ok"
    />

    <md-content class="md-elevation-3">
      <div class="title">
        <img src="../assets/img/logo.png" />
        <div class="md-title">EtherEgo</div>
        <div class="md-body-1">Deconnexion réussie.</div>
      </div>

      <div class="form"></div>

      <div class="actions md-layout center-element">
        <md-button class="md-raised md-prokov" to="/login"
          >Se connecter</md-button
        >
      </div>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-stroke="2"
        ></md-progress-spinner>
      </div>
    </md-content>
    <div class="background" />
    <div class="drawRectTop" />
    <div class="drawRectBot" />
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "App",
  data() {
    return {
      loading: false,
      alert: {
        active: false,
        message: "",
      },
      login: {
        email: "",
        password: "",
      },
    };
  },
  created: function () {
    localStorage.removeItem("token");
  },
};
</script>
