/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: usersList.js
 * @Last modified by:   florian
 * @Last modified time: 24-Nov-2020
 */

import store from "@/store";

const state = {
  data: {
    notif: false,
    message: "",
    type: "",
    verticalAlign: "",
    horizontalAlign: "",
    icon: "",
  },
};

const getters = {
  getNotification(context) {
    return context.data;
  },
};

const mutations = {
  setNotification(context, obj) {
    context.data = obj;
  },

  removeNotificaition() {
    context.data = {};
    context.notif = false;
  },
};

const actions = {
  sendNotification(context, params) {
    var notif = {};

    notif.verticalAlign = "top";
    notif.horizontalAlign = "right";
    if (!params.message) {
      if (params.type == "success" && params.method == "update") {
        notif.notif = true;
        notif.message = "Mise à jour des données réussies.";
        notif.icon = "verified_user";
        notif.type = "success";
      }

      if (params.type == "fail" && params.method == "update") {
        notif.notif = true;
        notif.message = "Echec de mise à jour des données.";
        notif.icon = "warning";
        notif.type = "danger";
      }

      if (params.type == "success" && params.method == "ticketsAttribution") {
        notif.notif = true;
        notif.message = "Attribution du ticket réussies.";
        notif.icon = "verified_user";
        notif.type = "success";
      }

      if (params.type == "fail" && params.method == "ticketsAttribution") {
        notif.notif = true;
        notif.message = "Echec d'attribution du ticket.";
        notif.icon = "warning";
        notif.type = "danger";
      }
    } else {
      notif.notif = true;
      notif.message = params.message;
      notif.icon = params.icon;
      notif.type = params.type;
    }

    context.commit("setNotification", notif);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
