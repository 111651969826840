<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesChanges.vue
@Last modified by:   florian
@Last modified time: 08-Mar-2021
-->

<template>
  <div class="">
    <div class="md-subheading">Changements de station depuis sa création</div>
    <md-divider style="margin-top: 5px; margin-bottom: 10px"></md-divider>

    <div class="md-layout" style="margin-bottom: 25px">
      <div
        class="md-layout-item md-small-size-100 md-size-30"
        style="text-align: center"
      >
        Date
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-30"
        style="text-align: center"
      >
        Station retirée
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-30"
        style="text-align: center"
      >
        IP
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-10"
        style="text-align: center"
      >
        Ping
      </div>
    </div>

    <md-card v-if="mouves.length == 0" style="margin-top: -30px">
      <md-card-content>
        <div class="md-layout">
          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="text-align: center"
          >
            Aucun changement n'a eu lieu
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div v-if="mouves.length > 0">
      <md-card
        v-for="mv in mouves"
        :key="mv.deviceChangeID"
        style="margin-top: -30px"
      >
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-small-size-100 md-size-30"
              style="text-align: center"
            >
              {{ getDate(mv.date) }}
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-30"
              style="text-align: center"
            >
              {{ mv.oldDeviceID }}
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-30"
              style="text-align: center"
            >
              {{ mv.oldDeviceLastIP }}
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-10"
              style="text-align: center"
            >
              {{ mv.oldDeviceNbLog }}
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";

export default {
  name: "licences-changes",
  props: ["propsTarget", "propsLicence"],
  computed: {
    mouves() {
      var res = store.getters["licences/getLicence"];
      if (res && res.data && res.data.mouves) {
        return res.data.mouves;
      }
      return [];
    },
  },
  data() {
    return {
      oui: "Oui",
      non: "Non",
      pingSuccess: null,
    };
  },
  methods: {
    getDate(da) {
      return toolsFormat.getDateLong(da);
    },
  },
};
</script>

<style lang="scss"></style>
