<!-- eslint-disable prettier/prettier -->
<!--
@Author: Jessy DROUIN
@Date:   01-dec-2023
@Email:  jessy.drouin@equasens.com
@Project: CRM UI USER
@Filename: EditProfileServices.vue
@Last modified by:   Jessy
@Last modified time: 14-dec-2023
-->

<template>
  <div>
    <div style="width: 100% display: flex; flex-direction: column; align-items: flex-start;" class="md-layout">
      <h4 style="font-weight: bold; margin-left: 0.9rem">Service SEGUR</h4>
      <!-- TABLE NUMERO -->
      <table v-if="currentItemSegur" class="styled-table">
        <thead>
          <tr>
            <th>N° Éditeur</th>
            <th>Numero ASP</th>
            <th>Date de commande</th>
            <th>Preuves</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- NUMERO EDITEUR -->
            <td>{{ currentItemSegur.numeroEditeur }}</td>

            <!-- NUMERO ASP -->
            <td>{{ currentItemSegur.numeroASP }}</td>

            <!-- DATE DE COMMANDE -->
            <td>{{ formatDateSegur(currentItemSegur.createdAt) }}</td>

            <!-- PREUVES -->
            <td>
              <v-chip v-if="checkPreuve(currentItemSegur)" color="green" text-color="white">
                {{ checkDate(currentItemSegur.dateOfOrder) }}
              </v-chip>
              <v-chip v-else color="red" text-color="white">Non</v-chip>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="margin-left: 0.9rem">
        Aucune commande Segur trouvée.
      </div>

      <br />

      <!-- ENCART DONNEES RPPS -->
      <div class="md-layout-item md-small-size-100 md-size-100">
        <div class="rpps-action-container d-flex">
          <!-- Conteneur pour les données RPPS -->
          <div class="rpps-data-container">
            <h4 style="font-weight: bold">Services par RPPS</h4>
            <div v-if="Array.isArray(rppsData) && rppsData.length > 0">
              <div v-for="(item, index) in rppsData" :key="index"
                class="d-flex justify-content-between align-items-center">
                <md-card style="max-width: 60%; margin: 10px 0">
                  <span class="md-nav-tabs-title" style="color: black; margin-top: 10px">
                    {{ item }}
                  </span>
                  <md-card-content style="margin-top: 20px"> </md-card-content>
                </md-card>
                <v-icon class="delete-icon" @click="supprimerRppsData(item)">mdi-delete</v-icon>
              </div>
            </div>
            <div v-else-if="this.obj.rpps == null">
              Aucun service par RPPS trouvé.
            </div>
            <div v-else>Aucun service activé.</div>
            <!-- Affichage nouveau service -->
            <div v-if="showAddForm">
              <md-card style="max-width: 60%; margin: 10px 0">
                <md-card-content>
                  <md-field>
                    <md-select v-model="newRpps.codeActe" placeholder="Ajoutez un service">
                      <md-option v-for="option in codeOptions" :value="option" :key="option">
                        {{ option }}
                      </md-option>
                    </md-select>
                  </md-field>

                  <!-- Radio bouton 1 ou 0 -->
                  <v-radio-group v-model="newRpps.value" row>
                    <v-radio label="Permanent" value="1"></v-radio>
                    <v-radio label="Inactif" value="0"></v-radio>
                  </v-radio-group>

                  <!-- Date picker -->
                  <v-col cols="12" class="mb-3 d-flex align-items-center">
                    <label class="mr-3 labelStyle">A échéance</label>
                    <md-datepicker ref="datepicker" class="datePicker" @input="formatDate"></md-datepicker>
                  </v-col>
                </md-card-content>

                <!-- Bouton d'ajout/annulation RPPS -->
                <md-card-actions>
                  <v-icon class="add-icon" @click="addRpps()" style="color: #50c878">mdi-plus</v-icon>
                  <v-icon class="cancel-icon" @click="cancelAndClean()" style="color: red">mdi-close</v-icon>
                </md-card-actions>
              </md-card>
            </div>
            <br />
            <!-- Bouton d'affichage nouveau service RPPS -->
            <v-icon class="add-icon" @click="showAddForm = true" style="color: #50c878"
              v-if="!showAddForm">mdi-plus</v-icon>
            <span v-if="!showAddForm">Ajoutez un service</span>
          </div>

          <!-- Conteneur pour les données de caisse -->
          <div class="caisse-data-container flex-grow-1">
            <h4 style="font-weight: bold">Services par Caisse</h4>
            <div v-for="(item, index) in (Array.isArray(caisseData)
              ? caisseData
              : []
            ).filter((item) => !item.includes('CAISSE'))" :key="index"
              class="d-flex justify-content-between align-items-center">
              <md-card style="max-width: 70%; margin: 10px 0">
                <span class="md-nav-tabs-title" style="color: black; margin-top: 10px">
                  {{ codeMap[item] || item }}
                </span>
                <md-card-content style="margin-top: 20px"> </md-card-content>
              </md-card>
            </div>
            <div v-if="this.obj.caisse == null || this.obj.caisse == ''">
              Aucun service par caisse enregistré.
            </div>
            <!-- Affichage nouvelle caisse -->
            <div v-if="showAddCaisseForm">
              <md-card style="max-width: 60%; margin: 10px 0">
                <md-card-content>
                  <md-field>
                    <md-input v-model="newCaisse.codeActe" placeholder="Ajoutez une caisse"></md-input>
                  </md-field>
                  <md-field>
                    <md-input v-model="newCaisse.value" placeholder="1 | date: 12/12/2023"></md-input>
                  </md-field>
                </md-card-content>
                <md-card-actions>
                  <v-icon class="add-icon" @click="addCaisse()" style="color: #50c878">mdi-plus</v-icon>
                  <v-icon class="cancel-icon" @click="showAddCaisseForm = false" style="color: red">mdi-close</v-icon>
                </md-card-actions>
              </md-card>
            </div>
          </div>
        </div>
      </div>

      <!-- Dialogue de confirmation pour l'activation d'un service RPPS -->
      <v-dialog v-model="showActivationDialogRpps" max-width="500px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>Voulez-vous vraiment activer ce service RPPS ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirmActivationRpps">Oui</v-btn>
            <v-btn color="red darken-1" text @click="showActivationDialogRpps = false">Non</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue de confirmation pour l'activation d'un service Caisse -->
      <v-dialog v-model="showActivationDialogCaisse" max-width="500px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>Voulez-vous vraiment activer ce service Caisse ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirmActivationCaisse">Oui</v-btn>
            <v-btn color="red darken-1" text @click="showActivationDialogCaisse = false">Non</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue de confirmation pour la suppression -->
      <v-dialog v-model="showDeleteConfirm" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Supprimer l'élément</v-card-title>
          <v-card-text>Voulez-vous vraiment supprimer cet élément ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelDelete">Non</v-btn>
            <v-btn color="green darken-1" text @click="confirmDelete">Oui</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { MdField, MdInput } from "vue-material/dist/components";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "edit-profile-services",
  data() {
    return {
      newRpps: {
        codeActe: "",
        rppsItem: "",
        value: "",
      },
      newCaisse: {
        codeActe: "",
        caisseItem: "",
        value: "",
      },
      codeMap: {
        CERFA: "EV - Imp. fond Cerfa",
        SCOR_MEDECIN: "EV - SCOR Médecin",
        APCV: "MS4 - App Carte Vitale",
        "EPRE - PRESERIE": "MS4 - Ordo Num BETA",
        EPRE: "MS4 - Ordo Numérique",
        ROSP: "ROSP",
      },
      menu: false,
      codeOptions: [
        "CERFA",
        "SCOR_MEDECIN",
        "APCV",
        "EPRE",
        "EPRE - PRESERIE",
        "ROSP",
      ],
      datePattern: /^\d{2}\/\d{2}\/\d{4}$/,
      state: "idle",
      itemToDelete: null,
      showAddForm: false,
      showActivationDialogRpps: false,
      showActivationDialogCaisse: false,
      showAddCaisseForm: false,
      givenname: "",
      familyname: "",
      query: "",
      dialog: false,
      item_select: null,
      currentItemSegur: null,
    };
  },
  directives: {
    focus: {
      // definition directive (hook) - focus sur l'élément au chargement et à la mise à jour
      inserted: function (el) {
        el.focus();
      },
    },
  },
  computed: {
    // Récupération des données RPPS et caisse depuis le store
    ...mapGetters("rpps", ["rppsData"]),
    ...mapGetters("rpps", ["caisseData"]),
    ...mapGetters("segur", ["getResidentID"]),

    // Affichage de la confirmation de suppression
    showDeleteConfirm() {
      return this.state === "deletingRpps" || this.state === "deletingCaisse";
    },

    residentData() {
      return this.$store.getters["segur/getResidentID"];
    },

    obj: {
      get() {
        var x = store.getters["user/getData"];
        return x?.data?.user || {};
      },
    },
  },
  watch: {
    // Charger les données du résident lorsqu'elles sont disponibles
    residentData: function (newVal) {
      if (newVal) {
        this.currentItemSegur = newVal.data.SEGUR_OrderFormSegurs;
      }
    },
    "obj.id": function (newVal) {
      if (newVal) {
        this.$store.dispatch("segur/getResidentSegurID", newVal).then(() => {});
      }
    },
    // Récupération des données RPPS et caisse à chaque changement de RPPS ou caisse
    "obj.rpps": function (newVal) {
      if (newVal) {
        this.fetchRppsDataFromStore(newVal);
      } else {
        this.$store.commit("rpps/SET_RPPS_DATA", null);
      }
    },
    "obj.caisse": function (newVal) {
      if (newVal) {
        this.fetchCaisseDataFromStore(newVal);
      } else {
        this.$store.commit("rpps/SET_CAISSE_DATA", null);
      }
    },
  },
  methods: {
    //===========================================================================
    // CHANGES - Fonction de mise à jour de la taille de la progress bar
    //===========================================================================
    changes(newVal) {
      if (newVal && newVal.count) {
        this.size = Math.round(newVal.count / 100);
      }

      this.size += 1;
    },

    //===========================================================================
    // FETCH RPPS DATA FROM STORE - Fonction de récupération des données RPPS
    //===========================================================================
    async fetchRppsDataFromStore(rppsCode) {
      if (rppsCode) {
        try {
          await this.$store.dispatch("rpps/fetchRppsData", rppsCode);
        } catch (error) {
          throw new Error(
            "Erreur lors de la récupération des données RPPS:",
            error
          );
        }
      }
    },

    //===========================================================================
    // FETCH CAISSE DATA FROM STORE - Fonction de récupération des données de caisse
    //===========================================================================
    async fetchCaisseDataFromStore(caisseCode) {
      if (caisseCode) {
        try {
          await this.$store.dispatch("rpps/fetchCaisseData", caisseCode);
        } catch (error) {
          throw new Error(
            "Erreur lors de la récupération des données de caisse:",
            error
          );
        }
      }
    },

    //===========================================================================
    // ADD RPPS - Fonction d'ajout d'un service RPPS
    // CHANGEMENT D'ETAT VERS "confirmationDialogRpps"
    //===========================================================================
    async addRpps() {
      this.showActivationDialogRpps = true;
    },

    //===========================================================================
    // CONFIRM ACTIVATION RPPS - Fonction de confirmation d'activation d'un service RPPS
    //===========================================================================
    async confirmActivationRpps() {
      try {
        const rppsData = {
          codeActe: this.newRpps.codeActe,
          rppsItem: [this.obj.rpps],
          value: this.newRpps.value,
        };

        // Dispatch de l'action Vuex pour ajouter le RPPS
        await this.$store.dispatch("rpps/activateFeature", rppsData);

        // Réinitialisation du formulaire et actualisation des données affichées
        this.newRpps = { codeActe: "", rppsItem: "", value: "" };
        this.fetchRppsDataFromStore(this.obj.rpps);
        this.fetchCaisseDataFromStore(this.obj.caisse);
        this.showAddForm = false;
        this.showActivationDialogRpps = false;
      } catch (error) {
        throw new Error("Erreur lors de l'ajout des données RPPS:", error);
      }
    },

    //===========================================================================
    // ADD CAISSE - Fonction d'ajout d'un service Caisse
    // CHANGEMENT D'ETAT VERS "confirmationDialogCaisse"
    //===========================================================================
    async addCaisse() {
      this.showActivationDialogCaisse = true;
    },

    //===========================================================================
    // CONFIRM ACTIVATION CAISSE - Fonction de confirmation d'activation d'un service Caisse
    //===========================================================================
    async confirmActivationCaisse() {
      try {
        const caisseData = {
          codeActe: `${this.newCaisse.codeActe} - CAISSE`,
          rppsItem: [this.obj.caisse],
          value: this.newCaisse.value,
        };

        // Dispatch de l'action Vuex pour ajouter le RPPS
        await this.$store.dispatch("rpps/activateFeature", caisseData);

        // Réinitialisation du formulaire et actualisation des données affichées
        this.newCaisse = { codeActe: "", rppsItem: "", value: "" };
        this.fetchCaisseDataFromStore(this.obj.caisse);
        this.fetchRppsDataFromStore(this.obj.rpps);
        this.showAddCaisseForm = false;
        this.showActivationDialogCaisse = false;
      } catch (error) {
        throw new Error("Erreur lors de l'ajout des données de caisse:", error);
      }
    },

    //===========================================================================
    // SUPPRIMER RPPS DATA OU CAISSE DATA - Fonction de suppression d'un service RPPS ou caisse
    // CHANGEMENT D'ETAT :
    //  - "deletingRpps" pour un service RPPS
    //  - "deletingCaisse" pour un service Caisse
    //  - "cancellingDelete" pour annuler la suppression
    //  - "idle" pour réinitialiser l'état
    //===========================================================================
    supprimerRppsData(item) {
      this.itemToDelete = item;
      this.state = "deletingRpps";
    },

    supprimerCaisseData(item) {
      this.itemToDelete = item;
      this.state = "deletingCaisse";
    },

    cancelDelete() {
      this.state = "cancellingDelete";
    },

    resetState() {
      this.state = "idle";
    },

    //===========================================================================
    // CONFIRM DELETE - Fonction de confirmation de suppression d'un service RPPS ou caisse
    //===========================================================================
    async confirmDelete() {
      if (this.state === "deletingCaisse") {
        try {
          const res = await this.$store.dispatch("rpps/serviceDelete", {
            caisseCode: this.obj.caisse,
            item: this.itemToDelete,
          });
          if (res && res.status == 200) {
            this.fetchCaisseDataFromStore(this.obj.caisse);
          }
        } catch (error) {
          throw new Error(
            "Erreur lors de la suppression des données RPPS:",
            error
          );
        }
      } else if (this.state === "deletingRpps") {
        const res = await this.$store.dispatch("rpps/serviceDelete", {
          rppsCode: this.obj.rpps,
          item: this.itemToDelete,
        });
        if (res && res.status == 200) {
          this.fetchRppsDataFromStore(this.obj.rpps);
        }
      }
      this.state = "idle";
      this.itemToDelete = null;
    },

    //===========================================================================
    // FORMAT DATE - Fonction de formatage de la date en jj/mm/aaaa
    //===========================================================================
    formatDate(date) {
      let d = new Date(date);
      let day = ("0" + d.getDate()).slice(-2);
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      let year = d.getFullYear();

      this.newRpps.value = day + "/" + month + "/" + year;
    },

    //===========================================================================
    // CANCEL AND CLEAN - Fonction d'annulation de recherche
    //===========================================================================
    cancelAndClean() {
      this.newRpps = { codeActe: "", rppsItem: "", value: "" };
      this.showAddForm = false;
    },

    //===========================================================================
    // FORMAT DATE SEGUR - Fonction de formatage de la date en jj/mm/aaaa
    //===========================================================================
    formatDateSegur(date) {
      let d = new Date(date);
      let day = ("0" + d.getDate()).slice(-2);
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      let year = d.getFullYear();
      return day + "/" + month + "/" + year;
    },

    //===========================================================================
    // CHECK PREUVE - Fonction de vérification de la preuve (BOOL)
    //===========================================================================
    checkPreuve(item) {
      if (!item.tutoDMP || !item.tutoMSS) {
        return false;
      }

      if (item.vaPDF) {
        return true;
      }

      if (item.countDMP < 10 && item.countMSS < 10 && item.countMSSTest < 1) {
        return false;
      }

      return true;
    },

    //===========================================================================
    // CHECK DATE - Fonction de vérification de la date
    //===========================================================================
    checkDate(item) {
      var da = toolsFormat.getDate(item);
      if (da == "01/01/1970") {
        return "Oui";
      }
      return da;
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/project/service.scss";
</style>
