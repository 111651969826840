<!--
@Author: Florian Merel <florian>
@Date:   27-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Liste.vue
@Last modified by:   florian
@Last modified time: 17-Sep-2021
-->

<template>
  <div class="listMain" ref="listMain">
    <v-card
      v-for="ti in getTickets"
      :key="ti.id"
      elevation="0"
      tile
      class="ticketCard"
      v-bind:class="[
        select_Tickets && select_Tickets.id == ti.id ? 'ticketCardSelect' : '',
        'errorClass',
      ]"
      v-on:click="selectTickets(ti)"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
    >
      <div style="float: right; width: 100%; margin-bottom: -60px">
        <v-chip
          v-if="ti.ticketFollowUp && ti.ticketFollowUp.type"
          style="float: right"
          class="ma-2"
          :color="getChipsTypeColor(ti.ticketFollowUp.type)"
          label
          text-color="white"
        >
          <!-- Suggestion -->
          {{
            ti.ticketFollowUp.type == "Idée"
              ? "Suggestion"
              : ti.ticketFollowUp.type
          }}
        </v-chip>
        <v-chip
          style="float: right"
          class="ma-2"
          :color="getChipsFolderColor(ti)"
          label
          text-color="white"
        >
          {{ getChipsFolder(ti) }}
        </v-chip>
      </div>

      <v-card-text>
        <div class="ticketNumber">{{ ti.id }}</div>
        <p class="ticketTitle">{{ ti.sujet }}</p>

        <div class="text--primary description" v-html="ti.description"></div>
      </v-card-text>

      <p class="md-caption dateTicket">{{ getDate(ti.date) }}</p>
      <p class="md-caption identityTicket">{{ getIdentity(ti) }}</p>
      <div class="md-caption mailTicket">{{ getMail(ti) }}</div>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";
import Tickets_Tools from "./Tickets_Tools.js";
export default {
  name: "tickets-liste",
  data() {
    return {
      select_Tickets: null,
      saveold_Tickets: null,
    };
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    // ----- Identité -----
    getIdentity(ticket) {
      return Tickets_Tools.getIdentity(ticket);
    },

    getMail(ticket) {
      return Tickets_Tools.getMail(ticket);
    },

    // ------ FOLDER ------
    getChipsFolder(ticket) {
      return toolsFormat.getChipsFolder(ticket);
    },

    getChipsFolderColor(ticket) {
      return toolsFormat.getChipsFolderColor(ticket);
    },

    // ------ TYPE ------
    getChipsTypeColor(type) {
      return toolsFormat.getChipsTypeColor(type);
    },

    // ------ Mouse ------
    mouseover() {},

    mouseleave() {},

    async saveTickets() {
      if (this.saveold_Tickets != null && this.select_Tickets != null) {
        // Copy
        var old = JSON.parse(JSON.stringify(this.saveold_Tickets));
        var select = JSON.parse(JSON.stringify(this.select_Tickets));

        // Les éléments à ne pas vérifier
        delete old.ticketActivities;
        delete select.ticketActivities;

        if (!(JSON.stringify(old) === JSON.stringify(select))) {
          var obj = {};
          obj.url = this.select_Tickets.id;
          obj.data = this.select_Tickets;
          const resDisp = await store.dispatch(
            "ticketsV2Tickets/updateTicket",
            obj
          );
          return;
        }
      }
      //console.log("IL FAUT PAS SAVE !");
    },

    selectTickets(ticket) {
      this.saveTickets();

      if (JSON.stringify(this.select_Tickets) === JSON.stringify(ticket)) {
        this.select_Tickets = null;
        this.saveold_Tickets = null;

        // Set interface
        this.$parent.deSelectTicket();
      } else {
        // Set interface
        this.$parent.selectTicket();

        this.select_Tickets = ticket;
      }

      this.saveold_Tickets = JSON.parse(JSON.stringify(ticket));
      store.commit("ticketsV2Filters/setSelectTickets", ticket);

      var obj = store.getters["ticketsV2Filters/getData"];
      if (
        obj != null &&
        obj.data &&
        obj.data.ticketUsers &&
        obj.data.ticketUsers.notifList
      ) {
        if (
          ticket &&
          ticket.id &&
          obj.data.ticketUsers.notifList.includes(ticket.id)
        ) {
          store.dispatch("ticketsV2Notifications/postNotifications", {
            id: ticket.id,
          });
        }
      }
    },

    // -------- Gestion des évenements clavier --------
    keywordEvent: function (key) {
      if (this.select_Tickets != null) {
        var ar = this.getTickets;
        var nb = ar.findIndex((e) => e.id === this.select_Tickets.id);
        var element = this.$refs["listMain"];
        if (key === "ArrowDown" && nb + 1 != ar.length) {
          this.selectTickets(ar[nb + 1]);
          element.scrollTo(0, nb * 180);
        } else if (key === "ArrowUp" && nb > 0) {
          this.selectTickets(ar[nb - 1]);
          element.scrollTo(0, (nb - 2) * 180);
        }
      }
    },
  },
  computed: {
    getKeyUp() {
      var key = store.getters["keyup/getKeyup"];
      return key;
    },

    getTickets() {
      var obj = store.getters["ticketsV2Filters/getDataTickets"](
        this.$route.params.filters
      );

      var query = this.$parent.$refs.searchbar.query;
      if (query && query.length != 0) {
        var re = store.getters["ticketsV2Filters/getDataTickets"](query);
        if (re) {
          obj = re;
        }
      }
      if (obj != null) {
        if (obj.data && obj.data.tickets && obj.data.tickets.rows) {
          if (obj.data.tickets.rows.length > 0) {
            if (
              (this.select_Tickets != null &&
                obj.data.tickets.rows.filter(
                  (e) => e.id === this.select_Tickets.id
                ).length == 0) ||
              this.select_Tickets == null
            ) {
              if (this.select_Tickets != null) {
                this.selectTickets(obj.data.tickets.rows[0]);
              }
            } else {
              var fo = obj.data.tickets.rows.find(
                (e) => e.id === this.select_Tickets.id
              );
              //this.selectTickets(fo);
            }
          } else {
            this.selectTickets(null);
          }
          return obj.data.tickets.rows;
        }
      }
      return [];
    },
  },
  watch: {
    $route(to, from) {
      this.saveTickets();
    },
    getKeyUp(value) {
      if (value) {
        window.addEventListener("keyup", this.handler);
      } else {
        window.removeEventListener("keyup", this.handler);
      }
    },
  },
  created() {
    const component = this;
    this.handler = function (e) {
      e.stopPropagation();
      e.preventDefault();
      component.keywordEvent(e.key);
      component.$emit("keyup", e);
    };
    window.addEventListener("keyup", this.handler);
  },
  beforeDestroy() {
    this.saveTickets();
    window.removeEventListener("keyup", this.handler);
  },
};
</script>

<style lang="scss" scoped>
.ticketCard {
  height: 180px;
  margin-top: 1px;
  cursor: default;
}

.ticketCardSelect {
  margin-top: 1px;
  background-color: #eaeaea !important;
}

.ticketCard:hover {
  margin-top: 1px;
  background-color: #eaeaea !important;
}

.chipType {
  position: absolute;
  top: 0;
  right: 0;
}

.chipCat {
  position: absolute;
  top: 0;
  right: 90px;
}

.description {
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mailTicket {
  position: absolute;
  bottom: 5px;
  left: 16px;
}

.identityTicket {
  position: absolute;
  bottom: 7px;
  left: 16px;
}

.dateTicket {
  position: absolute;
  bottom: 7px;
  right: 16px;
}

.ticketTitle {
  margin-top: 8px;
  margin-bottom: 0px;
}

.ticketNumber {
  color: #e2007a;
}

.listMain {
  overflow: scroll !important;
  max-height: 91vh;
  margin-top: 10px;
  margin-left: -15px;
  background-color: #dcdcdc !important;
  padding-right: 1px;
  padding-left: 1px;
  padding-bottom: 1px;
  padding-top: 1px;
}
</style>
