<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Type.vue
@Last modified by:   florian
@Last modified time: 03-Sep-2021
-->

<template>
  <div
    v-if="getTicket != null"
    class="md-layout-item md-medium-size-100 md-size-50"
    style="margin-top: 10px"
  >
    <div class="md-body-2" style="color: #e2007a">Type</div>

    <v-btn-toggle
      v-if="getTicket.ticketFollowUp.type == null"
      v-model="getTicket.ticketFollowUp.type"
      tile
      color="prokov accent-3"
      group
    >
      <v-btn :disabled="getIsAssistance()" value="Idée"> Suggestion </v-btn>

      <v-btn :disabled="getIsAssistance()" value="Bug"> Bug </v-btn>

      <v-btn :disabled="getIsAssistance()" value="Question"> Question </v-btn>
    </v-btn-toggle>

    <v-btn-toggle
      v-else
      v-model="getTicket.ticketFollowUp.type"
      tile
      color="prokov accent-3"
      group
      mandatory
    >
      <v-btn :disabled="getIsAssistance()" value="Idée"> Suggestion </v-btn>

      <v-btn :disabled="getIsAssistance()" value="Bug"> Bug </v-btn>

      <v-btn :disabled="getIsAssistance()" value="Question"> Question </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-type",
  data() {
    return {};
  },
  methods: {
    getIsAssistance() {
      return store.getters["authentification/getIsAssistance"];
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
