<!-- eslint-disable prettier/prettier -->
<!--
@Author: Jessy DROUIN
@Date:   14-dec-2023
@Email:  jessy.drouin@equasens.com
@Project: CRM User EEGO
@Filename: EditProfileInstallation.vue
@Last modified by:   Jessy
@Last modified time: 14-dec-2023
-->

<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Numéro de licence</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in getLicenceUser && getLicenceUser.data
              ? getLicenceUser.data.rows
              : []"
            :key="index">
            <td>{{ row.licenceID }}</td>
            <td>
              <md-button
                class="md-raised md-prokov"
                @click="openLicenceMenu(row.licenceID)"
                >Menu licences</md-button
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="showDialog" max-width="1500px">
      <v-card>
        <!-- TABLES -->
        <div
          class="md-layout-item md-size-100"
          :class="target ? 'md-size-50' : 'md-size-100'">
          <licences-table
            table-header-color="prokov"
            :linkLicence="false"
            title="Licence trouvée"></licences-table>

          <licences-table
            table-header-color="prokov"
            :linkLicence="true"
            title="Au sein du même lieu"></licences-table>
        </div>
        <div v-if="target && licence" class="md-layout-item">
          <form>
            <md-card>
              <md-card-content>
                <div>
                  <md-tabs class="md-danger" md-alignment="left">
                    <md-tab id="tab-home" md-label="Informations">
                      <licences-informations
                        :propsTarget="target"
                        :propsLicence="licence"></licences-informations>
                    </md-tab>

                    <md-tab
                      id="tab-connec"
                      md-label="Connexions"
                      @click="$refs.liconnexions.resetPing()">
                      <licences-connexions
                        ref="liconnexions"
                        :propsTarget="target"
                        :propsLicence="licence"></licences-connexions>
                    </md-tab>

                    <md-tab id="tab-station" md-label="Changements de station">
                      <licences-changes
                        :propsTarget="target"
                        :propsLicence="licence"></licences-changes>
                    </md-tab>

                    <md-tab id="tab-black" md-label="Liste noire">
                      <licences-blackList
                        :propsTarget="target"
                        :propsLicence="licence"></licences-blackList>
                    </md-tab>

                    <md-tab id="tab-logs" md-label="Activité">
                      <licences-logs
                        :propsTarget="target"
                        :propsLicence="licence"></licences-logs>
                    </md-tab>
                  </md-tabs>
                </div>
              </md-card-content>
            </md-card>
          </form>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showDialog = false"
            >Fermer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import LicencesInformations from "@/components/Licences/LicencesInformations.vue";
import LicencesConnexions from "@/components/Licences/LicencesConnexions.vue";
import LicencesChanges from "@/components/Licences/LicencesChanges.vue";
import LicencesBlackList from "@/components/Licences/LicencesBlackList.vue";
import LicencesLogs from "@/components/Licences/LicencesLogs.vue";
import LicencesTable from "@/components/Tables/LicencesTable.vue";

export default {
  name: "edit-profile-installation",
  components: {
    LicencesTable,
    LicencesInformations,
    LicencesConnexions,
    LicencesChanges,
    LicencesLogs,
    LicencesBlackList,
  },
  data() {
    return {
      showDialog: false, // Modale
      userLicence: null, // Stocker les informations de licence ici
      userId: null,
      userDataVariable: null,
      nuquery: "",
      requery: "",
      ipquery: "",
      keyb: "",
    };
  },

  computed: {
    // Getters store
    ...mapGetters("licences", ["getLicenceUser", "getTarget", "getLicence"]),
    target() {
      var res = store.getters["licences/getTarget"];
      return res;
    },
    licence() {
      var res = store.getters["licences/getLicence"];
      if (res && res.data && res.data.licence) {
        return res.data.licence;
      }
      return null;
    },
    obj: {
      get() {
        var x = this.$store.getters["user/getData"];
        return x?.data?.user || {};
      },
    },
    inputValue: {
      get() {
        return this.nuquery || this.userLicence;
      },
      set(value) {
        this.nuquery = value;
      },
    },
  },

  created: async function () {
    try {
      var res = this.$store.getters["user/getData"];
      if (res.data && res.data.user && res.data.user.id) {
        this.userId = res.data.user.id;
        await this.fetchLicenceUser(this.userId);
      }
    } catch (error) {
      throw new Error(
        "Erreur lors de la récupération des données RPPS:",
        error
      );
    }
  },
  watch: {
    obj: {
      handler: async function (newVal) {
        if (newVal && newVal.id) {
          try {
            await this.fetchLicenceUser(newVal.id);
          } catch (error) {
            throw(
              "Erreur lors de la tentative de récupération des informations de licence :",
              error
            );
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("licences", ["fetchLicenceUser"]),

    //Fetch licence user
    async fetchLicenceUser(userId) {
      if (userId) {
        try {
          await this.$store.dispatch("licences/getLicenceUser", userId);
          this.userLicence = this.getLicenceUser.data.rows.licenceID;
        } catch (error) {
          throw("Erreur lors de la récupération des données!", error);
        }
      }
    },

    search() {
      var param = "";
      if (this.nuquery.length > 0) {
        param = "?n=" + encodeURIComponent(this.nuquery);
      }

      if (this.requery.length > 0) {
        param += "&r=" + encodeURIComponent(this.requery.toUpperCase());
      }

      if (this.ipquery.length > 0) {
        param += "&i=" + encodeURIComponent(this.ipquery);
      }

      if (this.keyb.length > 0) {
        param += "&k=" + encodeURIComponent(this.keyb);
      }

      if (param.length > 0 && param.charAt(0) == "&") {
        param = param.replace(param.charAt(0), "?");
      }

      store.dispatch("licences/getLicences", param);
    },

    openLicenceMenu(licenceID) {
      this.showDialog = true;
      if (licenceID) {
        this.nuquery = licenceID;
        this.search();
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/project/service.scss";
</style>
