<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileForm.vue
@Last modified by:   florian
@Last modified time: 25-Nov-2020
-->

<template>
  <md-field>
    <label for="prospec">Catégorie</label>
    <md-select v-model="inputVal">
      <md-option value="Priorités">Priorités</md-option>
      <md-option value="Logiciels">Logiciels</md-option>
      <md-option value="Catégories">Catégories</md-option>
      <md-option value="Personnes">Personnes</md-option>
      <md-option value="Autres">Autres</md-option>
    </md-select>
  </md-field>
</template>

<script>
export default {
  name: "select-categorie-badge",
  components: {},
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss"></style>
