<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileForm.vue
@Last modified by:   florian
@Last modified time: 25-Nov-2020
-->

<template>
  <md-field>
    <label>Type</label>
    <md-select
      v-model="selectedType"
      name="Catégorie"
      id="cat"
      :multiple="multiple"
    >
      <md-option
        v-for="(item, key) in getTypes()"
        :key="item"
        :value="parseInt(key)"
        >{{ item }}</md-option
      >
    </md-select>
  </md-field>
</template>

<script>
import ticketsType from "@/tools/ticketsType.js";

export default {
  name: "select-type-tickets",
  components: {},
  //props: ['value'],
  props: {
    value: [Number, Array],
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getTypes() {
      return ticketsType.ticketsTypes;
    },
  },
};
</script>

<style lang="scss"></style>
