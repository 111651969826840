<!--
@Author: Florian Merel <florian>
@Date:   25-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: SelectStatut.vue
@Last modified by:   florian
@Last modified time: 20-Jan-2021
-->

<template>
  <md-field>
    <label for="statut">Statut</label>
    <md-select name="Statut" id="Statutselect" v-model="selectStatut" md-dense>
      <md-optgroup label="Statut" v-if="this.ticketsInput">
        <md-option
          v-for="item in this.statut"
          :key="item.name"
          :value="item.id"
          >{{ item.name }}</md-option
        >
      </md-optgroup>
    </md-select>
  </md-field>
</template>

<script>
import store from "@/store";

export default {
  name: "select-statut",
  components: {},
  props: ["value"],
  computed: {
    selectStatut: {
      get() {
        if (this.statut) {
          var found;
          if (this.value.aSupprimer != 0) {
            found = this.ticketsInput.folders.rows.find(
              (element) =>
                element.deleteValue &&
                element.deleteValue == this.value.aSupprimer
            );
          } else {
            found = this.ticketsInput.folders.rows.find(
              (element) => element.traiteValue == this.value.traite
            );
          }

          if (found) {
            return found.id;
          }
        }
        return this.value;
      },
      set(id) {
        const found = this.ticketsInput.folders.rows.find(
          (element) => element.id == id
        );
        if (found) {
          var obj = {
            traite: found.traiteValue,
            aSupprimer: found.deleteValue,
            name: found.name,
          };
          this.$emit("input", obj);
        }
      },
    },
    ticketsInput() {
      return store.getters["tickets/getFolders"];
    },
    statut() {
      return this.ticketsInput.folders.rows;
    },
  },
  beforeMount() {
    /*if (this.ticketsInput == null) {
      store.dispatch("tickets/serviceGetFolder");
    }*/
  },
};

/*
return  {aSupprimer: this.item.aSupprimer, traite: this.item.traite};

*/
</script>

<style lang="scss"></style>

<!--
computed: {
  folders() {
    let res = store.getters["tickets/getFolders"];
    return res;
  },
},
beforeMount() {
  if (this.folders == null) {
    store.dispatch("tickets/serviceGetFolder");
  }
},-->
