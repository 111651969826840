<!--
@Author: Florian Merel <florian>
@Date:   10-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogsTickets.vue
@Last modified by:   florian
@Last modified time: 25-Nov-2020
-->

<template>
  <form class="formTickets">
    <md-card>
      <md-card-header data-background-color="prokov">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <h4
              v-if="item.delete == false"
              class="title"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              Ajouter le ticket
            </h4>

            <h4
              v-if="item.delete == true"
              class="title"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              Supprimer le ticket
            </h4>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-70 text-right">
            <md-button class="md-raised" @click="setBadgeBool()"
              >Créer un badge</md-button
            >
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div v-if="createBadgeBool" class="md-layout">
            <badges-created></badges-created>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-bottom: 20px"
          >
            <div class="md-subheading">Ticket</div>
            <md-divider />
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-20">
            <span class="emailTicket">{{ item.data.EeUser.email }} </span>
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-10">
            <div class="dateTicket">{{ getDate(item.data.date) }}</div>
          </div>

          <div class="md-layout-item md-medium-size-100 md-size-20">
            <span class="typeTicket"
              >{{ item.data.specialites }}
              <md-tooltip md-direction="top">Spécialités</md-tooltip>
            </span>
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-50">
            <span class="typeTicket"
              >{{ getType(item.data.kindID) }}
              <md-tooltip md-direction="top">type</md-tooltip>
            </span>
          </div>

          <!--Besoin d'un nouvel outil dans MédiStory-->
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div>{{ item.data.sujet }}</div>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-80">
            <div>{{ item.data.description }}</div>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-100"></div>

          <div class="md-layout-item md-small-size-100 md-size-33">
            <div>{{ item.data.ref1 }}</div>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-33">
            <div>{{ item.data.ref2 }}</div>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-33">
            <div>{{ item.data.ref3 }}</div>
          </div>

          <div
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-top: 30px"
          >
            <div class="md-subheading">Informations, Badges</div>
            <md-divider />
          </div>

          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="margin-bottom: -10px"
          >
            <select-statut v-model="statutSelect"></select-statut>
          </div>

          <badges-lists-chips></badges-lists-chips>

          <div
            class="md-layout-item md-medium-size-100 md-size-100"
            style="margin-top: 10px"
          >
            <div class="md-subheading">Commentaires</div>
            <md-divider />
          </div>

          <div class="md-layout-item md-medium-size-100 md-size-100">
            <md-field>
              <md-textarea v-model="textarea" md-counter="300"></md-textarea>
            </md-field>
          </div>

          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="margin-top: 10px"
          ></div>

          <div class="md-layout-item md-size-100 text-right">
            <md-divider />
            <md-button
              class="md-raised md-danger"
              style="margin-right: 10px"
              @click="closeTicket()"
              >Annuler</md-button
            >
            <md-button
              v-if="item.delete == true"
              class="md-raised md-danger"
              @click="sendValue()"
              >Supprimer</md-button
            >

            <md-button
              v-if="item.delete == false"
              class="md-raised md-success"
              @click="sendValue()"
              >Ajouter</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import { BadgesListsChips, SelectStatut, BadgesCreated } from "@/components";

import Vue from "vue";

export default {
  name: "dialog-Ticket",
  components: {
    BadgesListsChips,
    SelectStatut,
    BadgesCreated,
  },
  data: () => ({
    showDialog: true,
    textarea: "",
    statutSelect: null,
    createBadgeBool: false,
  }),
  computed: {
    item() {
      return store.getters["tickets/getOpenTicket"];
    },

    ticketsInput() {
      return store.getters["tickets/getBadgeResult"];
    },

    statut() {
      var ar = this.ticketsInput.statut;
      if (this.item.delete) {
        return ar.filter((st) => st == "SUPPRIMER");
      }
      return ar;
    },
  },
  beforeMount() {
    if (this.ticketsInput != null) {
      for (var i = 0; i < this.ticketsInput.badges.rows.length; i++) {
        Vue.set(this.ticketsInput.badges.rows[i], "select", false);
      }
    }
    this.setValueItem();
  },
  watch: {
    ticketsInput: function (val) {
      this.setTicketValue();
    },
  },
  methods: {
    setValueItem() {
      if (this.item.delete) {
        this.statutSelect = "SUPPRIMER";
      }
      if (this.item.data.PropFollowUp) {
        this.textarea = this.item.data.PropFollowUp.comment;
      }
      this.setTicketValue();
    },

    setTicketValue() {
      if (this.ticketsInput && this.ticketsInput.badges) {
        if (this.item.data.PropFollowUp && this.item.data.PropFollowUp.badges) {
          let badges = JSON.parse(this.item.data.PropFollowUp.badges);
          for (var i = 0; i < badges.length; i++) {
            const index = this.ticketsInput.badges.rows.findIndex(
              (element) => element.name == badges[i]
            );
            if (index != -1) {
              Vue.set(this.ticketsInput.badges.rows[index], "select", true);
            }
          }
        }
      }
    },

    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getType(type) {
      let typeName = toolsFormat.getType(type);
      return typeName;
    },

    closeTicket() {
      store.commit("tickets/setTicket", null);
    },

    setBadgeBool() {
      this.createBadgeBool = !this.createBadgeBool;
    },

    sendValue() {
      var arBadge = [];
      arBadge = this.ticketsInput.badges.rows.filter(
        (element) => element.select != undefined && element.select == true
      );
      arBadge = [...new Set(arBadge.map((item) => item.name))];
      var param = {
        url: this.item.data.id,
        data: {
          comment: this.textarea,
          badges: arBadge,
        },
        urlGet: this.$route.fullPath.split("/"),
      };

      if (this.item.data.PropFollowUp) {
        param.data.propId = this.item.data.PropFollowUp.id;
      }

      if (this.statutSelect != undefined) {
        param.data.statut = this.statutSelect;
      }
      store.dispatch("tickets/servicePostExtension", param);
      this.closeTicket();
    },

    clickChips(chip) {
      this.ticketsInput.badges.rows;
      const index = this.ticketsInput.badges.rows.findIndex(
        (element) => element.name == chip
      );

      if (this.ticketsInput.badges.rows[index].select) {
        Vue.set(this.ticketsInput.badges.rows[index], "select", false);
      } else {
        Vue.set(this.ticketsInput.badges.rows[index], "select", true);
      }
    },
  },
};
</script>

<style lang="scss">
.formTickets {
  position: fixed; /* postulat de départ */
  top: 50%;
  left: 55%; /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 10;
  height: auto;
  overflow-y: scroll;
}

.md-chip.md-theme-default.md-primary {
  background-color: #4caf51 !important;
}
</style>
