/**
 * @Author: Florian Merel <florian>
 * @Date:   03-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: ServicesError.js
 * @Last modified by:   florian
 * @Last modified time: 23-Dec-2020
 */

import { router } from "../main.js";

export default {
  isConnection(error) {
    //console.log(error.response.data);
    /*if (!error.status) {
      router.push("/login");
      return false;
    }*/
    return true;
  },

  analyseErrorService(data) {
    if (data && data.success == false && data.data) {
      if (data.data.code == 4.1) {
        router.push("/login");
      }
    }
  },
};
