<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesTable.vue
@Last modified by:   florian
@Last modified time: 15-Jul-2021
-->

<template>
  <md-card v-if="linkLicence == false || (licences.length != 0 && linkLicence)">
    <div class="md-subheading titleTables">
      {{ title }}

      <span v-if="!linkLicence" class="md-caption" style="margin-left: 10px">
        {{ countLi }}</span
      >
    </div>
    <md-divider></md-divider>
    <md-card-content>
      <md-table
        v-model="licences"
        :table-header-color="tableHeaderColor"
        @md-selected="onSelect"
        v-if="licences.length != 0"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
        >
          <md-table-cell md-label="Numéro de licence">{{
            item.licenceID
          }}</md-table-cell>
          <md-table-cell md-label="Logiciel">
            <span v-if="item.type == 'M'">MédiStory 4</span>
            <span v-if="item.type == 'E'">ExpressVitale</span>
            <span v-if="item.type == 'P'">VigiPaiement</span>
            <span v-if="item.type == 'C'">VigiCompta</span>
          </md-table-cell>
          <md-table-cell md-label="Revendeur / Client">{{
            item.customer
          }}</md-table-cell>
          <md-table-cell md-label="Capacité">{{ item.capacite }}</md-table-cell>
          <md-table-cell md-label="Dernière connexion">{{
            getDate(item.licenceLastLog)
          }}</md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="licences.length == 0">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <div style="text-align: center">
              La recherche ne trouve aucune licence.
            </div>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "licences-table",
  props: {
    tableHeaderColor: { type: String, default: "" },
    linkLicence: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
  data() {
    return {};
  },
  methods: {
    onSelect(item) {
      store.commit("licences/setTargetLicence", item);
      if (item) {
        store.dispatch(
          "licences/getLicence",
          "/" + item.licenceID + "/" + item.type
        );
        store.dispatch("licences/getBlackList", item.licenceID);
        store.dispatch("licences/getReport", item.licenceID);
      }
    },
    getDate(date) {
      if (date == null) {
        return "";
      }
      return toolsFormat.getDateLong(date);
    },
  },
  computed: {
    countLi() {
      var res = store.getters["licences/getData"];
      //correspondances trouvées, seulement la première est affichée, si vous ne voyez pas votre recherche essayer d'être plus précis.
      if (res && res.data && res.data.licences) {
        var count =
          res.data.licences.count -
          res.data.licences.rows.length -
          res.data.links.length;
        if (count <= 0) {
          return "";
        }
        if (count > 20) {
          count = "De nombreuses";
        }
        return (
          "" +
          count +
          " correspondances trouvées, seulement la première est affichée, si vous ne voyez pas votre recherche essayer d'être plus précis."
        );
        return res.data.licences.count;
      }
      return "";
    },
    licences() {
      var res = store.getters["licences/getData"];
      if (res && res.data && res.data.licences) {
        if (!this.linkLicence) {
          return res.data.licences.rows;
        } else {
          if (res.data.links) {
            return res.data.links;
          }
          return [];
        }
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
.titleTables {
  margin-top: 16px !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}
</style>
