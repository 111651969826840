<!--
@Author: Florian Merel <florian>
@Date:   13-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesBlackList.vue
@Last modified by:   florian
@Last modified time: 26-Jul-2021
-->

<template>
  <div>
    <div class="md-subheading">Station dans la liste noire</div>
    <md-divider style="margin-top: 5px; margin-bottom: 10px"></md-divider>

    <div class="md-layout" style="margin-bottom: 25px">
      <div
        class="md-layout-item md-small-size-100 md-size-20"
        style="text-align: center"
      >
        Licence
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-30"
        style="text-align: center"
      >
        Station
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-30"
        style="text-align: center"
      >
        Date
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-20"
        style="text-align: center"
      ></div>
    </div>

    <md-card v-if="getListData.length == 0" style="margin-top: -30px">
      <md-card-content>
        <div class="md-layout">
          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="text-align: center"
          >
            Aucune station dans la liste noire.
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div v-if="getListData.length > 0">
      <md-card
        v-for="dt in getListData"
        :key="dt.deviceID"
        style="margin-top: -30px"
      >
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-small-size-100 md-size-20"
              style="text-align: center"
            >
              {{ dt.licenceID }}
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-30"
              style="text-align: center"
            >
              {{ dt.deviceID }}
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-30"
              style="text-align: center"
            >
              {{ getDate(dt.date) }}
            </div>

            <div
              class="md-layout-item md-small-size-100 md-size-20"
              style="text-align: left"
            >
              <span v-if="getRules('CONTROL_LICENCES_BLACKLIST')">
                <md-button
                  class="md-raised md-info"
                  @click="removeBlackList(dt)"
                >
                  Retirer</md-button
                >
              </span>

              <span v-if="!getRules('CONTROL_LICENCES_BLACKLIST')">
                <p>Merci de contacter un administrateur</p>
                <md-button
                  class="md-raised md-info"
                  @click="removeBlackList(dt)"
                  disabled
                >
                  Retirer</md-button
                >
              </span>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <md-dialog-confirm
      :md-active.sync="active"
      md-title="Êtes-vous sûr de vouloir retirer cette station de la liste noir ?"
      md-confirm-text="Supprimer"
      md-cancel-text="Annuler"
      @md-confirm="valideRemoveBlackList"
    />
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";

export default {
  name: "licences-blackList",
  props: ["propsTarget", "propsLicence"],
  computed: {},
  data() {
    return {
      active: false,
      dt_select: null,
    };
  },
  /*created: async function () {
    //store.dispatch("licences/getBlackList", this.propsTarget.licenceID);
  },*/
  computed: {
    getListData() {
      let x = store.getters["licences/getBlackListData"];
      if (x.data && x.data.blackList && x.data.blackList.rows) {
        return x.data.blackList.rows;
      }
      return [];
    },
    allRules: {
      get() {
        var x = store.getters["authentification/getDroits"];
        return x;
      },
    },
  },
  methods: {
    getDate(da) {
      return toolsFormat.getDateLong(da);
    },

    getRules(rules) {
      if (this.allRules) {
        return this.allRules.includes(rules);
      }
      return false;
    },

    removeBlackList(dt) {
      this.active = true;
      this.dt_select = dt;
    },

    valideRemoveBlackList() {
      var params = {
        data: {
          deviceID: this.dt_select.deviceID,
        },
        id: this.dt_select.licenceID,
      };
      store.dispatch("licences/removeBlackList", params);

      for (var i = 0; i < this.getListData.length; i++) {
        if (
          this.getListData[i].deviceID == params.data.deviceID &&
          this.getListData[i].licenceID == params.id
        ) {
          this.getListData.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
