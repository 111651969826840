/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 05-Mar-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getData(context) {
    return context.data;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async downloadFiles(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.downloadFiles + "/" + params.id,
        headers: server.getHeader(),
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", params.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
