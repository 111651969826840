<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileSecu.vue
@Last modified by:   florian
@Last modified time: 09-Dec-2020
-->

<template>
  <div class="md-layout">
    <div v-if="!secu" class="md-layout-item md-small-size-100 md-size-100">
      <div style="text-align: center">
        Au chargement de ces informations, un message sera envoyé à
        l'utilisateur pour le prévenir que ces informations ont été lues par
        l'assistance Prokov Editions.
      </div>
    </div>
    <div
      v-if="!secu"
      class="md-layout-item md-small-size-100 md-size-100 text-center"
    >
      <md-button class="md-raised md-prokov" @click="sendSecu()"
        >Charger</md-button
      >
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <span class="md-nav-tabs-title" style="color: black; margin-top: 10px"
          >Modifier les questions et réponses de sécurité</span
        >
        <md-card-content style="margin-top: 20px">
          <div class="md-layout" v-if="secu">
            <md-field>
              <label for="movie">Question 1</label>
              <md-select
                v-model="userAnswers.rows[2].userQuestionId"
                :md-dense="true"
                disabled
              >
                <md-option
                  v-for="item in getQuestions(2)"
                  :key="item.userQuestionId"
                  :value="item.id"
                  >{{ item.label }}</md-option
                >
              </md-select>
            </md-field>

            <md-field class="marginTopM10">
              <label>Réponse</label>
              <md-input
                type="text"
                v-model="userAnswers.rows[2].text"
                disabled
              ></md-input>
            </md-field>

            <md-field>
              <label for="movie">Question 2</label>
              <md-select
                v-model="userAnswers.rows[1].userQuestionId"
                :md-dense="true"
                disabled
              >
                <!-- v-model="question2" -->
                <md-option
                  v-for="item in getQuestions(0)"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.label }}</md-option
                >
              </md-select>
            </md-field>

            <md-field class="marginTopM10">
              <label>Réponse</label>
              <md-input
                type="text"
                v-model="userAnswers.rows[1].text"
                disabled
              ></md-input>
            </md-field>

            <md-field>
              <label for="movie">Question 3</label>
              <md-select
                v-model="userAnswers.rows[0].userQuestionId"
                :md-dense="true"
                disabled
              >
                <md-option
                  v-for="item in getQuestions(1)"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.label }}</md-option
                >
              </md-select>
            </md-field>

            <md-field class="marginTopM10">
              <label>Réponse</label>
              <md-input
                type="text"
                v-model="userAnswers.rows[0].text"
                disabled
              ></md-input>
            </md-field>

            <!--<div class="md-layout-item md-size-100 text-right">
              <md-button class="md-raised md-prokov" disabled
                >Modifier</md-button
              >
            </div>-->
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <span class="md-nav-tabs-title" style="color: black; margin-top: 10px"
          >Modifier l'email ou le mot de passe de connexion à EtherEgo</span
        >
        <md-card-content style="margin-top: 20px">
          <div class="md-layout" v-if="secu">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Courriel</label>
                <md-input v-model="obj.email" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Confirmer le courriel</label>
                <md-input v-model="confirmecouriel" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nouveau mot de passe</label>
                <md-input v-model="mdp" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Confirmer le mot de passe</label>
                <md-input v-model="confirmemdp" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-prokov"
                @click="emailPasswordEEGO()"
                >Modifier</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <span class="md-nav-tabs-title" style="color: black; margin-top: 10px"
          >Modifier le mot de passe de connexion MS4</span
        >
        <md-card-content style="margin-top: 20px">
          <div class="md-layout" v-if="secu">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nouveau mot de passe</label>
                <md-input v-model="mdpMs4" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Confirmer le mot de passe</label>
                <md-input v-model="confirmemdpMs4" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-100 text-right">
              <md-button class="md-raised md-prokov" @click="passwordMS4()"
                >Modifier</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
//                  <md-option v-for="(item, key) in getTypes()" :key="item" :value="key">{{ item }}</md-option>

import store from "@/store";

export default {
  name: "edit-profile-secu",
  data: () => ({
    confirmemdp: "",
    confirmemdpMs4: "",
    confirmecouriel: "",
    mdpMs4: "",
    mdp: "",
  }),
  methods: {
    sendSecu() {
      var x = store.getters["usersQuestions/getQuestions"];
      if (x == null) {
        store.dispatch("usersQuestions/service");
      }
      store.dispatch("usersUnlock/service", "/" + this.$route.query.u);
    },

    getQuestions(nb) {
      /*var i = 1;
      var ar = [];
      while (i < this.questions.length) {
        if (i % 3 == nb) {
          ar.push(this.questions[i]);
        }
        i += 1;
      }*/
      return this.questions;
    },
    passwordMS4() {
      if (this.mdpMs4.length != 0 && this.confirmemdpMs4 == this.mdpMs4) {
        var params = {};

        params.passwordMs = this.mdpMs4;
        store.dispatch("user/servicePutSecu", {
          url: "/" + this.$route.query.u,
          obj: params,
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    },

    emailPasswordEEGO() {
      if (
        (this.confirmemdp.length != 0 && this.confirmemdp == this.mdp) ||
        (this.confirmecouriel.length != 0 &&
          this.confirmecouriel == this.obj.email)
      ) {
        var params = {};

        if (this.confirmemdp.length != 0 && this.confirmemdp == this.mdp) {
          params.password = this.confirmemdp;
        }

        if (
          this.confirmecouriel.length != 0 &&
          this.confirmecouriel == this.obj.email
        ) {
          params.email = this.confirmecouriel;
        }
        store.dispatch("user/servicePutSecu", {
          url: "/" + this.$route.query.u,
          obj: params,
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    },
  },
  computed: {
    secu: {
      get() {
        var x = store.getters["usersUnlock/getUsersUnlock"];
        if (x.includes(this.$route.query.u)) {
          return true;
        } else {
          return false;
        }
      },
    },

    obj: {
      get() {
        var x = store.getters["user/getData"];
        if (x && x.data && x.data.user) {
          return x.data.user;
        }
        return {};
      },
    },

    userAnswers: {
      get() {
        var x = store.getters["usersUnlock/getData"];
        return x.data.userAnswers;
      },
    },
    questions: {
      get() {
        var x = store.getters["usersQuestions/getQuestions"];
        return x;
      },
    },
  },
};
</script>

<style lang="scss">
.marginTopM10 {
  margin-top: -20px !important;
}

/*
.md-menu-content {
    max-width: 600px !important;
}*/
</style>
