<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: UsersTable.vue
@Last modified by:   florian
@Last modified time: 16-Jun-2021
-->

<template>
  <div>
    <md-table
      v-model="ar"
      v-if="ar.length > 0"
      style="margin-top: 10px"
      table-header-color="blue"
      @md-selected="onSelect"
    >
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-label="Nom">{{ item.familyname }}</md-table-cell>
        <md-table-cell md-label="Prénom">{{ item.givenname }}</md-table-cell>
        <md-table-cell md-label="Contact">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Société">{{
          item.PL_Company.name
        }}</md-table-cell>
        <md-table-cell md-label="Actif">{{
          item.active == 0 ? "Non" : "Oui"
        }}</md-table-cell>
        <md-table-cell md-label="Validé">{{
          item.validated == 0 ? "Non" : "Oui"
        }}</md-table-cell>
        <md-table-cell md-label="Rôle">{{
          item.role == 2 ? "ProkOffre" : "Technicien MS4"
        }}</md-table-cell>

        <md-table-cell
          ><div
            @click="
              showDialog = true;
              editMember(item);
            "
            class="md-layout-item md-xsmall-size-100 md-size-20 text-right"
            style="margin-top: 5px"
          >
            <md-icon style="color: #24c4d8">settings</md-icon>
          </div></md-table-cell
        >
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="showDialog" v-if="members">
      <md-dialog-title
        >{{ members.givenname }} {{ members.familyname }}</md-dialog-title
      >

      <div class="md-layout margeTopSearchBar">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Nom</label>
            <md-input v-model="members.familyname" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Prénom</label>
            <md-input v-model="members.givenname" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-field>
            <label>Contact</label>
            <md-input v-model="members.email" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label for="movie">Actif</label>
            <md-select v-model="members.active">
              <md-option value="1">Oui</md-option>
              <md-option value="0">Non</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label for="movie">Validation </label>
            <md-select v-model="members.validated">
              <md-option value="1">Oui</md-option>
              <md-option value="0">Non</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-field>
            <label for="movie">Rôle </label>
            <md-select v-model="members.role">
              <md-option value="0">Technicien MS4</md-option>
              <md-option value="2">ProkOffre</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Mot de passe</label>
            <md-input v-model="pass" type="text"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field>
            <label>Confirmation</label>
            <md-input v-model="passConf" type="text"></md-input>
          </md-field>
        </div>
      </div>

      <div
        v-if="errorPass"
        class="md-layout-item md-small-size-100 md-size-100"
      >
        <div style="color: red">{{ errorText }}</div>
      </div>

      <md-dialog-actions>
        <md-button class="md-info" @click="showDialog = false"
          >Fermer</md-button
        >
        <md-button class="md-info" @click="enregistrerValue()"
          >Enregistrer</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import store from "@/store";
//@click="editMember(item)"

export default {
  name: "member-table",
  props: {
    dataArray: { type: Array, required: true },
  },
  data() {
    return {
      showDialog: false,
      members: null,
      pass: "",
      passConf: "",
      errorPass: false,
      errorText:
        "Erreur de validation : Le mot de passe doit contenir au minimum 6 caractères.",
      selected: [],
    };
  },
  methods: {
    onSelect(item) {
      /*if (item) {
        this.$emit("passData", item.PL_Company.name);
      }*/
    },
    editMember(item) {
      this.members = item;
    },
    enregistrerValue() {
      var obj = this.members;

      if (this.pass.length > 0 || this.passConf.length > 0) {
        if (this.pass.length <= 5) {
          this.errorPass = true;
          this.errorText =
            "Erreur de validation : Le mot de passe doit contenir au minimum 6 caractères.";
          return;
        }

        if (this.pass != this.passConf) {
          this.errorPass = true;
          this.errorText =
            "Erreur de validation : les mots de passe ne correspondent pas.";
          return;
        }

        if (
          this.pass.length > 5 &&
          this.passConf.length > 5 &&
          this.pass == this.passConf
        ) {
          obj.password = this.pass;
        } else if (
          this.pass.length > 0 ||
          this.passConf.length > 0 ||
          this.pass != this.passConf
        ) {
          store.dispatch("notification/sendNotification", {
            type: "fail",
            method: "update",
          });
          return;
        }
      }

      this.showDialog = false;
      obj.active = parseInt(obj.active);
      obj.validated = parseInt(obj.validated);
      obj.role = parseInt(obj.role);
      store.dispatch("providers/putMember", {
        url: obj.memberID,
        obj: obj,
      });
    },
  },
  computed: {
    ar() {
      return this.dataArray;
    },
  },
};
</script>

<style lang="scss">
.md-tabs-content table thead {
  display: contents !important;
}
</style>
