<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesConnexion.vue
@Last modified by:   florian
@Last modified time: 08-Mar-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100 md-size-50">
      <md-field>
        <label>Identifiant machine</label>
        <md-input
          v-model="licence.currentDeviceID"
          type="text"
          disabled
        ></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-50">
      <md-field>
        <label>Mise en service</label>
        <md-input v-model="starterService" type="text" disabled></md-input>
      </md-field>
    </div>

    <div v-if="target.type == 'M'" class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Temporaire</label>
          <md-input
            v-if="licence.isTempDevice == 1"
            v-model="oui"
            type="text"
            disabled
          ></md-input>
          <md-input
            v-if="licence.isTempDevice == 0"
            v-model="non"
            type="text"
            disabled
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Nb Ping</label>
          <md-input
            v-model="licence.deviceNbLog"
            type="text"
            disabled
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Première connexion</label>
          <md-input v-model="firstLog" type="text" disabled></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Dernière connexion</label>
          <md-input v-model="lastLog" type="text" disabled></md-input>
        </md-field>
      </div>
      <div
        class="md-layout-item md-small-size-100 md-size-50"
        v-if="directory && directory.lanAddress"
      >
        <md-field>
          <label>Adresse locale</label>
          <md-input
            v-model="directory.lanAddress"
            type="text"
            disabled
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>IP</label>
          <md-input v-model="licence.lastIp" type="text" disabled></md-input>
        </md-field>
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-25"
        v-if="directory && directory.ipPort"
      >
        <md-field>
          <label>Port</label>
          <md-input v-model="directory.ipPort" type="text" disabled></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field>
          <label>Base de données</label>
          <md-input v-model="licence.dbID" type="text" disabled></md-input>
        </md-field>
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-45"
        style="margin-top: 12px"
      >
        <span
          v-if="pingSuccess != null && pingSuccess == true"
          class="md-body-2"
          >Ping Réussi</span
        >
        <span
          v-if="pingSuccess != null && pingSuccess == false"
          class="md-body-2"
          >Échec du ping</span
        >
      </div>

      <div
        v-if="target.type == 'M'"
        class="md-layout-item md-small-size-100 md-size-100"
      >
        <md-field>
          <label>Présence dans l'annuaire des stations actives</label>
          <md-input v-if="directory" type="text" v-model="oui"></md-input>
          <md-input v-else type="text" v-model="non"></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-55 text-right">
        <md-button class="md-raised md-info text-right" @click="pingStation()"
          >Envoyer un ping</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";

export default {
  name: "licences-connexions",
  props: ["propsTarget", "propsLicence"],
  computed: {
    target: {
      get() {
        return this.propsTarget;
      },
    },
    licence: {
      get() {
        return this.propsLicence;
      },
    },
    directory() {
      var res = store.getters["licences/getLicence"];
      if (res && res.data && res.data.directory) {
        return res.data.directory;
      }
      return null;
    },
    starterService: {
      get: function () {
        if (this.target.licenceFirstLog) {
          return toolsFormat.getDateLongWithTimeZone(
            this.target.licenceFirstLog
          );
        }
        return null;
      },
      set: function (newValue) {},
    },
    firstLog: {
      get: function () {
        if (this.licence.deviceFirstLog) {
          return toolsFormat.getDateLongWithTimeZone(
            this.licence.deviceFirstLog
          );
        } else {
          return toolsFormat.getDateLongWithTimeZone(
            this.target.licenceFirstLog
          );
        }
        return null;
      },
      set: function (newValue) {},
    },
    lastLog: {
      get: function () {
        if (this.licence.deviceLastLog) {
          return toolsFormat.getDateLongWithTimeZone(
            this.licence.deviceLastLog
          );
        } else {
          return toolsFormat.getDateLongWithTimeZone(
            this.target.licenceLastLog
          );
        }
        return null;
      },
      set: function (newValue) {},
    },
  },
  data() {
    return {
      oui: "Oui",
      non: "Non",
      pingSuccess: null,
    };
  },
  methods: {
    async pingStation() {
      if (this.target && this.target.licenceID) {
        this.pingSuccess = null;
        const res = await store.dispatch(
          "licences/pingLicence",
          "/" + this.target.licenceID
        );
        this.pingSuccess = res;
      }
    },
    resetPing() {
      this.pingSuccess = null;
    },
  },
};
</script>

<style lang="scss"></style>
