<!--
@Author: Florian Merel <florian>
@Date:   16-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: AreaCharts.vue
@Last modified by:   florian
@Last modified time: 08-Jun-2021
-->

<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          datasets: [],
        };
      },
    },
  },
  data: () => ({
    gradient: null,
    gradient2: null,
    redGradient: null,
  }),
  methods: {
    callRenderChart() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
      });
    },

    getGradientColor() {
      return this.gradient;
    },

    getGradient2Color() {
      return this.gradient2;
    },

    getRedGradientColor() {
      return this.redGradient;
    },
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient2.addColorStop(0, "rgba(38, 160, 242, 0.5)");
    this.gradient2.addColorStop(0.5, "rgba(38, 160, 242, 0.25)");
    this.gradient2.addColorStop(1, "rgba(38, 160, 242, 0)");

    this.redGradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.redGradient.addColorStop(0, "rgba(242, 64, 63, 0.5)");
    this.redGradient.addColorStop(0.5, "rgba(242, 64, 63, 0.25)");
    this.redGradient.addColorStop(1, "rgba(242, 64, 63, 0)");

    this.gradient.addColorStop(0, "rgba(26, 188, 156, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(26, 188, 156, 0.25)");
    this.gradient.addColorStop(1, "rgba(26, 188, 156, 0)");

    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
    });
  },
};
</script>
