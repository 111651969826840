<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Licences-Informations.vue
@Last modified by:   florian
@Last modified time: 08-Mar-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100 md-size-33">
      <md-field>
        <label>Numéro</label>
        <md-input v-model="target.licenceID" type="text" disabled></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-33">
      <md-field>
        <label>Type</label>
        <md-input type="text" v-model="type" disabled></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-33">
      <md-field v-if="licence.usedVersion">
        <label>Version</label>
        <md-input type="text" v-model="licence.usedVersion" disabled></md-input>
      </md-field>

      <md-field v-if="licence.version">
        <label>Version</label>
        <md-input type="text" v-model="licence.version" disabled></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-33">
      <md-field>
        <label>Rôle</label>
        <md-input type="text" v-model="role" disabled></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-16">
      <md-field>
        <label>Quota</label>
        <md-input type="text" v-model="target.capacite" disabled></md-input>
      </md-field>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-16">
      <md-field>
        <label>Démonstration</label>
        <md-input
          v-if="target.demo == 1"
          v-model="oui"
          type="text"
          disabled
        ></md-input>
        <md-input
          v-if="target.demo == 0"
          v-model="non"
          type="text"
          disabled
        ></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-16">
      <md-field>
        <label>Suspension</label>
        <md-input
          v-if="target.suspended"
          v-model="oui"
          type="text"
          disabled
        ></md-input>
        <md-input v-else v-model="non" type="text" disabled></md-input>
      </md-field>
    </div>

    <div
      v-if="target.suspended"
      class="md-layout-item md-small-size-100 md-size-100"
    >
      <md-field>
        <label>Date suspension</label>
        <md-input type="text" v-model="suspended" disabled></md-input>
      </md-field>
    </div>
    <div v-if="abo" class="md-layout-item md-small-size-100 md-size-70">
      <md-field>
        <label>Abonnements Actu40</label>
        <md-input type="text" v-model="abo" disabled></md-input>
      </md-field>
    </div>

    <div v-if="ext" class="md-layout-item md-small-size-100 md-size-70">
      <md-field>
        <label>Abonnements Exit40</label>
        <md-input type="text" v-model="ext" disabled></md-input>
      </md-field>
    </div>

    <div
      v-if="target.options"
      class="md-layout-item md-small-size-100 md-size-30"
    >
      <md-field>
        <label>Options</label>
        <md-input type="text" v-model="target.options" disabled></md-input>
      </md-field>
    </div>

    <div
      v-if="licence.system"
      class="md-layout-item md-small-size-100 md-size-50"
    >
      <md-field>
        <label>Version du système</label>
        <md-input type="text" v-model="licence.system" disabled></md-input>
      </md-field>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "licences-informations",
  props: ["propsTarget", "propsLicence"],
  computed: {
    target: {
      get() {
        return this.propsTarget;
      },
    },
    licence: {
      get() {
        return this.propsLicence;
      },
    },
    role: {
      get: function () {
        if (this.licence) {
          if (this.licence.role == 1) {
            return "Serveur";
          } else if (this.licence.role == 2) {
            return "Sauvegarde";
          }
        }
        return "";
      },
      set: function (newValue) {},
    },
    type: {
      get: function () {
        var dict = {};

        dict["M"] = "MédiStory 4";
        dict["C"] = "VigiCompta";
        dict["E"] = "ExpressVitale";
        dict["P"] = "VigiPaiement";

        return dict[this.target.type];
      },
      set: function (newValue) {},
    },
    suspended: {
      get: function () {
        if (this.target.suspended) {
          return toolsFormat.getDateLong(this.target.suspended);
        }
        return null;
      },
      set: function (newValue) {},
    },
    abo: {
      get: function () {
        if (this.target.abonnements) {
          const obj = JSON.parse(this.target.abonnements);
          if (obj.ACTU40) {
            return toolsFormat.getDateLong(obj.ACTU40);
          }
          if (obj.connaissances) {
            return toolsFormat.getDateLong(obj.connaissances);
          }
        }
        return null;
      },
      set: function (newValue) {},
    },
    ext: {
      get: function () {
        if (this.target.abonnements) {
          const obj = JSON.parse(this.target.abonnements);
          if (obj.EXIT40) {
            return toolsFormat.getDateLong(obj.EXIT40);
          }
        }
        return null;
      },
      set: function (newValue) {},
    },
  },
  data() {
    return {
      oui: "Oui",
      non: "Non",
    };
  },
  methods: {},
};
</script>

<style lang="scss"></style>
