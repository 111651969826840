<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileForm.vue
@Last modified by:   florian
@Last modified time: 25-Nov-2020
-->

<template>
  <div v-if="this.ticketsInput">
    <div class="" style="" v-for="obj in this.ticketsInput.cats" :key="obj">
      <div
        class="md-layout-item md-small-size-100 md-size-100"
        style="margin-top: 10px"
      >
        <div class="body 2">{{ obj }}</div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-chip
          :class="chipColor"
          v-for="chip in getSelectBadgesWithCat(obj)"
          :key="chip"
          @click="clickChips(chip)"
          md-clickable
          >{{ chip }}</md-chip
        >
        <md-chip
          class=""
          v-for="chip in getBadgesWithCat(obj)"
          :key="chip"
          @click="clickChips(chip)"
          md-clickable
          >{{ chip }}</md-chip
        >
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";

export default {
  name: "badges-lists-chips",
  props: {
    chipColor: {
      type: String,
      default: "md-primary",
    },
  },
  computed: {
    ticketsInput() {
      return store.getters["tickets/getBadgeResult"];
    },
  },
  beforeMount() {
    if (this.ticketsInput == null) {
      store.dispatch("tickets/serviceGetBadges");
    } else {
    }
  },
  methods: {
    getSelectBadgesWithCat(cat) {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) =>
            element.cat == cat &&
            element.select != undefined &&
            element.select == true
        );
        ar = [...new Set(ar.map((item) => item.name))];
      }
      return ar;
    },

    getBadgesWithCat(cat) {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) =>
            element.cat == cat &&
            (element.select == undefined || element.select == false)
        );
        ar = [...new Set(ar.map((item) => item.name))];
      }
      return ar;
    },

    getNumbersSelectBadges() {
      var ar = [];
      if (
        this.ticketsInput &&
        this.ticketsInput.badges &&
        this.ticketsInput.badges.rows
      ) {
        ar = this.ticketsInput.badges.rows.filter(
          (element) => element.select != undefined && element.select == true
        );
      }
      return ar.length;
    },

    clickChips(chip) {
      this.ticketsInput.badges.rows;
      const index = this.ticketsInput.badges.rows.findIndex(
        (element) => element.name == chip
      );

      if (this.ticketsInput.badges.rows[index].select) {
        Vue.set(this.ticketsInput.badges.rows[index], "select", false);
      } else {
        Vue.set(this.ticketsInput.badges.rows[index], "select", true);
      }
    },
  },
};
</script>

<style lang="scss"></style>
