/**
 * @Author: Florian Merel <florian>
 * @Date:   29-Jul-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: Tickets_Tools.js
 * @Last modified by:   florian
 * @Last modified time: 29-Jul-2021
 */

// ----- Identité -----
function getIdentity(ticket) {
  var givenname = null;
  var familyname = null;

  if (ticket.EeUser) {
    if (ticket.EeUser.givenname != "Administrateur") {
      givenname = ticket.EeUser.givenname;
      familyname = ticket.EeUser.familyname;
    }
  }

  return (
    (givenname == null ? "" : givenname) +
    " " +
    (familyname == null ? "" : familyname)
  );
}

function getMail(ticket) {
  var email = null;

  if (ticket.EeUser) {
    if (ticket.EeUser.givenname != "Administrateur") {
      email = ticket.EeUser.email;
    }
  }
  if (
    email == null &&
    ticket.PropFollowUp &&
    ticket.PropFollowUp.email &&
    ticket.PropFollowUp.email.length > 0
  ) {
    email = ticket.PropFollowUp.email;
  }
  return email == null ? "" : email;
}

export default {
  getIdentity,
  getMail,
};
