<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: SimplySearchBar.vue
@Last modified by:   florian
@Last modified time: 14-Jun-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: -20px"
      >
        <md-card>
          <div class="md-layout margeTopSearchBar">
            <div class="md-layout-item">
              <!--<label>Recherche</label>
                <md-input v-on:keyup.enter="onEnter" v-model="query"></md-input>-->

              <md-autocomplete
                v-model="content"
                :md-options="ar"
                v-on:keyup.enter="onEnter"
                v-bind:change="changeEvent()"
                md-dense
              >
                <label>Recherche</label>
              </md-autocomplete>
            </div>
          </div>
          <md-button class="md-info researcheButton" @click="searchParent()"
            >Rechercher</md-button
          >
          <!--<button class="diagCloseButton" @click="clearSearch()">
            <md-icon
              >delete
              <md-tooltip md-direction="top"
                >Supprimer votre recherche</md-tooltip
              >
            </md-icon>
          </button>-->
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "auto-complete-search-bar",
  props: ["ar", "query"],
  data() {
    return {
      content: this.query,
    };
  },
  methods: {
    onEnter: function () {
      this.searchParent();
    },
    clearSearch() {
      this.content = "";
      this.$emit("passData", this.content);
    },
    searchParent() {
      this.$emit("passData", this.content);
    },
    changeEvent() {
      this.$emit("changeEvent", this.content);
    },
  },
  computed: {},
};
</script>
