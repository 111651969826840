<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 24-Aug-2021
-->
<template>
  <div class="content">
    <div class="md-layout">
      <!-- ////////////////////// -->
      <!-- Zone Top Boutton -->
      <!-- ////////////////////// -->

      <div
        class="md-layout-item md-xsmall-size-100 md-size-50"
        style="margin-top: 0px; margin-bottom: -20px"
      >
        <md-button class="md-prokov" to="/createdTicket">
          <md-icon style="margin-right: 10px; margin-left: -20px">add</md-icon>
          Créer un ticket</md-button
        >
      </div>

      <div
        class="md-layout-item md-xsmall-size-100 md-size-50 text-right"
        style="margin-top: 0px; margin-bottom: -20px"
      >
        <md-button
          class="md-button md-success md-round md-just-icon"
          style="margin-right: 10px"
          v-bind:class="[waitReponse ? 'md-prokov' : '']"
          @click="waitReponse = !waitReponse"
        >
          <md-icon
            >mark_email_unread
            <md-tooltip md-direction="top">Messages en attente</md-tooltip>
          </md-icon>
        </md-button>

        <md-button
          class="md-button md-round md-success md-just-icon"
          v-bind:class="[edition_all ? 'md-prokov' : '']"
          style="margin-right: 10px"
          @click="editValue()"
        >
          <md-icon v-if="edition_all"
            >edit
            <md-tooltip md-direction="top">Fermer tout les tickets</md-tooltip>
          </md-icon>

          <md-icon v-if="!edition_all"
            >edit
            <md-tooltip md-direction="top">Ouvrir tout les tickets</md-tooltip>
          </md-icon>
        </md-button>
        <md-button class="md-prokov" to="/setupTickets">Configurer</md-button>
      </div>

      <!-- ////////////////////// -->
      <!-- FIN Zone Top Boutton -->
      <!-- ////////////////////// -->

      <!-- ////////////////////// -->
      <!-- Page Ticket en attente -->
      <!-- ////////////////////// -->

      <div
        class="md-layout-item md-xsmall-size-100 md-size-50"
        style="margin-top: 30px"
        v-if="waitReponse"
      >
        <md-card>
          <md-card-header data-background-color="grefen">
            <h4 class="title">Réponse reçu récemment</h4>
          </md-card-header>
          <md-card-content>
            <tickets-table-response
              :edition_all="edition_all"
            ></tickets-table-response>
          </md-card-content>
        </md-card>
      </div>

      <div
        class="md-layout-item md-xsmall-size-100 md-size-50"
        style="margin-top: 30px"
        v-if="waitReponse"
      >
        <md-card>
          <md-card-header data-background-color="blufe">
            <h4 class="title">En attente de réponse</h4>
          </md-card-header>
          <md-card-content>
            <tickets-table-en-attente
              :edition_all="edition_all"
            ></tickets-table-en-attente>
          </md-card-content>
        </md-card>
      </div>

      <!-- ////////////////////// -->
      <!-- FIN Page Ticket en attente -->
      <!-- ////////////////////// -->

      <!-- ////////////////////// -->
      <!-- Zone DE RECHERCHE -->
      <!-- ////////////////////// -->

      <div
        class="md-layout-item md-medium-size-100 md-size-100"
        v-if="!waitReponse"
      >
        <md-card>
          <div class="md-layout margeTopSearchBar" style="margin-top: -10px">
            <div class="md-layout-item md-medium-size-100 md-size-50">
              <select-type-tickets v-model="selectedType"></select-type-tickets>
            </div>

            <div class="md-layout-item md-medium-size-100 md-size-50">
              <md-field>
                <label>Recherche</label>
                <md-input v-on:keyup.enter="onEnter" v-model="query"></md-input>
              </md-field>
            </div>

            <div
              class="md-layout-item md-medium-size-100 md-size-50"
              style="margin-top: -20px"
            >
              <md-field>
                <label>Badges</label>

                <md-select
                  v-model="selectedBadges"
                  v-if="getBadges"
                  name="badges"
                  id="badges"
                  multiple
                >
                  <md-optgroup
                    v-for="(item, key) in getBadges.cats"
                    :key="key"
                    :label="item"
                  >
                    <md-option
                      v-for="badge in getBadgesByCat(item)"
                      :key="badge.id"
                      :value="badge.id"
                      >{{ badge.name }}</md-option
                    >
                  </md-optgroup>
                </md-select>
              </md-field>
            </div>

            <div
              v-if="true"
              class="md-layout-item md-medium-size-100 md-size-50"
              style="margin-top: -20px"
            >
              <md-field>
                <label>Nombre par page</label>
                <md-select v-model="nombres" name="nombres" id="nombres">
                  <md-option value="10" select>10</md-option>
                  <md-option value="25" select>25</md-option>
                  <md-option value="50" select>50</md-option>
                  <md-option value="100" select>100</md-option>
                </md-select>
              </md-field>
            </div>

            <div
              v-if="false"
              class="md-layout-item md-medium-size-100 md-size-50"
              style="margin-top: -20px"
            >
              <md-field>
                <label>Trier par</label>
                <md-select v-model="orders" name="orders" id="orders">
                  <md-option value="dc" select>Date de création</md-option>
                  <md-option value="dd" select>Date d'attribution</md-option>
                </md-select>
              </md-field>
            </div>

            <div
              style="margin-left: 15px; margin-top: -25px; margin-bottom: -20px"
            >
              <md-checkbox class="md-prokov" v-model="assistanceBoolean"
                >Assistance
                <md-chip
                  class="md-accent"
                  style="height: 10px; margin-left: 5px"
                >
                </md-chip>
              </md-checkbox>

              <md-checkbox class="md-prokov" v-model="interneBoolean"
                >Interne
                <md-chip class="md-info" style="height: 10px; margin-left: 5px">
                </md-chip>
              </md-checkbox>

              <md-checkbox class="md-prokov" v-model="ticketsBoolean"
                >Mes tickets</md-checkbox
              >
            </div>
          </div>
          <md-button class="md-prokov researcheButton" @click="search()"
            >Rechercher</md-button
          >
          <button class="diagCloseButton" @click="clearSearch()">
            <md-icon
              >delete
              <md-tooltip md-direction="top"
                >Supprimer votre recherche</md-tooltip
              >
            </md-icon>
          </button>
        </md-card>
      </div>

      <!-- ////////////////////// -->
      <!-- FIN Zone DE RECHERCHE -->
      <!-- ////////////////////// -->

      <!-- ////////////////////// -->
      <!-- Zone TICKET -->
      <!-- ////////////////////// -->

      <div
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: 20px"
        v-if="!waitReponse"
      >
        <nav-tabs-card>
          <template slot="content">
            <span class="md-nav-tabs-title" style="color: white">Tickets:</span>

            <md-tabs class="md-danger" md-alignment="left">
              <template slot="md-tab" slot-scope="{ tab }">
                {{ tab.label }}
                <i class="ticketBadge" v-if="tab.data.badge">{{
                  tab.data.badge
                }}</i>
              </template>

              <!--                         <md-option v-for="(item, key) in this.ticketsInput.cats" :key="item" :value="key">{{ item }}</md-option>-->
              <!-- :md-template-data="{ badge: newTickets }" -->

              <md-tab
                :id="item.name"
                :md-label="item.name"
                v-for="item in getFolders"
                :key="item.name"
                :to="'/tickets/' + item.url + pathParam"
                :md-template-data="{ badge: item.counts }"
              >
                <tickets-table :edition_all="edition_all"></tickets-table>
              </md-tab>

              <!--
              <md-tab id="tab-traites" md-label="traités" md-icon="check" v-on:click="tabsChange('done')">
                <nav-tabs-table></nav-tabs-table>
              </md-tab>

              <md-tab id="tab-detete" md-label="Supprimer" md-icon="delete" v-on:click="tabsChange('delete')">
                <nav-tabs-table></nav-tabs-table>
              </md-tab>-->
            </md-tabs>
          </template>
        </nav-tabs-card>
      </div>

      <!-- ////////////////////// -->
      <!-- FIN Zone TICKET -->
      <!-- ////////////////////// -->

      <!-- ////////////////////// -->
      <!-- Zone Basse -->
      <!-- ////////////////////// -->

      <div
        class="md-layout-item md-medium-size-100 md-size-100 md-title"
        style="text-align: center"
        v-if="newTickets && !waitReponse"
      >
        Vous avez vu {{ this.ticketsNombres }} tickets sur {{ newTickets }}
        <md-progress-bar
          class="progressBarCenter"
          md-mode="determinate"
          :md-value="percent()"
        ></md-progress-bar>
        <div class="md-layout-item md-size-100 text-center">
          <md-button
            v-if="this.ticketsNombres != newTickets"
            class="md-raised md-prokov"
            @click="getMoreTickets()"
            >CHARGER PLUS</md-button
          >
        </div>
      </div>

      <!-- ////////////////////// -->
      <!-- Zone Basse -->
      <!-- ////////////////////// -->
    </div>
  </div>
</template>

<script>
import {
  NavTabsCard,
  TicketsTable,
  SelectTypeTickets,
  TicketsTableResponse,
  TicketsTableEnAttente,
} from "@/components";
import store from "@/store";
import ticketsType from "@/tools/ticketsType.js";
import Vue from "vue";

//?q=florian&c=tu&u=32426

export default {
  components: {
    NavTabsCard,
    TicketsTable,
    TicketsTableResponse,
    TicketsTableEnAttente,
    SelectTypeTickets,
  },
  data: () => ({
    newPosts: 8,
    selectedType: [],
    orders: "dc",
    nombres: 10,
    edition_all: false,
    query: "",
    selectedBadges: [],
    pathParam: "",
    amount: 10,
    waitReponse: false,
    assistanceBoolean: false,
    interneBoolean: false,
    ticketsBoolean: false,
  }),
  created: function () {
    this.urlLastGeneration();

    const param = this.$route.fullPath.split("/");
    store.dispatch("tickets/serviceGet", "/" + param.slice(-1)[0]);
    //store.dispatch("tickets/serviceGetFolder");
    this.setParemWithUrl();
  },
  computed: {
    newTickets() {
      return store.getters["tickets/getNbTicket"](this.$route.params.type);
    },
    ticketsNombres() {
      var tn = store.getters["tickets/getTicket"](this.$route.params.type);
      if (tn && tn.rows) {
        return tn.rows.length;
      }
      return 0;
    },
    getFolders() {
      let item = store.getters["tickets/getFolders"];
      if (item && item.folders && item.folders.rows) {
        return item.folders.rows;
      }
      return [];
    },

    getBadges() {
      let x = store.getters["tickets/getBadgeResult"];
      return x;
    },
  },
  watch: {
    $route(to, from) {
      const param = this.$route.fullPath.split("/");
      store.dispatch("tickets/serviceGet", "/" + param.slice(-1)[0]);
      this.setParemWithUrl();
    },
  },
  beforeMount() {
    if (this.getBadges == null) {
      store.dispatch("tickets/serviceGetBadges");
    }

    let lastData = store.getters["usersList/getData"];
    if (lastData != null && lastData.data != null) {
    }
  },
  methods: {
    percent() {
      return (this.ticketsNombres * 100) / this.newTickets;
    },
    tabsChange(root) {
      if (root != this.$route.params.type) {
        this.$router.push(root);
      }
    },
    blurBackgroundClick() {
      store.commit("tickets/setTicket", null);
    },

    getTypes() {
      return ticketsType.ticketsTypes;
    },

    getBadgesByCat(name) {
      var ar = [];
      if (this.getBadges && this.getBadges.badges) {
        ar = this.getBadges.badges.rows.filter(
          (element) => element.cat == name
        );
      }
      return ar;
    },

    setParemWithUrl() {
      if (this.$route.query.q) {
        this.query = this.$route.query.q;
      } else {
        this.query = "";
      }

      if (this.$route.query.t) {
        var ar = JSON.parse(this.$route.query.t);
        this.selectedType = ar;
      } else {
        this.selectedType = [];
      }

      if (this.$route.query.b) {
        var ar = JSON.parse(this.$route.query.b);
        this.selectedBadges = ar;
      } else {
        this.selectedBadges = [];
      }

      if (this.$route.query.a) {
        this.assistanceBoolean = true;
      } else {
        this.assistanceBoolean = false;
      }

      if (this.$route.query.i) {
        this.interneBoolean = true;
      } else {
        this.interneBoolean = false;
      }

      if (this.$route.query.m) {
        this.ticketsBoolean = true;
      } else {
        this.ticketsBoolean = false;
      }

      if (this.$route.query.o) {
      }

      if (this.$route.query.n) {
        if (this.$route.query.n <= 100 && this.$route.query.n > 0) {
          this.nombres = this.$route.query.n;
        } else if (this.$route.query.n > 100) {
          this.nombres = 100;
        } else {
          this.nombres = 10;
        }
      }

      this.pathParam = this.urlGeneration();
    },

    urlLastGeneration() {
      let x = store.getters["tickets/getData"];
      var param = "";
      if (x) {
        if (x.query) {
          if (Object.keys(x.query).length) {
            this.$router.push({
              query: Object.assign({}, this.$route.query, x.query),
            });
          }
        }
      }
    },
    clearSearch() {
      this.query = "";
      this.selectedType = [];
      this.selectedBadges = [];
      this.assistanceBoolean = false;
      this.interneBoolean = false;
      this.ticketsBoolean = false;

      var param = "?n=" + this.nombres;
      this.$router.push(this.$route.params.type + param);
    },

    urlGeneration() {
      var param = "";

      if (this.query.length) {
        param += "?q=" + encodeURIComponent(this.query);
      }

      if (this.selectedType.length > 0) {
        param += "&t=" + JSON.stringify(this.selectedType);
      }

      if (this.selectedBadges.length > 0) {
        param += "&b=" + JSON.stringify(this.selectedBadges);
      }

      if (this.assistanceBoolean) {
        param += "&a=" + this.assistanceBoolean;
      }

      if (this.interneBoolean) {
        param += "&i=" + this.interneBoolean;
      }

      if (this.ticketsBoolean) {
        param += "&m=" + this.ticketsBoolean;
      }

      if (this.orders) {
        param += "&o=" + this.orders;
      }
      param += "&n=" + this.nombres;

      if (param.length > 0 && param.charAt(0) == "&") {
        param = param.replace(param.charAt(0), "?");
      }
      return param;
    },
    onEnter: function () {
      this.search();
    },
    getMoreTickets() {
      var tk = store.getters["tickets/getTicket"](this.$route.params.type);
      if (tk && tk.rows) {
        var st = "&tk=";
        for (let i = 0; i != tk.rows.length; i++) {
          st += tk.rows[i].id;
          if (i + 1 != tk.rows.length) {
            st += ",";
          }
        }
      }
      var param = this.pathParam + st;
      store.dispatch(
        "tickets/serviceGetMoreTickets",
        "/" + this.$route.params.type + "/" + param
      );
    },
    search() {
      const param = this.urlGeneration();
      const path = "/tickets/" + this.$route.params.type + param;
      this.pathParam = param;
      if (this.$route.fullPath !== path) {
        //store.dispatch("usersList/usersList", param);
        this.$router.push(this.$route.params.type + param);
      }
    },
    editValue() {
      this.edition_all = !this.edition_all;
    },
  },
};
</script>

<style lang="scss">
.ticketBadge {
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 3px;
  background: #61153e;
  border-radius: 90%;
  color: #fff;
  //font-size: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px !important;
  //letter-spacing: -.05em;
  font-family: "Roboto Mono", monospace;
}

.blurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background: rgba(255, 255, 255, 0.2);
  //backdrop-filter: blur(1px);
}

.md-progress-bar.md-theme-default.md-determinate .md-progress-bar-fill {
  background-color: #e2007a !important;
}

.md-progress-bar.md-theme-default.md-determinate {
  background-color: rgba(226, 0, 122, 0.2) !important;
}

.progressBarCenter {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
