<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: UsersTable.vue
@Last modified by:   florian
@Last modified time: 15-Jun-2021
-->

<template>
  <md-table
    v-model="ar"
    v-if="ar.length > 0"
    style="margin-top: 10px"
    table-header-color="blue"
    @md-selected="onSelect"
  >
    <md-table-row
      slot="md-table-row"
      slot-scope="{ item }"
      md-selectable="single"
    >
      <md-table-cell md-label="Société">{{ item.name }}</md-table-cell>
      <md-table-cell md-label="Contact">{{ item.email }}</md-table-cell>
      <md-table-cell md-label="Techniciens">{{ item.members }}</md-table-cell>
      <md-table-cell md-label="Actif">{{ item.active }}</md-table-cell>
      <md-table-cell md-label="Validé">{{ item.validated }}</md-table-cell>
      <!--<md-table-cell
        ><div
          @click="editCompany(item)"
          class="md-layout-item md-xsmall-size-100 md-size-20 text-right"
          style="margin-top: 5px"
        >
          <md-icon style="color: #24c4d8">settings</md-icon>
        </div></md-table-cell
      >-->
    </md-table-row>
  </md-table>
</template>

<script>
import store from "@/store";

export default {
  name: "company-table",
  props: {
    dataArray: { type: Array, required: true },
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("passData", item.email);
      }
    },
  },
  computed: {
    ar() {
      return this.dataArray;
    },
  },
};
</script>

<style lang="scss">
.md-tabs-content table thead {
  display: contents !important;
}
</style>
