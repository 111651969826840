<script src="https://cdn.tailwindcss.com"></script>
<template>
  <div class="content">
    <v-card elevation="0" outlined>
      <v-card-title>STATISTIQUES</v-card-title>
      <v-card-text>
        <div class="flex">
          <div class="flex-auto m-2">
            <div class="flex flex-col">
              <div class="flex-initial font-bold">OFFRES ABONNEMENTS</div>
              <div class="flex-initial font-bold text-pink-500">TOTAL</div>
              <div class="basis-3/4 border-black border-2 p-2">
                <div class="grid grid-cols-4 gap-0 mb-2">
                  <div></div>
                  <div>%</div>
                  <div>Cumul.</div>
                  <div>Mois en cours</div>
                  <div>En cours</div>
                  <div class="font-bold">0%</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div>Envoyées</div>
                  <div class="font-bold">0%</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div>Signées</div>
                  <div class="font-bold">0%</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div class="font-bold">0 - 0 €</div>
                  <div class="col-span-4">-</div>
                  <div class="col-span-4">-</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-auto m-2">
            <div class="font-bold">ABONNEMENT</div>
            <div class="font-bold text-pink-500">_</div>

            <div class="border-black border-2 p-2 mb-2">
              <div class="grid grid-cols-2 gap-0">
                <div>Réccurent N-1</div>
                <div class="font-bold">0 €</div>
              </div>
            </div>

            <div class="border-black border-2 p-2">
              <div class="grid grid-cols-2 gap-0">
                <div>Cumul annuel</div>
                <div class="font-bold">0 €</div>
                <div>Réccurent N-1</div>
                <div class="font-bold">0 €</div>
                <div>Réccurent N-1</div>
                <div class="font-bold">0 €</div>
                <div>Réccurent N-1</div>
                <div class="font-bold">0 €</div>
              </div>
            </div>
          </div>
          <div class="flex-auto m-2">
            <div class="font-bold">EQUIPEMENTS</div>
            <div class="font-bold text-pink-500">_</div>
            <div class="border-black border-2 p-2">
              <div class="grid grid-cols-4 gap-0 mb-2">
                <div></div>
                <div>Cumul</div>
                <div>Années</div>
                <div>Mois en cours</div>
                <div>Stations</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div>Appareils</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div>CPS</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div>MSS</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div>MS Safe</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
                <div class="font-bold">0</div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import Vue from "vue";
import server from "../store/server.js";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "SubscriptionsStats",
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
