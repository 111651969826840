<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 13-Jul-2021
-->

<!-- 014C266C-4642-554C-BE6D-218894B2A4A4-1398-srvr -->
<template>
  <div class="content">
    <div class="md-layout">
      <!--<v-btn-toggle
        v-model="text"
        tile
        color="prokov accent-3"
        group
        style="margin-bottom: -30px;"
      >
        <v-btn value="left">
          Licences
        </v-btn>

        <v-btn value="center">
          Licences ces dernières 48h
        </v-btn>
      </v-btn-toggle>-->

      <md-card>
        <div class="md-layout margeTopSearchBar">
          <div class="md-layout-item">
            <md-field style="margin-top: -10px">
              <label>Numéro de licence</label>
              <md-input v-on:keyup.enter="search" v-model="nuquery"></md-input>
            </md-field>

            <md-field style="margin-top: -20px">
              <label>IP</label>
              <md-input v-on:keyup.enter="search" v-model="ipquery"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item">
            <md-field style="margin-top: -10px">
              <label>Revendeur ou client</label>
              <md-input v-on:keyup.enter="search" v-model="requery"></md-input>
            </md-field>

            <md-field style="margin-top: -20px">
              <label>Identifiant machine</label>
              <md-input v-on:keyup.enter="search" v-model="keyb"></md-input>
            </md-field>
          </div>
        </div>
        <md-button class="md-prokov researcheButton" @click="search()"
          >Rechercher</md-button
        >
      </md-card>

      <!-- TABLES -->
      <div
        class="md-layout-item md-medium-size-100"
        :class="target ? 'md-size-50' : 'md-size-100'"
      >
        <licences-table
          table-header-color="prokov"
          :linkLicence="false"
          title="Licence trouvée"
        ></licences-table>

        <licences-table
          table-header-color="prokov"
          :linkLicence="true"
          title="Au sein du même lieu"
        ></licences-table>
      </div>

      <div
        v-if="target && licence"
        class="md-layout-item md-medium-size-100 md-size-50"
      >
        <form>
          <md-card>
            <md-card-content>
              <div>
                <md-tabs class="md-danger" md-alignment="left">
                  <md-tab id="tab-home" md-label="Informations">
                    <licences-informations
                      :propsTarget="target"
                      :propsLicence="licence"
                    ></licences-informations>
                  </md-tab>

                  <md-tab
                    id="tab-connec"
                    md-label="Connexions"
                    @click="$refs.liconnexions.resetPing()"
                  >
                    <licences-connexions
                      ref="liconnexions"
                      :propsTarget="target"
                      :propsLicence="licence"
                    ></licences-connexions>
                  </md-tab>

                  <md-tab id="tab-station" md-label="Changements de station">
                    <licences-changes
                      :propsTarget="target"
                      :propsLicence="licence"
                    ></licences-changes>
                  </md-tab>

                  <md-tab id="tab-black" md-label="Liste noire">
                    <licences-blackList
                      :propsTarget="target"
                      :propsLicence="licence"
                    ></licences-blackList>
                  </md-tab>

                  <md-tab id="tab-logs" md-label="Activité">
                    <licences-logs
                      :propsTarget="target"
                      :propsLicence="licence"
                    ></licences-logs>
                  </md-tab>
                </md-tabs>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LicencesTable,
  LicencesInformations,
  LicencesConnexions,
  LicencesChanges,
  LicencesLogs,
  LicencesBlackList,
} from "@/components";
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  components: {
    LicencesTable,
    LicencesInformations,
    LicencesConnexions,
    LicencesChanges,
    LicencesLogs,
    LicencesBlackList,
  },
  name: "LicencesList",
  data: () => ({
    nuquery: "",
    requery: "",
    ipquery: "",
    keyb: "",
  }),
  computed: {
    target() {
      var res = store.getters["licences/getTarget"];
      return res;
    },

    licence() {
      var res = store.getters["licences/getLicence"];
      if (res && res.data && res.data.licence) {
        return res.data.licence;
      }
      return null;
    },
  },
  created: function () {},
  methods: {
    search() {
      var param = "";
      if (this.nuquery.length > 0) {
        param = "?n=" + encodeURIComponent(this.nuquery);
      }

      if (this.requery.length > 0) {
        param += "&r=" + encodeURIComponent(this.requery.toUpperCase());
      }

      if (this.ipquery.length > 0) {
        param += "&i=" + encodeURIComponent(this.ipquery);
      }

      if (this.keyb.length > 0) {
        param += "&k=" + encodeURIComponent(this.keyb);
      }

      if (param.length > 0 && param.charAt(0) == "&") {
        param = param.replace(param.charAt(0), "?");
      }

      store.dispatch("licences/getLicences", param);
    },
  },
};
</script>
