/** * @Author: Jessy DROUIN
 * * @Date: 16-Nov-2023
 * * @Email: jessy.drouin@equasens.com
 * @Project: RppsActivation
 * @Filename: road+Rpps.js
 * @Last modified by: Jessy
 * @Last modified time: 01-dev-2023 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";
import store from "@/store";

const state = {
  rppsData: [],
  caisseData: [],
};

const getters = {
  rppsData: (state) => state.rppsData,
  caisseData: (state) => state.caisseData,
};

const mutations = {
  SET_RPPS_DATA(context, obj) {
    context.rppsData = obj;
  },
  SET_CAISSE_DATA(context, obj) {
    context.caisseData = obj;
  },

  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setObj(context, obj) {
    context.data.data.rppsData = Object.assign(context.data.data.rppsData, obj);
    context.data.data.caisseData = Object.assign(
      context.data.data.caisseData,
      obj
    );
  },
  updateRppsDataAfterDeletion(state, rppsCode) {
    if (Array.isArray(state.rppsData)) {
      state.rppsData = state.rppsData.filter((item) => item.rpps === rppsCode);
    }
  },

  updateCaisseDataAfterDeletion(state, caisseCode) {
    if (Array.isArray(state.caisseData)) {
      state.caisseData = state.caisseData.filter(
        (item) => item.caisse === caisseCode
      );
    }
  },
};

const actions = {
  async fetchRppsData(context, rppsCode) {
    context.commit("setLoading", true);
    try {
      const res = await axios({
        method: "GET",
        url: `${server.url}/rpps/${rppsCode}`,
        headers: server.getHeader(),
      });
      context.commit(
        "SET_RPPS_DATA",
        res.data ? res.data : "Aucune donnée RPPS trouvée pour ce code."
      );
      if (res.data) {
        context.commit("SET_RPPS_DATA", res.data);
      } else {
        context.commit(
          "SET_RPPS_DATA",
          "Aucune donnée RPPS trouvée pour ce code."
        );
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async fetchCaisseData(context, caisseCode) {
    context.commit("setLoading", true);
    try {
      const res = await axios({
        method: "GET",
        url: `${server.url}/rpps/${caisseCode}`,
        headers: server.getHeader(),
      });
      context.commit(
        "SET_CAISSE_DATA",
        res.data ? res.data : "Aucune donnée de caisse trouvée pour ce code."
      );
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async fetchAllCaisseData(context) {
    context.commit("setLoading", true);
    try {
      const res = await axios({
        method: "GET",
        url: `${server.url}/rpps`,
        headers: server.getHeader(),
      });
      context.commit(
        "SET_CAISSE_DATA",
        res.data ? res.data : "Aucune donnée de caisse trouvée."
      );
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async serviceDelete(context, params) {
    context.commit("setLoading", true);
    let url;
    try {
      if ("caisseCode" in params) {
        url = `${server.url}/rpps/${params.caisseCode}/${params.item}`;
      } else {
        url = `${server.url}/rpps/${params.rppsCode}/${params.item}`;
      }
      const res = await axios({
        method: "DELETE",
        url: url,
        headers: server.getHeader(),
      });
      if (res.status == 200) {
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
        if ("caisseCode" in params) {
          context.commit("updateCaisseDataAfterDeletion", params.caisseCode);
        } else if ("rppsCode" in params) {
          context.commit("updateRppsDataAfterDeletion", params.rppsCode);
        }
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
      return { res, status: res.status };
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
      throw new Error("Erreur lors de la suppression des données RPPS:", error);
    }
    context.commit("setLoading", false);
  },

  async activateFeature(context, rppsData) {
    try {
      const res = await axios.post(`${server.url}/activateFeature`, rppsData, {
        headers: server.getHeader(),
      });

      if (res.data.errorCode === "DUPLICATE") {
        store.dispatch("notification/sendNotification", {
          type: "error",
          message: "Cet élément existe déjà dans le fichier plist.",
        });
      } else if (res.status == 200) {
        context.dispatch("fetchCaisseData");
        store.dispatch("notification/sendNotification", {
          type: "success",
          method: "update",
        });
      } else {
        store.dispatch("notification/sendNotification", {
          type: "fail",
          method: "update",
        });
      }
    } catch (error) {
      throw new Error("Erreur lors de l'activation du RPPS:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
