<!--
@Author: Florian Merel <florian>
@Date:   10-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: serversTable.vue
@Last modified by:   florian
@Last modified time: 15-Feb-2021
-->

<template>
  <div>
    <md-table v-model="items" table-header-color="blue">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Titre">{{ item.title }}</md-table-cell>
        <md-table-cell md-label="Description">{{
          item.description
        }}</md-table-cell>
        <md-table-cell md-label="État"
          ><md-chip
            v-bind:class="[
              { 'md-primary': item.alive },
              { 'md-danger': !item.alive },
            ]"
            class="chipsCenter"
          >
            <span v-if="item.alive">ON</span>
            <span v-else>OFF</span>
          </md-chip></md-table-cell
        >
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "servers-table",
  props: {
    dataArray: { type: Array, required: true },
    important: { type: Boolean, default: true },
  },
  computed: {
    items() {
      if (this.important) {
        return this.dataArray.filter(
          (item) => item.important == true || item.alive == false
        );
      } else {
        return this.dataArray;
      }
    },
  },
};
</script>
