/**
 * @Author: Florian Merel <florian>
 * @Date:   01-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: usersInscription.js
 * @Last modified by:   florian
 * @Last modified time: 23-Dec-2020
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";
import Vue from "vue";
import store from "@/store";

const state = {
  loading: false,
  error: null,
  data: null,
};

const getters = {
  getData(context) {
    return context.data;
  },
};

const mutations = {
  setData(context, data) {
    context.data = data;
  },
  setLoading(context, loading) {
    context.loading = loading;
  },
  removeEmail(context, email) {
    if (context.data && context.data.data) {
      context.data.data = context.data.data.filter(
        (person) => person.email != email
      );
    }
  },
  setError(context, error) {
    context.data = null;
    context.error = error;
  },
};

const actions = {
  async usersInscriptionList(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.usersInscription,
        headers: server.getHeader(),
      });
      context.commit("setData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        //servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async removeInscription(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url: server.url + server.urls.usersInscription,
        data: params,
        headers: server.getHeader(),
      });
      return true;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return false;
      }
    }
    context.commit("setLoading", false);
    return false;
  },

  async reSendinscription(context, params) {
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.usersReInscritpion,
        data: params,
        headers: server.getHeader(),
      });
      store.dispatch("notification/sendNotification", {
        message: "Mail envoyé",
        icon: "verified_user",
        type: "success",
      });

      return true;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return false;
      }
    }
  },

  async reSendLinkInscription(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "PUT",
        url: server.url + server.urls.usersInscription,
        data: params,
        headers: server.getHeader(),
      });
      return true;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
        return false;
      }
    }
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
