<!--
@Author: Florian Merel <florian>
@Date:   14-Dec-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: ItemTickets.vue
@Last modified by:   florian
@Last modified time: 03-Feb-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-size-20">
      <span class="emailTicket">{{ item.id }} </span>
    </div>

    <div class="md-layout-item md-medium-size-100 md-size-50">
      <span
        v-if="
          item.PropFollowUp &&
          item.PropFollowUp.email &&
          item.PropFollowUp.email.length > 0
        "
        class="emailTicket"
      >
        <span v-if="item.EeUser.givenname != 'Administrateur'"
          >({{ item.EeUser.givenname }} {{ item.EeUser.familyname }})</span
        >
        {{ item.PropFollowUp.email }}
      </span>
      <span
        v-if="
          (!item.PropFollowUp ||
            !item.PropFollowUp.email ||
            item.PropFollowUp.email.length == 0) &&
          item.EeUser &&
          item.EeUser.email
        "
        class="emailTicket"
        >({{ item.EeUser.givenname }} {{ item.EeUser.familyname }})
        {{ item.EeUser.email }}
      </span>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-30">
      <div class="dateTicket">{{ getDate(item.date) }}</div>
    </div>

    <!--<div class="md-layout-item md-medium-size-100 md-size-20">
      <span class="typeTicket"
        >{{ item.specialites }}
        <md-tooltip md-direction="top">Spécialités</md-tooltip>
      </span>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-30">
      <span class="typeTicket"
        >{{ getType(item.kindID) }}
        <md-tooltip md-direction="top">type</md-tooltip>
      </span>
    </div>-->

    <div class="md-layout-item md-small-size-100 md-size-20">
      <div><span class="colorTextProkov">Titre : </span>{{ item.sujet }}</div>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-75">
      <div
        style="overflow: scroll; max-height: 200px; white-space: pre-line"
        v-html="item.description"
      ></div>
    </div>

    <div
      v-if="
        (item.ref1 && item.ref1.length > 0) ||
        (item.ref2 && item.ref2.length > 0) ||
        (item.ref3 && item.ref3.length > 0)
      "
      class="md-layout-item md-small-size-100 md-size-100"
      style="margin-top: 20px"
    ></div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="item.ref1 && item.ref1.length > 0"
    >
      <div>
        <span class="colorTextProkov">Référence 1 : </span>{{ item.ref1 }}
      </div>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="item.ref2 && item.ref2.length > 0"
    >
      <div>
        <span class="colorTextProkov">Référence 2 : </span>
        {{ item.ref2 }}
      </div>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="item.ref3 && item.ref3.length > 0"
    >
      <div>
        <span class="colorTextProkov">Référence 3 : </span>
        {{ item.ref3 }}
      </div>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "item-tickets",
  props: ["item"],
  data() {
    return {};
  },
  computed: {
    desc: {
      get() {
        return "<br> " + this.item.description;
      },
      set(value) {},
    },
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getType(type) {
      return toolsFormat.getType(type);
    },
  },
};
</script>

<style lang="scss">
/*
.textDescription {
  display:inline-block;
  width: 100%;
  resize: none;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;

}*/
</style>
