<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: UsersTable.vue
@Last modified by:   florian
@Last modified time: 05-Jul-2021
-->

<template>
  <div>
    <md-table
      v-model="ar"
      v-if="ar.length > 0"
      style="margin-top: 10px"
      table-header-color="prokov"
    >
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-label="Numéro de licence">{{
          item.licence
        }}</md-table-cell>
        <md-table-cell md-label="Logiciel">{{ item.logiciel }}</md-table-cell>
        <md-table-cell md-label="Revendeur / Client">{{
          item.client
        }}</md-table-cell>
        <md-table-cell md-label="Capacité">{{ item.capacite }}</md-table-cell>
        <md-table-cell md-label="Derniers enregistrements">{{
          item.lastLog
        }}</md-table-cell>

        <!--<md-table-cell md-label="Prénom">{{ item.givenname }}</md-table-cell>
        <md-table-cell md-label="Contact">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Société">{{
          item.PL_Company.name
        }}</md-table-cell>
        <md-table-cell md-label="Actif">{{ item.active }}</md-table-cell>
        <md-table-cell md-label="Validé">{{ item.validated }}</md-table-cell>
        <md-table-cell
          ><div
            @click="showDialog = true; editMember(item)"
            class="md-layout-item md-xsmall-size-100 md-size-20 text-right"
            style="margin-top: 5px"
          >
            <md-icon style="color: #24c4d8">settings</md-icon>
          </div></md-table-cell
        >-->
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import store from "@/store";
//@click="editMember(item)"

export default {
  name: "licences-company-table",
  props: {
    dataArray: { type: Array, required: true },
  },
  data() {
    return {
      showDialog: false,
      members: null,
      pass: "",
      passConf: "",
      selected: [],
    };
  },
  methods: {},
  computed: {
    ar() {
      return this.dataArray;
    },
  },
};
</script>

<style lang="scss">
.md-tabs-content table thead {
  display: contents !important;
}
</style>
