/**
 * @Author: Florian Merel <florian>
 * @Date:   24-Aug-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: ticketsV2Badges.js
 * @Last modified by:   florian
 * @Last modified time: 24-Aug-2021
 */

import axios from "axios";
import server from "../../server.js";
import servicesError from "../../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: null,
};

const getters = {};

const mutations = {
  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async postNotifications(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.ticketV2Notifications,
        headers: server.getHeader(),
        data: params,
      });
      context.commit("setData", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
