<!--
@Author: Florian Merel <florian>
@Date:   15-Jan-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogFilesVue.vue
@Last modified by:   florian
@Last modified time: 14-Jun-2021
-->
<!--
@Author: Florian Merel <florian>
@Date:   10-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: DialogsTickets.vue
@Last modified by:   florian
@Last modified time: 14-Jun-2021
-->

<template>
  <md-card>
    <md-card-header v-if="this.stats" data-background-color="blue">
      <h4 class="title">{{ title }}</h4>
    </md-card-header>
    <md-card-content v-if="this.stats">
      <div class="md-layout">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25
          "
        >
          <div class="full-control">
            <div class="list">
              <md-list>
                <md-list-item>{{ token }}</md-list-item>
                <md-list-item v-if="settings.haveios">
                  <md-icon>person</md-icon>
                  <span class="md-list-item-text">Utilisateur unique</span>
                  <md-switch
                    class="md-primary"
                    @change="refresh"
                    v-model="settings.uuser"
                  />
                </md-list-item>

                <md-list-item v-if="settings.haveios">
                  <md-icon>smartphone</md-icon>
                  <span class="md-list-item-text">iOS</span>
                  <md-switch
                    class="md-primary"
                    @change="refresh"
                    v-model="settings.ios"
                  />
                </md-list-item>

                <md-list-item v-if="settings.havemacos">
                  <md-icon>computer</md-icon>
                  <span class="md-list-item-text">macOS</span>
                  <md-switch
                    class="md-primary"
                    @change="refresh"
                    v-model="settings.macos"
                  />
                </md-list-item>

                <md-list-item>
                  <md-icon>devices</md-icon>
                  <span class="md-list-item-text">iOS + macOS</span>
                  <md-switch
                    class="md-primary"
                    @change="refresh"
                    v-model="settings.fusion"
                  />
                </md-list-item>

                <md-list-item>
                  <md-icon>weekend</md-icon>
                  <span class="md-list-item-text">Week-end</span>
                  <md-switch
                    class="md-primary"
                    @change="refresh"
                    v-model="settings.weeks"
                  />
                </md-list-item>

                <md-list-item md-expand>
                  <md-icon>tag</md-icon>
                  <span class="md-list-item-text">iOS Versions</span>

                  <md-list slot="md-expand">
                    <md-list-item
                      v-for="item in iosVersion"
                      :key="item.version"
                    >
                      <md-checkbox v-model="item.act" @change="refresh()" />
                      <span class="md-list-item-text">{{ item.version }}</span>
                    </md-list-item>
                  </md-list>
                </md-list-item>

                <md-list-item md-expand>
                  <md-icon>tag</md-icon>
                  <span class="md-list-item-text">macOS Versions</span>

                  <md-list slot="md-expand">
                    <md-list-item
                      v-for="item in macosVersion"
                      :key="item.version"
                    >
                      <md-checkbox v-model="item.act" @change="refresh()" />
                      <span class="md-list-item-text">{{ item.version }}</span>
                    </md-list-item>
                  </md-list>
                </md-list-item>

                <md-list-item md-expand>
                  <md-icon>history</md-icon>
                  <span class="md-list-item-text">Échelle</span>

                  <md-list slot="md-expand">
                    <md-list-item>
                      <span class="md-list-item-text">Par jour</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.echelle.byweek = false;
                          settings.echelle.bymonth = false;
                          refresh();
                        "
                        v-model="settings.echelle.byday"
                      />
                    </md-list-item>

                    <md-list-item>
                      <span class="md-list-item-text">Par semaine</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.echelle.byday = false;
                          settings.echelle.bymonth = false;
                          refresh();
                        "
                        v-model="settings.echelle.byweek"
                      />
                    </md-list-item>

                    <md-list-item>
                      <span class="md-list-item-text">Par mois</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.echelle.byday = false;
                          settings.echelle.byweek = false;
                          refresh();
                        "
                        v-model="settings.echelle.bymonth"
                      />
                    </md-list-item>
                  </md-list>
                </md-list-item>

                <!--<md-list-item md-expand>
                  <md-icon>history</md-icon>
                  <span class="md-list-item-text">Période</span>

                  <md-list slot="md-expand">
                    <md-list-item>
                      <span class="md-list-item-text">1 mois</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.periode.three = false;
                          settings.periode.six = false;
                          refresh();
                        "
                        v-model="settings.periode.one"
                      />
                    </md-list-item>

                    <md-list-item>
                      <span class="md-list-item-text">3 mois</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.periode.six = false;
                          settings.periode.one = false;
                          refresh();
                        "
                        v-model="settings.periode.three"
                      />
                    </md-list-item>

                    <md-list-item>
                      <span class="md-list-item-text">6 mois</span>
                      <md-switch
                        class="md-primary"
                        @change="
                          settings.periode.three = false;
                          settings.periode.one = false;
                          refresh();
                        "
                        v-model="settings.periode.six"
                      />
                    </md-list-item>
                  </md-list>
                </md-list-item>-->
              </md-list>
            </div>
          </div>
        </div>
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-75
          "
        >
          <AreaCharts ref="nbStats" :chartData="chartData" />
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import Vue from "vue";
import AreaCharts from "./AreaCharts.vue";

export default {
  components: {
    AreaCharts,
  },
  name: "statistiques-charts",
  props: ["stats"],
  data: () => ({
    chartData: {
      labels: [],
      datasets: [],
    },
    title: "",
    token: "",
    settings: {
      haveios: false,
      ios: false,
      havemacos: false,
      macos: false,
      fusion: false,
      weeks: false,
      uuser: true,
      echelle: {
        byday: true,
        byweek: false,
        bymonth: false,
      },
      periode: {
        one: false,
        three: false,
        six: true,
      },
    },
    iosVersion: [],
    macosVersion: [],
    lastToken: null,
  }),
  watch: {
    stats: function (newVal, oldVal) {
      this.refresh();
    },
  },
  computed: {},
  methods: {
    test() {},

    refresh(dt = null) {
      /*var dta = null;

      if ((!this.stats || !this.stats.rows) && (!dt || !dt.rows)) {
        return;
      }

      if (dt) {
        dta = dt;
      } else {
        dta = this.stats;
      }
*/

      if (!this.stats && !this.stats.rows) {
        return;
      }

      let rows = this.stats.rows;
      this.chartData.datasets = [];

      var data_ios = {
        label: "iOS",
        borderColor: "#1abc9c",
        pointBackgroundColor: "#1abc9c",
        pointBorderColor: "#1abc9c",
        borderWidth: 1,
        backgroundColor: "transparent",
      };

      var data_u_ios = {
        label: "Utilisateur iOS",
        borderColor: "#FFA600",
        pointBackgroundColor: "#FFA600",
        pointBorderColor: "#FFA600",
        borderWidth: 1,
        backgroundColor: "transparent",
      };

      /*if (this.$refs.nbStats) {
        data_ios.backgroundColor = this.$refs.nbStats.getGradientColor();
      }*/

      var data_macos = {
        label: "macOS",
        borderColor: "#26A0F2",
        pointBackgroundColor: "#26A0F2",
        pointBorderColor: "#26A0F2",
        borderWidth: 1,
        backgroundColor: "transparent",
      };

      var data_u_macos = {
        label: "Utilisateur macOS",
        borderColor: "#F000FF",
        pointBackgroundColor: "#F000FF",
        pointBorderColor: "#F000FF",
        borderWidth: 1,
        backgroundColor: "transparent",
      };

      for (let i = 0; i < rows.length; i++) {
        this.token = rows[i].token;
        if (rows[i].description && rows[i].description.length > 0) {
          this.title = rows[i].description;
        }

        // Pour iOS
        if (rows[i].environment == "iOS") {
          this.settings.haveios = true;
          // On récupèrer les statistiques sur la plage de temps souhaités
          var ar = this.getLastMonthData(rows[i].stats);

          // On set les numéros de version
          this.iosVersion = this.getVersion(ar, this.iosVersion);
          var st = this.getStats(ar, this.iosVersion);
          this.chartData.labels = st.labels;
          data_ios.data = st.data;

          //UNIQUE
          var ust = this.getUniqueStats(ar, this.iosVersion);
          this.chartData.labels = ust.labels;
          data_u_ios.data = ust.data;
        } else if (rows[i].environment == "macOS") {
          this.settings.havemacos = true;
          var ar = this.getLastMonthData(rows[i].stats);

          // On set les numéros de version
          this.macosVersion = this.getVersion(ar, this.macosVersion);
          var st = this.getStats(ar, this.macosVersion);
          this.chartData.labels = st.labels;
          data_macos.data = st.data;
          data_ios.data = st.data;

          var ust = this.getUniqueStats(ar, this.macosVersion);
          this.chartData.labels = ust.labels;
          data_u_macos.data = ust.data;
        }
      }

      if (this.settings.ios) {
        this.chartData.datasets.push(data_ios);
      }

      if (this.settings.macos) {
        this.chartData.datasets.push(data_macos);
      }

      if (this.settings.uuser) {
        this.chartData.datasets.push(data_u_ios);
        this.chartData.datasets.push(data_u_macos);
      }

      if (this.settings.fusion) {
        var fusion = [];
        var sum = data_ios.data.map(function (num, idx) {
          return num + data_macos.data[idx];
        });

        var sumuser = data_u_ios.data.map(function (num, idx) {
          return num + data_u_macos.data[idx];
        });

        if (this.settings.macos || this.settings.ios) {
          var data_fusion = {
            label: "macOS + iOS",
            borderColor: "#F2403F",
            pointBackgroundColor: "#F2403F",
            pointBorderColor: "#F2403F",
            borderWidth: 1,
            backgroundColor: "transparent",
            data: sum,
          };
          this.chartData.datasets.push(data_fusion);
        }

        if (this.settings.uuser) {
          var user_data_fusion = {
            label: "Utilisateur macOS + iOS",
            borderColor: "#00FFFB",
            pointBackgroundColor: "#00FFFB",
            pointBorderColor: "#00FFFB",
            borderWidth: 1,
            backgroundColor: "transparent",
            data: sumuser,
          };
          this.chartData.datasets.push(user_data_fusion);
        }
      }

      if (this.settings.weeks) {
      }

      if (this.$refs.nbStats) {
        this.$refs.nbStats.callRenderChart();
      }
    },

    // Retourn le tableau de statistiques
    getStats(ar, version) {
      var obj = {};
      obj.labels = [];
      obj.data = [];

      var tmp_ar = [];
      for (let nb = 0; nb < ar.length; nb++) {
        const found = version.find((el) => el.version == ar[nb].version);
        if (found && found.act) {
          var date_st = new Date(ar[nb].date);

          if (this.settings.echelle.byweek || this.settings.echelle.bymonth) {
            var index;
            if (this.settings.echelle.bymonth) {
              index = Vue.material.locale.shortMonths[date_st.getMonth()];
            } else {
              index = this.getietNumberOfWeek(date_st);
            }

            if (tmp_ar[index]) {
              tmp_ar[index].push(ar[nb].nombre);
            } else {
              tmp_ar[index] = [ar[nb].nombre];
            }
          } else {
            let label = date_st.getDate() + "-" + (date_st.getMonth() + 1);
            const findex = obj.labels.findIndex((el) => el == label);
            if (findex == -1) {
              obj.labels.push(label);
              obj.data.push(ar[nb].nombre);
            } else {
              obj.data[findex] += ar[nb].nombre;
            }
          }
        }
      }

      if (this.settings.echelle.byweek || this.settings.echelle.bymonth) {
        for (const [index, num] of Object.entries(tmp_ar)) {
          obj.labels.push(index);
          const sum = num.reduce((a, b) => a + b, 0);
          const avg = sum / num.length || 0;
          obj.data.push(Math.round(avg));
        }
      }

      return obj;
    },

    // Retourn le tableau de statistiques
    getUniqueStats(ar, version) {
      var obj = {};
      obj.labels = [];
      obj.data = [];

      var tmp_ar = [];
      for (let nb = 0; nb < ar.length; nb++) {
        const found = version.find((el) => el.version == ar[nb].version);
        if (found && found.act) {
          var date_st = new Date(ar[nb].date);

          if (this.settings.echelle.byweek || this.settings.echelle.bymonth) {
            var index;
            if (this.settings.echelle.bymonth) {
              index = Vue.material.locale.shortMonths[date_st.getMonth()];
            } else {
              index = this.getietNumberOfWeek(date_st);
            }

            if (tmp_ar[index]) {
              tmp_ar[index].push(ar[nb].nombreUnique);
            } else {
              tmp_ar[index] = [ar[nb].nombreUnique];
            }
          } else {
            let label = date_st.getDate() + "-" + (date_st.getMonth() + 1);
            const findex = obj.labels.findIndex((el) => el == label);
            if (findex == -1) {
              obj.labels.push(label);
              obj.data.push(ar[nb].nombreUnique);
            } else {
              obj.data[findex] += ar[nb].nombreUnique;
            }
          }
        }
      }

      if (this.settings.echelle.byweek || this.settings.echelle.bymonth) {
        for (const [index, num] of Object.entries(tmp_ar)) {
          obj.labels.push(index);
          const sum = num.reduce((a, b) => a + b, 0);
          const avg = sum / num.length || 0;
          obj.data.push(Math.round(avg));
        }
      }

      return obj;
    },

    // Retourn le nombre de semaine de la date
    getietNumberOfWeek(date) {
      const today = date;
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
      const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    },

    // retourn le tableau des versions
    getVersion(ar, version) {
      var ar_io = [];
      for (let nb = 0; nb < ar.length; nb++) {
        var act_io = true;
        const found = version.find((el) => el.version == ar[nb].version);
        if (found) {
          act_io = found.act;
        }
        if (!ar_io.find((el) => el.version == ar[nb].version)) {
          ar_io.push({ version: ar[nb].version, act: act_io });
        }
      }
      ar_io = ar_io.sort(function (a, b) {
        return b.version.split(".").join("") - a.version.split(".").join("");
      });
      return ar_io;
    },

    // Calculer la plage des statistiques
    getLastMonthData(rows) {
      // Fixer la plage de temps de la courbe
      var days = 30;
      if (this.settings.periode.three) {
        days = 91;
      } else if (this.settings.periode.six) {
        days = 182;
      } else if (days == 30 && !this.settings.periode.one) {
        this.settings.periode.one = true;
      }

      const currentDate = new Date();
      const currentDateTime = currentDate.getTime();

      const lastDaysDate = new Date(
        currentDate.setDate(currentDate.getDate() - days)
      );
      const lastDaysDateTime = lastDaysDate.getTime();

      const lastDaysList = rows
        .filter((x) => {
          const dateElement = new Date(x.date);
          const elementDateTime = dateElement.getTime();

          if (!this.settings.weeks) {
            const isWeekend = dateElement.getDay();
            if (isWeekend === 6 || isWeekend === 0) {
              return false;
            }
          }

          if (
            elementDateTime <= currentDateTime &&
            elementDateTime > lastDaysDateTime
          ) {
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });

      /*var now = new Date();
        console.log(lastDaysDate);
        for (var d = lastDaysDate; d <= now; d.setDate(d.getDate() + 1)) {


          var fidate = lastDaysList.find((da) => {
            const dateElement = new Date(da.date);
            if (dateElement.getDate() === d.getDate() && dateElement.getMonth() === d.getMonth() && dateElement.getFullYear() === d.getFullYear()) {
              return true;
            } else {
              return false;
            }
          });


          if (!fidate) {
            lastDaysList.push({date: lastDaysDate, nombre: 0, nombreUnique: 0, version: "0"})
          } else {
          }
        }

        console.log(lastDaysList.length);*/
      return lastDaysList;
    },
  },
};
</script>

<style lang="scss" scoped></style>
