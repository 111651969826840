/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: main.js
 * @Last modified by:   florian
 * @Last modified time: 13-Jul-2021
 */

import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";

// ----------------------------------------------------------------------------
// Rooter Setup
// ----------------------------------------------------------------------------
import routes from "./routes/routes";

// ----------------------------------------------------------------------------
// PLugin Import
// ----------------------------------------------------------------------------

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import store from "./store";
import Login from "@/pages/Login.vue";
import MaterialDashboard from "./material-dashboard";
import Notifications from "./components/NotificationPlugin";

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

// ----------------------------------------------------------------------------
// Configuration des calendriers
// ----------------------------------------------------------------------------

Vue.material.locale.dateFormat = "yyyy-MM-dd";
Vue.material.locale.cancel = "Annuler";
Vue.material.locale.shortDays = [
  "Dim",
  "Lun",
  "Mar",
  "Mer",
  "Jeu",
  "Ven",
  "Sam",
];
Vue.material.locale.shorterDays = ["D", "L", "M", "M", "J", "V", "S"];
Vue.material.locale.months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
Vue.material.locale.shortMonths = [
  "Jan",
  "Fév",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Aoû",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
Vue.material.locale.shorterMonths = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "Ju",
  "Ju",
  "A",
  "Se",
  "O",
  "N",
  "D",
];

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import vuetify from "@/plugins/vuetify";

Vue.use(Vuetify);

// ----------------------------------------------------------------------------
// Configuration du rooter
// ----------------------------------------------------------------------------
export const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkActiveClass: "nav-item active",
});
//Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  vuetify: vuetify,
  router,
  store,
});
