<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 22-Jan-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <md-card>
        <div
          class="md-layout-item md-medium-size-100 md-size-100"
          style=""
          v-if="load"
        >
          <v-progress-linear indeterminate color="prokov"></v-progress-linear>
        </div>

        <div class="md-layout margeTopSearchBar">
          <div class="md-layout-item">
            <v-select
              :items="items"
              v-model="select"
              single-line
              label="Par"
              color="prokov"
            ></v-select>
          </div>

          <div class="md-layout-item">
            <md-field style="margin-top: 0px">
              <label>Recherche</label>
              <md-input v-on:keyup.enter="search" v-model="keyb"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout" style="margin-top: -20px">
          <div class="md-layout-item md-medium-size-100 md-size-20">
            <v-select
              :items="times"
              v-model="timeSelect"
              single-line
              label="Depuis"
              color="prokov"
            ></v-select>
          </div>

          <div class="md-layout-item">
            <md-checkbox v-model="error">Uniquement les erreurs</md-checkbox>
          </div>
        </div>

        <md-button class="md-prokov researcheButton" @click="search()"
          >Rechercher</md-button
        >
      </md-card>

      <div
        class="md-layout-item md-medium-size-100 md-size-100"
        style="margin-top: 20px"
        v-if="data != null"
      >
        <v-card>
          <v-card-title> {{ this.data.licence }} </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="">
            <div>BonjourID - {{ this.data.bonjour }}</div>
            <div style="margin-bottom: 20px">
              DeviceID - {{ this.data.device }}
            </div>

            <v-list-item-subtitle>
              <v-row no-gutters>
                <v-col>
                  <div style="color: green">Date</div>
                </v-col>

                <v-col>
                  <div style="color: green">Route</div>
                </v-col>

                <v-col>
                  <div style="color: green">Fonction</div>
                </v-col>

                <v-col>
                  <div style="color: green">IP</div>
                </v-col>

                <v-col>
                  <div style="color: green">Base de données</div>
                </v-col>

                <v-col>
                  <div style="color: green">Erreur</div>
                </v-col>

                <v-col>
                  <div style="color: green">Serveur</div>
                </v-col>

                <v-col>
                  <div style="color: green">DeviceID - BonjourID</div>
                </v-col>

                <v-col>
                  <div style="color: green"></div>
                </v-col>
              </v-row>
            </v-list-item-subtitle>

            <v-list-item-subtitle v-for="(ti, nb) in this.data.logs" :key="nb">
              <v-row no-gutters :style="getColor(ti)">
                <v-col>
                  <div>{{ getDate(ti.ts) }}</div>
                </v-col>

                <v-col>
                  <div>{{ ti.service }}</div>
                </v-col>

                <v-col>
                  <span> {{ getFunctionsName(ti.function) }} </span>
                  <span v-if="ti.software">
                    - {{ ti.software.toUpperCase() }}
                  </span>
                </v-col>

                <v-col>
                  <div>{{ ti.ip }}</div>
                </v-col>

                <v-col>
                  <div>{{ ti.db }}</div>
                </v-col>

                <v-col>
                  <div>{{ ti.error }}</div>
                </v-col>

                <v-col>
                  <div>{{ ti.server }}</div>
                </v-col>

                <v-col v-if="ti.bonjour">
                  <div>{{ ti.bonjour.substring(0, 9) }}</div>
                </v-col>

                <v-col v-else> </v-col>

                <v-col>
                  <v-btn
                    text
                    icon
                    x-small
                    color="gray lighten-2"
                    @click="open(ti)"
                  >
                    <v-icon>fas fa-info-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-card-text>
        </v-card>
      </div>

      <v-snackbar v-model="snackbar">
        {{ txt }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import server from "../store/server.js";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  components: {},
  data: () => ({
    error: false,
    load: false,
    keyb: "",
    timeSelect: "24 heures",
    times: ["24 heures", "48 heures", "Une semaine", "Un mois"],
    select: "Numéro de licence",
    items: ["Numéro de licence", "IP", "Bonjour ID", "Device ID"],
    data: null,
    functions: [],
    snackbar: false,
    txt: "",
    errors: [],
  }),
  created: async function () {
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.logservices + "/functions",
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.data) {
        this.functions = res.data.data.functions;
      }
    } catch (error) {}

    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.logservices + "/errors",
        headers: server.getHeader(),
      });
      if (res && res.data && res.data.data) {
        this.errors = res.data.data.functions;
      }
    } catch (error) {}
  },
  computed: {},
  methods: {
    open(it) {
      this.snackbar = true;
      this.txt = JSON.stringify(it, null, "\t");
    },

    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    getFunctionsName(value) {
      return this.functions.find((obj) => obj.value == value).label;
    },

    getErrorsName(value) {
      return this.errors.find((obj) => obj.value == value).label;
    },

    getColor(ti) {
      if (ti.error) {
        return "color: red;";
      }
      return "";
    },

    async search() {
      //console.log(this.nuquery, this.requery, this.ipquery, this.keyb)
      var data = {};
      this.load = true;
      if (this.keyb.length == 0) {
        return;
      }

      if (this.select == "Numéro de licence") {
        data.licence = this.keyb;
      } else if (this.select == "IP") {
        data.ip = this.keyb;
      } else if (this.select == "Bonjour ID") {
        data.bonjour = this.keyb;
      } else if (this.select == "Device ID") {
        data.device = this.keyb;
      }

      data.error = this.error ? 1 : 0;

      var min_time = new Date();
      var max_time = new Date();
      if (this.timeSelect == "24 heures") {
        min_time.setDate(min_time.getDate() - 1);
      } else if (this.timeSelect == "48 heures") {
        min_time.setDate(min_time.getDate() - 2);
      } else if (this.timeSelect == "Une semaine") {
        min_time.setDate(min_time.getDate() - 7);
      } else if (this.timeSelect == "Un mois") {
        min_time.setDate(min_time.getDate() - 30);
      }

      data.min_time = min_time.getTime();
      data.max_time = max_time.getTime();

      try {
        let res = await axios({
          method: "POST",
          url: server.url + server.urls.logservices,
          headers: server.getHeader(),
          data: data,
        });
        this.data = null;

        if (res && res.data && res.data.data) {
          this.title = res.data.data.licence;

          this.data = res.data.data;
          if (res.data.data.logs) {
            this.data.logs = this.data.logs.sort((a, b) => b.ts - a.ts);
          }
          this.load = false;
        }
      } catch (error) {
        this.load = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
