<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileForm.vue
@Last modified by:   florian
@Last modified time: 25-Nov-2020
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-size-100" style="">
      <div class="md-subheading">Créer un badge</div>
      <md-divider />
    </div>

    <div class="md-layout-item md-medium-size-100 md-size-50">
      <md-field>
        <label>Nom du badge</label>
        <md-input v-model="badgeName" type="text"></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-medium-size-100 md-size-50">
      <select-categorie-badge v-model="badgeCategorie"></select-categorie-badge>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-100 text-right"
      style="margin-top: -20px"
    >
      <md-button class="md-raised md-prokov" style="" @click="createBadge()"
        >Ajouter le badge</md-button
      >
    </div>
  </div>
</template>

<script>
import { SelectCategorieBadge } from "@/components";
import store from "@/store";
import Vue from "vue";

export default {
  name: "badges-created",
  components: {
    SelectCategorieBadge,
  },
  data: () => ({
    badgeCategorie: null,
    badgeName: "",
  }),
  computed: {
    ticketsInput() {
      return store.getters["tickets/getBadgeResult"];
    },
  },
  methods: {
    createBadge() {
      if (this.badgeName && this.badgeName.length > 0 && this.badgeCategorie) {
        for (var i = 0; i < this.ticketsInput.badges.rows.length; i++) {
          if (
            this.ticketsInput.badges.rows[i].name.toUpperCase() ==
            this.badgeName.toUpperCase()
          ) {
            Vue.set(this.ticketsInput.badges.rows[i], "select", true);
            return;
          }
        }
        store.dispatch("tickets/servicePostBadges", {
          name: this.badgeName,
          cat: this.badgeCategorie,
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
