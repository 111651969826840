<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfilePro.vue
@Last modified by:   florian
@Last modified time: 10-Nov-2020
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-field>
        <label>Numéro RPPS</label>
        <md-input v-model="obj.rpps" type="text"></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-field>
        <label>Caisse de rattachement</label>
        <md-input v-model="obj.caisse" type="text"></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-field>
        <label>Organisation / Cabinet</label>
        <md-input v-model="obj.organization" type="text"></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-field>
        <label>Téléphone professionnel</label>
        <md-input v-model="obj.phone" type="number"></md-input>
      </md-field>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-field>
        <label for="movie">Status</label>
        <md-select v-model="obj.activity">
          <md-option value="0">Praticien</md-option>
          <md-option value="2">Employe</md-option>
        </md-select>
      </md-field>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="obj.activity == 0 && obj.profession"
    >
      <profession-field :profession="obj.profession"></profession-field>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="obj.activity == 0 && obj.profession && obj.profession == 'G15_10'"
    >
      <profession-spec-field :profession="obj.spec"></profession-spec-field>
    </div>

    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-if="obj.activity == 0 && obj.profession && obj.profession == 'G15_21'"
    >
      <profession-spec-pharma-field
        :profession="obj.spec"
      ></profession-spec-pharma-field>
    </div>

    <div class="md-layout-item md-size-100 text-right">
      <md-button class="md-raised md-prokov" @click="enregistrerValue()"
        >Enregistrer</md-button
      >
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {
  PaysField,
  ProfessionField,
  ProfessionSpecField,
  ProfessionSpecPharmaField,
} from "@/components";

export default {
  name: "edit-profile-pro",
  components: {
    ProfessionField,
    ProfessionSpecField,
    ProfessionSpecPharmaField,
  },
  methods: {
    enregistrerValue() {
      store.dispatch("user/servicePut", {
        url: "/" + this.$route.query.u,
        obj: this.obj,
      });
    },
  },
  computed: {
    obj: {
      get() {
        var x = store.getters["user/getData"];
        if (x && x.data && x.data.user) {
          return x.data.user;
        }
        return {};
      },
    },
  },
};
</script>

<style lang="scss"></style>
