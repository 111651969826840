<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: ProfessionField.vue
@Last modified by:   florian
@Last modified time: 10-Nov-2020
-->

<template>
  <md-field>
    <label for="pros">Profession</label>
    <md-select v-model="myProf">
      <md-option value="G15_26">Audio-Prothésiste</md-option>
      <md-option value="G15_40">Chirurgien-Dentiste</md-option>
      <md-option value="G16_40">Chirurgien-Dentiste en formation</md-option>
      <md-option value="G15_85">Epithésiste</md-option>
      <md-option value="G15_94">Ergothérapeute</md-option>
      <md-option value="G15_60">Infirmier</md-option>
      <md-option value="G15_69">Infirmier psychiatrique</md-option>
      <md-option value="G15_98">Manipulateur ERM</md-option>
      <md-option value="G15_70">Masseur-Kinésithérapeute</md-option>
      <md-option value="G15_10">Médecin</md-option>
      <md-option value="G16_10">Médecin en formation</md-option>
      <md-option value="G15_84">Oculariste</md-option>
      <md-option value="G15_28">Opticien-Lunetier</md-option>
      <md-option value="G15_83">Orthopédiste-Orthésiste</md-option>
      <md-option value="G15_91">Orthophoniste</md-option>
      <md-option value="G15_81">Orthoprothésiste</md-option>
      <md-option value="G15_92">Orthoptiste</md-option>
      <md-option value="G15_21">Pharmacien</md-option>
      <md-option value="G16_21">Pharmacien en formation</md-option>
      <md-option value="G15_80">Pédicure-Podologue</md-option>
      <md-option value="G15_82">Podo-Orthésiste</md-option>
      <md-option value="G15_96">Psychomotricien</md-option>
      <md-option value="G15_50">Sage-Femme</md-option>
      <md-option value="G16_50">Sage-Femme en formation</md-option>
      <md-option value="ADDPsy">Psychologue</md-option>
    </md-select>
  </md-field>
</template>

<script>
import store from "@/store";

export default {
  name: "profession-field",
  props: ["profession"],
  data() {
    return {
      prof: null,
    };
  },
  computed: {
    myProf: {
      get() {
        return this.profession;
      },
      set(newValue) {
        this.prof = newValue;
        store.commit("user/setObj", { profession: newValue });
      },
    },
  },
};
</script>
<style></style>
