<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileForm.vue
@Last modified by:   florian
@Last modified time: 02-Dec-2020
-->

<template>
  <form>
    <md-card>
      <md-card-content>
        <div>
          <md-tabs class="md-danger" md-alignment="left">
            <md-tab id="tab-home" md-label="Personnelle">
              <edit-profile-perso> </edit-profile-perso>
            </md-tab>
            <md-tab
              id="tab-pages"
              v-if="!obj.validation"
              md-label="Professionnel"
            >
              <edit-profile-pro> </edit-profile-pro>
            </md-tab>
            <md-tab
              id="tab-services"
              v-if="!obj.validation"
              md-label="Services"
            >
              <edit-profile-services> </edit-profile-services>
            </md-tab>
            <md-tab id="tab-posts" v-if="!obj.validation" md-label="Sécuriser">
              <edit-profile-secu> </edit-profile-secu>
            </md-tab>
            <md-tab
              id="tab-favorites"
              v-if="!obj.validation"
              md-label="Actions"
            >
              <edit-profile-action> </edit-profile-action>
            </md-tab>
            <md-tab
              id="tab-installation"
              v-if="!obj.validation"
              md-label="Installation"
            >
              <edit-profile-installation> </edit-profile-installation>
            </md-tab>
          </md-tabs>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import {
  EditProfilePerso,
  EditProfilePro,
  EditProfileSecu,
  EditProfileServices,
  EditProfileAction,
} from "@/components";
import EditProfileInstallation from "./EditProfileInstallation.vue";
import store from "@/store";

export default {
  name: "edit-profile-form",
  components: {
    EditProfilePerso,
    EditProfilePro,
    EditProfileSecu,
    EditProfileServices,
    EditProfileAction,
    EditProfileInstallation,
  },
  computed: {
    obj: {
      get() {
        var x = store.getters["user/getData"];
        if (x && x.data && x.data.user) {
          return x.data.user;
        }
        return {};
      },
    },
  },
};
</script>

<style lang="scss"></style>
