<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 28-Jul-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout" style="margin-top: 20px">
      <div
        class="md-layout-item md-medium-size-100 md-size-25"
        @drop="onDrop($event, 'À Faire')"
        @dragover.prevent
        @dragenter.prevent
      >
        <md-card>
          <md-card-header data-background-color="prokovLow1">
            <h4 class="title">À Faire</h4>
          </md-card-header>
          <md-card-content>
            <div
              v-for="item in listàfaire"
              v-bind:key="item.email"
              draggable
              @dragstart="startDrag($event, item)"
            >
              <follow-up-card :item="item"></follow-up-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-size-25"
        @drop="onDrop($event, 'En Cours')"
        @dragover.prevent
        @dragenter.prevent
      >
        <md-card>
          <md-card-header data-background-color="prokovLow2">
            <h4 class="title">En Cours</h4>
          </md-card-header>

          <md-card-content class="drop-zone">
            <div
              v-for="item in listencours"
              v-bind:key="item.email"
              draggable
              @dragstart="startDrag($event, item)"
              class="drag-el"
            >
              <follow-up-card :item="item"></follow-up-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-size-25"
        @drop="onDrop($event, 'Revue, Test')"
        @dragover.prevent
        @dragenter.prevent
      >
        <md-card>
          <md-card-header data-background-color="prokovLow3">
            <h4 class="title">Revue, Test</h4>
          </md-card-header>

          <md-card-content>
            <div
              v-for="item in listrevue"
              v-bind:key="item.email"
              draggable
              @dragstart="startDrag($event, item)"
            >
              <follow-up-card :item="item"></follow-up-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-size-25"
        @drop="onDrop($event, 'Fait')"
        @dragover.prevent
        @dragenter.prevent
      >
        <md-card>
          <md-card-header data-background-color="prokov">
            <h4 class="title">Fait</h4>
          </md-card-header>

          <md-card-content>
            <div
              v-for="item in listfait"
              v-bind:key="item.email"
              draggable
              @dragstart="startDrag($event, item)"
            >
              <follow-up-card :item="item"></follow-up-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { FollowUpCard } from "@/components";
import store from "@/store";

export default {
  components: {
    FollowUpCard,
  },
  data: () => ({
    query: "",
    selectedBadges: [],
    list: null,
  }),
  computed: {
    getBadges() {
      let x = store.getters["tickets/getBadgeResult"];
      return x;
    },
    getTickets() {
      let x = store.getters["followup/getData"];
      if (x.data && x.data.tickets && x.data.tickets.rows) {
        return x.data.tickets.rows;
      }
      return [];
    },

    listàfaire() {
      return this.getList().filter(
        (item) =>
          !item.PropFollowUp ||
          item.PropFollowUp.followUpStatut == null ||
          item.PropFollowUp.followUpStatut === "À Faire"
      );
    },
    listencours() {
      return this.getList().filter(
        (item) =>
          item.PropFollowUp && item.PropFollowUp.followUpStatut === "En Cours"
      );
    },
    listrevue() {
      return this.getList().filter(
        (item) =>
          item.PropFollowUp &&
          item.PropFollowUp.followUpStatut === "Revue, Test"
      );
    },
    listfait() {
      return this.getList().filter(
        (item) =>
          item.PropFollowUp && item.PropFollowUp.followUpStatut === "Fait"
      );
    },
  },
  methods: {
    getList() {
      var base = this.getTickets;
      if (base != null) {
        this.list = base;
      } else {
        return [];
      }
      return this.list;
    },

    getBadgesByCat(name) {
      var ar = [];
      if (this.getBadges && this.getBadges.badges) {
        ar = this.getBadges.badges.rows.filter(
          (element) => element.cat == name
        );
      }
      return ar;
    },
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },

    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.list.find((item) => item.id == itemID);
      item.PropFollowUp.followUpStatut = list;
      store.dispatch("followup/moveFollowup", {
        id: itemID,
        data: { followUpStatut: list },
      });
    },

    search() {
      var param = "";
      if (this.query.length) {
        param += "?q=" + encodeURIComponent(this.query);
      }
      if (this.selectedBadges.length > 0) {
        param += "&b=" + JSON.stringify(this.selectedBadges);
      }

      if (param.length > 0 && param.charAt(0) == "&") {
        param = param.replace(param.charAt(0), "?");
      }
      store.dispatch("followup/followupTickets", param);
    },
  },
  beforeMount() {
    if (this.getBadges == null) {
      store.dispatch("tickets/serviceGetBadges");
    }
    store.dispatch("followup/followupTickets", "");
  },
};
</script>

<style lang="scss" scoped></style>
