<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Type.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div
    v-if="
      getTicket != null &&
      getTicket.ticketFollowUp != null &&
      getTicket.ticketFollowUp.type != null
    "
    class="md-layout-item md-medium-size-100 md-size-100 backgroundComponent"
    style="margin-top: 0px"
  >
    <div class="md-body-2" style="color: green; margin-top: 10px">
      Suivi du ticket

      <v-btn
        v-if="leftArrowHidden()"
        style="margin-left: 20px"
        color="green"
        v-on:click="leftArrowAction"
        x-small
        dark
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        v-if="rightArrowHidden()"
        style="margin-left: 8px"
        color="green"
        v-on:click="rightArrowAction"
        x-small
        dark
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-stepper
      class="backgroundComponent"
      elevation="0"
      :key="componentKey"
      v-model="this.e1"
    >
      <v-stepper-header>
        <template v-for="(n, index) in this.getSteps">
          <v-stepper-step
            color="green"
            :key="index"
            :complete="e1 > index"
            :step="index"
          >
            {{ n.ti }}
            <small>{{ getDate(n.da) }}</small>
          </v-stepper-step>
          <v-divider :key="`${index}-stepdivider`"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(n, index) in this.getSteps"
          :step="index"
          :key="index"
        >
          <span>{{ n.com }}</span>

          <v-text-field
            v-if="!rightArrowHidden() && getButtons(n.ti)"
            label="Commentaire pour la prochaine étape"
            color="green"
            style="margin-top: 10px"
            v-model="comment"
            :disabled="getIsAssistance()"
            clearable
          ></v-text-field>

          <div v-if="!rightArrowHidden()">
            <v-btn
              v-for="(b, bindex) in getButtons(n.ti)"
              :key="bindex"
              color="green"
              style="margin-right: 5px; color: white"
              :disabled="getIsAssistance()"
              @click="upSelect(b)"
            >
              {{ b }}
            </v-btn>
            <v-btn
              text
              v-if="index != 0"
              @click="dialogCancel = true"
              :disabled="getIsAssistance()"
            >
              Retour
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialogCancel" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Retour à l'étape précédente
        </v-card-title>

        <v-card-text>
          Si l'étape possède un commentaire il sera perdu, êtes vous sûrs de
          retourner à l'étape précédente ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogCancel = false">
            Annuler
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="
              cancel();
              dialogCancel = false;
            "
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text
          >{{ dialogExp }}
          <v-textarea
            name="input-7-1"
            color="green"
            v-model="dialogComment"
            row-height="5"
            value=""
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog(false)">
            Annuler
          </v-btn>
          <v-btn color="green darken-1" text @click="closeDialog(true)">
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-follow",
  data() {
    return {
      e1: 0,
      componentKey: 1,
      comment: "",
      dialogComment: "",
      dialogTitle: "",
      dialogExp: "",
      dialog: false,
      dialogCancel: false,
    };
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDate(date);
    },

    getIsAssistance() {
      return store.getters["authentification/getIsAssistance"];
    },

    clear() {
      this.comment = "";
      this.dialogComment = "";
    },

    cancel() {
      if (this.e1 != 0) {
        this.e1 = this.e1 - 1;
      }

      var obj = this.getTicket;
      if (
        obj &&
        obj.ticketFollowUp &&
        obj.ticketFollowUp.type &&
        obj.ticketFollowUp.steps
      ) {
        var st = JSON.parse(obj.ticketFollowUp.steps);
        st.pop();
        this.updateStTicket(st);
      }
      this.forceRerender();
    },

    updateStTicket(steps_ar) {
      var obj = this.getTicket;
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.type) {
        var ba = store.getters["ticketsV2Filters/getData"];
        if (ba && ba.data && ba.data.folders && ba.data.folders.rows) {
          const target = steps_ar[steps_ar.length - 1].ti.toUpperCase();
          const fo = ba.data.folders.rows.find((el) => el.name == target);
          if (fo) {
            obj.traite = fo.traiteValue;
            obj.aSupprimer = fo.deleteValue;

            Vue.set(this.getTicket, "traite", fo.traiteValue);
            Vue.set(this.getTicket, "aSupprimer", fo.deleteValue);
          } else {
            //console.log("ERROR NOT FOUND - updateStTicket - ", fo);
          }
        }
        obj.ticketFollowUp.steps = JSON.stringify(steps_ar);
        Vue.set(obj.ticketFollowUp, "steps", JSON.stringify(steps_ar));
      }
    },

    verifComment(elm) {
      this.dialog = true;
      this.dialogTitle = elm;
      this.dialogExp = "Merci d'expliquer la raison de ce choix.";
      this.dialogComment = "";
    },

    closeDialog(bo) {
      if (bo && this.dialogComment.length > 0) {
        this.comment = this.dialogComment;
        this.upSelect(this.dialogTitle);
        this.dialog = false;
      } else {
        this.dialog = false;
      }
    },

    upSelect(elm) {
      if (
        elm == "Mauvaise idée" ||
        elm == "Clôturer" ||
        elm == "En production"
      ) {
        if (this.comment.length == 0) {
          this.verifComment(elm);
          return;
        }
      }

      this.e1 = this.e1 + 1;
      var startObj = [
        {
          ti: "Nouveaux",
          com: "",
          da: Date.now(),
        },
      ];

      var newObj = {
        ti: elm,
        com: this.comment,
        da: Date.now(),
      };

      var obj = this.getTicket;
      if (!obj.ticketFollowUp.steps) {
        obj.ticketFollowUp.steps = JSON.stringify(startObj);
      }

      if (
        obj &&
        obj.ticketFollowUp &&
        obj.ticketFollowUp.type &&
        obj.ticketFollowUp.steps
      ) {
        var st = JSON.parse(obj.ticketFollowUp.steps);
        st.push(newObj);
        this.updateStTicket(st);
      } else {
        startObj.push(newObj);
        if (obj.ticketFollowUp.type == "Idée") {
          obj.ticketFollowUp.steps = JSON.stringify(startObj);
        } else if (obj.ticketFollowUp.type == "Bug") {
          obj.ticketFollowUp.steps = JSON.stringify(startObj);
        } else if (obj.ticketFollowUp.type == "Question") {
          obj.ticketFollowUp.steps = JSON.stringify(startObj);
        }
      }
      this.comment = "";
      this.forceRerender();
    },

    getButtons(title) {
      var obj = this.getTicket;

      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.type) {
        if (obj.ticketFollowUp.type == "Idée") {
          var bt = {
            Nouveaux: ["À Discuter", "À Pondérer", "À Étudier", "Clôturer"],
            "À Discuter": [
              "À Pondérer",
              "À Étudier",
              "À faire",
              "Bonne idée",
              "Mauvaise idée",
              "Clôturer",
            ],
            "À Pondérer": [
              "À Discuter",
              "À Étudier",
              "À faire",
              "Bonne idée",
              "Mauvaise idée",
              "Clôturer",
            ],
            "À Étudier": [
              "À Pondérer",
              "À Discuter",
              "À faire",
              "Bonne idée",
              "Mauvaise idée",
              "Clôturer",
            ],
            "Bonne idée": ["À faire", "Clôturer"],
            "À faire": ["Prise en charge", "Clôturer"],
            "Prise en charge": [
              "En cours de développement",
              "À Tester",
              "Clôturer",
            ],
            "À Tester": ["En production", "Clôturer"],
            "En cours de développement": [
              "À Tester",
              "En production",
              "Clôturer",
            ],
          };
        }

        if (obj.ticketFollowUp.type == "Bug") {
          var bt = {
            Nouveaux: [
              "Prise en charge",
              "En cours de développement",
              "Clôturer",
            ],
            "Prise en charge": [
              "En cours de développement",
              "À Tester",
              "Clôturer",
            ],
            "En cours de développement": ["À Tester", "Clôturer"],
            "À Tester": ["Clôturer"],
          };
        }

        if (obj.ticketFollowUp.type == "Question") {
          var bt = {
            Nouveaux: ["Réponse", "Clôturer"],
            Réponse: ["Clôturer"],
          };
        }
      }
      return bt[title];
    },

    forceRerender() {
      this.componentKey += 1;
    },

    setStep(nb) {
      this.e1 = nb;
      this.forceRerender();
    },

    leftArrowAction() {
      if (this.e1 > 0) {
        this.e1 = this.e1 - 1;
      }
    },

    rightArrowAction() {
      if (this.e1 + 1 < this.getSteps.length) {
        this.e1 = this.e1 + 1;
      }
    },

    leftArrowHidden() {
      if (this.e1 - 1 >= 0) {
        return true;
      }
      return false;
    },

    rightArrowHidden() {
      if (this.e1 + 1 < this.getSteps.length) {
        return true;
      }
      return false;
    },
  },
  computed: {
    getSteps() {
      var ar = [];
      var newobj = [
        {
          ti: "Nouveaux",
          com: "",
          da: Date.now(),
        },
      ];

      var obj = this.getTicket;
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.type) {
        if (obj.ticketFollowUp.steps) {
          var ar_steps = JSON.parse(obj.ticketFollowUp.steps);
          this.setStep(ar_steps.length - 1);
          return ar_steps;
        } else {
          if (obj.ticketFollowUp.type == "Idée") {
            ar = newobj;
          }
          if (obj.ticketFollowUp.type == "Bug") {
            ar = newobj;
          }
          if (obj.ticketFollowUp.type == "Question") {
            ar = newobj;
          }
          this.setStep(0);
          return ar;
        }
      }
      return [];
    },

    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.clear();
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.backgroundComponent {
  background-color: #fafafa;
}
</style>
