<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfileSecu.vue
@Last modified by:   florian
@Last modified time: 06-Nov-2020
-->

<template>
  <div class="md-layout">
    <!--div class="md-layout-item md-small-size-100 md-size-70">
      <md-content class="md-content-action">
        <div class="sizeFontAction"> Forcer la réinitialisation du mot de passe MS4</div>
      </md-content>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30 text-right">
      <md-content class="md-content-action-button">
        <md-button class="md-raised md-success" disabled>Forcer</md-button>
      </md-content>
    </div>

    <div class="md-layout-item md-small-size-100 md-size-70">
      <md-content class="md-content-action">
        <div class="sizeFontAction">Bloqué le compte</div>
      </md-content>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30 text-right">
      <md-content class="md-content-action-button">
        <md-button class="md-raised md-success" disabled>Bloquer</md-button>
      </md-content>
    </div>


    <div class="md-layout-item md-small-size-100 md-size-70">
      <md-content class="md-content-action">
        <div class="sizeFontAction">Mot de passe ASIP</div>
      </md-content>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30 text-right">
      <md-content class="md-content-action-button">
        <md-button class="md-raised md-success" disabled>Bloquer</md-button>
      </md-content>
    </div>-->

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <md-checkbox v-model="forcemdp"
            >Forcer la réinitialisation du mot de passe MS4</md-checkbox
          >
          <md-divider />
          <md-checkbox v-model="bloquerCompte">Compte bloqué</md-checkbox>
          <md-divider />
          <md-checkbox v-model="mdpasip"
            >Mot de passe ASIP (8 car. min dont au moins 1 majuscule, 1
            minuscule, 1 chiffre, 1 car. spécial)</md-checkbox
          >
          <md-divider class="marginTop10" />

          <md-field>
            <md-checkbox v-model="avert" class="marginTop10"></md-checkbox>
            <label class="marginLeftCheck"
              >Nombre de tentatives de connexion avant avertissement</label
            >
            <md-input
              v-model="avertnb"
              type="number"
              class="marginLeftCheck"
            ></md-input>
          </md-field>

          <md-field class="marginTopM10">
            <md-checkbox v-model="block" class="marginTop10"></md-checkbox>
            <label class="marginLeftCheck"
              >Nombre de tentatives de connexion avant blockage</label
            >
            <md-input
              v-model="blocknb"
              type="number"
              class="marginLeftCheck"
            ></md-input>
          </md-field>

          <md-field class="marginTopM10">
            <md-checkbox v-model="forcage" class="marginTop10"></md-checkbox>
            <label class="marginLeftCheck"
              >Nombre de jours avant forcage de mise à jour</label
            >
            <md-input
              v-model="forcagenb"
              type="number"
              class="marginLeftCheck"
            ></md-input>
          </md-field>

          <md-field class="marginTopM10">
            <md-checkbox v-model="reu" class="marginTop10"></md-checkbox>
            <label class="marginLeftCheck"
              >Nombre de jours d'empêchement de réutilisation des mots de
              passe</label
            >
            <md-input
              v-model="reunb"
              type="number"
              class="marginLeftCheck"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-raised md-prokov" disabled
            >Enregistrer</md-button
          >
        </div>
      </div>
    </md-card-content>
  </div>
</template>
<script>
export default {
  name: "edit-profile-action",
  data() {
    return {
      couriel: null,
      confirmecouriel: null,
      mdp: null,
      confirmemdp: null,
      mdpMS4: null,
      confirmeMS4: null,
      forcemdp: false,
      bloquerCompte: false,
      mdpasip: false,
      avert: false,
      avertnb: 3,
      block: false,
      blocknb: 5,
      forcage: false,
      forcagenb: 180,
      reu: false,
      reunb: 365,
      question1: null,
      response1: null,
      question2: null,
      response2: null,
      question3: null,
      response3: null,
      code: "Aucun",
    };
  },
};
</script>

<style lang="scss">
.md-content-action {
  height: 50px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-align: left;
}

.sizeFontAction {
  font-size: px;
}

.md-content-action-button {
  height: 50px;
  width: 100%;
}

.lowHeight {
  height: 35px !important;
}

.titleUserSecu {
  margin-top: -10px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginLeftCheck {
  margin-left: 30px !important;
}

.marginTopM10 {
  margin-top: -20px !important;
}

.secure {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  //filter : blur(30px);
}
</style>
