/**
 * @Author: Florian Merel <florian>
 * @Date:   26-Oct-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: index.js
 * @Last modified by:   florian
 * @Last modified time: 02-Nov-2020
 */

import Sidebar from "./SideBar.vue";
import SidebarLink from "./SidebarLink.vue";

const SidebarStore = {
  showSidebar: false,
  displaySidebar(value) {
    this.showSidebar = value;
  },
};

const SidebarPlugin = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          sidebarStore: SidebarStore,
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$sidebar", {
      get() {
        return this.$root.sidebarStore;
      },
    });
    Vue.component("side-bar", Sidebar);
    Vue.component("sidebar-link", SidebarLink);
  },
};

export default SidebarPlugin;
