<!--
@Author: Florian Merel <florian>
@Date:   25-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: FoldersTable.vue
@Last modified by:   florian
@Last modified time: 20-Jan-2021
-->

<template>
  <div>
    <md-dialog-confirm
      :md-active.sync="alert.active"
      md-title="Êtes-vous sûr de vouloir supprimer ?"
      md-content="Une fois supprimé les tickets contenues seront déportés dans le dossier 'NOUVEAUX'."
      md-confirm-text="Supprimer"
      md-cancel-text="Annuler"
      @md-cancel="onCancel"
      @md-confirm="onConfirm"
    />

    <md-table v-if="folders && folders.folders" v-model="folders.folders.rows">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell
          ><md-button
            v-if="item.deletePossible"
            class="md-just-icon md-simple md-danger"
            @click="dialogsOpen(item)"
          >
            <md-icon>arrow_back</md-icon>
            <md-tooltip md-direction="top">Supprimer</md-tooltip>
          </md-button></md-table-cell
        >

        <md-table-cell md-label="Nom">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Url">{{ item.url }}</md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="folders && folders.folders && folders.folders.rows.length == 0">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center">Aucun ticket trouvé.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
//import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "folders-table",
  data() {
    return {
      selected: [],
      badges: [],
      selectItem: null,
      alert: {
        active: false,
        message: "",
      },
    };
  },
  methods: {
    dialogsOpen(item) {
      this.alert.active = true;
      this.selectItem = item;
    },
    onConfirm() {
      this.alert.active = true;
      store.dispatch("tickets/serviceDeleteFolder", {
        folder: this.selectItem,
      });
    },
    onCancel() {
      this.alert.active = true;
    },
  },
  computed: {
    folders() {
      let res = store.getters["tickets/getFolders"];
      return res;
    },
  },
  beforeMount() {
    /*if (this.folders == null) {
      store.dispatch("tickets/serviceGetFolder");
    }*/
  },
};
</script>

<style lang="scss">
.emailTicket {
  margin-bottom: 5px;
  font-size: 12px;
  color: gray;
}

.dateTicket {
  margin-bottom: 5px;
  font-size: 12px;
  color: #e2007a;
  opacity: 0.5;
}

.colorTextProkov {
  color: #e2007a;
  opacity: 0.5;
}

.typeTicket {
  font-size: 12px;
  color: gray;
}

.commentText {
  font-style: italic;
}
</style>
