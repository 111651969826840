import { render, staticRenderFns } from "./TicketsData.vue?vue&type=template&id=48594191&scoped=true&"
import script from "./TicketsData.vue?vue&type=script&lang=js&"
export * from "./TicketsData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48594191",
  null
  
)

export default component.exports