<!--
@Author: Florian Merel <florian>
@Date:   02-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Login.vue
@Last modified by:   florian
@Last modified time: 14-Dec-2020
-->

<template>
  <div class="centered-container">
    <md-dialog-alert
      :md-active.sync="alert.active"
      :md-content="alert.message"
      md-title="Erreur de connexion"
      md-confirm-text="Ok"
    />

    <md-content class="md-elevation-3">
      <div class="title">
        <img src="../assets/img/logo.png" />
        <div class="md-title">EtherEgo</div>
        <div class="md-body-1">Site réservé aux administrateurs.</div>
      </div>

      <div class="form">
        <md-field>
          <label>Identifiant</label>
          <md-input
            v-on:keyup.enter="onEnter"
            v-model="login.email"
            autofocus
          ></md-input>
        </md-field>

        <md-field md-has-password>
          <label>Mot de passe</label>
          <md-input
            v-on:keyup.enter="onEnter"
            v-model="login.password"
            type="password"
          ></md-input>
        </md-field>
      </div>

      <div class="actions md-layout center-element">
        <md-button class="md-raised md-prokov" @click="launchLogin"
          >Se connecter</md-button
        >
      </div>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-stroke="2"
        ></md-progress-spinner>
      </div>
    </md-content>
    <div class="background" />
    <div class="drawRectTop" />
    <div class="drawRectBot" />
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "App",
  data() {
    return {
      loading: false,
      alert: {
        active: false,
        message: "",
      },
      login: {
        email: "",
        password: "",
      },
    };
  },
  created: function () {
    this.getLocalToken();
  },
  methods: {
    launchLogin() {
      if (this.login.email.length == 0 || this.login.password.length == 0) {
        this.alert.message = "Merci de remplir tous les champs.";
        this.alert.active = true;
        return;
      }
      this.loading = true;
      store
        .dispatch("authentification/login", {
          login: this.login.email,
          password: this.login.password,
        })
        .then(
          (response) => {
            this.loading = false;
            if (
              response.data &&
              response.data.success &&
              response.data.data.token
            ) {
              store.commit(
                "authentification/setToken",
                response.data.data.token
              );
              this.$router.push("/dashboard");
            }
          },
          (error) => {
            this.loading = false;
            this.alert.message =
              "Une erreur inattendue est survenue, merci de contacter un administrateur";
            if (error.data && error.data.data && error.data.data.code) {
              switch (error.data.data.code) {
                case 2.1:
                  this.alert.message =
                    "Vous avez saisi un identifiant ou un mot de passe invalide veuillez les ressaisir.";
                  break;
                case 1.2:
                  this.alert.message =
                    "Vous avez saisi un identifiant ou un mot de passe invalide veuillez les ressaisir.";
                  break;
              }
            }
            this.alert.active = true;
          }
        );
    },
    onEnter: function () {
      this.launchLogin();
    },

    getLocalToken() {
      var decodedToken = localStorage.getItem("token");

      // Verif
      if (decodedToken == null) {
      } else {
        store.dispatch("authentification/getTokenIsValid").then(
          (response) => {
            if (response && response.success) {
              this.$router.push("/dashboard");
            }
          },
          (error) => {}
        );
      }
    },
  },
};
</script>
