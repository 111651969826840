<!--
@Author: Florian Merel <florian>
@Date:   01-Dec-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: UsersInscriptionTable.vue
@Last modified by:   florian
@Last modified time: 09-Jul-2021
-->

<template>
  <div>
    <md-table v-if="usersInscription" v-model="usersInscription">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell v-if="item.email != null" md-label="ID" md-numeric>{{
          item.id
        }}</md-table-cell>
        <md-table-cell
          v-if="item.email != null"
          md-label="Email"
          md-sort-by="email"
          >{{ item.email }}</md-table-cell
        >
        <md-table-cell
          v-if="item.email != null"
          md-label="Nom"
          md-sort-by="name"
          >{{ item.familyname }}</md-table-cell
        >
        <md-table-cell v-if="item.email != null" md-label="Prénom">{{
          item.givenname
        }}</md-table-cell>
        <md-table-cell v-if="item.email != null" md-label="Date">{{
          getDateInscription(item.timeStamp)
        }}</md-table-cell>
        <md-table-cell v-if="item.email != null">
          <div class="md-layout-item md-size-100">
            <md-button class="md-raised md-success" @click="resendInsc(item)">
              Renvoyer</md-button
            >
            <md-button class="md-raised md-danger" @click="deleteOpen(item)">
              Supprimer</md-button
            >
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="usersInscription == null || usersInscription.length == 0">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center">Aucune inscription en cours.</div>
        </div>
      </div>
    </div>

    <md-dialog-confirm
      :md-active.sync="active"
      md-title="Êtes-vous sûr de vouloir supprimer ?"
      md-content="Une fois supprimé l'utilisateur devra recommencer son inscription."
      md-confirm-text="Supprimer"
      md-cancel-text="Annuler"
      @md-confirm="deleteInsc"
    />

    <md-dialog-alert
      :md-active.sync="activeLink"
      md-title="Voici le lien d'activation"
      :md-content="link"
    />
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "users-inscription-table",
  data: () => ({
    search: null,
    activeLink: false,
    active: false,
    emailSelect: null,
    link: "",
    searched: [],
  }),
  methods: {
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    },
    async resendInsc(item) {
      this.emailSelect = item.email;
      const res = await store.dispatch(
        "usersInscription/reSendLinkInscription",
        { email: this.emailSelect }
      );
      if (res) {
        store.dispatch("notification/sendNotification", {
          message: "Mail envoyé",
          icon: "verified_user",
          type: "success",
        });
      }
    },
    async deleteInsc() {
      const res = await store.dispatch("usersInscription/removeInscription", {
        email: this.emailSelect,
      });
      if (res) {
        store.commit("usersInscription/removeEmail", this.emailSelect);
      }
    },
    infoInsc(item) {
      this.link = item.link;
      this.activeLink = true;
    },
    deleteOpen(item) {
      this.active = true;
      this.emailSelect = item.email;
    },
    getDateInscription(date) {
      return toolsFormat.getDateInscription(date);
    },
  },
  computed: {
    usersInscription: {
      get() {
        const res = store.getters["usersInscription/getData"];
        if (res == null || res.data == null) {
          return null;
        }
        return res.data;
      },
      set(value) {
        this.actif = true;
        this.commentData = value;
      },
    },
  },
  beforeMount() {
    if (this.usersInscription == null) {
      store.dispatch("usersInscription/usersInscriptionList");
    }
  },
};
</script>

<style lang="scss">
.buttonMd {
  font-size: 11px;
}
</style>
