<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 01-Jul-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100 md-size-100">
      <md-card>
        <md-card-content>
          <div>
            <md-tabs class="md-info" md-alignment="left">
              <md-tab id="tab-soc" md-label="Sociétés">
                <company></company>
              </md-tab>
              <md-tab id="tab-tech" md-label="Techniciens">
                <technician></technician>
              </md-tab>
            </md-tabs>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import { Company, Technician } from "@/components";

export default {
  components: {
    Company,
    Technician,
  },
  name: "LicencesList",
  data: () => ({
    query: "",
  }),
  computed: {},
  created: function () {},
  methods: {},
};
</script>
