<!--
@Author: Florian Merel <florian>
@Date:   27-Jan-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: StoryCard.vue
@Last modified by:   florian
@Last modified time: 29-Jan-2021
-->

<template>
  <div>
    <md-card
      v-for="act in activity"
      :key="act.id"
      style="margin-top: -40px; margin-bottom: 10px"
    >
      <md-card-content>
        <div class="layout">
          <div class="md-layout" style="margin-top: -10px">
            <div
              class="md-layout-item md-size-5 md-alignment-center-center"
              style="display: table-cell; vertical-align: middle"
            >
              <md-avatar
                v-if="act.source == 'Created'"
                class="md-avatar-icon md-small md-info"
                ><md-icon>create_new_folder</md-icon></md-avatar
              >

              <md-avatar
                v-if="act.source == 'PublicQuestion'"
                class="md-avatar-icon md-small md-success"
                ><md-icon>contact_support</md-icon></md-avatar
              >

              <md-avatar
                v-if="act.source == 'PublicResponse'"
                class="md-avatar-icon md-small md-warning"
                ><md-icon>chat</md-icon></md-avatar
              >

              <md-avatar
                v-if="act.source == 'Update'"
                class="md-avatar-icon md-small md-info"
                ><md-icon>sync_alt</md-icon></md-avatar
              >

              <md-avatar
                v-if="act.source == 'ClosureResponse'"
                class="md-avatar-icon md-small md-danger"
                ><md-icon>subtitles_off</md-icon></md-avatar
              >

              <md-avatar
                v-if="act.source == 'Commentaire'"
                class="md-avatar-icon md-small md-success"
                ><md-icon>insert_comment</md-icon></md-avatar
              >
            </div>

            <div
              v-if="act.source == 'Update'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span class="spanInfo"
                >{{ act.userLogin }} a mis à jour le ticket</span
              >
            </div>

            <div
              v-if="act.source == 'Created'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span class="spanInfo">Création du ticket</span>
            </div>

            <div
              v-if="act.source == 'PublicQuestion'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span class="spanInfo"
                >{{ act.userLogin }} a posé une question à l'utilisateur</span
              >
            </div>

            <div
              v-if="act.source == 'ClosureResponse'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span>{{ act.userLogin }} a répondu à l'utilisateur</span>
            </div>

            <div
              v-if="act.source == 'PublicResponse'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span class="spanInfo">Réponse de la part de l'utilisateur</span>
            </div>

            <div
              v-if="act.source == 'Commentaire'"
              class="md-layout-item md-alignment-center-center md-size-75"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <span class="spanInfo">{{ act.userLogin }} - Commentaire</span>
            </div>

            <div
              class="md-layout-item md-alignment-center-center md-size-20"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <div class="dateTicket">
                {{ getDateLong(act.createdAt) }}
              </div>
            </div>

            <div
              class="md-layout-item md-alignment-center-center md-size-5"
            ></div>

            <div
              v-if="act.comment"
              class="md-layout-item md-alignment-center-center md-size-95"
              style="margin-top: -10px; margin-bottom: -10px"
            >
              {{ act.comment }}
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "activity-card",
  props: ["activity"],
  methods: {
    getDateLong(date) {
      return toolsFormat.getDateLong(date);
    },
  },
};
</script>

<style lang="scss">
.spanInfo {
  color: gray;
  font-size: 12px;
}
</style>
