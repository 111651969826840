<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Type.vue
@Last modified by:   florian
@Last modified time: 20-Sep-2021
-->

<template>
  <div v-if="getTicket != null" class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      style="margin-top: 10px"
    >
      <div class="md-body-2" color="primary">
        Détail de toutes les étapes

        <v-btn
          v-if="clp"
          style="margin-left: 20px"
          color="primary"
          v-on:click="collapse"
          elevation="0"
          x-small
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          v-else
          style="margin-left: 20px"
          color="primary"
          v-on:click="collapse"
          elevation="0"
          x-small
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>

      <div class="md-layout-item md-medium-size-100 md-size-100">
        <v-container>
          <v-timeline dense clipped>
            <v-timeline-item
              class="mb-4"
              :color="getColor(n.source)"
              icon-color="grey lighten-2"
              small
              v-for="n in getActivities"
              :key="n.id"
            >
              <!--{{ getDate(n.createdAt) }}-->
              <span v-if="n.userLogin">{{ n.userLogin }} - </span>
              <span>{{ getText(n.source) }} - {{ getDate(n.createdAt) }}</span>

              <v-divider></v-divider>
              <span> {{ n.comment }}</span>
            </v-timeline-item>
          </v-timeline>
        </v-container>
      </div>
    </div>
    <!--               :icon="getIcon(n.source)" -->
    <v-container fluid>
      <v-textarea
        outlined
        name="input-7-4"
        color="primary"
        label="Commentaire"
        v-model="comment"
      ></v-textarea>
    </v-container>

    <v-row style="margin-top: -45px; margin-bottom: 50px">
      <v-col> </v-col>
      <v-spacer></v-spacer>
      <v-col style="margin-right: 10px">
        <v-btn
          class="white--text"
          color="primary accent-4"
          style="float: right"
          v-on:click="add"
          depressed
        >
          Ajouter
        </v-btn>
        <v-btn
          class="white--text"
          color="primary accent-4"
          style="float: right"
          v-on:click="clear"
          text
        >
          <v-icon dark> delete </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "tickets-steps",
  data() {
    return {
      comment: "",
      clp: false,
    };
  },
  methods: {
    clear() {
      this.comment = "";
    },

    onEnter() {
      this.add();
    },

    async add() {
      var obj = this.getTicket;
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.id) {
        var params = { id: obj.ticketFollowUp.id };
        var data = {};
        data.comment = this.comment;
        data.publicAct = false;
        data.deletePossible = false;
        data.source = "Commentaire";

        params.data = data;
        var res = await store.dispatch("activity/postActivity", params);

        if (res) {
          obj.ticketActivities = res.data.activity.rows;
        }
        this.comment = "";
      }
    },

    collapse() {
      this.clp = !this.clp;
    },

    getCollapse() {
      return this.clp;
    },

    getDate(date) {
      return toolsFormat.getDateText(date);
    },

    getColor(source) {
      var color = {
        Created: "green",
        PublicQuestion: "purple",
        PublicResponse: "yellow",
        Update: "blue",
        ClosureResponse: "orange",
        Commentaire: "red",
      };
      return color[source];
    },

    getText(source) {
      var text = {
        Created: "Création du ticket",
        PublicQuestion: "Question à l'utilisateur",
        PublicResponse: "Réponse à l'utilisateur",
        Update: "Mise à jour du ticket",
        ClosureResponse: "Réponse fermer à l'utilisateur",
        Commentaire: "Commentaire",
      };
      return text[source];
    },

    getIcon(source) {
      var logo = {
        Created: "mdi-pencil",
        PublicQuestion: "mdi-message-processing",
        PublicResponse: "mdi-message-processing",
        Update: "mdi-update",
        ClosureResponse: "mdi-message-processing",
        Commentaire: "mdi-message-processing",
      };

      return logo[source];
    },
  },
  computed: {
    getActivities() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];

      var act = [];

      if (obj.ticketActivities) {
        act = obj.ticketActivities;
      }

      act = act.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      if (act.length == 0 || (act.length > 0 && act[0].source != "Created")) {
        var created = {
          createdAt: obj.date,
          deletePossible: false,
          source: "Created",
        };
        act.unshift(created);
      }

      if (!this.clp) {
        act = act.slice(-2);
      }

      return act;
    },

    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.clear();
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
