<!--
@Author: Florian Merel <florian>
@Date:   09-Jul-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: RightPanel.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div>
    <div class="list">
      <md-list>
        <md-list-item to="/createdTicket" class="margLeft">
          <md-icon>add</md-icon>
          <span class="md-list-item-text">Créer un ticket</span>
        </md-list-item>

        <md-divider class="margLeft"></md-divider>

        <md-list-item v-on:click="refreshButton()" class="margLeft">
          <md-icon>refresh</md-icon>
          <span
            class="md-list-item-text"
            style="text-transform: uppercase; font-size: 12px"
            >Actualiser</span
          >
        </md-list-item>

        <md-divider class="margLeft"></md-divider>

        <md-list-item
          v-bind:class="
            getSelectFilters('3a4645be-ebb6-11eb-9a03-0242ac130002')
          "
          to="3a4645be-ebb6-11eb-9a03-0242ac130002"
          class="margLeft"
        >
          <span
            class="md-list-item-text"
            style="text-transform: uppercase; font-size: 12px"
            >Notifications</span
          >
          <v-avatar color="prokov" size="25">
            <span class="white--text">{{ getNotification }}</span>
          </v-avatar>
        </md-list-item>

        <md-divider class="margLeft"></md-divider>
        <md-list-item
          v-for="filters in getFilters"
          class="margLeft"
          v-bind:key="filters.id"
          :to="filters.id"
          v-bind:class="getSelectFilters(filters.id)"
          >{{ filters.title }}</md-list-item
        >
        <md-divider class="margLeft"></md-divider>

        <md-list-item
          v-for="filters in getFiltersPerso"
          class="margLeft"
          v-bind:key="filters.id"
          :to="filters.id"
          v-bind:class="getSelectFilters(filters.id)"
          >{{ filters.title }}</md-list-item
        >

        <md-divider class="margLeft"></md-divider>

        <!--<md-list-item class="margLeft" md-expand>
          <md-icon>mail</md-icon>
          <span class="md-list-item-text md-body-1">Expéditeur</span>
          <md-list slot="md-expand">
            <md-list-item class="margLeft">
              <span class="md-list-item-text md-caption">INTERNE</span>
              <md-checkbox v-model="interne" class="md-prokov"></md-checkbox>
            </md-list-item>

            <md-list-item class="margLeft">
              <span class="md-list-item-text md-caption">ASSISTANCE</span>
              <md-checkbox v-model="assistance" class="md-prokov"></md-checkbox>
            </md-list-item>
          </md-list>
        </md-list-item>-->

        <md-list-item class="margLeft" md-expand>
          <md-icon>dashboard</md-icon>
          <span class="md-list-item-text md-body-1">Catégories</span>
          <md-list slot="md-expand">
            <md-list-item
              v-for="(folder, key) in getFolders"
              v-bind:key="key"
              class="margLeft"
            >
              <span class="md-list-item-text md-caption">{{
                folder.name
              }}</span>
              <md-checkbox
                v-model="checkValue[key]"
                class="md-prokov"
              ></md-checkbox>
            </md-list-item>
          </md-list>
        </md-list-item>

        <md-list-item class="margLeft" md-expand>
          <md-icon>badge</md-icon>
          <span class="md-list-item-text md-body-1">Badges</span>
          <md-list slot="md-expand">
            <md-list-item class="margLeft" md-expand>
              <md-icon>person</md-icon>
              <span class="md-list-item-text md-body-1">Personnes</span>
              <md-list slot="md-expand">
                <md-list-item
                  v-for="(folder, key) in getBadgesTypes('Personnes')"
                  v-bind:key="key"
                  class="margLeft"
                >
                  <span class="md-list-item-text md-caption">{{
                    folder.name
                  }}</span>
                  <md-checkbox
                    v-model="personnesBadge[key]"
                    class="md-prokov"
                  ></md-checkbox>
                </md-list-item>
              </md-list>
            </md-list-item>

            <md-list-item class="margLeft" md-expand>
              <md-icon>apps</md-icon>
              <span class="md-list-item-text md-body-1">Applications</span>
              <md-list slot="md-expand">
                <md-list-item
                  v-for="(folder, key) in getBadgesTypes('Logiciels')"
                  v-bind:key="key"
                  class="margLeft"
                >
                  <span class="md-list-item-text md-caption">{{
                    folder.name
                  }}</span>
                  <md-checkbox
                    v-model="logicielsBadge[key]"
                    class="md-prokov"
                  ></md-checkbox>
                </md-list-item>
              </md-list>
            </md-list-item>

            <md-list-item class="margLeft" md-expand>
              <md-icon>category</md-icon>
              <span class="md-list-item-text md-body-1">Catégories</span>
              <md-list slot="md-expand">
                <md-list-item
                  v-for="(folder, key) in getBadgesTypes('Catégories')"
                  v-bind:key="key"
                  class="margLeft"
                >
                  <span class="md-list-item-text md-caption">{{
                    folder.name
                  }}</span>
                  <md-checkbox
                    v-model="categoriesBadge[key]"
                    class="md-prokov"
                  ></md-checkbox>
                </md-list-item>
              </md-list>
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>

      <md-list-item class="margLeft" v-if="size > 0">
        <v-pagination v-model="page" :length="size"></v-pagination>
      </md-list-item>
    </div>
  </div>
</template>

<script>
import toolsFormat from "@/tools/toolsFormat.js";
import store from "@/store";
import Vue from "vue";

export default {
  name: "tickets-left-panel",
  data() {
    return {
      checkValue: [],
      personnesBadge: [],
      categoriesBadge: [],
      logicielsBadge: [],
      oldSearch: {},
      interne: false,
      assistance: false,
      size: 0,
      page: 1,
    };
  },
  methods: {
    async refreshButton() {
      try {
        const result = await store.dispatch(
          "ticketsV2Filters/postTicketsFilter",
          this.oldSearch
        );
        if (
          result &&
          result.data &&
          result.data.tickets &&
          result.data.tickets.count
        ) {
          this.size = Math.round(result.data.tickets.count / 40);
        }
      } catch (rejectedValueOrSerializedError) {}
    },

    getSelectFilters(id) {
      if (id == this.$route.params.filters) {
        return "selectColor";
      }
      return "noselectColor";
    },

    folderCheckboxInit(obj) {
      if (obj && obj.data && obj.data.folders && obj.data.folders.rows) {
        obj.data.folders.rows.forEach((folder, index) => {
          if (this.checkValue[index] == undefined) {
            this.checkValue[index] = false;
          }
        });
      }
      this.refreshTickets();
    },

    async refreshTickets() {
      if (this.$route.params.filters) {
        var obj = {};
        obj.id = this.$route.params.filters;
        obj.data = {};
        obj.data.offset = (this.page - 1) * 40;
        obj.data.cats = [];
        obj.data.badges = [];
        // GET CAT
        var ba = store.getters["ticketsV2Filters/getData"];

        if (ba && ba.data && ba.data.folders && ba.data.folders.rows) {
          ba.data.folders.rows.forEach((folder, index) => {
            if (this.checkValue[index] === true) {
              obj.data.cats.push(folder.id);
            }
          });
        }

        if (ba && ba.data && ba.data.badges && ba.data.badges.rows) {
          this.getBadges
            .filter((ob) => ob.cat == "Personnes")
            .forEach((folder, index) => {
              if (this.personnesBadge[index] === true) {
                obj.data.badges.push(folder.name);
              }
            });

          this.getBadges
            .filter((ob) => ob.cat == "Logiciels")
            .forEach((folder, index) => {
              if (this.logicielsBadge[index] === true) {
                obj.data.badges.push(folder.name);
              }
            });

          this.getBadges
            .filter((ob) => ob.cat == "Catégories")
            .forEach((folder, index) => {
              if (this.categoriesBadge[index] === true) {
                obj.data.badges.push(folder.name);
              }
            });
        }

        if (JSON.stringify(obj) != JSON.stringify(this.oldSearch)) {
          try {
            const result = await store.dispatch(
              "ticketsV2Filters/postTicketsFilter",
              obj
            );
            if (
              result &&
              result.data &&
              result.data.tickets &&
              result.data.tickets.count != null
            ) {
              this.size = Math.round(result.data.tickets.count / 40);
            }
          } catch (rejectedValueOrSerializedError) {}

          this.oldSearch = obj;
        }
      }
    },

    updatePage() {
      this.size = 0;
    },

    getBadgesTypes(type) {
      var obj = this.getBadges;
      obj = obj.filter((ob) => ob.cat == type);
      return obj;
    },
  },
  computed: {
    getTickets() {
      var obj = store.getters["ticketsV2Filters/getDataTickets"](
        this.$route.params.filters
      );

      if (
        this.$parent &&
        this.$parent.$refs &&
        this.$parent.$refs.searchbar &&
        this.$parent.$refs.searchbar.query
      ) {
        var query = this.$parent.$refs.searchbar.query;
        if (query && query.length != 0) {
          var re = store.getters["ticketsV2Filters/getDataTickets"](query);
          if (re) {
            obj = re;
          }
          this.updatePage();
        }
      }
      return obj;
    },

    getFilters() {
      var obj = store.getters["ticketsV2Filters/getData"];
      if (obj && obj.data && obj.data.filters) {
        return obj.data.filters.rows.filter(
          (li) => li.target && li.target == "global"
        );
      }
      return [];
    },

    getFiltersPerso() {
      var obj = store.getters["ticketsV2Filters/getData"];
      if (obj && obj.data && obj.data.filters) {
        return obj.data.filters.rows.filter(
          (li) => li.target && li.target == "perso"
        );
      }
      return [];
    },

    getFolders() {
      var obj = store.getters["ticketsV2Filters/getData"];
      if (obj && obj.data && obj.data.folders) {
        this.folderCheckboxInit(obj);

        return obj.data.folders.rows;
      }
      return [];
    },

    getNotification() {
      var obj = store.getters["ticketsV2Filters/getData"];
      if (
        obj != null &&
        obj.data &&
        obj.data.ticketUsers &&
        obj.data.ticketUsers.notifList
      ) {
        return JSON.parse(obj.data.ticketUsers.notifList).length;
      }
      return 0;
    },

    getBadges() {
      var obj = store.getters["ticketsV2Badges/getData"];
      if (obj != null && obj.data && obj.data.badges && obj.data.badges.rows) {
        return obj.data.badges.rows;
      }
      return [];
    },
  },
  watch: {
    page: function (val, oldVal) {
      this.refreshTickets();
    },
    $route(to, from) {
      this.refreshTickets();
    },
  },
  created: async function () {
    if (this.$route.params && this.$route.params.filters) {
    }

    let obj = await store.dispatch("ticketsV2Filters/getFilters");
    if (this.$route.params && !this.$route.params.filters) {
      if (obj && obj.data && obj.data.filters && obj.data.filters.count > 0) {
        this.$router.push({
          path: `/ticketsv2/filters/${obj.data.filters.rows[0].id}`,
        });
      }
    } else {
      this.refreshTickets();
    }
  },
};
</script>

<style lang="scss">
.margLeft {
  margin-left: -24px !important;
}

.selectColor {
  color: #e2007a;
}

.noselectColor {
  color: black;
}
</style>
