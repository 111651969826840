<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: LicencesTable.vue
@Last modified by:   florian
@Last modified time: 16-Feb-2021
-->

<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          series: [],
        };
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
    });
  },
  methods: {
    callRenderChart() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
      });
    },
  },
};
</script>

<!--
 {
   labels: ["Books", "Magazines", "Newspapers"],
   datasets: [
     {
       backgroundColor: ["#00D8FF", "#00D8FF", "#00D8FF"],
       data: [33, 33, 33]
     }
   ]
 }
-->
