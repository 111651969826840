<script src="https://cdn.tailwindcss.com"></script>
<template>
  <div class="content">
    <md-table
      v-if="!selectedItem"
      v-model="filteredItems"
      md-sort="updatedDate"
      md-sort-order="asc"
      @md-selected="onCellPress"
      md-card
    >
      <md-table-toolbar class="mb-6">
        <div class="md-toolbar-section-start">
          <div class="text-xl font-medium">SÉLECTION</div>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Recherche..." v-model="search" />
        </md-field>
      </md-table-toolbar>
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-sort-by="statusLabel" md-label="Etat">
          <div v-if="item.status == 'DRAFT'" class="md-chip bg-slate-200">
            {{ item.statusLabel }}
          </div>
          <div
            v-else-if="item.status == 'SENT'"
            class="md-chip bg-blue-500 text-white px-4"
          >
            {{ item.statusLabel }}
          </div>
          <div
            v-else-if="item.status == 'SIGNED'"
            class="md-chip bg-green-500 text-white px-5"
          >
            {{ item.statusLabel }}
          </div>
        </md-table-cell>
        <md-table-cell md-sort-by="cabinetName" md-label="Cabinet médical">
          {{ item.cabinetName }}
        </md-table-cell>
        <md-table-cell md-label="Dept" md-sort-by="department">
          {{ item.department }}
        </md-table-cell>
        <md-table-cell md-label="Expert" md-sort-by="expertCompany">
          {{ item.expertCompany }}
        </md-table-cell>
        <md-table-cell md-label="Nom expert" md-sort-by="expertName">
          {{ item.expertName }}
        </md-table-cell>
        <md-table-cell md-label="Date création" md-sort-by="createdAt">
          {{ new Date(item.createdAt).toLocaleDateString("fr") }}
        </md-table-cell>
        <md-table-cell md-label="Date modification" md-sort-by="updatedAt">
          {{ new Date(item.updatedAt).toLocaleDateString("fr") }}
        </md-table-cell>
        <md-table-cell md-label="Capacité" md-sort-by="deviceCount">
          {{ item.deviceCount }}
        </md-table-cell>
        <md-table-cell md-label="CPS" md-sort-by="situationCount">
          {{ item.situationCount }}
        </md-table-cell>
        <md-table-cell md-label="Montant mens." md-sort-by="amount">
          {{ item.amount ? item.amount + " €" : "" }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div v-if="selectedItem">
      <v-card elevation="0" outlined>
        <md-button class="md-success" @click="onBackPress()">
          <md-icon style="margin-right: 10px; margin-left: -20px">undo</md-icon>
          Retour
        </md-button>
        <v-card-title>SÉLECTION</v-card-title>
        <v-card-text>
          <div>Etat : {{ selectedItem.statusLabel }}</div>
          <div>
            Expert : {{ selectedItem.expertCompany }} ({{
              selectedItem.expertName
            }})
          </div>
          <div class="mb-4">
            Date de création :
            {{ new Date(selectedItem.createdAt).toLocaleDateString("fr") }}
          </div>
          <div class="flex flex-row">
            <div class="mr-6">
              <div class="font-bold my-2">Information Prospect</div>
              <div>
                Nom : {{ selectedItem.firstName }} {{ selectedItem.lastName }}
              </div>
              <div>Tel : {{ selectedItem.personalPhone }}</div>
              <div>Email : {{ selectedItem.personalEmail }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Information du Cabinet</div>
              <div>Nom facturation : {{ selectedItem.billingName }}</div>
              <div>Nom cabinet : {{ selectedItem.cabinetName }}</div>
              <div>RPPS : {{ selectedItem.rpps }}</div>
              <div>Activité : {{ selectedItem.activity }}</div>
              <div>Effectif : {{ selectedItem.headcount }}</div>
              <div>Logiciel actuel : {{ selectedItem.currentSoftware }}</div>
              <div>Tel : {{ selectedItem.officePhone }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Adresse</div>
              <div>Ville : {{ selectedItem.city }}</div>
              <div>Code postal : {{ selectedItem.zip }}</div>
              <div>Rue : {{ selectedItem.street }}</div>
              <div>Complément : {{ selectedItem.addressSupplement }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Licences MS3 actuelles</div>
              <div>{{ JSON.stringify(selectedItem.ms3Licenses, null, 2) }}</div>
              <div class="font-bold my-2">Commentaire</div>
              <div>{{ selectedItem.comment }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Abonnement</div>
              <div>
                Nombre d'appareils connectés : {{ selectedItem.deviceCount }}
              </div>
              <div>
                Nombre de situations : {{ selectedItem.situationCount }}
              </div>
              <div>
                Mode d'utilisation :
                {{ selectedItem.multiMode == true ? "Multi" : "Mono" }}
              </div>
              <div>
                Montant de la mensualité :
                {{ selectedItem.amount ? selectedItem.amount + " €" : "" }}
              </div>
              <div>
                Montant des FAS :
                {{
                  selectedItem.installationPrice
                    ? selectedItem.installationPrice + " €"
                    : ""
                }}
              </div>
              <div>
                Date prévisionnelle d'installation :
                {{ selectedItem.estimatedStartDate }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SubscriptionsOrders",
  data: function () {
    return {
      search: null,
      items: [],
      filteredItems: [],
      selectedItem: null,
    };
  },
  created: function () {
    this.$store.dispatch("subscription/getOrderForms");
  },
  watch: {
    orderForms: function (newVal, oldVal) {
      this.items = newVal.map((v) => {
        v.statusLabel = this.getLocalizedStatus(v.status);
        v.cabinetName = [v.firstName, v.lastName, v.billingName]
          .filter((v) => !!v)
          .join(" ");
        v.expertCompany = v.prokOffreUserCompany;
        const expertName = [v.prokOffreUserFirstName, v.prokOffreUserLastName]
          .filter((v) => !!v)
          .join(" ");
        v.expertName =
          expertName?.length > 0 ? expertName : v.prokOffreUserEmail;
        v.department = v.zip?.slice(0, 2);
        v.amount =
          this.getDeviceAmount(v.deviceCount) +
          this.getSituationAmount(v.situationCount);
        return v;
      });
      this.filteredItems = this.items;
    },
    search: function (newVal, oldVal) {
      const searchVal = newVal.toLowerCase();
      const searchKeys = [
        "statusLabel",
        "cabinetName",
        "expertName",
        "expertCompany",
        "amount",
        "department",
        "createdAt",
        "updatedAt",
      ];
      this.filteredItems = this.items.filter((item) =>
        newVal.length == 0
          ? true
          : searchKeys
              .map((key) => String(item[key]).toLowerCase())
              .map((val) => val.includes(searchVal))
              ?.includes(true)
      );
    },
  },
  computed: {
    ...mapState("subscription", ["orderForms"]),
  },
  methods: {
    onBackPress() {
      this.selectedItem = null;
    },
    onCellPress(item) {
      this.selectedItem = item;
    },
    getLocalizedStatus(status) {
      const statusList = {
        DRAFT: "Brouillon",
        SENT: "Envoyé",
        SIGNED: "Signé",
      };
      return statusList[status];
    },
    getDeviceAmount(itemCount) {
      return !!itemCount
        ? this.computePrice({ itemCount, priceList: [89, 118, 175, 45] })
        : 0;
    },
    getSituationAmount(itemCount) {
      return !!itemCount
        ? this.computePrice({ itemCount, priceList: [35, 70, 105, 35] })
        : 0;
    },
    computePrice({ itemCount, priceList }) {
      const discounted = [...priceList];
      const additional = discounted.pop();
      if (discounted.length == 0) {
        return additional * itemCount;
      } else {
        if (itemCount <= discounted.length) {
          return discounted[itemCount - 1];
        } else {
          return (
            [...discounted].pop() + (itemCount - discounted.length) * additional
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
