/**
 * @Author: Florian Merel <florian>
 * @Date:   10-Jun-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: material-dashboard.js
 * @Last modified by:   florian
 * @Last modified time: 14-Oct-2020
 */

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "./components/SidebarPlugin";

// asset imports
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";
import "./assets/scss/project.scss";

//import 'vue-material/dist/vue-material.min.css'
import "vue-material/dist/theme/default.css";

// library auto imports
import "es6-promise/auto";

/**
 * This is the main Light Bootstrap Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
  install(Vue) {
    Vue.use(SideBar);
    Vue.use(VueMaterial);
  },
};
