<!--
@Author: Florian Merel <florian>
@Date:   22-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: parkmanagement.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <div class="content">
    <v-card v-if="counter" elevation="0" outlined>
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="row" style="margin-top: -30px">
          <div class="col">
            Total bons de commande :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm }}
            </span>
            <br />
            Bons de commande MS3 :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_MS3 }}
            </span>
            <br />
            Bons de commande MS3 aujourd'hui :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_today_MS3 }}
            </span>
            <br />
            Messagerie Mailliz :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Mss_Mailliz }} ({{
                Math.round(
                  (100 * counter.nb_Mss_Mailliz) /
                    (counter.nb_Mss_MS +
                      counter.nb_Mss_Mailliz +
                      counter.nb_Mss_Other)
                )
              }}%)</span
            ><br />
            Total factures :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice }}
            </span>
          </div>
          <div class="col">
            Bons de commande envoyés à l'ASP :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_send_ASP }}
            </span>
            <br />
            Bons de commande MS4 :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_MS4 }} </span
            ><br />
            Bons de commande MS4 aujourd'hui :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_today_MS4 }} </span
            ><br />
            Messagerie MédiStory :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Mss_MS }} ({{
                Math.round(
                  (100 * counter.nb_Mss_MS) /
                    (counter.nb_Mss_MS +
                      counter.nb_Mss_Mailliz +
                      counter.nb_Mss_Other)
                )
              }}%) ({{ counter.nb_Mss_MS * 95 }}€)</span
            ><br />
            Factures envoyées à l'ASP :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice_send_ASP }}
            </span>
            <br />
          </div>
          <div class="col">
            Bons de commande envoyé à 4D :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_OrderForm_send_4D }}
            </span>
            <br /><br />
            Bons de commande aujourd'hui :
            <span style="color: black; font-weight: bold">
              {{
                counter.nb_OrderForm_today_MS4 + counter.nb_OrderForm_today_MS3
              }} </span
            ><br />
            Messagerie Autres :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Mss_Other }} ({{
                Math.round(
                  (100 * counter.nb_Mss_Other) /
                    (counter.nb_Mss_MS +
                      counter.nb_Mss_Mailliz +
                      counter.nb_Mss_Other)
                )
              }}%)</span
            ><br />
            Facture envoyées à 4D :
            <span style="color: black; font-weight: bold">
              {{ counter.nb_Invoice_send_4D }} </span
            ><br />
          </div>
        </div>

        <div class="row" style="">
          <div class="col">
            Montant total estimé de l'avance :
            <span style="color: black">
              {{ counter.nb_OrderForm_amount }} € HT
            </span>
            ----
            <span style="color: green; font-weight: bold"
              >{{
                Math.ceil(
                  counter.nb_OrderForm_amount * 0.2 +
                    counter.nb_OrderForm_amount
                )
              }}
              € TTC</span
            >
          </div>
          <div class="col">
            Montant total estimé :
            <span style="color: black"> {{ counter.nb_amount }} € HT </span>
            ----
            <span style="color: green; font-weight: bold"
              >{{ Math.ceil(counter.nb_amount * 0.2 + counter.nb_amount) }} €
              TTC</span
            >
          </div>
        </div>
      </v-card-text>
    </v-card>

    <md-card style="margin-bottom: 40px; margin-top: 10px">
      <div class="md-layout">
        <div class="md-layout-item">
          <md-field style="">
            <label>Recherche</label>
            <md-input v-on:keyup.enter="search" v-model="query"></md-input>
          </md-field>
        </div>

        <div class="md-layout-item" style="margin-top: 7px">
          <v-select
            :items="items"
            v-model="items_select"
            label="Trier par"
          ></v-select>
        </div>

        <div class="md-layout-item" style="margin-top: 7px">
          <v-select
            :items="items_ordre"
            v-model="items_select_ordre"
            label="Tri"
          ></v-select>
        </div>
      </div>
      <md-button class="md-prokov researcheButton" @click="search()"
        >Rechercher</md-button
      >

      <button class="diagCloseButton" @click="clearSearch()">
        <md-icon>delete</md-icon>
      </button>
    </md-card>

    <v-tabs v-model="tabs" fixed-tabs background-color="prokov" dark>
      <v-tab key="all" ref="all" href="#all">
        <v-badge
          v-if="this.orderForm && this.orderForm.nb_AllOrder"
          color="black"
          :content="this.orderForm.nb_AllOrder"
          style="color: white"
        >
          Bon de commande
        </v-badge>
        <span v-else> Bon de commande </span>
      </v-tab>

      <v-tab key="failasp" ref="failasp" href="#failasp">
        <v-badge
          v-if="this.orderForm && this.orderForm.nb_BdcASP"
          color="black"
          :content="this.orderForm.nb_BdcASP"
          style="color: white"
        >
          Échecs ASP
        </v-badge>
        <span v-else> Échecs ASP </span>
      </v-tab>

      <v-tab key="fail4d" ref="fail4d" href="#fail4d">
        <v-badge
          v-if="this.orderForm && this.orderForm.nb_Fail4D"
          color="black"
          :content="this.orderForm.nb_Fail4D"
          style="color: white"
        >
          Échecs 4D
        </v-badge>
        <span v-else> Échecs 4D </span>
      </v-tab>

      <v-tab key="successProof" ref="successProof" href="#successProof">
        <v-badge
          v-if="this.orderForm && this.orderForm.nb_SuccessProof"
          color="black"
          :content="this.orderForm.nb_SuccessProof"
          style="color: white"
        >
          Preuve réussi
        </v-badge>
        <span v-else> Preuve réussi </span>
      </v-tab>

      <v-tab key="successSegur" ref="successSegur" href="#successSegur">
        <v-badge
          v-if="this.orderForm && this.orderForm.nb_SuccessSegur"
          color="black"
          :content="this.orderForm.nb_SuccessSegur"
          style="color: white"
        >
          Ségur réussi
        </v-badge>
        <span v-else> Ségur réussi </span>
      </v-tab>

      <!--

      <v-tab key="tabs3" ref="tabs3" href="#tab-6">
        <v-badge color="black" :content="getNumberList(2)" style="color: white">
          Récolte de preuve
        </v-badge>
      </v-tab>

      <v-tab key="tabs3" ref="tabs3" href="#tab-7">
        <v-badge color="black" :content="getNumberList(2)" style="color: white">
          Ségur réussi
        </v-badge>
      </v-tab>-->

      <!--<v-tab key="tabs1" ref="tabs1" href="#tab-1">
        <v-badge color="black" :content="getNumberList(0)" style="color: white">
          Résidents
        </v-badge>
      </v-tab>

      <v-tab key="tabs2" ref="tabs2" href="#tab-2">
        <v-badge color="black" :content="getNumberList(1)" style="color: white">
          MSP
        </v-badge>
      </v-tab>-->

      <!--<v-tab key="tabs4" ref="tabs4" href="#tab-4">
        <v-badge color="black" :content="getNumberList(3)" style="color:white;">
          Condensée
        </v-badge>
      </v-tab>-->
    </v-tabs>

    <v-tabs-items v-model="tabs" style="background: rgba(0, 0, 0, 0)">
      <v-tab-item key="all" value="all">
        <div style="width: 100%" class="md-layout">
          <md-table
            v-if="this.orderForm && this.orderForm.rows"
            v-model="this.orderForm.rows"
          >
            <md-table-row
              slot="md-table-row"
              md-selectable="single"
              slot-scope="{ item }"
            >
              <!--<md-table-cell md-label="Apps">
                  {{ item.apps }}
                </md-table-cell>-->

              <md-table-cell md-label="Résident">
                {{ item.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                {{ item.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
              </md-table-cell>

              <!--<md-table-cell
                md-label="RPPS"
                v-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell
                v-else-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>-->

              <md-table-cell
                v-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>
              <md-table-cell
                md-label="RPPS"
                v-else-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell v-else md-label="RPPS"> </md-table-cell>

              <md-table-cell md-label="N° Éditeur">
                {{ item.numeroEditeur }}
              </md-table-cell>

              <md-table-cell md-label="Numero ASP">
                {{ item.numeroASP }}
              </md-table-cell>

              <md-table-cell md-label="Montant">
                {{ item.montantSegurHT }} €
              </md-table-cell>

              <md-table-cell md-label="Date de commande">
                {{ getDate(item.createdAt) }}
              </md-table-cell>

              <md-table-cell md-label="BDC 4D">
                <v-chip
                  v-if="item.OrderFormSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdcto4DDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="BDC ASP">
                <v-chip
                  v-if="item.OrderFormSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdctoASPDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde 4D">
                <v-chip
                  v-if="item.InvoiceSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.invoiceDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde ASP">
                <v-chip
                  v-if="item.InvoiceSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.dateOfOrder) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Preuves">
                <v-chip
                  v-if="tcheckPreuve(item)"
                  color="green"
                  text-color="white"
                  >Oui</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Messagerie">
                {{ item.operateurMessagerie }}
              </md-table-cell>

              <md-table-cell md-label="">
                <v-btn
                  class="mx-2"
                  icon
                  small
                  color="teal"
                  @click="(dialog = true), (item_select = item)"
                >
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <v-dialog v-model="dialog" width="800">
          <v-card>
            <v-card-title v-if="item_select" class="text-h5 grey lighten-2">
              {{ item_select.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
              {{ item_select.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
            </v-card-title>

            <v-card-text v-if="item_select">
              <!-- 
                BON DE COMMANDE
              -->

              <v-col cols="12" sm="12" style="margin-bottom: -20px">
                <div class="text-overline mb-4" style="color: black">
                  <h4>---- BON DE COMMANDE ----</h4>
                </div>
                <div
                  class="text-overline mb-4"
                  style="color: black; margin-left: 10px"
                >
                  <span style="color: blue">
                    ID : {{ item_select.id }} <br />
                    Apps : {{ item_select.apps }} --- N° éditeur :
                    {{ item_select.numeroEditeur }} --- N° ASP :
                    {{ item_select.numeroASP }} <br />
                    Montant HT : {{ item_select.montantSegurHT }} € --- Licence
                    : {{ item_select.licence }}<br /><br />

                    Centre De Sante :
                    {{ item_select.isCentreDeSante == true ? "OUI" : "NON" }}
                    <br />
                    Direct To DMP :
                    {{ item_select.isDirectToDMP == true ? "OUI" : "NON" }}
                    <br />
                    Direct To MSSCitoyen :
                    {{
                      item_select.isDirectToMSSCitoyen == true ? "OUI" : "NON"
                    }}
                    <br />
                    Direct To MSSPro :
                    {{ item_select.isDirectToMSSPro == true ? "OUI" : "NON" }}
                    <br />
                    Application tierce DMP :
                    {{ item_select.isPFIToDMP == true ? "OUI" : "NON" }} <br />
                    Application tierce MSSCitoyen :
                    {{ item_select.isPFIToMSSCitoyen == true ? "OUI" : "NON"
                    }}<br />
                    Application tierce MSSPro :
                    {{ item_select.isPFIToMSSPro == true ? "OUI" : "NON"
                    }}<br />
                    ProSante Connect:
                    {{
                      item_select.isProSanteConnectNeeded == true
                        ? "OUI"
                        : "NON"
                    }}
                    <br /><br />

                    Certificat d'aptitude :
                    {{ item_select.vaPDF ? "OUI" : "NON" }}<br />
                    Ordre de mise en marche :
                    {{ item_select.momPDF ? "OUI" : "NON" }}<br />
                    Facture : {{ item_select.invoicePDF ? "OUI" : "NON" }}<br />
                    Bon de commande :
                    {{ item_select.orderFormPDF ? "OUI" : "NON" }}

                    <br /><br />
                    Operateur de messagerie :
                    {{ item_select.operateurMessagerie }} <br />
                    MSS : {{ item_select.MSST_ADDR }} <br />
                    Identifiant DMP : {{ item_select.identDMP }}

                    <br /><br />

                    <span style="color: green">Preuves : </span><br />
                    CountDMP : {{ item_select.countDMP }}, CountMSS :
                    {{ item_select.countMSS }}, CountMSSTest :
                    {{ item_select.countMSSTest }}<br />
                    TutoDMP : {{ item_select.tutoDMP == true ? "OUI" : "NON" }},
                    TutoMSS : {{ item_select.tutoMSS == true ? "OUI" : "NON" }},
                    TutoINS : {{ item_select.tutoINS == true ? "OUI" : "NON" }},
                    TutoPro : {{ item_select.tutoPro == true ? "OUI" : "NON" }},
                    TutoEPres :
                    {{ item_select.tutoEPres == true ? "OUI" : "NON" }}<br />

                    <br />
                    <span style="color: green">Retour de l'ASP : </span><br />
                    Code de traitement : {{ item_select.codeTraitement }}<br />
                    Message de traitement : {{ item_select.libelleTraitement
                    }}<br />
                  </span>
                </div>

                <div
                  class="text-overline mb-4"
                  style="color: black; margin-top: 30px"
                  v-if="item_select && item_select.SEGUR_Resident"
                >
                  <h4>---- RESIDENT ----</h4>

                  <span style="color: blue">
                    ID : {{ item_select.SEGUR_Resident.id }} <br />
                    {{ item_select.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
                    {{ item_select.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                    <br />

                    RPPS : {{ item_select.SEGUR_Resident.RPPS }} <br />
                    Adresse : {{ item_select.SEGUR_Resident.address }},
                    {{ item_select.SEGUR_Resident.city }},
                    {{ item_select.SEGUR_Resident.zip }}<br />
                    Téléphone : {{ item_select.SEGUR_Resident.phone
                    }}<br /><br />

                    Informations envoyées par la Station :<br />
                    {{
                      JSON.parse(
                        item_select.SEGUR_Resident.last_station_request
                      )
                    }}<br /><br />
                    Informations répondu par EEGO :<br />
                    {{
                      JSON.parse(
                        item_select.SEGUR_Resident.last_station_response
                      )
                    }}
                  </span>
                </div>

                <div
                  class="text-overline mb-4"
                  style="color: black; margin-top: 30px"
                  v-if="item_select && item_select.SEGUR_MSP"
                >
                  <h4>---- MSP ----</h4>

                  <span style="color: blue">
                    ID : {{ item_select.SEGUR_MSP.id }} <br />
                    FINESS : {{ item_select.SEGUR_MSP.FINESS }} <br />
                    residentId : {{ item_select.SEGUR_MSP.residentId }} <br />

                    Adresse : {{ item_select.SEGUR_MSP.address }},
                    {{ item_select.SEGUR_MSP.city }},
                    {{ item_select.SEGUR_MSP.zip }}<br />
                  </span>
                </div>
              </v-col>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="text-center">
          <v-pagination v-model="page" :length="size"></v-pagination>
        </div>
      </v-tab-item>

      <v-tab-item key="failasp" value="failasp">
        <div style="width: 100%" class="md-layout">
          <md-table
            v-if="this.orderForm && this.orderForm.rows"
            v-model="this.orderForm.rows"
          >
            <md-table-row
              slot="md-table-row"
              md-selectable="single"
              slot-scope="{ item }"
            >
              <!--<md-table-cell md-label="Apps">
                  {{ item.apps }}
                </md-table-cell>-->

              <md-table-cell md-label="Résident" v-if="item.SEGUR_Resident">
                {{ item.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                {{ item.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
              </md-table-cell>

              <md-table-cell
                v-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>
              <md-table-cell
                md-label="RPPS"
                v-else-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell v-else md-label="RPPS"> </md-table-cell>

              <md-table-cell md-label="N° Éditeur">
                {{ item.numeroEditeur }}
              </md-table-cell>

              <md-table-cell md-label="Numero ASP">
                {{ item.numeroASP }}
              </md-table-cell>

              <md-table-cell md-label="BDC 4D">
                <v-chip
                  v-if="item.OrderFormSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdcto4DDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="BDC ASP">
                <v-chip
                  v-if="item.OrderFormSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdctoASPDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde 4D">
                <v-chip
                  v-if="item.InvoiceSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.invoiceDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde ASP">
                <v-chip
                  v-if="item.InvoiceSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.dateOfOrder) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Preuves">
                <v-chip
                  v-if="tcheckPreuve(item)"
                  color="green"
                  text-color="white"
                  >Oui</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="codeTraitement">
                <span>
                  {{ item.codeTraitement }}
                </span>
              </md-table-cell>

              <md-table-cell md-label="libelleTraitement">
                <span>
                  {{ item.libelleTraitement }}
                </span>
              </md-table-cell>

              <md-table-cell md-label="">
                <v-btn
                  class="mx-2"
                  icon
                  small
                  color="teal"
                  @click="(dialog = true), (item_select = item)"
                >
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="text-center">
          <v-pagination v-model="page" :length="size"></v-pagination>
        </div>
      </v-tab-item>

      <v-tab-item key="fail4d" value="fail4d">
        <div style="width: 100%" class="md-layout">
          <md-table
            v-if="this.orderForm && this.orderForm.rows"
            v-model="this.orderForm.rows"
          >
            <md-table-row
              slot="md-table-row"
              md-selectable="single"
              slot-scope="{ item }"
            >
              <!--<md-table-cell md-label="Apps">
                  {{ item.apps }}
                </md-table-cell>-->

              <md-table-cell md-label="Résident" v-if="item.SEGUR_Resident">
                {{ item.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                {{ item.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
              </md-table-cell>

              <md-table-cell
                v-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>
              <md-table-cell
                md-label="RPPS"
                v-else-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell v-else md-label="RPPS"> </md-table-cell>

              <md-table-cell md-label="N° Éditeur">
                {{ item.numeroEditeur }}
              </md-table-cell>

              <md-table-cell md-label="Numero ASP">
                {{ item.numeroASP }}
              </md-table-cell>

              <md-table-cell md-label="BDC 4D">
                <v-chip
                  v-if="item.OrderFormSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdcto4DDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="BDC ASP">
                <v-chip
                  v-if="item.OrderFormSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdctoASPDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde 4D">
                <v-chip
                  v-if="item.InvoiceSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.invoiceDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde ASP">
                <v-chip
                  v-if="item.InvoiceSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.dateOfOrder) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Preuves">
                <v-chip
                  v-if="tcheckPreuve(item)"
                  color="green"
                  text-color="white"
                  >Oui</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="codeTraitement">
                <span>
                  {{ item.codeTraitement }}
                </span>
              </md-table-cell>

              <md-table-cell md-label="libelleTraitement">
                <span>
                  {{ item.libelleTraitement }}
                </span>
              </md-table-cell>

              <md-table-cell md-label="">
                <v-btn
                  class="mx-2"
                  icon
                  small
                  color="teal"
                  @click="(dialog = true), (item_select = item)"
                >
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="text-center">
          <v-pagination v-model="page" :length="size"></v-pagination>
        </div>
      </v-tab-item>

      <v-tab-item key="successProof" value="successProof">
        <div style="width: 100%" class="md-layout">
          <md-table
            v-if="this.orderForm && this.orderForm.rows"
            v-model="this.orderForm.rows"
          >
            <md-table-row
              slot="md-table-row"
              md-selectable="single"
              slot-scope="{ item }"
            >
              <!--<md-table-cell md-label="Apps">
                  {{ item.apps }}
                </md-table-cell>-->

              <md-table-cell md-label="Résident" v-if="item.SEGUR_Resident">
                {{ item.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                {{ item.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
              </md-table-cell>

              <md-table-cell
                v-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>
              <md-table-cell
                md-label="RPPS"
                v-else-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell v-else md-label="RPPS"> </md-table-cell>

              <md-table-cell md-label="N° Éditeur">
                {{ item.numeroEditeur }}
              </md-table-cell>

              <!-- 
              <md-table-cell md-label="Numero ASP">
                {{ item.numeroASP }}
              </md-table-cell>

              <md-table-cell md-label="Montant">
                {{ item.montantSegurHT }} €
              </md-table-cell>

              <md-table-cell md-label="Date de commande">
                {{ getDate(item.createdAt) }}
              </md-table-cell>
            -->

              <md-table-cell md-label="VA">
                <!-- vaPDF -->

                <v-chip v-if="item.vaPDF" color="green" text-color="white"
                  >Oui</v-chip
                >
                <span v-else> --- </span>
              </md-table-cell>

              <md-table-cell md-label="Adr MSSanté">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.MSST_ADDR"
                  color="green"
                  text-color="white"
                >
                  {{ item.MSST_ADDR }}
                </v-chip>
                <v-chip v-else color="red" text-color="white">---</v-chip>
              </md-table-cell>

              <md-table-cell md-label="ID DMP">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.identDMP"
                  color="green"
                  text-color="white"
                >
                  {{ item.identDMP }}
                </v-chip>
                <v-chip v-else color="red" text-color="white">---</v-chip>
              </md-table-cell>

              <md-table-cell md-label="DMP">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.countDMP >= 10"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
              </md-table-cell>

              <md-table-cell md-label="MSS">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.countMSS >= 10"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
              </md-table-cell>

              <md-table-cell md-label="MSSTest">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.countMSSTest >= 1"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
              </md-table-cell>

              <md-table-cell md-label="Tuto DMP">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.tutoDMP"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Tuto MSS">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.tutoMSS"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Tuto E-Presc">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.tutoEPres"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Tuto INS">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.tutoINS"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Tuto PSC">
                <span v-if="item.vaPDF"> --- </span>

                <v-chip
                  v-else-if="item.tutoPro"
                  color="green"
                  text-color="white"
                >
                  Oui
                </v-chip>
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <!--

              <md-table-cell md-label="BDC 4D">
                <v-chip
                  v-if="item.OrderFormSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdcto4DDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="BDC ASP">
                <v-chip
                  v-if="item.OrderFormSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdctoASPDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde 4D">
                <v-chip
                  v-if="item.InvoiceSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.invoiceDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde ASP">
                <v-chip
                  v-if="item.InvoiceSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.dateOfOrder) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Preuves">
                <v-chip
                  v-if="tcheckPreuve(item)"
                  olor="green"
                  text-color="white"
                  >Oui</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>-->

              <md-table-cell md-label="Messagerie">
                {{ item.operateurMessagerie }}
              </md-table-cell>

              <md-table-cell md-label="">
                <v-btn
                  class="mx-2"
                  icon
                  small
                  color="teal"
                  @click="(dialog = true), (item_select = item)"
                >
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="text-center">
          <v-pagination v-model="page" :length="size"></v-pagination>
        </div>
      </v-tab-item>

      <v-tab-item key="successSegur" value="successSegur">
        <div style="width: 100%" class="md-layout">
          <md-table
            v-if="this.orderForm && this.orderForm.rows"
            v-model="this.orderForm.rows"
          >
            <md-table-row
              slot="md-table-row"
              md-selectable="single"
              slot-scope="{ item }"
            >
              <!--<md-table-cell md-label="Apps">
                  {{ item.apps }}
                </md-table-cell>-->

              <md-table-cell md-label="Résident" v-if="item.SEGUR_Resident">
                {{ item.SEGUR_Resident.INFOS_PORTEUR_PrenomUsuel }}
                {{ item.SEGUR_Resident.INFOS_PORTEUR_NomPatronym }}
              </md-table-cell>

              <md-table-cell
                v-if="item.SEGUR_MSP && item.SEGUR_MSP.FINESS"
                md-label="RPPS"
              >
                F - {{ item.SEGUR_MSP.FINESS }}
              </md-table-cell>
              <md-table-cell
                md-label="RPPS"
                v-else-if="item.SEGUR_Resident && item.SEGUR_Resident.RPPS"
              >
                R - {{ item.SEGUR_Resident.RPPS }}
              </md-table-cell>
              <md-table-cell v-else md-label="RPPS"> </md-table-cell>

              <md-table-cell md-label="N° Éditeur">
                {{ item.numeroEditeur }}
              </md-table-cell>

              <md-table-cell md-label="Numero ASP">
                {{ item.numeroASP }}
              </md-table-cell>

              <md-table-cell md-label="Montant">
                {{ item.montantSegurHT }} €
              </md-table-cell>

              <md-table-cell md-label="Date de commande">
                {{ getDate(item.createdAt) }}
              </md-table-cell>

              <md-table-cell md-label="BDC 4D">
                <v-chip
                  v-if="item.OrderFormSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdcto4DDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="BDC ASP">
                <v-chip
                  v-if="item.OrderFormSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.bdctoASPDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde 4D">
                <v-chip
                  v-if="item.InvoiceSend4D"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.invoiceDate) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Solde ASP">
                <v-chip
                  v-if="item.InvoiceSendPay"
                  color="green"
                  text-color="white"
                  >{{ getDate(item.dateOfOrder) }}</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Preuves">
                <v-chip
                  v-if="tcheckPreuve(item)"
                  color="green"
                  text-color="white"
                  >Oui</v-chip
                >
                <v-chip v-else color="red" text-color="white">Non</v-chip>
              </md-table-cell>

              <md-table-cell md-label="Messagerie">
                {{ item.operateurMessagerie }}
              </md-table-cell>

              <md-table-cell md-label="">
                <v-btn
                  class="mx-2"
                  icon
                  small
                  color="teal"
                  @click="(dialog = true), (item_select = item)"
                >
                  <v-icon dark> mdi-format-list-bulleted-square </v-icon>
                </v-btn>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="text-center">
          <v-pagination v-model="page" :length="size"></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import Vue from "vue";
import server from "../store/server.js";
import axios from "axios";

export default {
  name: "Parkmanagement",
  data() {
    return {
      tabs: "all",
      query: "",
      apps: "MSX",
      counter: null,
      dialog: false,
      item_select: null,
      items: [
        "N° Éditeur",
        "Numero ASP",
        "Montant",
        "Date de commande",
        "BDC 4D",
        "BDC ASP",
        "Solde 4D",
        "Solde ASP",
        "Messagerie",
      ],
      items_select: "N° Éditeur",
      items_ordre: ["Croissant", "Décroissant"],
      items_select_ordre: "Décroissant",
      page: 1,
      size: 0,
    };
  },
  created: async function () {
    var res = store.getters["segur/getOrderForm"];

    if (res == null) {
      store.dispatch("segur/getOrderformSegur", { search: "" });
    }

    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/count",
        headers: server.getHeader(),
      });

      if (res && res.data && res.data.data) {
        this.counter = res.data.data;
      }
    } catch (error) {}
  },
  watch: {
    page: function (val, oldVal) {
      this.search();
    },

    tabs: function (val, oldVal) {
      this.page = 1;
      this.size = 0;
      this.search();
    },
  },
  computed: {
    orderForm() {
      var res = store.getters["segur/getOrderForm"];

      if (res == null || res.data == null) {
        return [];
      }
      this.changes(res.data);
      return res.data;
    },
  },
  methods: {
    changes(newVal) {
      if (newVal && newVal.count) {
        this.size = Math.round(newVal.count / 100);
      }

      this.size += 1;
    },

    tcheckPreuve(item) {
      if (!item.tutoDMP || !item.tutoMSS) {
        return false;
      }

      if (item.vaPDF) {
        return true;
      }

      if (item.countDMP < 10 && item.countMSS < 10 && item.countMSSTest < 1) {
        return false;
      }

      return true;
    },

    getDate(dd) {
      var da = toolsFormat.getDate(dd);
      if (da == "01/01/1970") {
        return "Oui";
      }
      return da;
    },

    clearSearch() {
      this.query = "";
      this.search();
    },

    search() {
      var calc = (this.page - 1) * 100;

      this.page = 1;
      this.size = 0;

      store.dispatch("segur/getOrderformSegur", {
        search: this.query,
        apps: this.apps,
        order: this.items_select_ordre,
        variable: this.items_select,
        offset: calc,
        type: this.tabs,
      });
    },

    /*openBonDeCommande(id) {
      this.query = id;
      this.tabs = "tab-3";
      this.search();
    },

    openResident(id) {
      this.query = id;
      this.tabs = "tab-1";
      this.search();
    },

    getIsActif(obj, finess) {
      if (finess) {
        const found = this.msp.find((element) => element.FINESS == finess);
        if (found) {
          const foundOrder = this.orderForm.find(
            (element) => element.mspID == found.id
          );
          if (foundOrder) {
            return foundOrder.state;
          }
        }
        return "OFF";
      }

      if (obj.state) {
        return obj.state;
      }

      if (obj.SEGUR_OrderFormSegurs) {
        if (obj.SEGUR_OrderFormSegurs.state) {
          obj.state = obj.SEGUR_OrderFormSegurs.state;
          return obj.state;
        }
      } else {
      }

      obj.state = "OFF";
      return "OFF";
    },

    getBonDeCommande(dt) {},

    getMsp(dt) {},

    getResident(dt) {},*/

    /* async donwloadBDC(ti) {
      const res = await store.dispatch("segur/donwloadBDC", ti.id);

      //numeroEditeur
      if (res && res.data && res.data.data) {
        var string64 = res.data.data.toString("base64");
        const linkSource = `data:application/pdf;base64,${string64}`;
        const downloadLink = document.createElement("a");
        const fileName = "BonDeCommandeMS4-" + ti.numeroEditeur + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    async downloadAP(ti) {
      const res = await store.dispatch("segur/downloadAP", ti.id);

      if (res && res.data && res.data.data) {
        var string64 = res.data.data.toString("base64");
        const linkSource = `data:application/pdf;base64,${string64}`;
        const downloadLink = document.createElement("a");
        const fileName = "CertificatAptitude-" + ti.numeroEditeur + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    async downloadFAC(ti) {
      const res = await store.dispatch("segur/downloadFAC", ti.id);

      if (res && res.data && res.data.data) {
        var string64 = res.data.data.toString("base64");
        const linkSource = `data:application/pdf;base64,${string64}`;
        const downloadLink = document.createElement("a");
        const fileName = "FactureMS4-" + ti.numeroEditeur + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    async files(id, obj) {
      const res = await store.dispatch("segur/getFiles", id);
      if (res && res.data && res.data.data) {
        obj.files = {};
        obj.files.invoicePDF = res.data.data.invoicePDF;
        obj.files.orderFormPDF = res.data.data.orderFormPDF;
        obj.files.vaPDF = res.data.data.vaPDF;
        //console.log(obj.files);
        this.$forceUpdate();
      }
    },

    dataJson(dt) {
      this.data = JSON.stringify(dt);
      const blob = new Blob([this.data], { type: "application/text" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = dt.id;
      link.click();
      URL.revokeObjectURL(link.href);
    },*/
  },
};
</script>
