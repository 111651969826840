<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: PaysField.vue
@Last modified by:   florian
@Last modified time: 10-Nov-2020
-->

<template>
  <md-field>
    <label for="movie">Pays</label>
    <md-select v-model="myPays">
      <md-option value="BL">Belgique</md-option>
      <md-option value="FR">France</md-option>
      <md-option value="AF">Afghanistan</md-option>
      <md-option value="CF">Afrique Centrale</md-option>
      <md-option value="ZA">Afrique du Sud</md-option>
      <md-option value="AL">Albanie</md-option>
      <md-option value="DZ">Algerie</md-option>
      <md-option value="DE">Allemagne</md-option>
      <md-option value="AD">Andorre</md-option>
      <md-option value="AG">Angola</md-option>
      <md-option value="AI">Anguilla</md-option>
      <md-option value="SA">Arabie Saoudite</md-option>
      <md-option value="AR">Argentine</md-option>
      <md-option value="AA">Armenie</md-option>
      <md-option value="AU">Australie</md-option>
      <md-option value="AT">Autriche</md-option>
      <md-option value="AZ">Azerbaidjan</md-option>
      <md-option value="BS">Bahamas</md-option>
      <md-option value="BD">Bangladesh</md-option>
      <md-option value="BB">Barbade</md-option>
      <md-option value="BH">Bahrein</md-option>
      <md-option value="BE">Belgique</md-option>
      <md-option value="BZ">Belize</md-option>
      <md-option value="BJ">Benin</md-option>
      <md-option value="BM">Bermudes</md-option>
      <md-option value="BY">Bielorussie</md-option>
      <md-option value="BO">Bolivie</md-option>
      <md-option value="BW">Botswana</md-option>
      <md-option value="BT">Bhoutan</md-option>
      <md-option value="BA">Boznie-Herzegovine</md-option>
      <md-option value="BR">Bresil</md-option>
      <md-option value="BN">Brunei</md-option>
      <md-option value="BG">Bulgarie</md-option>
      <md-option value="BF">Burkina Faso</md-option>
      <md-option value="BI">Burundi</md-option>
      <md-option value="KY">Caiman</md-option>
      <md-option value="KH">Cambodge</md-option>
      <md-option value="CM">Cameroun</md-option>
      <md-option value="CA">Canada</md-option>
      <md-option value="IC">Canaries</md-option>
      <md-option value="CV">Cap_Vert</md-option>
      <md-option value="CL">Chili</md-option>
      <md-option value="CN">Chine</md-option>
      <md-option value="CY">Chypre</md-option>
      <md-option value="CO">Colombie</md-option>
      <md-option value="CC">Comores</md-option>
      <md-option value="CG">Congo</md-option>
      <md-option value="CD">RD Congo</md-option>
      <md-option value="CK">Cook</md-option>
      <md-option value="KP">Coree du Nord</md-option>
      <md-option value="KR">Coree du Sud</md-option>
      <md-option value="CR">Costa Rica</md-option>
      <md-option value="CT">Cote d'Ivoire</md-option>
      <md-option value="HR">Croatie</md-option>
      <md-option value="CU">Cuba</md-option>
      <md-option value="DK">Danemark</md-option>
      <md-option value="DJ">Djibouti</md-option>
      <md-option value="DM">Dominique</md-option>
      <md-option value="EG">Egypte</md-option>
      <md-option value="AE">Emirats Arabes Unis</md-option>
      <md-option value="EC">Equateur</md-option>
      <md-option value="ER">Erythree</md-option>
      <md-option value="ES">Espagne</md-option>
      <md-option value="EE">Estonie</md-option>
      <md-option value="US">Etats-Unis</md-option>
      <md-option value="ET">Ethiopie</md-option>
      <md-option value="FK">Falkland</md-option>
      <md-option value="FO">Feroe</md-option>
      <md-option value="FJ">Fidji</md-option>
      <md-option value="FI">Finlande</md-option>
      <md-option value="GA">Gabon</md-option>
      <md-option value="GM">Gambie</md-option>
      <md-option value="GE">Georgie</md-option>
      <md-option value="GH">Ghana</md-option>
      <md-option value="GI">Gibraltar</md-option>
      <md-option value="GR">Grece</md-option>
      <md-option value="GD">Grenade</md-option>
      <md-option value="GL">Groenland</md-option>
      <md-option value="GP">Guadeloupe</md-option>
      <md-option value="GU">Guam</md-option>
      <md-option value="GT">Guatemala</md-option>
      <md-option value="GG">Guernesey</md-option>
      <md-option value="GN">Guinee</md-option>
      <md-option value="GW">Guinee Bissau</md-option>
      <md-option value="GQ">Guinee Equatoriale</md-option>
      <md-option value="GY">Guyana</md-option>
      <md-option value="GF">Guyane Francaise</md-option>
      <md-option value="HT">Haiti</md-option>
      <md-option value="HN">Honduras</md-option>
      <md-option value="HK">Hong_Kong</md-option>
      <md-option value="HU">Hongrie</md-option>
      <md-option value="IN">Inde</md-option>
      <md-option value="ID">Indonesie</md-option>
      <md-option value="IR">Iran</md-option>
      <md-option value="IQ">Iraq</md-option>
      <md-option value="IE">Irlande</md-option>
      <md-option value="IS">Islande</md-option>
      <md-option value="IL">Israel</md-option>
      <md-option value="IT">Italie</md-option>
      <md-option value="JM">Jamaique</md-option>
      <md-option value="JP">Japon</md-option>
      <md-option value="JE">Jersey</md-option>
      <md-option value="JO">Jordanie</md-option>
      <md-option value="KZ">Kazakhstan</md-option>
      <md-option value="KE">Kenya</md-option>
      <md-option value="KG">Kirghizistan </md-option>
      <md-option value="KI">Kiribati </md-option>
      <md-option value="KW">Koweit </md-option>
      <md-option value="LA">Laos </md-option>
      <md-option value="LS">Lesotho </md-option>
      <md-option value="LE">Lettonie</md-option>
      <md-option value="LB">Liban</md-option>
      <md-option value="LR">Liberia</md-option>
      <md-option value="LI">Liechtenstein</md-option>
      <md-option value="LT">Lituanie</md-option>
      <md-option value="LU">Luxembourg</md-option>
      <md-option value="LY">Lybie</md-option>
      <md-option value="MO">Macao</md-option>
      <md-option value="MK">Macedoine</md-option>
      <md-option value="MG">Madagascar</md-option>
      <md-option value="MY">Malaisie</md-option>
      <md-option value="MW">Malawi</md-option>
      <md-option value="MV">Maldives</md-option>
      <md-option value="ML">Mali</md-option>
      <md-option value="MT">Malte</md-option>
      <md-option value="MP">Mariannes du Nord</md-option>
      <md-option value="MA">Maroc</md-option>
      <md-option value="MH">Marshall</md-option>
      <md-option value="MQ">Martinique</md-option>
      <md-option value="MU">Maurice</md-option>
      <md-option value="MR">Mauritanie</md-option>
      <md-option value="YT">Mayotte</md-option>
      <md-option value="MX">Mexique</md-option>
      <md-option value="FM">Micronesie</md-option>
      <md-option value="MD">Moldavie</md-option>
      <md-option value="MC">Monaco</md-option>
      <md-option value="MN">Mongolie</md-option>
      <md-option value="ME">Montenegro</md-option>
      <md-option value="MS">Montserrat</md-option>
      <md-option value="MZ">Mozambique</md-option>
      <md-option value="NA">Namibie</md-option>
      <md-option value="NR">Nauru</md-option>
      <md-option value="NP">Nepal</md-option>
      <md-option value="NI">Nicaragua</md-option>
      <md-option value="NE">Niger</md-option>
      <md-option value="NG">Nigeria</md-option>
      <md-option value="NU">Niue</md-option>
      <md-option value="NF">Norfolk</md-option>
      <md-option value="NO">Norvege</md-option>
      <md-option value="NC">Nouvelle Caledonie</md-option>
      <md-option value="NZ">Nouvelle Zelande</md-option>
      <md-option value="OM">Oman</md-option>
      <md-option value="UG">Ouganda</md-option>
      <md-option value="UZ">Ouzbekistan</md-option>
      <md-option value="PK">Pakistan</md-option>
      <md-option value="PW">Palau</md-option>
      <md-option value="PS">Palestine</md-option>
      <md-option value="PA">Panama</md-option>
      <md-option value="PG">Papouasie Nouvelle Guinee</md-option>
      <md-option value="PY">Paraguay</md-option>
      <md-option value="NL">Pays_Bas</md-option>
      <md-option value="PE">Perou</md-option>
      <md-option value="PH">Philippines</md-option>
      <md-option value="PL">Pologne</md-option>
      <md-option value="PF">Polynesie</md-option>
      <md-option value="PR">Porto_Rico</md-option>
      <md-option value="PT">Portugal</md-option>
      <md-option value="QA">Qatar</md-option>
      <md-option value="DO">Republique Dominicaine</md-option>
      <md-option value="CZ">Republique Tcheque</md-option>
      <md-option value="RE">Reunion</md-option>
      <md-option value="RO">Roumanie</md-option>
      <md-option value="GB">Royaume Uni</md-option>
      <md-option value="RU">Russie</md-option>
      <md-option value="RW">Rwanda</md-option>
      <md-option value="EH">Sahara Occidental</md-option>
      <md-option value="LC">Sainte Lucie</md-option>
      <md-option value="SM">Saint Marin</md-option>
      <md-option value="SB">Salomon</md-option>
      <md-option value="SV">Salvador</md-option>
      <md-option value="WS">Samoa Occidentales</md-option>
      <md-option value="AS">Samoa Americaine</md-option>
      <md-option value="ST">Sao Tome et Principe</md-option>
      <md-option value="SN">Senegal</md-option>
      <md-option value="RS">Serbie</md-option>
      <md-option value="SC">Seychelles</md-option>
      <md-option value="SL">Sierra Leone</md-option>
      <md-option value="SG">Singapour</md-option>
      <md-option value="SK">Slovaquie</md-option>
      <md-option value="SI">Slovenie</md-option>
      <md-option value="SO">Somalie</md-option>
      <md-option value="SD">Soudan</md-option>
      <md-option value="LK">Sri Lanka</md-option>
      <md-option value="SE">Suede</md-option>
      <md-option value="CH">Suisse</md-option>
      <md-option value="SR">Surinam</md-option>
      <md-option value="SZ">Swaziland</md-option>
      <md-option value="SY">Syrie</md-option>
      <md-option value="TJ">Tadjikistan</md-option>
      <md-option value="TW">Taiwan</md-option>
      <md-option value="TO">Tonga</md-option>
      <md-option value="TZ">Tanzanie</md-option>
      <md-option value="TD">Tchad</md-option>
      <md-option value="TH">Thailande</md-option>
      <md-option value="TL">Timor Oriental</md-option>
      <md-option value="TG">Togo</md-option>
      <md-option value="TT">Trinite et Tobago</md-option>
      <md-option value="TN">Tunisie</md-option>
      <md-option value="TM">Turmenistan</md-option>
      <md-option value="TR">Turquie</md-option>
      <md-option value="UA">Ukraine</md-option>
      <md-option value="UY">Uruguay</md-option>
      <md-option value="VU">Vanuatu</md-option>
      <md-option value="VA">Vatican</md-option>
      <md-option value="VE">Venezuela</md-option>
      <md-option value="VN">Vietnam</md-option>
      <md-option value="WF">Wallis et Futuma</md-option>
      <md-option value="YE">Yemen</md-option>
      <md-option value="ZM">Zambie</md-option>
      <md-option value="ZW">Zimbabwe</md-option>
    </md-select>
  </md-field>
</template>

<script>
import store from "@/store";
export default {
  name: "pays-field",
  props: {
    pays: {
      type: String,
      default: "FR",
    },
  },
  data() {
    return {
      pa: null,
    };
  },
  computed: {
    myPays: {
      get() {
        return this.pays;
      },
      set(newValue) {
        this.pa = newValue;
        store.commit("user/setObj", { country: newValue });
      },
    },
  },
};
</script>
<style></style>
