<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Type.vue
@Last modified by:   florian
@Last modified time: 03-Sep-2021
-->

<template>
  <div
    v-if="getTicket != null"
    class="md-layout-item md-medium-size-100 md-size-50"
    style="margin-top: 10px; margin-bottom: 10px"
  >
    <div class="md-body-2" style="color: #e2007a">Mots clés</div>

    <!--<md-chips
      class="md-primary"
      style="margin-top: -10px"
      v-model="chips"
      :md-check-duplicated="true"
      :md-format="updateChips"
      :md-auto-insert="true"
      md-placeholder="Ajouter des mots clés"
      :md-static="getIsAssistance()"
    >
    </md-chips>-->

    <vue-tags-input
      v-model="tag"
      :tags="tags"
      placeholder="Ajouter des mots clés"
      :autocomplete-items="autocompleteItems"
      @tags-changed="update"
      @focus="disabledKeyEvent"
      @blur="enabledKeyEvent"
    />

    <!--<div style="margin-top: -20px;">
      <md-chip v-for="ba in this.chipsPrev" :key="ba.id" style="background-color: white;">{{ ba.keywords }}</md-chip>
    </div>-->
  </div>
</template>

<script>
import store from "@/store";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "tickets-keywords",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      chips: [],
      chipsPrev: [],

      autocompleteItems: [],
      tag: "",
      tags: [],
    };
  },
  methods: {
    disabledKeyEvent() {
      store.dispatch("keyup/setKeyupAction", false);
    },

    enabledKeyEvent() {
      store.dispatch("keyup/setKeyupAction", true);
    },

    update(newTags) {
      this.tags = newTags;
      this.chips = [];
      var obj = this.getTicket;

      for (let i = 0; i < newTags.length; i++) {
        this.chips.push(newTags[i].text);
      }
      obj.ticketFollowUp.keywords = JSON.stringify(this.chips);
    },

    getKeyWordsTicket(obj) {
      var ar = [];
      this.tag = "";
      if (obj && obj.ticketFollowUp && obj.ticketFollowUp.keywords) {
        let jsbadge = JSON.parse(obj.ticketFollowUp.keywords);
        for (let i = 0; i < jsbadge.length; i++) {
          ar.push({ text: jsbadge[i] });
        }
      }
      this.tags = ar;
    },

    /*clickChips(chips) {
      if (!this.chips.includes(chips.keywords)) {
        this.chips.push(chips.keywords);

        var obj = this.getTicket;
        if (obj && obj.ticketFollowUp) {
          obj.ticketFollowUp.keywords = JSON.stringify(this.chips);
        }
      }
    },

    getIsAssistance() {
      return store.getters["authentification/getIsAssistance"];
    },

    updateChips(val) {
      var ar = this.getKeywords;

      if (val.length != 0) {
        ar = ar.filter((txt) =>
          txt.keywords.toUpperCase().includes(val.toUpperCase())
        );
        ar = ar.slice(0, 10);
        this.chipsPrev = ar;
      } else {
        this.chipsPrev = [];
      }

      var obj = this.getTicket;
      if (obj && obj.ticketFollowUp) {
        obj.ticketFollowUp.keywords = JSON.stringify(this.chips);
      }

      return val;
    },

    setChips(keyword) {
      this.chips = keyword;
    },

    refreshKeyword() {
      this.forceRender = false;
      this.$nextTick(() => {
            this.forceRender = true;
      });
    }*/
  },
  computed: {
    getKeywords() {
      var obj = store.getters["ticketsV2Keywords/getData"];
      if (obj && obj.data && obj.data.rows) {
        return obj.data.rows;
      }
      return [];
    },

    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      this.getKeyWordsTicket(obj);
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
    tag: {
      handler(val) {
        var ar = this.getKeywords;
        this.autocompleteItems = [];
        if (val.length != 0) {
          ar = ar.filter((txt) =>
            txt.keywords.toUpperCase().includes(val.toUpperCase())
          );

          for (let i = 0; i < ar.length; i++) {
            this.autocompleteItems.push({ text: ar[i].keywords });
          }
        }
      },
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
