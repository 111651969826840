<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 19-Jan-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <md-card>
          <div class="md-layout margeTopSearchBar">
            <div class="md-layout-item">
              <md-field>
                <label for="cat">Catégorie</label>
                <md-select v-model="cat" name="Catégorie" id="cat">
                  <md-option value="ud">Utilisateurs Demo</md-option>
                  <md-option value="up">Utilisateurs Prokov 🏆</md-option>
                  <md-option value="uv">Utilisateurs à Valider</md-option>
                  <md-option value="tu">Tous les Utilisateurs</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field>
                <label>Recherche</label>
                <md-input v-on:keyup.enter="onEnter" v-model="query"></md-input>
              </md-field>
            </div>
          </div>
          <md-button class="md-prokov researcheButton" @click="performSearch()"
            >Rechercher</md-button
          >

          <button class="diagCloseButton" @click="clearSearch()">
            <md-icon>delete</md-icon>
          </button>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100"
        style="margin-top: 10px"
        :class="persoSelect ? 'md-size-50' : 'md-size-100'"
      >
        <md-card>
          <md-card-content>
            <users-table table-header-color="prokov"></users-table>
          </md-card-content>
        </md-card>
      </div>

      <div
        v-if="persoSelect"
        class="md-layout-item md-medium-size-100 md-size-50"
        style="margin-top: 10px"
      >
        <edit-profile-form> </edit-profile-form>
      </div>
    </div>

    <div v-if="countResult > 10">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="text-align: center">
            {{ countResult }} correspondances trouvées, seulement 10 sont
            affichés, si vous ne voyez pas votre recherche essayer d'être plus
            précis.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UsersTable, EditProfileForm } from "@/components";

import store from "@/store";

export default {
  components: {
    UsersTable,
    EditProfileForm,
  },
  name: "UsersList",
  data: () => ({
    cat: "tu",
    query: "",
    persoSelect: false,
  }),
  created: function () {
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }

    if (this.$route.query.c) {
      this.cat = this.$route.query.c;
    }

    if (this.$route.query.u) {
      this.persoSelect = true;
    }

    if (this.query.length != 0) {
      var obj = { q: this.query, c: this.cat };
      store.dispatch(
        "usersList/usersList",
        "?q=" +
          encodeURIComponent(this.query) +
          "&c=" +
          encodeURIComponent(this.cat)
      );
    }
  },
  computed: {
    countResult() {
      var res = store.getters["usersList/getData"];
      if (res && res.data && res.data.users && res.data.users.count) {
        return res.data.users.count;
      }
      return 0;
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.u) {
        this.persoSelect = true;
      } else {
        this.persoSelect = false;
      }
    },
  },
  beforeMount() {
    let lastData = store.getters["usersList/getData"];
    if (lastData != null && lastData.data != null) {
      this.cat = lastData.data.cat;
      this.query = lastData.data.search;

      var obj = { q: lastData.data.search, c: lastData.data.cat };
      this.$router.push({ query: Object.assign({}, this.$route.query, obj) });
    }
  },
  methods: {
    onEnter() {
      this.performSearch();
    },

    performSearch() {
      // Détermine si la recherche est entourée de guillemets pour une recherche spéciale
      const isSpecialSearch =
        this.query.startsWith('"') && this.query.endsWith('"');
      let searchQuery = this.query;

      // Si la recherche est spéciale, garde les guillemets pour la requête
      if (!isSpecialSearch) {
        searchQuery = encodeURIComponent(this.query);
      } else {
        searchQuery = this.query;
      }

      const param = "?q=" + searchQuery + "&c=" + encodeURIComponent(this.cat);

      const path = "/users" + param;
      if (this.$route.fullPath !== path) {
        store.dispatch("usersList/usersList", param);
        this.$router.push(path);
      }
    },

    clearSearch() {
      this.query = "";
      this.cat = "tu";
      // Effectue la recherche à nouveau pour réinitialiser les résultats
      this.$router.push({ query: Object.assign({}, "", obj) });
    },
  },
};
</script>

<style lang="scss"></style>
