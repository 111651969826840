<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Users.vue
@Last modified by:   florian
@Last modified time: 01-Sep-2021
-->

<template>
  <div>
    <div class="topSize">
      <div class="full-control leftPanel">
        <tickets-left-panel ref="leftPanel"></tickets-left-panel>
      </div>

      <tickets-search-bar
        ref="searchbar"
        class="searchbar"
      ></tickets-search-bar>
      <div class="md-layout">
        <tickets-liste
          ref="ticketList"
          :class="ticketListClass"
        ></tickets-liste>
        <div class="md-layout-item" v-if="ticketListClass == 'ticketList'">
          <tickets-open class="tickeOpen"></tickets-open>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";
import {
  Tickets_LeftPanel,
  Tickets_SearchBar,
  Tickets_Liste,
  Tickets_Open,
} from "@/components";

export default {
  name: "TicketsV2Filters",
  components: {
    "tickets-left-panel": Tickets_LeftPanel,
    "tickets-search-bar": Tickets_SearchBar,
    "tickets-liste": Tickets_Liste,
    "tickets-open": Tickets_Open,
  },
  data: () => ({
    ticketListClass: "deticketList",
  }),
  computed: {},
  created: function () {},
  methods: {
    selectTicket() {
      this.ticketListClass = "ticketList";
    },

    deSelectTicket() {
      this.ticketListClass = "deticketList";
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 991px) {
  .topSize {
    margin-top: -50px;
    margin-left: -160px;
  }
}

.leftPanel {
  width: 260px;
  float: left;
  margin-left: 15px;
  margin-right: 30px;
}

.searchbar {
  margin-left: 290px;
  margin-right: 15px;
}

.ticketList {
  width: 300px;
  margin-right: 0px;
}

.deticketList {
  width: 100%;
  margin-right: 0px;
}

.tickeOpen {
  margin-top: 10px;
}
</style>
