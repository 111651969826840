<!--
@Author: Florian Merel <florian>
@Date:   02-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Serveurs.vue
@Last modified by:   florian
@Last modified time: 09-Jul-2021
-->

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="blue">
            <h4 class="title">Maintenances programmées</h4>
          </md-card-header>
          <md-card-content>
            <div class="content">
              <div class="md-layout">
                <div class="md-layout-item md-medium-size-100 md-size-100">
                  Aucune maintenance programmée pour le moment.
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25
          "
        >
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">EtherEgo.com</h4>
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  LoadBalancer Roubaix (RBX)

                  <md-chip
                    v-if="etherEgo('145.239.192.64')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>

                  <span style="float: right; margin-right: 20px"
                    >145.239.192.64</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Graveline (GRA7)
                  <md-chip
                    v-if="etherEgo('51.91.140.251')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.91.140.251</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Strasbourg (SBG5)
                  <md-chip
                    v-if="etherEgo('51.68.93.69')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.68.93.69</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Noms de domaine</span
                  >
                  <br />
                  etherego.com, updt.prokov.com, form.prokov.com,
                  info.prokov.com, www.tabulo.com, utilisateurs.prokov.com
                  www.prokov.com, prepa4bio.etherego.com, www.etherego.com,
                  www.medistory.com, www.medistorypouripad.com medistory.com
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Applications</span
                  >

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Site vitrine EtherEgo
                    <md-chip
                      v-if="etherEgo('https://www.etherego.com')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Site vitrine Medistory
                    <md-chip
                      v-if="etherEgo('https://www.medistory.com')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Inscription EtherEgo
                    <md-chip
                      v-if="
                        etherEgo(
                          'https://www.etherego.com/inscription/index.php'
                        )
                      "
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Medistoriels
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Compteurs TLSI
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25
          "
        >
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">ExpEtherEgo</h4>
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  LoadBalancer Strasbourg (SBG)
                  <md-chip
                    v-if="expEtherEgo('137.74.127.171')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >137.74.127.171</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Graveline (GRA7)
                  <md-chip
                    v-if="expEtherEgo('51.210.249.216')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.210.249.216</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Strasbourg (SBG5)
                  <md-chip
                    v-if="expEtherEgo('51.210.158.111')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.210.158.111</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur DB Strasbourg (SBG5)
                  <md-chip
                    v-if="expEtherEgo('51.210.159.117')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.210.159.117</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Noms de domaine</span
                  >
                  <br />
                  g1.expetherego.com
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Applications</span
                  >

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    ProkovServices
                    <md-chip
                      v-if="expEtherEgo('https://g1.expetherego.com/sondes')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    ProkovLicences
                    <md-chip
                      v-if="expEtherEgo('https://g1.expetherego.com/sondes')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Site Communauté MS4
                    <md-chip
                      v-if="expEtherEgo('https://g1.expetherego.com/sondes')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Harmonisation
                    <md-chip
                      v-if="expEtherEgo('Hprim')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25
          "
        >
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">Dl.EtherEgo</h4>
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  LoadBalancer Gravelines (GRA)
                  <md-chip
                    v-if="dlEtherEgo('137.74.127.40')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >137.74.127.40</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Graveline (GRA7)
                  <md-chip
                    v-if="dlEtherEgo('135.125.91.15')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >135.125.91.15</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="md-inset"></md-divider>
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Strasbourg (SBG5)
                  <md-chip
                    v-if="dlEtherEgo('51.210.159.164')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.210.159.164</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Noms de domaine</span
                  >
                  <br />
                  dl.etherego.com
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Applications</span
                  >

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    DataTransfert
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    OnDemandResources
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Gamme40
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Banque de données
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>

        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25
          "
        >
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">Interne</h4>
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                >
                  Serveur Graveline (GRA7)
                  <md-chip
                    v-if="interne('51.210.37.51')"
                    class="baServeurs md-primary"
                  >
                    <span>ON</span>
                  </md-chip>
                  <md-chip v-else class="baServeurs md-danger">
                    <span>OFF</span>
                  </md-chip>
                  <span style="float: right; margin-right: 20px"
                    >51.210.37.51</span
                  >
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Noms de domaine</span
                  >
                  <br />
                  assistanceg1.etherego.com, admin.etherego.com,
                  prokoffre.etherego.com, versions.etherego.com
                </div>

                <div
                  class="
                    md-layout-item
                    md-medium-size-100
                    md-xsmall-size-100
                    md-size-100
                  "
                  style="margin-top: 10px; margin-bottom: 10px"
                >
                  <md-divider class="margin-bottom: 10px;"></md-divider>
                  <br />
                  <span
                    class="md-body-2"
                    style="font-weight: bold; margin-top: 10px"
                    >Applications</span
                  >
                  <br />

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Assistance
                    <md-chip
                      v-if="
                        interne(
                          'https://assistanceg1.etherego.com/assistance/login.xhtml'
                        )
                      "
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Contrôle
                    <md-chip
                      v-if="interne('https://admin.expetherego.com/login')"
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    Versions
                    <md-chip
                      v-if="
                        interne(
                          'https://versions.etherego.com/login.php?logout=true'
                        )
                      "
                      class="baServeurs md-primary"
                    >
                      <span>ON</span>
                    </md-chip>
                    <md-chip v-else class="baServeurs md-danger">
                      <span>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    ProkOffre
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>

                  <div
                    class="
                      md-layout-item
                      md-medium-size-100
                      md-xsmall-size-100
                      md-size-100
                    "
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    <md-divider class="md-inset"></md-divider>
                  </div>

                  <div
                    class="md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    ComEditServer
                    <md-chip
                      v-bind:class="[
                        { 'md-primary': true },
                        { 'md-danger': false },
                      ]"
                      style="float: right; margin-top: -4px"
                    >
                      <span v-if="true">ON</span>
                      <span v-else>OFF</span>
                    </md-chip>
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <md-card>
        <md-card-header data-background-color="blue">
          <h4 class="title">Suivi d'activités</h4>
        </md-card-header>
        <md-card-content>
          <div class="content">
            <div
              class="md-layout"
              v-if="
                items &&
                items.data &&
                items.data.servers &&
                items.data.servers.rows
              "
            >
              <div
                v-for="item in items.data.servers.rows"
                :key="item.id"
                class="md-layout-item md-medium-size-100 md-size-100"
              >
                {{ getDate(item.updatedAt) }}

                <div
                  v-for="txt in getFalse(item)"
                  :key="txt"
                  class="md-layout-item md-medium-size-100 md-size-100"
                >
                  {{ txt }}
                </div>

                <div style="margin-bottom: 10px"></div>
                <md-divider />
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <!--<div class="md-layout-item md-medium-size-100 md-size-100">
        <md-table v-if="items && items.data && items.data.servers && items.data.servers.rows" v-model="items.data.servers.rows">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <h1>{{item.ip}}</h1>
          </md-table-row>
        </md-table>
      </div>-->
    </div>
  </div>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  //components: { ServersTable },
  data: () => ({
    eego: true,
    important: true,
  }),

  created: function () {
    store.dispatch("server/getServer");
  },
  computed: {
    items() {
      return store.getters["server/getData"];
    },
    /*etherEgo: function (target) {
      if (this.items && this.items.data && this.items.data.servers) {
        var obj = JSON.parse(this.items.data.servers.rows[0].EtherEgoCom);
        console.log(obj);
        console.log(target);
        //console.log(this.items.data.servers);
      }
      return true;
    },*/
    /*EtherEgo() {

    },*/
    /*
    apps() {
      if (this.items && this.items.data && this.items.data.apps) {
        return this.items.data.apps;
      }
      return [];
    },
    servers() {
      if (this.items && this.items.data && this.items.data.servers) {
        return this.items.data.servers;
      }
      return [];
    },*/
  },
  methods: {
    etherEgo(target) {
      if (this.items && this.items.data && this.items.data.servers) {
        var obj = JSON.parse(this.items.data.servers.rows[0].EtherEgoCom);
        let fd = obj.find((elm) => elm.ip == target || elm.url == target);
        if (fd) {
          return fd.alive;
        }
      }
      return true;
    },

    expEtherEgo(target) {
      if (this.items && this.items.data && this.items.data.servers) {
        var obj = JSON.parse(this.items.data.servers.rows[0].ExpEtherEgo);
        let fd = obj.find((elm) => elm.ip == target || elm.url == target);
        if (fd) {
          return fd.alive;
        }
      }
      return true;
    },

    dlEtherEgo(target) {
      if (this.items && this.items.data && this.items.data.servers) {
        var obj = JSON.parse(this.items.data.servers.rows[0].DlEtherEgo);
        let fd = obj.find((elm) => elm.ip == target || elm.url == target);
        if (fd) {
          return fd.alive;
        }
      }
      return true;
    },

    interne(target) {
      if (this.items && this.items.data && this.items.data.servers) {
        var obj = JSON.parse(this.items.data.servers.rows[0].Interne);
        let fd = obj.find((elm) => elm.ip == target || elm.url == target);
        if (fd) {
          return fd.alive;
        }
      }
      return true;
    },

    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    getFalse(item) {
      var list = [
        ["Interne", item.Interne],
        ["ExpEtherEgo", item.ExpEtherEgo],
        ["DlEtherEgo", item.DlEtherEgo],
        ["EtherEgoCom", item.EtherEgoCom],
      ];
      var txt = [];

      list.forEach(function (t, index, array) {
        var ar = JSON.parse(t[1]);
        ar.forEach(function (tar, index, array) {
          var t = "OFF - " + (tar.ip ? tar.ip : tar.url);
          if (!tar.alive && txt.indexOf(t) === -1) {
            txt.push(t);
          }
        });
      });
      if (txt.length == 0) {
        txt.push("Rien à signaler");
      }
      return txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.baServeurs {
  float: right;
  margin-top: -4px;
}
</style>
