/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 01-Sep-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  residents: null,
  residentsID: {},
  msp: null,
  orderform: null,
};

const getters = {
  getData(context) {
    return context.data;
  },

  getResidents(context) {
    return context.residents;
  },

  getResidentID(context) {
    return context.residentsID;
  },

  getMSP(context) {
    return context.msp;
  },

  getOrderForm(context) {
    return context.orderform;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setResidents(context, residents) {
    context.residents = residents;
  },

  setResidentID(state, data) {
    state.residentsID = data;
  },

  setMsp(context, msp) {
    context.msp = msp;
  },

  setOrderform(context, orderform) {
    context.orderform = orderform;
    /*if (context.orderform == null) {
      context.orderform = {}
    }
    
    if (orderform.type) {
      context.orderform[orderform.type] = orderform;
    } else {
      context.orderform["all"] = orderform;
    }*/
  },
};

const actions = {
  async getResidentSegur(context, search) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.segur + "/residents",
        headers: server.getHeader(),
        data: search,
      });

      context.commit("setResponse", res);
      context.commit("setResidents", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getResidentSegurID(context, search) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.segur + "/residents/" + search,
        headers: server.getHeader(),
      });

      context.commit("setResponse", res);
      context.commit("setResidentID", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getMSPSegur(context, search) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.segur + "/msp",
        headers: server.getHeader(),
        data: search,
      });

      context.commit("setResponse", res);
      context.commit("setMsp", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getOrderformSegur(context, search) {
    context.commit("setLoading", true);
    context.commit("setOrderform", null);
    try {
      let res = await axios({
        method: "POST",
        url: server.url + server.urls.segur + "/orderform",
        headers: server.getHeader(),
        data: search,
      });

      context.commit("setResponse", res);
      context.commit("setOrderform", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  async getFiles(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/orderform/" + id,
        headers: server.getHeader(),
      });
      return res;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    return null;
    context.commit("setLoading", false);
  },

  async donwloadBDC(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/download/bdc/" + id,
        headers: server.getHeader(),
      });
      return res;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    return null;
    context.commit("setLoading", false);
  },

  async downloadFAC(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/download/fac/" + id,
        headers: server.getHeader(),
      });
      return res;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    return null;
    context.commit("setLoading", false);
  },

  async downloadAP(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.segur + "/download/va/" + id,
        headers: server.getHeader(),
      });
      return res;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    return null;
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
