<!--
@Author: Florian Merel <florian>
@Date:   10-Jun-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Dashboard.vue
@Last modified by:   florian
@Last modified time: 01-Dec-2020
-->

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <md-card>
          <md-card-content>
            <users-inscription-table></users-inscription-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { UsersInscriptionTable } from "@/components";

export default {
  components: {
    UsersInscriptionTable,
  },
};
</script>
