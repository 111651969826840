<!--
@Author: Florian Merel <florian>
@Date:   04-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: ProfessionField.vue
@Last modified by:   florian
@Last modified time: 10-Nov-2020
-->

<template>
  <md-field>
    <label for="prospec">Spécialité</label>
    <md-select v-model="myProf">
      <md-option value="G05_A">Pharmacien - Titulaire de pharmacie</md-option>
      <md-option value="G05_B"
        >Pharmacien - Fabricant, importateur ou exploitant</md-option
      >
      <md-option value="G05_C">Pharmacien - Distribution en gros</md-option>
      <md-option value="G05_DA">Pharmacien Assistant</md-option>
      <md-option value="G05_H"
        >Pharmacien d'un établissement de soins</md-option
      >
      <md-option value="G05_DM">Pharmacien mutualiste ou minière</md-option>
      <md-option value="G05_G">Pharmacien biologiste</md-option>
    </md-select>
  </md-field>
</template>

<script>
import store from "@/store";

export default {
  name: "profession-spec-pharma-field",
  //props: ['profession'],
  props: {
    profession: {
      type: String,
      default: "G05_A",
    },
  },
  data() {
    return {
      prof: null,
    };
  },
  computed: {
    myProf: {
      get() {
        return this.profession;
      },
      set(newValue) {
        this.prof = newValue;
        store.commit("user/setObj", { spec: newValue });
      },
    },
  },
};
</script>
<style></style>
