<!--
@Author: Florian Merel <florian>
@Date:   26-Oct-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: App.vue
@Last modified by:   florian
@Last modified time: 09-Jul-2021
-->

<template>
  <v-app style="background-color: #eeeeee">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {};
</script>
