<!--
@Author: Florian Merel <florian>
@Date:   03-Nov-2020
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: EditProfilPerso.vue
@Last modified by:   florian
@Last modified time: 15-Jun-2021
-->

<template>
  <div class="md-layout">
    <div class="md-layout" v-if="obj.validation">
      <label
        >Référence validation : {{ obj.validation.code }} (validité :
        {{ getDate(obj.validation.validity) }}).</label
      >
      <label
        >Lien : https://www.etherego.com/inscription/validAccount.php?code={{
          obj.validation.code
        }}</label
      >

      <div class="md-layout-item md-size-100 text-right">
        <md-button class="md-raised md-prokov" @click="resendMail()"
          >Renvoyer le mail d'invitation</md-button
        >
      </div>
    </div>
    <div class="md-layout" v-if="!obj.validation">
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Titre</label>
          <md-input v-model="obj.title" type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label for="movie">Civilité</label>
          <md-select v-model="obj.sex">
            <md-option value="1">M.</md-option>
            <md-option value="2">Mme.</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label for="movie">Type</label>
          <md-select v-model="obj.kind">
            <md-option value="0">Classique</md-option>
            <md-option value="1">Demo</md-option>
            <md-option value="2">Système</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-5O">
        <md-field>
          <label>Nom</label>
          <md-input v-model="obj.familyname" type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Prénom</label>
          <md-input v-model="obj.givenname" type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-datepicker v-model="obj.birthdate" md-immediately>
          <label>Date de naissance</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Téléphone de confiance</label>
          <md-input v-model="obj.mobilePhone" type="number"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-40">
        <md-field>
          <label>Adresse</label>
          <md-input v-model="obj.address" type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-40">
        <md-field>
          <label>Ville</label>
          <md-input v-model="obj.city" type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Code Postal</label>
          <md-input v-model="obj.zip" type="number"></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100">
        <pays-field :pays="obj.country"></pays-field>
      </div>

      <div class="md-layout-item md-size-100 text-right">
        <md-button class="md-raised md-prokov" @click="enregistrerValue()"
          >Enregistrer</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { PaysField } from "@/components";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "edit-profile-perso",
  components: {
    PaysField,
  },
  watch: {
    $route(to, from) {
      this.callValue();
    },
  },
  beforeMount() {
    this.callValue();
  },
  methods: {
    callValue() {
      if (this.$route.query.u) {
        store.dispatch("user/service", "/" + this.$route.query.u);
      }
    },
    enregistrerValue() {
      //this.notifyVue('top', 'right');
      store.dispatch("user/servicePut", {
        url: "/" + this.$route.query.u,
        obj: this.obj,
      });
    },
    getDate(date) {
      return toolsFormat.getDateLong(date);
    },
    async resendMail() {
      const res = await store.dispatch("usersInscription/reSendinscription", {
        id: this.obj.validation.userId,
      });
    },
  },
  computed: {
    obj: {
      get() {
        var x = store.getters["user/getData"];
        if (x && x.data && x.data.user) {
          return x.data.user;
        }
        return {};
      },
    },
  },
};
</script>

<style lang="scss"></style>
