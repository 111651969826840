<!--
@Author: Florian Merel <florian>
@Date:   08-Mar-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Technician.vue
@Last modified by:   florian
@Last modified time: 16-Jun-2021
-->

<template>
  <div class="md-layout margeTopSearchBar">
    <div class="md-layout-item md-xsmall-size-100 md-size-80">
      <md-field>
        <label>Recherche</label>
        <md-input v-on:keyup.enter="getMember" v-model="query"></md-input>
      </md-field>
    </div>
    <div
      class="md-layout-item md-xsmall-size-100 md-size-20"
      style="margin-top: 20px"
    >
      <md-button class="md-info researcheButton" @click="getMember()"
        >Rechercher</md-button
      >
    </div>

    <md-card>
      <div class="md-subheading titleTables">Techniciens</div>
      <md-divider></md-divider>
      <member-table
        v-on:passData="search"
        :dataArray="memberData"
      ></member-table>
    </md-card>
  </div>
</template>

<script>
import store from "@/store";
import MemberTable from "./MemberTable.vue";

export default {
  components: {
    MemberTable,
  },
  name: "technician",
  data() {
    return {
      selected: [],
      query: "",
      lastquery: null,
    };
  },
  methods: {
    search: function (data) {
      this.query = data;
      this.getMember();
    },

    getMember() {
      var q = "";
      if (this.query.length > 0) {
        q = "?q=" + this.query;
      }
      if (this.lastquery != q) {
        store.dispatch("providers/getMember", q);
        this.lastquery = q;
      }
    },
  },
  beforeMount() {
    this.getMember();
  },
  computed: {
    memberData() {
      var cp = store.getters["providers/getMemberData"];
      if (cp && cp.data && cp.data.member && cp.data.member.rows) {
        return cp.data.member.rows;
      }
      return [];
    },
  },
};
</script>
