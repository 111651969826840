<!--
@Author: Florian Merel <florian>
@Date:   22-Sep-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: parkmanagement.vue
@Last modified by:   florian
@Last modified time: 22-Sep-2021
-->

<template>
  <body>
    <iframe
      src="https://versions.etherego.com/"
      frameborder="0"
      style="overflow: hidden; height: 100%; width: 100%"
      height="100%"
      width="100%"
    ></iframe
    >-->
  </body>
</template>

<script>
import store from "@/store";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  name: "Versions",
  data() {
    return {};
  },
  created: function () {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
iframe {
  position: fixed;
  background: rgb(219, 219, 219);
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
