/**
 * @Author: Florian Merel <florian>
 * @Date:   13-Jul-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: vuetify.js
 * @Last modified by:   florian
 * @Last modified time: 13-Jul-2021
 */

import Vuetify from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        prokov: "#e2007a", // #3F51B5
      },
    },
  },
});
