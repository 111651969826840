/**
 * @Author: Florian Merel <florian>
 * @Date:   17-Feb-2021
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: login.js
 * @Last modified by:   florian
 * @Last modified time: 23-Feb-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  data: {},
};

const getters = {
  getData(context) {
    return context.data;
  },
};

const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },
};

const actions = {
  async getAdmins(context) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.admin,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data);
      return res.data;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
