/**
 * @Author: Florian Merel <florian>
 * @Date:   06-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: ticketsType.js
 * @Last modified by:   florian
 * @Last modified time: 24-Nov-2020
 */

var ticketsTypes = {
  22: "Action",
  13: "Actualités",
  14: "Banque",
  15: "Corbeille",
  19: "Dotation",
  32: "Efficience",
  26: "État",
  12: "Formulaire",
  25: "Formulaire de laboratoire",
  29: "Formulaire de synthèse",
  9: "Formule texte",
  10: "Formule numérique",
  11: "Formule date",
  21: "Glossaire",
  35: "Kit",
  20: "Papeterie",
  30: "Problème",
  33: "Questionnaire",
  31: "Révélateur",
  34: "Recherche",
  24: "Suivi",
  23: "Tâche",
  27: "Texte d'aide",
  28: "Texte action",
};

export default {
  ticketsTypes,
};
