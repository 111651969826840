<!--
@Author: Florian Merel <florian>
@Date:   03-Feb-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Logs.vue
@Last modified by:   florian
@Last modified time: 23-Feb-2021
-->

<template>
  <div class="content">
    <div class="content">
      <div class="md-layout">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33
          "
        ></div>

        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33
          "
        ></div>

        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33
          "
        ></div>

        <div
          v-if="getData && getData.data && getData.data.smsMode"
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="blue">
            <template slot="header">
              <md-icon>sms</md-icon>
            </template>

            <template slot="content">
              <p class="category">SMS Envoyés ce mois-ci</p>
              <h3 class="title">{{ getData.data.smsMode * -1 }}</h3>
            </template>

            <template slot="footer">
              <div class="stats">
                <md-icon>local_offer</md-icon>
                Suivi depuis smsmode
              </div>
            </template>
          </stats-card>
        </div>

        <!--<div
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="blue">
            <template slot="header">
              <md-icon>sms</md-icon>
            </template>

            <template slot="content">
              <p class="category">SMS Envoyés ce mois-ci</p>
              <h3 class="title">75</h3>
            </template>

            <template slot="footer">
              <div class="stats">
                <md-icon>local_offer</md-icon>
                Suivi depuis smsmode
              </div>
            </template>
          </stats-card>
        </div>

        <div
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="blue">
            <template slot="header">
              <md-icon>sms</md-icon>
            </template>

            <template slot="content">
              <p class="category">SMS Envoyés</p>
              <h3 class="title">75</h3>
            </template>

            <template slot="footer">
              <div class="stats">
                <md-icon>local_offer</md-icon>
                Suivi depuis smsmode
              </div>
            </template>
          </stats-card>
        </div>

        <div
          class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="blue">
            <template slot="header">
              <md-icon>sms</md-icon>
            </template>

            <template slot="content">
              <p class="category">SMS Envoyés</p>
              <h3 class="title">75</h3>
            </template>

            <template slot="footer">
              <div class="stats">
                <md-icon>local_offer</md-icon>
                Suivi depuis smsmode
              </div>
            </template>
          </stats-card>
        </div>-->

        <div
          v-if="getData && getData.data && getData.data.admin"
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
          "
        >
          <md-card>
            <md-card-header data-background-color="prokov">
              <h4 class="title">Utilisateurs</h4>
              <p class="category">listes des utilisateurs et leurs droits</p>
            </md-card-header>
            <md-card-content>
              <md-table v-model="getData.data.admin">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Login">{{
                    item.login
                  }}</md-table-cell>
                  <md-table-cell md-label="Dernière connexion">{{
                    getDate(item.lastConnection)
                  }}</md-table-cell>
                  <md-table-cell md-label="Droits">{{
                    getDroits(item.droits)
                  }}</md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </div>

        <!--<div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title">Logs important</h4>
              <p class="category">Logs des modifications des utilisateurs</p>
            </md-card-header>
            <md-card-content>
              <ordered-table table-header-color="blue"></ordered-table>
            </md-card-content>
          </md-card>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

import { StatsCard } from "@/components";
import toolsFormat from "@/tools/toolsFormat.js";

export default {
  components: {
    StatsCard,
  },
  data() {
    return {};
  },
  created: async function () {
    store.dispatch("admin/getAdmins");
  },
  computed: {
    getData() {
      let x = store.getters["admin/getData"];
      return x;
    },
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    getDroits(dr) {
      var arDr = [
        ["CONTROL_SERVER", "Serveurs"],
        ["CONTROL_ANALITICS", "Analytics"],
        ["CONTROL_USERS", "Utilisateurs"],
        ["CONTROL_USERS_INSCRIPTION", "Inscription En Cours"],
        ["CONTROL_LICENCES", "Licences"],
        ["CONTROL_TICKETS", "Tickets"],
        ["CONTROL_SEGUR", "Segur"],
      ];
      var droits_text = "";
      for (let p = 0; p < arDr.length; p++) {
        if (dr.includes(arDr[p][0])) {
          droits_text += arDr[p][1] + ", ";
        }
      }
      return droits_text.slice(0, -2);
    },
  },
};
</script>

<!--
v-if="getRules('CONTROL_SERVER')"
v-if="getRules('CONTROL_ANALITICS')"
  v-if="getRules('CONTROL_USERS')"
  v-if="getRules('CONTROL_USERS_INSCRIPTION')"
getRules('CONTROL_LICENCES')"
getRules('CONTROL_TICKETS')"
-->

<style lang="scss" scoped></style>
